import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import { twoOrMoreDecimals } from 'utils/helpers';

const useStyles = makeStyles({
  root: {},
  bold: {
    fontWeight: 'bold',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 12,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',    

    '& thead tr': {
      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& tbody tr': {
      '&:not(.no-hover):hover': {
        backgroundColor: '#d9d9d9',
        cursor: 'pointer',
      },

      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& input[type="number"], input[type="text"]': {
      textAlign: 'right',
      border: '1px solid #316ac5',
      borderRadius: '4px',
      margin: '2px 0px',

      '&[disabled]': {
        border: '1px solid #cccccc',
      }
    },

    '& input[type="text"]': {
      borderColor: '#c4c4c4',
      padding: '6px 6px 7px',
      color: '#12161b',
      textAlign: 'left',
    },
  },
}); 

const NormPriceComposition = ({ item, hideAdditionals }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderTotalsUnit = (value) => {
    switch (value) {
      case 'percent':
        return '%'
      
      case 'coef':
        return t('koef.');

      case 'amount':
        return t('suma');

      default:
        return '-';
    }
  }

  if (!_.get(item, 'totals')) return (
    <Typography
      variant='h3'
      style={{ marginTop: '15px', textAlign: 'center' }}
    >
      {t('Nėra kainos sudėties duomenų')}
    </Typography>
  );

  return (
    <table className={classes.table}>
      <thead>
        <tr className="no-hover">
          <th rowSpan={2}>{t('Pavadinimas')}</th>
          <th rowSpan={2} style={{ width: '16%' }}>{t('Suma')}, EUR <br /> <span style={{ fontWeight: 'normal' }}>({t('be priskaitymų')})</span></th>
          {!hideAdditionals && <th colSpan={3}>{t('Papildomi priskaitymai')}</th>}
          <th rowSpan={2} style={{ width: '16%' }}>{t('Suma')}, EUR <br /> <span style={{ fontWeight: 'normal' }}>({t('su priskaitymais')})</span></th>
        </tr>
        {!hideAdditionals && (
          <tr className="no-hover">
            <th style={{ width: '8%' }}>{t('Matas')}</th>
            <th style={{ width: '15%' }}>{t('Rodiklio reikšmė')}</th>
            <th style={{ width: '16%' }}>{t('Kaina, EUR')}</th>
          </tr>
        )}
      </thead>
      <tbody>
        {/* Darbo užmokestis */}
        <tr>
          <td>{t('Darbo užmokestis')}</td>
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.beforePduAmount)}
          </td>
          {!hideAdditionals && (
            <>
              <td className={classes.textCenter}>{renderTotalsUnit(item.totals.pdu.unit)}</td>
              <td className={classes.textRight}>
                K8 - {twoOrMoreDecimals(item.totals.k8.norm)} <br />
                K9 - {twoOrMoreDecimals(item.totals.k9.norm)} <br />
                PDU - {twoOrMoreDecimals(item.totals.pdu.norm)}
              </td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(item.totals.k8.price)} <br />
                {twoOrMoreDecimals(item.totals.k9.price)} <br />
                {twoOrMoreDecimals(item.totals.pdu.price)}
              </td>
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.pdu.amount)}
          </td>
        </tr>
        {/* Medžiagos */}
        <tr>
          <td>{t('Medžiagos')}</td>
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.beforePmedAmount)}
          </td>
          {!hideAdditionals && (
            <>
              <td className={classes.textCenter}>{renderTotalsUnit(item.totals.pmed.unit)}</td>
              <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pmed.norm)}</td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(item.totals.pmed.price)}
              </td>
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.pmed.amount)}
          </td>
        </tr>
        {/* Mechanizmų eksploatacija */}
        <tr>
          <td>{t('Mechanizmų eksploatacija')}</td>
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.beforePmechAmount)}
          </td>
          {!hideAdditionals && (
            <>
              <td className={classes.textCenter}>{renderTotalsUnit(item.totals.pmech.unit)}</td>
              <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pmech.norm)}</td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(item.totals.pmech.price)}
              </td>
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.pmech.amount)}
          </td>
        </tr>
        {/* Soc. draudmas */}
        <tr>
          <td>{t('Soc. draudmas')}</td>
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.beforeSdAmount)}
          </td>
          {!hideAdditionals && (
            <>
              <td className={classes.textCenter}>{renderTotalsUnit(item.totals.sd.unit)}</td>
              <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.sd.norm)}</td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(item.totals.sd.price)}
              </td>
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.sd.amount)}
          </td>
        </tr>
        {/* TOTAL Statybos išlaidos */}
        <tr className={classes.bold}>
          <td>{t('Statybos išlaidos')}</td>
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.beforeAmount)}
          </td>
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.amountConstExp)}
          </td>
        </tr>
        {/* Statybvietės išlaidos */}
        <tr>
          <td>{t('Statybvietės išlaidos')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td className={classes.textCenter}>{renderTotalsUnit(item.totals.sti.unit)}</td>
              <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.sti.norm)}</td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(item.totals.sti.price)}
              </td>
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.sti.amount)}
          </td>
        </tr>
        {/* TOTAL Viso tiesioginės išlaidos */}
        <tr className={classes.bold}>
          <td>{t('Viso tiesioginės išlaidos')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.amountDirectExp)}
          </td>
        </tr>
        {/* Pridėtinės išlaidos */}
        <tr>
          <td>{t('Pridėtinės išlaidos')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td className={classes.textCenter}>{renderTotalsUnit(item.totals.pr.unit)}</td>
              <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pr.norm)}</td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(item.totals.pr.price)}
              </td>
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.pr.amount)}
          </td>
        </tr>
        {/* Pelnas */}
        <tr>
          <td>{t('Pelnas')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td className={classes.textCenter}>{renderTotalsUnit(item.totals.p.unit)}</td>
              <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.p.norm)}</td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(item.totals.p.price)}
              </td>
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.p.amount)}
          </td>
        </tr>
        {/* TOTAL Viso netiesioginės išlaidos */}
        <tr className={classes.bold}>
          <td>{t('Viso netiesioginės išlaidos')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.amountIndirectExp)}
          </td>
        </tr>
        {/* TOTAL Nedetalizuota kainos dalis */}
        <tr className={classes.bold}>
          <td>{t('Nedetalizuota kainos dalis')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.nonDetailedAmount)}
          </td>
        </tr>
        {/* TOTAL Viso statybos kaina be PVM */}
        <tr className={classes.bold}>
          <td className={classes.textRight}>{t('Viso statybos kaina be PVM')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.constructionAmount)}
          </td>
        </tr>
        {/* PVM Statybos kainai*/}
        <tr className={classes.bold}>
          <td className={classes.textRight}>{t('PVM')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td className={classes.textCenter}>{renderTotalsUnit(item.totals.pvm.unit)}</td>
              <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pvm.norm)}</td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(item.totals.constructionVat)}
              </td>
            </>
          )}
          <td className={[classes.textRight, classes.bold].join(' ')}>
            {twoOrMoreDecimals(item.totals.constructionVat)}
          </td>
        </tr>
        {/* TOTAL Viso statybos kaina su PVM */}
        <tr className={classes.bold}>
          <td className={classes.textRight}>{t('Viso statybos kaina su PVM')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.constructionTotalAmount)}
          </td>
        </tr>
        {/* TOTAL Įrenginių kaina be PVM */}
        <tr className={classes.bold}>
          <td className={classes.textRight}>{t('Įrenginių kaina be PVM')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.equipmentAmount)}
          </td>
        </tr>
        {/* PVM Statybos kainai*/}
        <tr className={classes.bold}>
          <td  className={classes.textRight}>{t('PVM')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td className={classes.textCenter}>{renderTotalsUnit(item.totals.pvm.unit)}</td>
              <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pvm.norm)}</td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(item.totals.equipmentVat)}
              </td>
            </>
          )}
          <td className={[classes.textRight, classes.bold].join(' ')}>
            {twoOrMoreDecimals(item.totals.equipmentVat)}
          </td>
        </tr>
        {/* TOTAL Įrenginių kaina su PVM */}
        <tr className={classes.bold}>
          <td className={classes.textRight}>{t('Įrenginių kaina su PVM')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.equipmentTotalAmount)}
          </td>
        </tr>
        {/* TOTAL Viso kaina */}
        <tr className={classes.bold}>
          <td>{t('Viso kaina be PVM')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.amountPrice)}
          </td>
        </tr>
        {/* PVM */}
        <tr className={classes.bold}>
          <td>{t('PVM')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.pvm.amount)}
          </td>
        </tr>
        {/* TOTAL Viso kaina su PVM */}
        <tr className={classes.bold}>
          <td>{t('Viso kaina su PVM')}</td>
          <td />
          {!hideAdditionals && (
            <>
              <td />
              <td />
              <td />
            </>
          )}
          <td className={classes.textRight}>
            {twoOrMoreDecimals(item.totals.amountPriceVat)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default NormPriceComposition;