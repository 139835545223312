import React, { Component } from 'react';
import Container from './Container';
import store from './_store';

class AccountList extends Component {

  render() {
    return <Container store={store} {...this.props} />
  }
}

export default AccountList;