import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/projects';

export const list = async (params) => {
  const query = objectToQueryString(params);
  const { data } = await api.get(`${servicePath}${query ? '?' + query : ''}`);
    
  return data;
}

export const show = async (id) => {
  const { data } = await api.get(`${servicePath}/${id}`);
    
  return data;
}

export const create = async (details) => {
  const { data } = await api.post(servicePath, details);

  return data;
}

export const update = async (id, details) => {
  const { data } = await api.put(`${servicePath}/${id}`, details);

  return data;
}

export const remove = async (id) => {
  return await api.delete(`${servicePath}/${id}`);
}