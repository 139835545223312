import { observable, action, decorate } from 'mobx';
import _ from 'lodash';
import {
  getTree,
  getPackages,
  getDetails,
  getPackageCodesByType,
} from 'services/norms';
import { scrollToElement, blinkElement } from 'utils/helpers';
import * as normsTreeService from '../../_services';

class Store {
  type = 'viewer'

  // Observable
  typeToExpand = null
  
  // Observable
  data = []

  // Observable
  loading = false

  // Observable
  version = null

  // Observable
  lastExpandedCodePath = null

  // Action
  reset = () => {
    this.data = [];
    this.loading = false;
    this.typeToExpand = null;
    this.version = null;
    this.lastExpandedCodePath = null;
  }

  // Action
  setData = data => {
    this.data = data;
  }

  // Action
  setTypeToExpand = type => {
    this.typeToExpand = type;
  }

  // Action
  setLoading = loading => {
    this.loading = loading;
  }

  // Action
  setLastExpandedCodePath = lastExpandedCodePath => {
    this.lastExpandedCodePath = lastExpandedCodePath;
  }

  // Action
  setVersion = version => {
    this.version = version;
    if (version) {
      this.loadData();
    }
  }

  loadData = async () => {
    this.setLoading(true);

    // const version = this.version.code;
    // const data = await getPackages({ version });
    const data = await getPackages({ type: this.type });

    this.setData(data);

    if (this.lastExpandedCodePath) {
      try {
        this.expandPath(this.lastExpandedCodePath.split(';'));
      } catch (err) {
        console.log('Cannot expand code path');
      }
    }

    if (this.typeToExpand) {
      await this.expandByType(this.typeToExpand);
    }

    this.setLoading(false);
  }

  // Action
  handleExpand = item => {

    item.expanded = !item.expanded;

    if (!item.expanded) {
      this.setLastExpandedCodePath(null);
    } else {
      this.setLastExpandedCodePath(item.codePath);
    }

    if (!item.expanded) {
      return;
    }

    this.loadNext(item);
  }

  loadNext = async (item) => {
    if (item.type && item.type === 'package') {
      return;
    }

    this.setLoading(true);

    const data = await getTree({ code: item.code, version: this.version.code });
    const hasChildren = data.length > 0;
    item.hasChildren = hasChildren;

    if (hasChildren) {
      const children = _.map(data, row => ({ ...row, parentTitle: item.title }));
      item.children = children;
    } else {
      this.loadDetails(item);
    }

    this.setLoading(false);
  }

  // Action
  handleParameterExpand = item => {
    item.expanded = !item.expanded;
  }

  // Action
  updateItemWithDetails = (item, details) => {
    item.details = details;
  }

  loadDetails = async (item) => {
    this.setLoading(true);

    let details = await getDetails({ code: item.code, version: this.version.code, type: this.type });
    let title = `${item.title} (${item.unit})`;
    
    if (item.code.indexOf('#') > 0) {
      title = `${item.parentTitle} ${title}`;
    }

    title = `${item.code} ${title}`;

    details['title'] = title;
    details['details'] = item.description;

    this.updateItemWithDetails(item, details);

    this.setLastExpandedCodePath(details.codePath);

    this.setLoading(false);
  }

  expandPath = async (codes) => {
    this.setLoading(true);

    // Build collection - package dictionary for faster search
    let collectionDict = {};
    _.forEach(this.data, item => {
      if (item.type === 'package') {
        _.forEach(item.children, child => {
          if (!collectionDict[child.code]) {
            collectionDict[child.code] = item;
          }
        });
      }
    });
    
    let rootItem = null;
    let loop = true;
    let codesToLoop = codes;

    // Sequentionally loop through codes and expand branches
    while (loop) {
      const code = _.first(codesToLoop);
      if (!rootItem) {
        rootItem = collectionDict[code];
      }

      const child = _.find(rootItem.children, { code });
      rootItem.expanded = true;
      if (child) {
        await this.loadNext(child);
        rootItem = child;
        rootItem.expanded = true;
      }

      if (codesToLoop.length > 1) {
        codesToLoop = _.slice(codesToLoop, 1);
      } else {
        loop = false;
        
        const el = document.querySelector(`.code-${code.replace('#', '')}`);
        if (el) {
          scrollToElement(el);
          blinkElement(el);
        }
      }
    }

    this.setLoading(false);
  }

  expandByType = async (type) => {
    if (!type) return;

    try {
      const codes = await getPackageCodesByType(type);
      if (!codes.length) return;

      // Expand top level items only
      codes.forEach(code => {
        const item = this.data.find(d => d.code === code);
        if (!item) return;

        item.expanded = true;
      })
    } catch (err) {
      console.log('Cannot expand by type');
    }
  }

  getLayoutTitle = () => {
    switch (this.typeToExpand) {
      case 'pbw':
        return 'Sustambintų darbų kainynas';
      case 'prw':
        return 'Renovacijos darbų kainynas';
      default:
        return '-';
    }
  }

  downloadCollection = async (code, version) => {
    this.setLoading(true);
    let response;

    try {
      response = normsTreeService.downloadCollection(code, version);
    } catch (err) {
      console.log(err);
    }

    this.setLoading(false);
    return response;
  }
}

decorate(Store, {
  data: observable,
  loading: observable,
  version: observable,
  typeToExpand: observable,
  lastExpandedCodePath: observable,
  reset: action,
  setData: action,
  setLoading: action,
  setTypeToExpand: action,
  setVersion: action,
  setLastExpandedCodePath: action,
  handleExpand: action,
  handleParameterExpand: action,
  updateItemWithDetails: action,
});

export default new Store;