import { observable, action, decorate } from 'mobx';
// import _ from 'lodash';
import {
  getDetails,
} from 'services/accounts';

const DEFAULT_RECORD = {};

class Store {
  record = { ...DEFAULT_RECORD }

  // Action
  reset = () => {
    this.record = { ...DEFAULT_RECORD };
  }

  // Action
  setRecord = record => {
    this.record = record;
  }

  loadData = async (accountId) => {
    const record = await getDetails(accountId);
    
    this.setRecord(record);
  }
}

decorate(Store, {
  record: observable,
  setRecord: action,
  reset: action,
});

export default new Store;