import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import { twoDecimals } from 'utils/helpers';
import styles from './styles';

class PricingTable extends React.Component {
  
  render() {
    const { classes, item, store, t } = this.props;
    const { settings } = store;

    return (
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>{t('Terminas')}</TableCell>
              {_.map(settings.services.pricingRange, ({ months }) => (
                <TableCell
                  key={`months-${months}`}
                  style={{ textAlign: 'center' }}
                >
                  {months} {t('mėn.')}
                </TableCell>
              ))}
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>{t('Nuolaida')}</TableCell>
              {_.map(settings.services.pricingRange, ({ months, discountPercent }) => (
                <TableCell
                  key={`discount-${months}`}
                  style={{ textAlign: 'center' }}
                >
                  {discountPercent || 0} %
                </TableCell>
              ))}
            </TableRow>
            
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>{t('Kaina')}, EUR</TableCell>
              {_.map(settings.services.pricingRange, ({ months }) => {
                const found = item && item.prices 
                  ? _.find(item.prices, { months }) 
                  : null;

                return (
                  <TableCell
                    key={`prices-${months}`}
                    style={{ textAlign: 'center' }}
                  >
                    {found ? twoDecimals(found.price) : '-'}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default compose(withStyles(styles), inject('store'), withTranslation())(observer(PricingTable));