import {
  SENDING_REQUEST,
  FAILURE,
  GET_TREE_SUCCESS,
} from './_constants';

const initialState = {
  isLoading: false,
  tree: null,
};

export const treeTemplatesForm = (state = initialState, action) => {
  switch (action.type) {
    
    case SENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_TREE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tree: action.tree,
      };

    case FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state;
  }
}