export default theme => ({
  root: {},
  noData: {
    padding: theme.spacing(2),
  },
  table: {
    // width: '100%',
    // overflowX: 'scroll',
    
    '& tr': {
      height: 'auto',
    }
  },
  rowSeparator: {
    backgroundColor: '#d9d9d9',

    '& td': {
      fontWeight: 'bold',
    }
  },
  columns: {
    // width: 250,
  },
});
