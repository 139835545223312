import userConstants from './_constants';

const initialState = {
  // Shared props
  isLoading: false,
  error: null,

  //  User list
  list: {
    limit: 10,
    users: [],
    selectedUsers: [],
  },

  // For single user
  single: {
    user: null,
  }
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.UPDATE_REQUEST:
    case userConstants.CREATE_REQUEST:
    case userConstants.GETONE_REQUEST:
    case userConstants.GETALL_REQUEST:
    case userConstants.HARD_DELETE_REQUEST:
    case userConstants.DELETE_TRIAL_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: {
          ...state.list,
          users: action.users,
        }
      };
    case userConstants.CREATE_FAILURE:
    case userConstants.GETONE_FAILURE:
    case userConstants.GETALL_FAILURE:
    case userConstants.UPDATE_FAILURE:
    case userConstants.HARD_DELETE_FAILURE:
    case userConstants.DELETE_TRIAL_ORDERS_FAILURE:
      return { 
        ...state,
        isLoading: false,
        error: action.error,
      };
    case userConstants.GETONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        single: {
          ...state.single,
          user: action.user,
        }
      }
    case userConstants.CREATE_SUCCESS:
    case userConstants.UPDATE_SUCCESS:
    case userConstants.HARD_DELETE_SUCCESS:
    case userConstants.DELETE_TRIAL_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case userConstants.RESET_FORM:
      return {
        ...state,
        isLoading: false,
        single: {
          ...initialState.single,
        }
      }
    default:
      return state
  }
}