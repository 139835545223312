const constants = {
    GETALL_REQUEST: 'PERMISSIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PERMISSIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PERMISSIONS_GETALL_FAILURE',
  
    GETONE_REQUEST: 'PERMISSIONS_GETONE_REQUEST',
    GETONE_SUCCESS: 'PERMISSIONS_GETONE_SUCCESS',
    GETONE_FAILURE: 'PERMISSIONS_GETONE_FAILURE',
  
    UPDATE_REQUEST: 'PERMISSIONS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PERMISSIONS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PERMISSIONS_UPDATE_FAILURE',
  
    CREATE_REQUEST: 'PERMISSIONS_CREATE_REQUEST',
    CREATE_SUCCESS: 'PERMISSIONS_CREATE_SUCCESS',
    CREATE_FAILURE: 'PERMISSIONS_CREATE_FAILURE',
  
    RESET_FORM: 'PERMISSIONS_RESET_FORM',
  };
  
  export default constants;