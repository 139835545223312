export default theme => ({
  root: {
    padding: theme.spacing(4)
  },
  item: {
    height: '100%'
  },
  verticalBlock: {
    marginTop: theme.spacing(3)
  },
});
