import { observable, action, decorate } from 'mobx';
// import _ from 'lodash';
import {
  getAll,
  getStatusTypes,
} from 'services/accounts';

class Store {
  records = []
  options = {
    status: null,
  };

  // Action
  reset = () => {
    this.records = [];
    this.options = { status: null };
  }

  // Action
  setRecords = records => {
    this.records = records;
  }

  // Action
  setOptions = (field, data) => {
    this.options[field] = data;
  }

  loadOptions = async () => {
    const status = await getStatusTypes();

    this.setOptions('status', status);
  }

  getAll = async () => {
    const data = await getAll();

    this.setRecords(data);
  }
}

decorate(Store, {
  records: observable,
  options: observable,
  reset: action,
  setRecords: action,
  getRecords: action,
  setOptions: action,
});

export default new Store;