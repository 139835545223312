import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { DataTable } from 'components';
import Accounts from 'views/Accounts';


import styles from './styles';

class Container extends React.Component {

  componentDidMount() {
    this.props.store.loadOptions();

    this.props.store.getAll();
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  prepareTableColumns() {
    const { t } = this.props;

    const columns = [
      'ID',
      'LINK',
      t('Vardas, pavardė'),
      t('El. paštas'),
      t('Įmonė'),
      t('Būsena'),
      t('Pareigos'),
      t('Sukurta'),
      t('Atnaujinta'),
    ];

    return columns;
  }

  prepareTableData(records) {
    const { t } = this.props;
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/accounts/${record._id}`);
      row.push(record.user ? record.user.fullName : t('IŠTRINTAS VARTOTOJAS'))
      row.push(record.user ? record.user.email : t('IŠTRINTAS VARTOTOJAS'))
      row.push(this.renderCompany(record.company));
      row.push(this.renderStatus(record.status));
      row.push(record.position);
      row.push(record.createdAt ? moment(record.createdAt).utc().format('YYYY-MM-DD') : null);
      row.push(record.updatedAt ? moment(record.updatedAt).utc().format('YYYY-MM-DD') : null);
      return row;
    });

    return data;
  }

  renderCompany(company) {
    if(!company) return null;

    return (
      <Link to={`/companies/${company._id}`}>
        {company.name}
      </Link>
    )
  }

  renderStatus(status) {
    const { options } = this.props.store;

    return options.status[status] || status;
  }

  render() {
    const { classes, store, t } = this.props;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(store.records);

    return (
      <Accounts
        title={t('Paskyros')}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Paskyros') },
        ]}
      >
        <div className={classes.wrap}>
          <DataTable 
            key={data.length}
            columns={columns}
            tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
            data={data}
            createUrl="/accounts/create"
          />
        </div>
      </Accounts>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(Container));