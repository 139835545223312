import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { DataTable } from 'components';
import styles from './styles';

import Users from 'views/Users';

import { getAll } from 'views/Users/_actions';


class UserList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    // Get all users
    dispatch(getAll());
  }

  prepareTableColumns() {
    const { t } = this.props;

    const columns = [
      'ID',
      'LINK',
      t('Vardas'),
      t('El. paštas'),
      t('Aktyvuotas'),
      t('Paskyros'),
      t('Sukurta'),
      t('Jungėsi'),
    ];

    return columns;
  }

  prepareTableData(records) {
    const { t } = this.props;
  
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/users/${record._id}`);
      row.push(record.fullName);
      row.push(record.email);
      row.push(record.isActivated ? t('Taip') : t('Ne'));
      row.push(record.accounts.length);
      row.push(moment.parseZone(record.createdAt).format('YYYY-MM-DD'));
      row.push(record.lastLoginAt ? moment.parseZone(record.lastLoginAt).format('YYYY-MM-DD') : null);

      return row;
    });

    return data;
  }
  

  renderUsers() {
    const { classes, isLoading, list, t } = this.props;
    const { users } = list;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(users);

    return (
      <DataTable 
        title={t('Vartotojų sarašas')}
        columns={columns} 
        data={data}
        tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
        createUrl="/users/create"
      />
    );
  }

    

  render() {
    const { classes, t } = this.props;

    return (
      <Users
        title={t('Vartotojų sarašas')}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Vartotojai') },
        ]}
      >
        
        <div className={classes.content}>
          {this.renderUsers()}
        </div>

      </Users>
    );
  }
}

const mapStateToProps = ({ users, dispatch }) => {
  return { 
    ...users,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(UserList);