// Palette
import palette from '../palette';

export default {
  outlined: {
    padding: '2px 10px',
  },
  text: {
    padding: '2px 8px',
  },
  contained: {
    backgroundColor: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.neutral
    }
  },
  containedSizeSmall: {
    padding: '2px 8px',
    fontSize: '12px',
  },
  textSizeSmall: {
    padding: '2px 5px',
    fontSize: '12px',
  }
};
