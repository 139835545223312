import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Typography,
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';
import {
  CommonDialog,
} from 'components';
import api from 'utils/api';

const useStyles = makeStyles({
  root: {},
  filePickerTableContainer: {
    maxWidth: 800,
  },
  footerWrap: {
    marginTop: 40,
  }
})

const RecoveryPriceClone = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { id: itemId } = useParams();
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [versions, setVersions] = useState([]);
  const [version, setVersion] = useState('');

  const fetchItem = async () => {
    setLoading(true);

    try {
      const { data } = await api.get(`/services/recovery-price/${itemId}`);

      setItem(data);
      setVersion(data.pricing.code);
    } catch (err) {
      console.error(err);
    }


    setLoading(false);
  }

  const fetchVersions = async () => {
    setLoading(true);

    try {
      const { data } = await api.post('/services/recovery-price/get-versions', { type: item.type });

      setVersions(data);
    } catch (err) {
      console.error(err);
    }


    setLoading(false);
  }

  useEffect(() => {
    if (!item) fetchItem();
  }, []);

  useEffect(() => {
    if (item) fetchVersions();
  }, [item]);

  const handleClose = () => {
    history.push(`/recovery-price/${itemId}`);
  };

  const onChangeVersion = (value) => {
    setVersion(value);
  }

  const onSubmit = async () => {
    setLoading(true);

    try {
      const { data } = await api.post(`services/recovery-price/${itemId}/clone`, { version });

      window.location.href = `/recovery-price/${data._id}`;
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  }

  const onCancel = () => {
    handleClose();
  }

  return (
    <CommonDialog
      visible
      onClose={handleClose}
      maxWidth="xs"
      style={{ position: 'relative' }}
      actions={[
        <Button
          color="default"
          variant="text"
          onClick={onCancel}
        >
          Atšaukti
        </Button>,
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          Perskaičiuoti ir išsaugoti
        </Button>
      ]}
    >
      <div>
        {!loading && !!item && (
          <div>
            <Typography variant="body1" style={{ marginBottom: 10 }}>
              <strong>Statinių paskirties grupė:</strong><br />
              {item.type === 'NTK pastatai' ? 'Pastatai' : 'Inžineriniai statiniai'}
            </Typography>

            <Typography variant="body1" style={{ marginBottom: 10 }}>
              <strong>Statinių tipas pagal paskirtį:</strong><br />
              {item.buildingType?.titleFull || '- '}
            </Typography>

            <Typography variant="body1" style={{ marginBottom: 10 }}>
              <strong>Kainyno versija:</strong><br />
              {item.pricing?.version || '-'}
            </Typography>

            <Typography variant="body1" style={{ marginBottom: 10 }}>
              <strong>Kiekis:</strong><br />
              {item.amount || '-'} {item.unit}
            </Typography>

            <Typography variant="body1" style={{ marginBottom: 10 }}>
              <strong>Pavadinimas:</strong><br />
              {item.title}
            </Typography>

            <Typography variant="body1" style={{ marginBottom: 10 }}>
              <strong>Visa vertė, EUR:</strong><br />
              {item.totals?.totalRevisedPrice || '-'}
            </Typography>

            <Typography variant="body1" style={{ marginBottom: 10 }}>
              <strong>Pastabos:</strong><br />
              {item.description || '-'}
            </Typography>

            <hr />

            <Typography variant="body1" style={{ marginTop: 10 }}>
              <strong>{t('Pasirinkite kainyno versiją')}</strong>
            </Typography>
            <TextField
              required
              fullWidth
              select
              // label={t('Kainyno versija perskaičiavimui')}
              onChange={(e) => onChangeVersion(e.target.value)}
              name="version"
              value={version}
              margin="dense"
              variant="outlined"
            >
              {_.map(versions, version => (
                <MenuItem key={version.code} value={version.code}>
                  {version.version}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
      </div>
    </CommonDialog>
  )
}

export default RecoveryPriceClone;