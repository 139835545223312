import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import { Auth as AuthLayout } from 'layouts';
import styles from './styles';
import { 
  Checkbox,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  withStyles,
} from '@material-ui/core';

import { toast, validateEmail } from 'utils/helpers';
import { register, checkEmail, termsAgreed, activate } from 'views/Users/_actions';
import { RECAPTCHA_SITE_KEY } from 'utils/config';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      passwordConfirm: '',
      isActivating: false,
    };
    this._reCaptchaRef = React.createRef();
  }

  componentWillMount() {
    if (this.props.app.user) {
      this.props.history.push('/');
    }

    const { match } = this.props;
    if (match.params.hash) {
      this.setState({ isActivating: true });
      
      const hash = match.params.hash;
      this.props.dispatch(activate(hash));
    }
  }

  componentWillUnmount() {
    this.setState = ({
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      passwordConfirm: '',
      isActivating: false,
    });
  }

  handleFieldChange = (field, value) => {
    this.setState({ 
      [field]: value,
    });
  }

  async handleSignUp() {
    const { dispatch, t } = this.props;
    const { email, password, firstName, lastName, passwordConfirm } = this.state;

    const captchaToken = await this._reCaptchaRef.current.executeAsync();

    if (!captchaToken) console.log('reCaptcha token missing');

    if (email && password && passwordConfirm && firstName && lastName && captchaToken) {
      if (!password || password != passwordConfirm) return toast(t('Slaptažodžiai nesutampa'), 'error');

      dispatch(register({ email, password, firstName, lastName, captchaToken }));
    }
  }

  handleEmailCheck() {
    const { dispatch, t } = this.props;
    const { email } = this.state;

    if (!email) return toast(t('Neįvestas el. pašto adresas'), 'error');
    if (!validateEmail(email)) return toast(t('Neteisingas el. pašto formatas'), 'error');

    dispatch(checkEmail(email));
  }


  render() {
    const { classes, t } = this.props;
    const { email, password, passwordConfirm, firstName, lastName } = this.state;

    if (this.state.isActivating) {
      return (
        <AuthLayout
          title={`${t('Aktyvuojama')}...`}
          loading
        />
      )
    }

    if (!RECAPTCHA_SITE_KEY) return 'Recaptcha not availaible';

    return (
      <AuthLayout title={t('Registracija')}>
        <Typography variant="body1" style={{ marginTop: 5, opacity: 0.8 }}>
          {t('Nerekomenduojame naudoti interneto naršyklių versijų, kurių atnaujinimus gamintojas nutraukė (pvz. Internet Explorer)')}
        </Typography>

        <div className={classes.root}>
          <div className={classes.fields}>
            {!this.props.app.canRegister ? (
              <React.Fragment>
                <TextField
                  className={classes.textField}
                  label={t('El. p. adresas')}
                  name="email"
                  onChange={event =>
                    this.handleFieldChange('email', event.target.value)
                  }
                  type="email"
                  value={email}
                  autoComplete="off"
                />

                <FormControlLabel
                  style={{ marginTop: 10 }}
                  control={
                    <Checkbox
                      checked={this.props.app.termsAgreed}
                      onChange={() => this.props.dispatch(termsAgreed(!this.props.app.termsAgreed))}
                      value="true"
                      color="primary"
                    />
                  }
                  label={(
                    <Typography>
                      <a target="_blank" href="https://bimgates.lt/naudojimosi-teises-ir-taisykles/">
                        {t('Su BIMGates taisyklėmis susipažinau ir sutinku. Šis patvirtinimas reiškia elektroninės sutarties su paslaugų teikėju sudarymą. Atsisakymo tvarka įvardinta prie sąlygų.')}
                      </a>
                    </Typography>
                  )}
                />

                <Button
                  className={classes.SignUpButton}
                  color="primary"
                  onClick={() => this.handleEmailCheck()}
                  size="large"
                  variant="contained"
                  disabled={!this.props.app.termsAgreed}
                >
                  {t('Toliau')}
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TextField
                  className={classes.textField}
                  label={t('Vardas')}
                  name="firstName"
                  onChange={event =>
                    this.handleFieldChange('firstName', event.target.value)
                  }
                  type="text"
                  value={firstName}
                  autoComplete="off"
                />
                <TextField
                  className={classes.textField}
                  label={t('Pavardė')}
                  name="lastName"
                  onChange={event =>
                    this.handleFieldChange('lastName', event.target.value)
                  }
                  type="text"
                  value={lastName}
                  autoComplete="off"
                />
                <TextField
                  className={classes.textField}
                  label={t('El. p. adresas')}
                  name="email"
                  disabled={email}
                  onChange={event =>
                    this.handleFieldChange('email', event.target.value)
                  }
                  type="text"
                  value={email}
                  autoComplete="off"
                />
                <TextField
                  className={classes.textField}
                  label={t('Slaptažodis')}
                  name="password"
                  onChange={event =>
                    this.handleFieldChange('password', event.target.value)
                  }
                  type="password"
                  value={password}
                  autoComplete="new-password"
                />
                <TextField
                  className={classes.textField}
                  label={t('Slaptažodžio patvirtinimas')}
                  name="passwordConfirm"
                  onChange={event =>
                    this.handleFieldChange('passwordConfirm', event.target.value)
                  }
                  type="password"
                  value={passwordConfirm}
                  autoComplete="new-password-confirm"
                />
                <Button
                  className={classes.SignUpButton}
                  color="primary"
                  onClick={() => this.handleSignUp()}
                  size="large"
                  variant="contained"
                >
                  {t('Registruotis')}
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>

        <ReCAPTCHA
          size="invisible"
          ref={this._reCaptchaRef}
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={value =>
            this.handleFieldChange('captcha', value)
          }
        />
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withRouter,
  withTranslation(),
)(SignUp);
