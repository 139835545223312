// Palette
import palette from '../palette';

export default {
  root: {
    // height: '56px',
    height: 'auto',
    '&$selected': {
      backgroundColor: palette.background.default
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.background.default
      }
    },
    '& .MuiCheckbox-root': {
      padding: 0,
    },
  }
};
