import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  withStyles,
} from '@material-ui/core';
import {
  TreeView,
  TreeItem,
} from '@material-ui/lab';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

import styles from './styles';

class TreeStructure extends Component {

  renderNode(node) {
    if (node.hasNodes) {
      return (
        <TreeItem key={node.treeCode} nodeId={node.treeCode} label={node.title}>
          {_.map(node.nodes, childNode => this.renderNode(childNode))}
        </TreeItem>
      )
    } else {
      return <TreeItem key={node.treeCode} nodeId={node.treeCode} label={node.title} />
    }
  }

  renderTree() {
    const { classes, tree, t } = this.props;

    if (!tree) {
      return <span>{t('Nėra duomenų')}</span>
    }

    return (
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {/* <TreeItem nodeId="1" label="Įrašas 1">
          <TreeItem nodeId="2" label="Įrašas 2" />
        </TreeItem> */}
        {_.map(tree, (node) => {
          return this.renderNode(node);
        })}

      </TreeView>
    )
  }

  render() {
    const { classes } = this.props;
    
    return (
      <div className={classes.root}>
        {this.renderTree()}
      </div>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(TreeStructure);