import {
  SENDING_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  SET_VALUE,
  FAILURE,
} from './_constants';

const initialState = {
  isLoading: false,
  groups: [],
  categories: [],
  selected: {},
};

export const permissionMatrix = (state = initialState, action) => {
  switch (action.type) {
    
    case SENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_VALUE:
      return {
        ...state,
        [action.data.name]: action.data.value,
      };
      
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: action.groups,
      };

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
      };

    case FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state;
  }
}