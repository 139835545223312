import React, { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { list } from 'services/news';

const useStyles = makeStyles(theme => ({
  root: {},
  recordWrap: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const NewsWidget = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      
      try {
        const data = await list();

        setRecords(data);
      } catch (err) {
        console.error(err)
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      {!loading && _.map(records, record => (
        <div key={record._id} className={classes.recordWrap}>
          <Typography variant="h6">
            <span title={moment(record.publishedAt).format('YYYY-MM-DD HH:mm')}>
              {record.title} <i>({moment(record.publishedAt).format('YYYY-MM-DD')})</i>
            </span>
          </Typography>

          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {record.content}
          </Typography>
        </div>
      ))}

      {!loading && !records.length && (
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          {t('Įrašų nėra')}
        </Typography>
      )}
    </div>
  )
}

export default NewsWidget;