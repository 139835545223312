import React, { Component } from 'react';
import NormPackageFormContainer from './NormPackageFormContainer';
import store from './_store';

class NormPackageForm extends Component {
  render() {
    return <NormPackageFormContainer store={store} {...this.props} />
  }
}

export default NormPackageForm;