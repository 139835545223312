import ServiceList from './components/ServiceList';
import ServiceForm from './components/ServiceForm';

export default [
  {
    key: 'list',
    component: ServiceList,
    path: '/services',
    public: false,
    canReloadOnLinkClick: false,
  },
  {
    key: 'create',
    component: ServiceForm,
    path: '/services/create',
    public: false,
  },
  {  
    key: 'edit',
    component: ServiceForm,
    path: '/services/:id',
    public: false,
  }
];