import {
  SENDING_REQUEST,
  GET_PERMISSION_GROUPS_SUCCESS,
  FAILURE,
  RESET_FORM,
} from './_constants';

const initialState = {
  isLoading: false,
  permissionGroups: [],
};

export const permissionPicker = (state = initialState, action) => {
  switch (action.type) {
    
    case RESET_FORM:
      return {
        ...initialState,
      };

    case SENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_PERMISSION_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        permissionGroups: action.permissionGroups,
      };

    case FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state;
  }
}