import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import _ from 'lodash';
import { useOutsideClick } from 'utils/helpers';
import { EstimateContext } from './EstimateObjectForm';
import { ClickAwayListener } from '@material-ui/core';

const TreeEditableCell = (props) => {
  const { value: initialValue, name, item, type, onChange, className = '', inputProps = {}, style = {} } = props;
  const [value, setValue] = useState(initialValue);
  const handleClickOutside = (ignoreChange = false) => {
    setIsEditing(false);
    setEstimateCurrentEditable(null);
    if (initialValue !== value && !ignoreChange) onChange({ name, value, item });
  };
  // const wrapperRef = useOutsideClick(handleClickOutside);
  const inputRef = useRef(null);
  const { setEstimateCurrentEditable, estimateCurrentEditable } = useContext(EstimateContext);
  const currentBeingEdited = estimateCurrentEditable?.id === item.id && estimateCurrentEditable?.name === name;
  const [isEditing, setIsEditing] = useState(currentBeingEdited);


  useEffect(() => {
    if (!isEditing) return;

    inputRef.current?.focus();
    !currentBeingEdited && setEstimateCurrentEditable({ id: item.id, name });
  }, [isEditing]);

  const throttledOnChange = useCallback(
    _.debounce((params) => onChange(params), 2000),
    [],
  );

  const handleOnChange = (newValue) => {
    setValue(newValue);

    // throttledOnChange({ name, value: newValue, item });
  };

  const handleOnKeyDown = (e) => {
    if (e.shiftKey) e.stopPropagation();  // Prevent SHIFT key from losing focus of input

    if (e.keyCode === 13) handleClickOutside();

    if (e.key === 'Escape') {
      setValue(initialValue);
      handleClickOutside(true);
    }
  };

  let input;
  switch (type) {

    case 'textarea':
      input = (
        <div className="popout-input">
          <textarea ref={inputRef} {...inputProps} onKeyDown={handleOnKeyDown} onChange={(e) => handleOnChange(e.target.value)} value={value} />
        </div>
      );
      break;

    default:
      input = <input ref={inputRef} {...inputProps} onKeyDown={handleOnKeyDown} onChange={(e) => handleOnChange(e.target.value)} value={value} />;
  }

  return (
    <ClickAwayListener onClickAway={() => handleClickOutside()}>
      <div onClick={() => setIsEditing(true)} style={style} className={`tree-editable-cell ${className} ${isEditing ? 'is-editing' : ''}`} title={value}>
        {isEditing ? input : ((value === null || value === undefined || value === '') ? <>&nbsp;</> : value)}
      </div>
    </ClickAwayListener>
  );
}

export default TreeEditableCell;