import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import * as THREE from "three";
import * as BUI from "@thatopen/ui";
import * as OBC from "@thatopen/components";

import { Dashboard as DashboardLayout } from 'layouts';
import { toast } from 'utils/helpers';
import * as projectService from 'services/projects';

const ModelViewer = () => {
  const { t } = useTranslation();
  const [model, setModel] = useState(null);
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const dropzoneRef = useRef();

  const containerRef = useRef();
  const worldRef = useRef();
  const componentsRef = useRef();
  const fragmentIfcLoaderRef = useRef();

  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2),
      minHeight: 'calc(100vh - 80px)',
      // maxWidth: '1200px',
    }
  }));

  const classes = useStyles();

  const fetchProject = async (projectId) => {
    try {
      const data = await projectService.show(projectId);
      setProject(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašą'), 'error');
    }
  }

  useEffect(() => {
    fetchProject(projectId)
      .then(() => {
        initViewer();
      });


  }, []);

  const initViewer = async () => {
    const container = containerRef.current;

    componentsRef.current = new OBC.Components();
    const worlds = componentsRef.current.get(OBC.Worlds);
    worldRef.current = worlds.create(
      OBC.SimpleScene,
      OBC.SimpleCamera,
      OBC.SimpleRenderer
    );

    worldRef.current.scene = new OBC.SimpleScene(componentsRef.current);
    worldRef.current.renderer = new OBC.SimpleRenderer(componentsRef.current, container);
    worldRef.current.camera = new OBC.SimpleCamera(componentsRef.current);

    componentsRef.current.init();

    worldRef.current.camera.controls.setLookAt(12, 6, 8, 0, 0, -10);

    worldRef.current.scene.three.background = null;


    fragmentIfcLoaderRef.current = componentsRef.current.get(OBC.IfcLoader);

    await fragmentIfcLoaderRef.current.setup();


    worldRef.current.scene.setup();

    const grids = componentsRef.current.get(OBC.Grids);
    grids.create(worldRef.current);
  };

  const handleDrop = async (files) => {
    console.log('upload', { files });

    const fragments = componentsRef.current.get(OBC.FragmentsManager);
    fragments.dispose();

    const file = files[0];
    const data = await file.arrayBuffer();
    const buffer = new Uint8Array(data);

    const ifcModel = await fragmentIfcLoaderRef.current.load(buffer);
    ifcModel.name = file.name;
    worldRef.current.scene.three.add(ifcModel);

    setModel({ name: file.name });
  };

  return (
    <DashboardLayout
      title={t('IFC peržiūra')}
      breadcrumbs={[
        { title: t('Projektai'), to: '/projects/' },
        { title: project?.title || '-', to: `/projects/${projectId}/dashboard` },
        { title: t('IFC peržiūra') },
        { title: model?.name || t('Įkelkite IFC bylą') },
      ]}
    >
      <div className={classes.root} style={{ paddingTop: 12 }}>

        <Dropzone
          ref={dropzoneRef}
          onDrop={handleDrop}
          style={{ backgroundColor: 'red', width: '100%', height: 400 }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p
                style={{
                  backgroundColor: '#ededed',
                  padding: '5px 10px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  border: '1px solid #9E9E9E',
                }}
              >
                {t('Įtempkite IFC bylą arba paspauskite ant šio teksto')}
              </p>
            </div>
          )}
        </Dropzone>

        <div
          ref={containerRef}
          style={{
            width: '100%',
            height: 'calc(100vh - 150px)',
            // border: '2px solid #333333',
          }}
        >
        </div>

      </div>
    </DashboardLayout>
  )
}

export default ModelViewer;