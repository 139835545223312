import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { FormField } from 'components';
import _ from 'lodash';

class JoinRequest extends Component {

  validate(values) {
    let errors = {};

    if (!values.position) {
      // errors.position = 'Privalomas';
    }

    if (_.keys(errors).length) {
      console.log('Validation errors', errors);
    }

    return errors;
  }

  handleSubmit(values) {
    const { onSubmit } = this.props;

    onSubmit(values);
  }

  render() {
    const { onSubmit } = this.props;

    return (
      <Form
        // initialValues={values}
        onSubmit={values => onSubmit(values)}
        validate={this.validate.bind(this)}
        render={({ handleSubmit }) => (
          <form
            id="companyJoinForm"
            // className={classes.form}
            onSubmit={handleSubmit}
          >
            
            <FormField name="position" type="text" label="Pareigos"/>

            <FormField name="comment" type="textarea" label="Pastabos"/>
            
          </form>
        )}
      />
    );
  }
}

export default JoinRequest;