export default theme => ({
  root: {},
  form: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  checkboxWrap: {
    marginTop: theme.spacing(2),
  },
});
