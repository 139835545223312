import CompaniesList from './components/CompaniesList';
import CompaniesForm from './components/CompaniesForm';
import CompaniesSearch from './components/CompaniesSearch';
import ServicesManager from './components/ServicesManager';

export default [
  {
    key: 'list',
    component: CompaniesList,
    path: '/companies',
    public: false,
  },
  {  
    key: 'join',
    component: CompaniesSearch,
    path: '/companies/join',
    public: false,
  },
  {
    key: 'create',
    component: CompaniesForm,
    path: '/companies/create',
    public: false,
  },
  {  
    key: 'services',
    component: ServicesManager,
    path: '/companies/services',
    public: false,
  },
  {  
    key: 'edit',
    component: CompaniesForm,
    path: '/companies/:id',
    public: false,
  },
];