import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
} from '@material-ui/core';
import {
  CommonDialog,
  ResourceSearch,
  FormField2 as Field,
} from 'components';
import api from 'utils/api';
import { toast, twoOrMoreDecimals } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const FIELDS = {
  CODE: 'code',
  TITLE: 'title',
  UNIT: 'unit',
  PRICE: 'price',
};

const useStyles = makeStyles({
  root: {},
  footerWrap: {
    marginTop: 40,
  },
  viewWrap: {
    // height: '600px',
    // overflow: 'auto',
  },
  rateWrap: {
    background: '#ddd',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  compareWrap: {
    marginTop: '10px',
  },
  compareTable: {
    '& th': {
      backgroundColor: '#f8fafc',
    },
  },
})

const ResourceSearchModal = ({ objectId, itemId, onClose, version, type, originalItem, onlyRates = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMultiple = !!originalItem?.multiple;
  const [rate, setRate] = useState(null);
  const [selected, setSelected] = useState([]);
  const [result, setResult] = useState(null);

  const handleClose = (hadChanges = false) => {
    onClose(hadChanges);
  };

  const handleSingleSubmit = async () => {
    try {
      await api.put(`/estimates/${objectId}/items/${itemId}/resource`, { resource: result, version });

      toast(t('Resursas pasirinktas'), 'success');
    } catch (err) {
      console.error(err);
      toast(t('Klaida pasirenkant resursą'), 'error');
    }

    handleClose(true);
  }

  const handleMultipleSubmit = async (importAsRates = false) => {
    try {
      await api.put(`/estimates/${objectId}/items/${itemId}/resource/batch`, { resources: result, version, asRates: importAsRates });

      toast(t('Resursai įkelti'), 'success');
    } catch (err) {
      if (err.response.data.error == 'RATE_ITEM_COUNT_OVER_TRIAL_LIMIT') {
        toast('Viršytas sąmatos bandomosios paslaugos įkainių kiekis', 'error');
      } else {
        toast(t('Klaida įkeliant resursus'), 'error');
      }
      console.error(err);
    }

    handleClose(true);
  }

  const handleResultChange = (values) => {
    setResult(values);
  }

  const handleSubmit = (importAsRates = false) => {
    console.log('submit', { result });

    // If multiple resources mode
    if (isMultiple) return handleMultipleSubmit(importAsRates);

    // If single resource mode
    if (result?.unit && result?.unit !== originalItem?.unit) {
      if (!window.confirm(t('Pasirinkto ir sąmatos resurso matavimo vienetai skiriasi. Ar tęsti įkėlimą?'))) return;
    }

    return handleSingleSubmit();
  }

  const fetchRate = async () => {
    try {
      const { data } = await api.get(`/estimates/${objectId}/items/${itemId}/rate`);

      setRate(data);
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko gauti įkainio duomenų'), 'error');
    }
  }

  useEffect(() => {
    if (!isMultiple) fetchRate();
  }, []);

  return (
    <CommonDialog
      visible
      onClose={handleClose}
      maxWidth="lg"
      style={{ position: 'relative' }}
      // title={!isMultiple ? 'Resurso paieška' : undefined}
    >
      <div className={classes.viewWrap}>
        {!!rate?.code && (
          <RateSummary rate={rate} />
        )}

        <ResourceSearch
          onCancel={handleClose}
          onSelect={(selection) => !isMultiple ? setSelected(selection) : setResult(selection)}
          lockedType={type}
          version={version}
          originalItem={originalItem}
        />

        {!isMultiple && !!selected.length && (
          <OutputBuilder
            selected={selected}
            originalItem={originalItem}
            onChange={handleResultChange}
          />
        )}

        <Actions
          isMultiple={isMultiple}
          onSubmit={handleSubmit}
          disabled={isMultiple ? !result?.length : !result || !Object.keys(result)?.length}
          onlyRates={onlyRates}
        />
      </div>

    </CommonDialog>
  )
}

const Actions = ({ isMultiple, disabled, onSubmit, onlyRates }) => {
  const { t } = useTranslation();

  return (
    <div style={{ flexGrow: 1, marginTop: '10px' }}>
      <Grid container spacing={2}>
          <Grid item xs align="right">
            {!isMultiple ? (
              <Button
                key="import"
                onClick={onSubmit}
                color="primary"
                variant="contained"
                disabled={disabled}
              >
                {t('Įkelti resursą')}
              </Button>
            ) : (
              <>
                <Button
                  key="import-rates"
                  onClick={() => onSubmit(true)}
                  color="primary"
                  variant="contained"
                  disabled={disabled}
                >
                  {t('Įkelti kaip įkainius')}
                </Button>

                {!onlyRates && (
                  <Button
                    key="import-resources"
                    onClick={() => onSubmit()}
                    color="primary"
                    variant="contained"
                    disabled={disabled}
                    style={{ marginLeft: '10px' }}
                  >
                    {t('Įkelti kaip resursus')}
                  </Button>
                )}
              </>
            )}
          </Grid>
      </Grid>
    </div>
  )
}

const OutputBuilder = ({ selected, originalItem, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [importFields, setImportFields] = useState([]);
  const [output, setOutput] = useState({});
  const firstSelected = selected.length > 0 ? selected[0] : {};
  
  const columns = [
    { key: FIELDS.CODE, label: t('Kodas') },
    { key: FIELDS.TITLE, label: t('Pavadinimas') },
    { key: FIELDS.UNIT, label: t('Mato vnt.') },
    { key: 'norm', label: t('Norma'), type: 'number' },
    { key: 'quantity', label: t('Kiekis'), type: 'number' },
    { key: FIELDS.PRICE, label: t('Kaina, EUR'), type: 'number' },
  ];

  const handleImportFields = ({ name: field, value: isChecked }) => {
    const fields = [...importFields];

    if(isChecked)
       fields.push(field);
    else{
       const idx = fields.indexOf(field);
       fields.splice(idx, 1);
    }

    setImportFields(fields);
  }

  useEffect(() => {
    onChange(_.pick(output, importFields));
  }, [output, importFields]);

  useEffect(() => {
    const fields = columns.map(c => c.key);
    setOutput({
      ...(_.pick(originalItem, fields)),
      ...(_.pick(firstSelected, fields)),
    });
  }, [firstSelected]);

  return (
    <TableContainer className={classes.compareWrap}>
      <Table size="small" className={classes.compareTable}>
        <TableHead>
          <TableRow>
            <TableCell />
            {columns.map((column) => <TableCell key={column.key}>{column.label}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{t('Esamas įrašas')}</TableCell>
            {columns.map((column) => (
              <TableCell key={column.key}>
                {column.type === 'number'
                  ? (originalItem[column.key] || '-')
                  : (twoOrMoreDecimals(originalItem[column.key]) || 0)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>{t('Pasirinktas įrašas')}</TableCell>
            {columns.map((column) => (
              <TableCell key={column.key}>
                {column.type === 'number'
                  ? (firstSelected[column.key] || '-')
                  : (twoOrMoreDecimals(firstSelected[column.key]) || 0)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>{t('Įkelti')}?</TableCell>
            {columns.map((column) => (
              <TableCell key={column.key}>
                <Field
                  name={column.key}
                  type="checkbox"
                  value={importFields.includes(column.key)}
                  onChange={handleImportFields}
                  disabled={!selected.length}
                />
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>{t('Naujos reikšmės')}</TableCell>
            {columns.map((column) => (
              <TableCell key={column.key}>
                <Field
                  name={column.key}
                  value={output[column.key] || (column.type === 'number' ? 0 : null)}
                  type={column.type === 'number' ? 'number' : 'text'}
                  onChange={({ name, value }) => setOutput({ ...output, [name]: value })}
                  disabled={!importFields.includes(column.key)}
                  multiline={column.key === FIELDS.TITLE}
                  naked
                />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const RateSummary = ({ rate }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <div className={classes.rateWrap}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('Įkainio kodas')}</TableCell>
              <TableCell>{t('Pavadinimas')}</TableCell>
              <TableCell>{t('Mato vnt.')}</TableCell>
              <TableCell>{t('Kiekis')}</TableCell>
              <TableCell>{t('Kaina, EUR')}</TableCell>
              <TableCell>{t('Suma, EUR')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{rate.code || '-'}</TableCell>
              <TableCell>{rate.title || '-'}</TableCell>
              <TableCell>{rate.unit || '-'}</TableCell>
              <TableCell>{rate.quantity || '-'}</TableCell>
              <TableCell>{twoOrMoreDecimals(rate.price || 0)}</TableCell>
              <TableCell>{twoOrMoreDecimals(rate.amount) || 0}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ResourceSearchModal;