import { observable, action, decorate } from 'mobx';
// import _ from 'lodash';
import {
  getAll
} from 'services/servicePackages';

class Store {
  records = []

  // Action
  reset = () => {
    this.records = [];
  }

  // Action
  setRecords = records => {
    this.records = records;
  }

  getAll = async () => {
    const data = await getAll();

    this.setRecords(data);
  }
}

decorate(Store, {
  records: observable,
  reset: action,
  setRecords: action,
  getRecords: action,
});

export default new Store;