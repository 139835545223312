import * as dataService from '../../shared/_services';
import { toast } from 'views/App/_actions';
import { history } from 'utils/history';
import {
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  FAILURE,
  SENDING_REQUEST,
  RESET_FORM,
  VALUE_CHANGED,
} from './_constants';
const listPath = '/projects';

export const valueChanged = (data) => (dispatch) => {
  dispatch({ type: VALUE_CHANGED, data });
}

export const resetForm = () => (dispatch) => {
  dispatch({ type: RESET_FORM });
}

export const getOne = (id) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const record = await dataService.getOne(id, { simple: true });

    dispatch({ type: CREATE_SUCCESS, record });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }

}

export const updateOne = (details) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    await dataService.updateOne(details);

    // Updated successfully
    dispatch({ type: UPDATE_SUCCESS });
    history.push(listPath);
    toast('Atnaujinta', 'success');
  } catch (error) {
    // Update failed
    dispatch({ type: FAILURE });
    toast('Nepavyko išsaugoti', 'error');
  }
}

export const createOne = (details, onSubmitCallback) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const { id } = await dataService.createOne(details);

    // company created successfully
    dispatch({ type: CREATE_SUCCESS });
    toast('Projektas sukurtas', 'success');
    if (onSubmitCallback) {
      onSubmitCallback(id);
    } else {
      history.push(listPath);
    }
  } catch (error) {
    if (error.response.data.error === "CODE_IN_USE") {
      toast('Projekto kodas jau yra naudojamas', 'error');
    }
    // company create failed
    dispatch({ type: FAILURE });
    toast('Nepavyko sukurti projekto', 'error');
  }
}
