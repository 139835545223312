import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  withStyles,
  Typography,
  Button,
} from '@material-ui/core';

import { Paper } from 'components';

import styles from './styles';

class FeaturedItem extends Component {
  render() {
    const { classes, className, footerLink, footerClickHandler, footerText, subtitle, title, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.subtitle}
              variant="body2"
            >
              {subtitle}
            </Typography>
            <Typography
              className={classes.title}
              variant="h3"
            >
              {title}
            </Typography>
          </div>
        </div>
        <div className={classes.footer}>
          {footerLink ? (
            footerClickHandler ? (
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={(e) => footerClickHandler(e)}
              >
                {footerText}
              </Button>
            ) : (
              <Link
                className={classes.link}
                to={footerLink}
              >
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                >
                  {footerText}
                </Button>
              </Link>
            )
          ) : (
            <Typography
              className={classes.caption}
              variant="caption"
            >
              {footerText}
            </Typography>
          )}
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(FeaturedItem);
