export default theme => ({
  root: {},
  searchRoot: {},
  searchField: {},
  btnSearch: {
    marginTop: '7px',
  },
  panel: {
    marginBottom: theme.spacing(2),
  },
});
