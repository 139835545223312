import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  IconButton,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import NestedMenuItem from 'material-ui-nested-menu-item';
import { EstimateContext } from './EstimateObjectForm';

const useStyles = makeStyles(theme => ({
  root: {},
  itemMenu: {
    '& div': {
      minWidth: '120px',
    },
    '& svg': {
      right: '4px',
      position: 'absolute',
    },
  },
}));

const ActionMenu = ({ item, handler }) => {
  const { t } = useTranslation();
  const { itemActions, print, record, setShowItemPrintModal, setActiveItem, setShowRatesModal, setShowRateImportModal } = useContext(EstimateContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  // const { original: item } = row;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = async (action) => {
    handleClose();

    switch (action) {
      case 'add-estimate':
      case 'add-part':
      case 'add-chapter':
      case 'add-subchapter':
      case 'add-group':
      case 'add-element':
      case 'add-rate':
      case 'add-rate-material':
      case 'add-rate-mechanism':
      case 'add-rate-work':
      case 'add-rate-equipment':
      case 'add-resource-other':
        itemActions.addItem(item, action.replace('add-', ''));
        break;

      case 'add-resource-material':
      case 'add-resource-mechanism':
      case 'add-resource-work':
      case 'add-resource-equipment':
        if (['estimate', 'part', 'chapter', 'subchapter'].indexOf(item.type) > -1) {
          itemActions.addItemAsExtractedResource(item, action.replace('add-', ''));
        } else {
          itemActions.addItem(item, action.replace('add-', ''));
        }
        break;

      case 'search-resources':
        itemActions.multipleResourceSearch(item);
        break;

      case 'show-estimate-rates':
        setActiveItem({ _id: item._id, parent: item.parent });
        setShowRatesModal(true);
        break;

      case 'add-rate-and-show-estimate-rates':
        setActiveItem({ ...item, createRateImportItem: true });
        setShowRatesModal(true);
        break;

      case 'search-resources-as-rates-only':
        itemActions.multipleResourceSearch(item, { onlyRates: true });
        break;

      case 'extract-as-rate':
        itemActions.extractResourceAsRate(item);
        break;

      case 'extract-as-estimate':
        itemActions.extractItemAsEstimate(item);
        break;

      case 'item-summary':
        itemActions.showItemSummary(item);
        break;

      case 'item-job-composition':
        itemActions.showItemSummary(item, 'job_composition');
        break;

      case 'rates-import':
        itemActions.showRatesImport(item);
        break;

      case 'import-rates-from-estimate':
        setActiveItem(item);
        setShowRateImportModal(true);
        break;

      case 'remove':
        itemActions.removeItem(item);
        break;

      case 'print':
        setActiveItem(item);
        setShowItemPrintModal(true);
        break;

      default:
        console.error(`Unknown action: ${action}`);
    }
  }

  const adds = {
    estimate: { action: 'add-estimate', title: t('Sąmata') },
    part: { action: 'add-part', title: t('Sąmatos dalis') },
    chapter: { action: 'add-chapter', title: t('Skyrius') },
    subchapter: { action: 'add-subchapter', title: t('Poskyris') },
    group: { action: 'add-group', title: t('Elementų grupė') },
    element: { action: 'add-element', title: t('Elementas') },
    rate: { action: 'add-rate', title: t('Įkainis') },
    // rateMaterial: { action: 'add-rate-material', title: t('Įkainis medžiaga') },
    // rateMechanism: { action: 'add-rate-mechanism', title: t('Įkainis mechanizmas') },
    // rateWork: { action: 'add-rate-work', title: t('Įkainis darbas') },
    // rateEquipment: { action: 'add-rate-equipment', title: t('Įkainis įrenginys') },
    resourceMaterial: { action: 'add-resource-material', title: t('Medžiaga') },
    resourceMechanism: { action: 'add-resource-mechanism', title: t('Mechanizmas') },
    resourceWork: { action: 'add-resource-work', title: t('Darbas') },
    resourceEquipment: { action: 'add-resource-equipment', title: t('Įrenginys') },
    // resourceOther: { action: 'add-resource-other', title: t('Kitas resursas') },
  };

  const insertOptions = [];

  switch (item.type) {
    case 'estimate':
      insertOptions.push(...[
        adds.estimate,
        adds.part,
        adds.chapter,
        adds.subchapter,
        adds.group,
        adds.element,
        adds.rate,
        // adds.rateMaterial,
        // adds.rateMechanism,
        // adds.rateWork,
        // adds.rateEquipment,
        adds.resourceMaterial,
        adds.resourceMechanism,
        adds.resourceWork,
        adds.resourceEquipment,
        // adds.resourceOther,
      ]);
      break;
    case 'part':
      insertOptions.push(...[
        adds.part,
        adds.chapter,
        adds.subchapter,
        adds.group,
        adds.element,
        adds.rate,
        // adds.rateMaterial,
        // adds.rateMechanism,
        // adds.rateWork,
        // adds.rateEquipment,
        adds.resourceMaterial,
        adds.resourceMechanism,
        adds.resourceWork,
        adds.resourceEquipment,
        // adds.resourceOther,
      ]);
      break;
    case 'chapter':
      insertOptions.push(...[
        adds.chapter,
        adds.subchapter,
        adds.group,
        adds.element,
        adds.rate,
        // adds.rateMaterial,
        // adds.rateMechanism,
        // adds.rateWork,
        // adds.rateEquipment,
        adds.resourceMaterial,
        adds.resourceMechanism,
        adds.resourceWork,
        adds.resourceEquipment,
        // adds.resourceOther,
      ]);
      break;
    case 'subchapter':
      insertOptions.push(...[
        adds.subchapter,
        adds.group,
        adds.element,
        adds.rate,
        // adds.rateMaterial,
        // adds.rateMechanism,
        // adds.rateWork,
        // adds.rateEquipment,
        adds.resourceMaterial,
        adds.resourceMechanism,
        adds.resourceWork,
        adds.resourceEquipment,
        // adds.resourceOther,
      ]);
      break;
    case 'group':
      // insertOptions.push(...[adds.group, adds.element]);
      insertOptions.push(...[
        adds.group,
        adds.element,
        adds.rate,
        // adds.rateMaterial,
        // adds.rateMechanism,
        // adds.rateWork,
        // adds.rateEquipment,
        adds.resourceMaterial,
        adds.resourceMechanism,
        adds.resourceWork,
        adds.resourceEquipment,
        // adds.resourceOther,
      ]);
      break;
    case 'element':
      insertOptions.push(...[
        adds.element,
        adds.rate,
        // adds.rateMaterial,
        // adds.rateMechanism,
        // adds.rateWork,
        // adds.rateEquipment,
        adds.resourceMaterial,
        adds.resourceMechanism,
        adds.resourceWork,
        adds.resourceEquipment,
        // adds.resourceOther,
      ]);
      break;
    case 'rate':
      let additionals = [];
      if (item.hasEquipmentResources && !item.hasNonEquipmentResources) {
        additionals = [
          adds.resourceEquipment,
        ]
      } else if (item.hasNonEquipmentResources && !item.hasEquipmentResources) {
        additionals = [
          adds.resourceMaterial,
          adds.resourceMechanism,
          adds.resourceWork,
        ]
      } else {
        additionals = [
          adds.resourceMaterial,
          adds.resourceMechanism,
          adds.resourceWork,
          adds.resourceEquipment,
          // adds.resourceOther,
        ]
      }

      insertOptions.push(...[
        adds.rate,
        // adds.rateMaterial,
        // adds.rateMechanism,
        // adds.rateWork,
        // adds.rateEquipment,
        ...additionals,
      ]);
      break;
    case 'resource':
      if (item.resourceType !== 'equipment') {
        insertOptions.push(...[
          adds.resourceMaterial,
          adds.resourceMechanism,
          adds.resourceWork,
          // adds.resourceEquipment,
          // adds.resourceOther,
        ]);
      } else {
        insertOptions.push(...[
          // adds.resourceMaterial,
          // adds.resourceMechanism,
          // adds.resourceWork,
          adds.resourceEquipment,
          // adds.resourceOther,
        ]);
      }
      break;
    default:
      break;
  }

  const options = [];
  options.push({ title: t('Įvesti'), action: 'add', childs: insertOptions });
  if ([
    'estimate',
    'part',
    'chapter',
    'subchapter',
    'element',
    'group',
    'rate',
  ].includes(item.type)) {
    options.push({ action: 'add-rate-and-show-estimate-rates', title: t('Įkainio paieška') });
  }
  if (['rate', 'resource', 'estimate', 'part', 'chapter', 'subchapter'].includes(item.type)) {
    options.push({ action: 'search-resources', title: t('Resursų paieška') });
  }
  if (['resource'].includes(item.type)) {
    options.push({ action: 'extract-as-rate', title: t('Iškelti kaip įkainį') });
  }
  if ([
    'estimate',
    'part',
    'chapter',
    'subchapter',
    'group',
    'element',
    'rate',
  ].includes(item.type)) {
    options.push({ action: 'item-summary', title: t('Kainos sudėtis') });
    options.push({ action: 'item-job-composition', title: t('Darbų sudėtis') });
  }

  if ([
    'estimate',
    'part',
    'chapter',
    'subchapter',
    'group',
  ].includes(item.type)) {
    options.push({ action: 'rates-import', title: t('Įkelti įkainius iš Excel') });
  }

  if ([
    'estimate',
    'part',
    'chapter',
    'subchapter',
    'rate',
  ].includes(item.type)) {
    options.push({
      title: t('Įkelti duomenis iš kito objekto'),
      action: 'import-rates-from-estimate',
    })
  };

  if ([
    'part',
    'chapter',
    'subchapter',
    'group',
  ].includes(item.type)) {
    options.push({ action: 'extract-as-estimate', title: t('Iškelti kaip sąmatą') });
  }

  if (['estimate'].includes(item.type)) {
    options.push({
      title: t('Spausdinti'),
      action: 'print',
    });
  }

  options.push({ title: t('Pašalinti'), action: 'remove' });

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        disableRipple
        style={{ background: '#0967da', borderRadius: '5px', color: '#ffffff' }}
      >
        <MoreVertIcon />
      </IconButton>
      {/* <Button
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        disableRipple
        variant="contained"
        color="primary"
        endIcon={<MoreVertIcon />}
      >
        Veiksmai
      </Button> */}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        variant="menu"
        className={classes.itemMenu}
        // anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {options.map(({ title, action, disabled, childs = [] }) => !childs.length ? (
          <MenuItem
            key={action}
            onClick={!childs.length ? () => handleSelect(action) : undefined}
            disabled={disabled}
          >
            {title}
          </MenuItem>
        ) : (
          <NestedMenuItem
            key={action}
            disabled={disabled}
            label={title}
            parentMenuOpen={!!open}
          >
            {childs.map((child) => (
              <MenuItem
                key={`child-${child.action}`}
                onClick={() => handleSelect(child.action)}
                disabled={child.disabled}
              >
                {child.title}
              </MenuItem>
            ))}
          </NestedMenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ActionMenu;