import { observable, action, decorate } from 'mobx';
import _ from 'lodash';

import {
  getVersions,
  getHierarchy,
  getMaterials,
  getCombinations,
  getNtkPrices,
  getFiles,
} from 'services/ntk';

const DEFAULT_RECORD = {
  type: 'Inv planavimas',
  // type: 'NTK inzineriniai',
  version: null,
  buildingType: null,
  materials: [],
  prices: null,
  files: [],
};

const DEFAULT_OPTIONS = {
  types: [],
  versions: [],
  buildingTypes: [],
  materials: [],
  initialMaterials: [],
  combinations: [],
};

class Store {
  record = { ...DEFAULT_RECORD }
  options = { ...DEFAULT_OPTIONS }

  // Action
  reset = () => {
    this.record = { ...DEFAULT_RECORD };
    this.options = { ...DEFAULT_OPTIONS };
  }

  // Action
  setRecord = data => {
    this.record = data;
  }

  // Action
  setRecordValue = (field, value, setOnly = false) => {
    this.record[field] = value;

    
    if (!setOnly) this.recordValueChanged(field, value);
  }

  // Action
  setOptions = data => {
    this.options = data;
  }

  // Action
  setOptionsValue = (field, value) => {
    this.options[field] = value;
  }

  recordValueChanged = async (field, value) => {
    switch (field) {
      case 'version':
        this.setRecordValue('buildingType', null, true);
        this.setOptionsValue('buildingTypes', []);
        this.setRecordValue('materials', [], true);
        this.setOptionsValue('initialMaterials', []);
        this.setOptionsValue('materials', []);
        this.setRecordValue('prices', null);
        await this.loadBuildingTypes();
        break;
      
      case 'buildingType':
        this.setRecordValue('materials', [], true);
        this.setRecordValue('prices', null, true);
        this.setRecordValue('files', null, true);
        this.setOptionsValue('initialMaterials', []);
        this.setOptionsValue('materials', []);

        if (value) {
          await this.loadMaterials();
          await this.loadFiles();
        }
        break;
      
      case 'materials':
        await this.loadAvailableMaterials();
        await this.loadCombinations();
        await this.loadPrices();
        break;

      default:
        // Do nothing
    }
  }

  loadFiles = async () => {
    const { record } = this;

    const params = {
      code: record.buildingType.code,
      version: record.version,
      type: record.type,
    };

    const files = await getFiles(params);

    this.setRecordValue('files', files);
  }

  loadPrices = async () => {
    const { record, options } = this;

    const combinations = _.uniq(_.map(options.combinations, c => c.code)).join(';');

    const params = {
      code: record.buildingType.code,
      version: record.version,
      combinations,
    }

    const prices = await getNtkPrices(params);

    this.setRecordValue('prices', prices);
  }

  loadCombinations = async () => {
    const { record, options } = this;

    const params = {
      code: record.buildingType.code,
      version: record.version,
      type: record.type,
      materials: record.materials,
    }

    const combinations = await getCombinations(params);

    this.setOptions({ ...options, combinations });
  }

  loadAvailableMaterials = async () => {
    const { record, options } = this;

    const params = {
      code: record.buildingType.code,
      version: record.version,
      materials: record.materials,
    }

    const materials = await getMaterials(params);

    // if (materials.length) 
    this.setOptions({ ...options, materials });
  }

  loadMaterials = async () => {
    const { record, options } = this;

    const params = {
      code: record.buildingType.code,
      version: record.version,
    };
    
    const materials = await getMaterials(params);
    const initialMaterials = options.initialMaterials.length
      ? options.initialMaterials
      : materials;

    this.setOptions({ 
      ...options,
      materials,
      initialMaterials,
    });

    // Attempt to load without selecting materials
    await this.loadCombinations();
    await this.loadPrices();
  }

  loadBuildingTypes = async () => {
    const { record, options } = this;

    const buildingTypes = await getHierarchy({
      type: record.type,
      version: record.version,
    });
    
    this.setOptions({ ...options, buildingTypes });
  }

  loadVersionOptions = async () => {
    const { record, options } = this;

    const versions = await getVersions({ type: record.type });
    const versionOptions = versions.map(v => ({ label: v.version, value: v.code }));
    this.setOptions({ ...options, versions: versionOptions });

    if (versions.length) {
      const firstAccessibleVersion = versions.find(version => version.code != null);
      this.setRecordValue('version', firstAccessibleVersion.code);
    }
  }

  init = async () => {
    await this.loadVersionOptions();
  }
}

decorate(Store, {
  record: observable,
  options: observable,
  setRecord: action,
  setRecordValue: action,
  setOptionsValue: action,
  setOptions: action,
  reset: action,
});

export default new Store;