import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import { DataTable } from 'components';

import PermissionGroups from 'views/PermissionGroups';
import { getAll } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { permissionGroupsList } from './_reducers';
store.injectReducer('permissionGroupsList', permissionGroupsList);


class PermissionGroupsList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  prepareTableColumns() {
    const { t } = this.props;

    const columns = [
      'ID',
      'LINK',
      t('Pavadinimas'),
      t('Kodas'),
      t('Priskirta teisių'),
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/permission-groups/${record._id}`);
      row.push(record.title);
      row.push(record.code);
      row.push(record.permissions ? record.permissions.length : null);
      
      return row;
    });

    return data;
  }

  render() {
    const { classes, records, isLoading, t } = this.props;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    return (
      <PermissionGroups
        isLoading={isLoading}
        title={t('Teisių grupės')}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Prieigos teisių grupės') },
        ]}
      >
        
        <div className={classes.content}>
          {!isLoading && (
            <DataTable 
              columns={columns}
              data={data}
              tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
              createUrl="/permission-groups/create"
              // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
              // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
            />
          )}
        </div>

      </PermissionGroups>
    );
  }
}

const mapStateToProps = ({ permissionGroupsList, dispatch }) => {
  return { 
    ...permissionGroupsList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(PermissionGroupsList);