export default () => ({
  root: {},
  wrap: {
    padding: 0,
    maxWidth: '1200px',
    position: 'relative',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 12,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',    

    '& thead tr': {
      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& tbody tr': {
      '&:not(.no-hover):hover': {
        backgroundColor: '#d9d9d9',
        cursor: 'pointer',
      },

      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& input[type="number"], input[type="text"]': {
      textAlign: 'right',
      border: '1px solid #316ac5',
      borderRadius: '4px',
      margin: '2px 0px',

      '&[disabled]': {
        border: '1px solid #cccccc',
      }
    },

    '& input[type="text"]': {
      borderColor: '#c4c4c4',
      padding: '6px 6px 7px',
      color: '#12161b',
      textAlign: 'left',
    },
  },
});
