import React, { useState, useEffect, useContext, useRef, createContext } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table, Form, Input } from 'antd';
import {
  Storage as StorageIcon,
} from '@material-ui/icons';

import { EstimateContext, TYPES, DETAIL_LEVEL, DETAIL_LEVEL_VISIBILITY } from './EstimateObjectForm';
import ActionMenu from './ActionMenu';
import { FormField2 as Field, ClassifierPicker } from 'components';

import 'antd/dist/antd.css';

const { TextArea } = Input;

const EditableContext = createContext(null);

const OldEstimateTreeTable = (props) => {
  const { t } = useTranslation();
  const {
    record,
    loading,
    treeExpandedRowKeys,
    setTreeExpandedRowKeys,
    itemActions,
    treeDetailLevel,
    setTreeDetailLevel,
  } = useContext(EstimateContext);

  const onExpand = (expanded, { key }) => {
    const expandedNow = [...treeExpandedRowKeys];
    if (!expanded && expandedNow.includes(key)) {
      _.remove(expandedNow, item => item === key);
    } else {
      expandedNow.push(key);
    }

    setTreeExpandedRowKeys(expandedNow);
    setTreeDetailLevel('');
  };

  const expandByDetailLevel = (level) => {
    if (!level) return;

    const visibleTypes = DETAIL_LEVEL_VISIBILITY[level];

    const flat = (array) => {
      let result = [];
      array.forEach(function (a) {
        result.push({ type: a.type, _id: a._id });
        if (Array.isArray(a.children)) {
          result = result.concat(flat(a.children));
        }
      });
      return result;
    }

    const allIds = flat(record.items);

    const idsToExpand = allIds
      .filter(a => visibleTypes.includes(a.type))
      .map(a => a._id);

    setTreeExpandedRowKeys(idsToExpand);
  };

  useEffect(() => {
    if (!treeExpandedRowKeys.length) expandByDetailLevel(DETAIL_LEVEL.ESTIMATE);
  }, []);

  const typeRenderer = (text, record) => {
    let value = typeLangMap[text] || text;

    if (text === TYPES.RATE && record.rateType) {
      value = `${t('Įk.')} ${rateTypeLangMap[record.rateType].toLowerCase()}`;
    } else if (text === TYPES.RESOURCE && record.resourceType) {
      value = resourceTypeLangMap[record.resourceType];
    }

    return (
      <span title={value}>{value}</span>
    )
  }

  const typeLangMap = {
    'estimate': t('Sąmata'),
    'part': t('Sąm. dalis'),
    'chapter': t('Skyrius'),
    'subchapter': t('Poskyris'),
    'group': t('El. grupė'),
    'element': t('Elementas'),
    'rate': t('Įkainis'),
    'resource': t('Resursas'),
  }

  const resourceTypeLangMap = {
    'work': t('Darbas'),
    'mechanism': t('Mechanizmas'),
    'material': t('Medžiaga'),
    'equipment': t('Įrenginys'),
    'other': t('Kita'),
  }

  const rateTypeLangMap = {
    'work': t('Darbas'),
    'mechanism': t('Mechanizmas'),
    'material': t('Medžiaga'),
    'equipment': t('Įrenginys'),
  }

  const defaultColumns = [
    {
      title: t('Kodas'),
      dataIndex: 'code',
      key: 'code',
      width: 100,
      ellipsis: { showTitle: false },
      render: (text) => <span title={text}>{text}</span>,
      editable: true,
      fixed: 'left',
    },
    {
      title: t('Pavadinimas'),
      dataIndex: 'title',
      key: 'title',
      width: 350,
      ellipsis: { showTitle: false },
      render: (text) => <span title={text}>{text}</span>,
      editable: true,
      fixed: 'left',
    },
    {
      title: t('Mat. vnt.'),
      dataIndex: 'unit',
      key: 'unit',
      width: 70,
      // align: 'center',
      editable: true,
    },
    {
      title: t('Norma'),
      dataIndex: 'norm',
      key: 'norm',
      width: 100,
      align: 'right',
      editable: true,
    },
    {
      title: t('Kiekis'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      width: 100,
      editable: true,
    },
    {
      title: t('Kaina, EUR'),
      dataIndex: 'price',
      key: 'price',
      width: 100,
      align: 'right',
      editable: true,
    },
    {
      title: t('Suma, EUR'),
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      align: 'right',
      editable: true,
    },
    {
      title: t('Tipas'),
      dataIndex: 'type',
      key: 'type',
      width: 80,
      ellipsis: { showTitle: false },
      render: (text, record) => typeRenderer(text, record),
      align: 'center',
    },
    {
      title: t('Struktūra'),
      dataIndex: 'structure',
      key: 'structure',
      width: 80,
      ellipsis: { showTitle: false },
      editable: true,
      render: (structure) => <span title={structure?.title}>{structure?.title}</span>,
    },
    {
      ellipsis: { showTitle: false },
      align: 'center',
      width: 40,
      fixed: 'right',
      render: (text, record) => <ActionMenu key='actionMenu' item={record} />
    }
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: itemActions.cellChanged,
      }),
    };
  });

  const data = !record?.items
    ? []
    : record.items.map(item => ({ expanded: true, ...item }));

  return (
    <>
      <div style={{ margin: '8px 0px' }}>

        <Field
          label={t('Detalizavimas')}
          name="detail-level"
          value={treeDetailLevel}
          placeholder={t('Pasirinkite...')}
          type="select"
          options={[
            { value: DETAIL_LEVEL.ESTIMATE, label: t('Sąmata') },
            { value: DETAIL_LEVEL.PART, label: t('Sąmatos dalis') },
            { value: DETAIL_LEVEL.CHAPTER, label: t('Skyrius') },
            { value: DETAIL_LEVEL.SUBCHAPTER, label: t('Poskyris') },
            { value: DETAIL_LEVEL.RATE, label: t('Įkainis') },
            { value: DETAIL_LEVEL.RESOURCE, label: t('Įkainis ir resursai') },
          ]}
          style={{ width: 200 }}
          onChange={({ value }) => {
            setTreeDetailLevel(value);
            expandByDetailLevel(value);
          }}
        />

      </div>

      <Table
        components={components}
        className="estimate-tree-table"
        size="small"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        bordered
        expandable={{
          indentSize: 2,
          expandedRowKeys: treeExpandedRowKeys,
          onExpand,
        }}
        tableLayout="fixed"
        scroll={{
          x: 1100,
          // y: window.innerHeight - 400,
          // y: 240,
        }}
        rowClassName={(record) => `row-type-${record.type}`}
      />
    </>
  )
}

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const {
    setActiveItem,
    setShowRatesModal,
  } = useContext(EstimateContext);

  const hasRedWarning = record && record?.type === TYPES.RESOURCE && (!record?.price || !record?.norm);

  useEffect(() => {
    if (editing && inputRef && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = (close = false) => {
    // Prevent closing edit mode when clicked on focused input
    if (!close && editing) return;

    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const rawValues = form.getFieldsValue(true);
      const values = await form.validateFields();

      if (!dataIndex) {
        console.warn('Missing data index');
        return;
      }

      if (['structure'].includes(dataIndex)) {
        values[dataIndex] = rawValues[dataIndex];
      }

      const oldValue = record[dataIndex];
      const newValue = values[dataIndex];

      const changed = oldValue != newValue;
      if (!changed) {
        toggleEdit(true);
        return;
      };

      record[dataIndex] = newValue;
      try {
        await handleSave(dataIndex, record, newValue);
      } catch (err) {
        record[dataIndex] = oldValue;
        console.error(`Cannot update: ${err.message}`);
      }

      toggleEdit(true);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const onClassifierPickerSave = (structure) => {
    form.setFieldsValue({ structure });
    save();
  }

  let childNode = children;

  const rules = [];
  if (dataIndex === 'title') rules.push({ required: true, message: t('Pavadinimas privalomas') });

  let input = null;
  let addon = null;

  switch (dataIndex) {
    case 'title':
      input = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={rules}
        >
          <TextArea rows={3} ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
      break;

    case 'structure':
      input = (
        <ClassifierPicker
          inputRef={inputRef}
          onChange={onClassifierPickerSave}
          value={record.structure}
          codeBegins="MOD."
        />
      )
      break;

    default:
      input = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          className={`form-item-${record?.type}`}
          rules={rules}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
  }

  const cellProps = { ...restProps };
  cellProps.className = `${cellProps.className || ''} cell-type-${dataIndex}`;
  cellProps.className = `${cellProps.className} ${hasRedWarning ? 'red-warning' : ''}`;

  if (editable) {
    // Set onclick handler on whole cell
    cellProps.onClick = () => toggleEdit();

    childNode = editing ? input : children;
  }

  if (dataIndex === 'title' && record.type === TYPES.RATE) {
    const renderRateCoefficients = (coefficients = {}) => {
      // Coefficients: K1, K2, K3, K11, K21, K31, K41, K51
      // Output format: k1=1.2,1.3 k2=0.4 ...
      const output = [];

      // Object.keys(coefficients).forEach(key => {
      //   const values = coefficients[key].filter(v => Number(v) !== 1);
      //   if (!values.length) return;

      //   output.push(`${key}=${values.join(',')}`);
      // });

      Object.keys(coefficients).forEach(key => {
        const values = [];
        const coeffs = coefficients[key];
        if (!coeffs.length) return;

        for (const coeff of coeffs) {
          if (coeff.type === 'coef' && coeff.value === 1) continue;

          if (coeff.type === 'coef') {
            values.push(coeff.value);
          } else if (coeff.type === 'amount') {
            values.push(`${coeff.value}EUR`);
          } else if (coeff.type === 'percent') {
            values.push(`${coeff.value}%`);
          } else {
            console.error(`Coefficient type ${coeff.type} not supported`);
          }
        }

        if (values.length) output.push(`${key}=${values.join(',')}`);
      });

      if (!output.length) return null;

      return (
        <div className="title-coefficients">
          {output.join(' ')}
        </div>
      )
    }

    const showRatesModal = (id, parent, code) => {
      setActiveItem({ _id: id, parent, code });
      setShowRatesModal(true);
    }

    addon = (
      <>
        <div style={{ position: 'absolute', right: '3px', top: '7px' }}>
          <a href="#" title={t('Įkelti įkainį')} onClick={() => showRatesModal(record._id, record.parent, record.code)}>
            <StorageIcon />
          </a>
        </div>

        {renderRateCoefficients(record.coefficients)}
      </>
    )
  }



  return <td {...cellProps}>{childNode} {addon}</td>;
};

export default OldEstimateTreeTable;