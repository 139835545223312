import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import {
  Link as HtmlLink,
  withStyles,
  Button,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';

import TableSearch from './components/TableSearch';

import palette from 'theme/palette';
import typography from 'theme/typography';
import overrides from 'theme/overrides';
import { confirmDialog, toast } from 'utils/helpers';

import styles from './styles';

class DataTable extends Component {
  state = {
    defaultOptions: {},
    preparedData: [],
    preparedColumns: []
  };

  prepareDataAndColumns(data, columns) {
    const { classes } = this.props;

    let preparedData = data;
    let preparedColumns = columns;

    // If ID column exists - hide it
    let idColIdx = _.indexOf(preparedColumns, 'ID');
    if (idColIdx >= 0) {
      // Update ID column to advanced value and set it's options so value will be hidden from user
      preparedColumns[idColIdx] = {
        name: 'ID',
        options: {
          display: 'excluded',
          filter: false,
          searchable: false,
          print: false,
          download: false
        }
      };
    }

    // If LINK column exists - hide it and update next column to be rendered as a clickable link
    let linkColIdx = _.indexOf(preparedColumns, 'LINK');
    if (linkColIdx >= 0) {
      // Update LINK column to advanced value and set it's options so value will be hidden from user
      preparedColumns[linkColIdx] = {
        name: 'LINK',
        options: {
          display: 'excluded',
          filter: false,
          searchable: false,
          print: false,
          download: false
        }
      };

    }

    let nextCol = preparedColumns[linkColIdx + 1];

      if (nextCol && typeof nextCol !== 'object') {
        preparedColumns[linkColIdx + 1] = {
          name: nextCol,
          options: {
            customBodyRender: (value, tableMeta) => {
              // Get link for this record
              const url = tableMeta.rowData[linkColIdx];

              // Render a link with title
              return (
                <HtmlLink to={url} component={Link} className={classes.link}>
                  {value}
                </HtmlLink>
              );
            }
          }
        };
      }

    return { preparedData, preparedColumns };
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.data !== this.props.data) {
      const { columns, data } = this.props;
      const { preparedData, preparedColumns } = this.prepareDataAndColumns(
        data,
        columns
      );

      this.setState({ preparedData, preparedColumns });
    }
  }

  componentWillMount() {
    const { title, columns, data, t } = this.props;
    const { preparedData, preparedColumns } = this.prepareDataAndColumns(
      data,
      columns
    );

    const defaultOptions = {
      elevation: 0,
      rowsPerPage: 15,
      searchOpen: true,
      searchPlaceholder: 'Paieška...',
      selectableRows: 'none',
      print: true, // Will need custom print handler because MUIDT just prints what's visible and also causes: https://github.com/gregnb/mui-datatables/issues/750
      textLabels: {
        body: {
          noMatch: t('Įrašų nerasta'),
          toolTip: t('Rikiuoti')
        },
        pagination: {
          next: t('Kitas'),
          previous: t('Atgal'),
          rowsPerPage: t('Įrašų puslapyje'),
          displayRows: t('iš')
        },
        toolbar: {
          search: t('Paieška'),
          downloadCsv: t('Atsisiųsti CSV'),
          print: t('Spausdinti'),
          viewColumns: t('Rodomi stulpeliai'),
          filterTable: t('Filtravimas')
        },
        filter: {
          all: t('Visi'),
          title: t('Filtravimas'),
          reset: t('Atstatyti')
        },
        viewColumns: {
          title: t('Rodomi stulpeliai'),
          titleAria: t('Rodyti/slėpti stulpelius')
        },
        selectedRows: {
          text: t('pažymėtų įrašų'),
          delete: t('Pašalinti'),
          deleteAria: t('Pašalinti pažymėtus')
        }
      },
      onRowsDelete: this.onRowsDelete.bind(this),
      // responsive: 'scrollFullHeight',
      responsive: 'scrollMaxHeight',
      downloadOptions: {
        filename: `${_.deburr(title) || 'Export'} - ${moment().format(
          'YYYY-MM-DD'
        )}.csv`,
        separator: ','
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        // Fix UTF8 issues on exported CSV
        return '\uFEFF' + buildHead(columns) + buildBody(data);
      },
      filterType: 'multiselect'
    };

    this.setState({ defaultOptions, preparedData, preparedColumns });
  }

  async onRowsDelete({ data }) {
    const { onDeleteBatch, onDelete, t } = this.props;
    const msgConfirmDelete = t('Ar tikrai norite ištrinti pažymėtus įrašus?');
    const msgCannotDelete = t('Negalima pašalinti');

    if (data.length === 0) {
      toast(msgCannotDelete, 'error');
      return false;
    }

    // If deleting more than one but handler is undefined
    if (data.length > 1 && !onDeleteBatch) {
      toast(msgCannotDelete, 'error');
      return false;
    }

    // If deleting one but handler is undefined
    if (data.length === 1 && !onDelete) {
      toast(msgCannotDelete, 'error');
      return false;
    }

    // If cancels deletion from dialog
    if (!confirmDialog(msgConfirmDelete)) {
      return false;
    }

    if (data.length > 1) {
      // Deleting batch of records
      await onDeleteBatch(_.map(data, record => record.dataIndex));
      console.log('Deleting batch');
    } else {
      // Deleting one record
      await onDelete(data[0].dataIndex);
      console.log('Deleting one');
    }

    // Always return false because table will be newly rendered after item is successfully removed from array
    // and it won't be rendered if removal fails
    return false;
  }

  getMuiTheme = tableHeight =>
    createTheme({
      palette,
      typography,
      zIndex: {
        appBar: 1200,
        drawer: 1100
      },
      overrides: {
        ...overrides,
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: tableHeight
          }
        }
      }
    });

  render() {
    const { t } = this.props;
    const { defaultOptions, preparedData, preparedColumns } = this.state;
    const {
      title,
      createUrl,
      createHandler,
      classes,
      rootStyle,
      tableHeight,
      options = defaultOptions,
      createButtonText = t('Pridėti')
    } = this.props;

    options.customSearchRender = (searchText, onSearch, onHide, options) => (
      <TableSearch
        searchText={searchText}
        onSearch={onSearch}
        onHide={onHide}
        options={options}
      />
    );

    const finalOptions = _.merge(defaultOptions, options);

    return (
      <div className={classes.root} style={rootStyle}>
        {/* Data table */}
        <MuiThemeProvider theme={this.getMuiTheme(tableHeight)}>
          <MUIDataTable
            title={title}
            columns={preparedColumns}
            data={preparedData}
            options={finalOptions}
          />
        </MuiThemeProvider>

        {/* Link to new record form */}
        {createUrl ? (
          <div className={classes.buttonWrapper}>
            <Link to={createUrl}>
              <Button color="primary" size="small" variant="contained">
                <AddIcon className={classes.createButtonIcon} />
                {createButtonText}
              </Button>
            </Link>
          </div>
        ) : null}

        {/* Link to new record form */}
        {createHandler ? (
          <div className={classes.buttonWrapper}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={e => createHandler(e)}>
              <AddIcon className={classes.createButtonIcon} />
              {createButtonText}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(DataTable);
