import userConstants from 'views/Users/_constants';
import appConstants from './_constants';

// const storedLocale = ;

const initialState = {
  user: null,
  canRegister: false,
  termsAgreed: false,
  currentAccount: null,
  isLoading: false, // Controls blanket loader
  isCheckingSession: false,
  isAuthenticated: false,
  isAccountDialogOpen: false,
  activeLocale: localStorage.getItem('locale') || 'lt',
};

export const app = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        canRegister: action.canRegister,
      };

    case userConstants.TERMS_AGREED:
      return {
        ...state,
        termsAgreed: action.termsAgreed
      }

    case appConstants.SET_ACCOUNT_REQUEST:
    case userConstants.SIGNUP_REQUEST:
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.user,
      };
    case userConstants.LOGOUT:
      return {
        ...initialState,
        isLoading: false,
        isAuthenticated: false,
      };
    case userConstants.SESSION_REQUEST:
      return {
        ...state,
        isCheckingSession: true,
        // isLoading: true,
      };
    case userConstants.SESSION_VALID:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        isCheckingSession: false,
      }
    case appConstants.SET_ACCOUNT_FAILURE:
    case userConstants.LOGIN_FAILURE:
    case userConstants.SESSION_INVALID:
    case userConstants.SIGNUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isCheckingSession: false,
      }
    case userConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case appConstants.ACCOUNT_DIALOG_OPEN:
      return {
        ...state,
        isAccountDialogOpen: true,
      };
    case appConstants.ACCOUNT_DIALOG_CLOSED:
      return {
        ...state,
        isAccountDialogOpen: false,
      };
    case appConstants.SET_ACCOUNT_SUCCESS:
      return {
        ...state,
        currentAccount: action.account,
        isAccountDialogOpen: false,
        isLoading: false,
      };
    case appConstants.SET_LOCALE:
      return {
        ...state,
        activeLocale: action.locale,
      };
    case appConstants.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state
  }
}
