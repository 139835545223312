import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Edit as EditIcon } from '@material-ui/icons';
import {
  withStyles,
} from '@material-ui/core';

import styles from './styles';

class DataTableEditAction extends Component {
  render() {
    const { classes, url } = this.props;

    return (
      <Link to={url} className={classes.link}>
        <EditIcon className={classes.icon} />
      </Link>
    );
  }
}

export default withStyles(styles)(DataTableEditAction);
