import api from 'utils/api';

const createOne = async (details) => {
  const response = await api.post('/permissions', details);
  const { permission } = response.data;

  return permission;
}

const updateOne = async (details) => {
  const response = await api.put(`/permissions/${details._id}`, details);
  const { permission } = response.data;

  return permission;
}

const getAll = async () => {
  const response = await api.get('/permissions');
  const permissions = response.data;

  return permissions;
}

const getOne = async (id) => {
  const response = await api.get(`/permissions/${id}`);
  const permission = response.data;

  return permission;
}

export const permissionService = {
  createOne,
  updateOne,
  getAll,
  getOne,
};