import React from 'react';

import BuildingTypePicker from 'components/NtkFilters/components/BuildingTypePicker';

import * as dataService from 'views/RecoveryPrice/shared/_services';

class NtkFullTreeSelect extends React.Component {

  state = {
    hierarchy: null,
  }

  componentDidMount() {

    this.loadTree();
  }

  componentWillUnmount() {
    this.setState({ hierarchy: null });
  }

  async loadTree() {
    const hierarchy = await dataService.getFullHierarchy();

    this.setState({ hierarchy });
  }

  render() {

    return (
      <BuildingTypePicker
        hierarchy={this.state.hierarchy}
        name={this.props.name}
        handleChange={this.props.onChange}
      />
    )
  }
}

export default NtkFullTreeSelect;