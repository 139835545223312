// Palette
import palette from '../palette';

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    // padding: '14px 24px 14px 24px'
    padding: '10px 14px',
  },
  head: {
    // fontSize: '14px',
    fontSize: '12px',
    color: palette.text.primary
  },
  sizeSmall: {
    padding: '2px 16px 3px',
  },
  paddingCheckbox: {
    padding: '0 0 0 12px',
  }
};
