import NormCalcTreeTable from './components/NormCalcTreeTable';

export default [
  {
    key: 'show',
    component: NormCalcTreeTable,
    path: '/norms-calc',
    public: false,
    canReloadOnLinkClick: false,
  },
];