import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { 
  withStyles,
  TextField,
  Button,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import styles from './styles';

import { CommonPanel, FormDialog } from 'components';
import CompaniesForm from 'views/Companies/components/CompaniesForm';
import JoinRequest from './components/JoinRequest';
import Companies from 'views/Companies';

import { search, sendJoinRequest, resetForm } from './_actions';
import { getCurrentUser, clearNonDigits } from 'utils/helpers';

// Inject reducers
import { store } from 'utils/store';
import { EDU_MODE } from 'utils/config';
import { companiesSearch } from './_reducers';
import { classes } from 'istanbul-lib-coverage';
store.injectReducer('companiesSearch', companiesSearch);

class CompaniesSearch extends Component {

  state = {
    code: '',
    type: '',
    registerCompanyModalVisible: false,
    joinCompanyModalVisible: false,
    isIndividual: false,
    showSearch: false,
    selectedCompany: null,
  };

  componentWillMount() {
    const { dispatch } = this.props;

    dispatch(resetForm());
  }

  submit() {
    const { dispatch } = this.props;
    const { code } = this.state;

    if (code) {
      dispatch(search(code));
    }
  }

  registerIndividual() {
    this.setState({
      registerCompanyModalVisible: true,
      isIndividual: true,
      showSearch: false,
      type: 'personal',
    });
  }

  showJoinCompanyModal(company) {
    this.setState({
      joinCompanyModalVisible: true,
      selectedCompany: company,
    });
  }

  closeJoinCompanyModal(company) {
    this.setState({
      joinCompanyModalVisible: false,
      selectedCompany: null,
    });
  }

  onJoinCompanySubmit({ position, comment }) {
    const { dispatch } = this.props;

    const values = {
      position,
      comment,
      company: this.state.selectedCompany._id,
    };

    dispatch(sendJoinRequest(values));
  }

  showRegisterCompanyModal() {
    this.setState({
      isIndividual: false,
      registerCompanyModalVisible: true,
    });
  }

  closeRegisterCompanyModal(wasSubmitted) {
    this.setState({
      registerCompanyModalVisible: false,
      isPersonal: false,
      type: '',
    });

    // Trigger change to show pending company status
    if (wasSubmitted) {
      this.submit();
    }
  }

  renderForm() {
    const { classes, t } = this.props;
    const { code, showSearch } = this.state;

    return (
      <CommonPanel className={[classes.searchRoot, classes.panel]}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            {showSearch ? (
              <Grid container spacing={1}>
                <Grid item sm={8}>
                  <TextField
                    label={t('Įmonės kodas')}
                    variant="outlined"
                    onChange={e => this.setState({ code: e.target.value })}
                    margin="dense"
                    type="text"
                    value={code}
                    className={classes.searchField}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                
                <Grid item sm={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.submit()}
                    className={classes.btnSearch}
                    fullWidth
                  >
                    {t('Ieškoti')}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => this.setState({ showSearch: true })}
                className={classes.btnSearch}
              >
                {t('Registruotis prie įmonės/padalinio paskyros')}
              </Button>
            )}
          </Grid>
          {!EDU_MODE && (
            <Grid item sm={6}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => this.registerIndividual()}
                className={classes.btnSearch}
              >
                {t('Registruoti savarankiškai dirbančio paskyrą')}
              </Button>
            </Grid>
          )}
        </Grid>

        {!EDU_MODE && (
          <div style={{ marginTop: '20px' }}>
            <Typography variant="body2">
              {t('Registruojant naują įmonę sistemoje, arba prisijungiant prie sistemoje esančios įmonės, Jums bus sukuriama įmonės (arba savarankiškai dirbančio) naudojo paskyra.')}
            </Typography>
          </div>
        )}
      </CommonPanel>
    );
  }

  renderAlreadyJoinedMessage({ isActive, userIsPending, userIsDeclined }) {
    const { t } = this.props;
    let message = t('Jūs jau esate prisijungęs prie įmonės');

    if (userIsPending) {
      message = t('Jūsų paskyra laukia įmonės administratoriaus patvirtinimo');
    } else if (userIsDeclined) {
      message = t('Jūsų prašymas prisijungti buvo atmestas');
    }

    return (
      <React.Fragment>
        <Typography variant="body1" style={{ display: 'inline-block', marginRight: 10 }}>
          <strong>{message}</strong>
        </Typography>

        {userIsDeclined && isActive ? (
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => this.showJoinCompanyModal()}
          >
            {t('Pakartoti prašymą')}
          </Button>
        ) : null}
      </React.Fragment>
    )
  }

  renderAccountStatus({ userAlreadyJoined, isActive, userIsPending, userIsDeclined, userIsInactive }) {
    const { t } = this.props;

    if (!isActive) {
      return t('Įmonės paskyra laukia BIMGates administratoriaus patvirtinimo');
    }

    if (!userAlreadyJoined) {
      return t('Neesate prisijungęs prie šios įmonės');
    }

    let message = t('Jūs jau esate prisijungęs prie įmonės');
    if (userIsPending) {
      message = t('Laukiama įmonės administratoriaus patvirtinimo');
    } else if (userIsDeclined) {
      message = t('Prašymas prisijungti buvo atmestas');
    } else if (userIsInactive) {
      message = t('Paskyra deaktyvuota');
    }

    return message;
  }

  renderAccountButton(company) {
    const { userAlreadyJoined, isActive, userIsPending, userIsDeclined, userIsInactive, userIsActive } = company;

    if (!isActive || userIsPending) {
      return null;
    }
    
    const { t } = this.props;

    let button = (
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => this.showJoinCompanyModal(company)}
      >
        {t('Prisijungti')}
      </Button>
    )

    if (userAlreadyJoined && (userIsInactive || userIsDeclined)) {
      button = (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => this.showJoinCompanyModal(company)}
        >
          {t('Pakartoti prašymą')}
        </Button>
      )
    } else if (userIsActive) {
      return null;
    }

    return button;
  }

  renderCompany() {
    const { companies, searched, t } = this.props;
    const { showSearch } = this.state;

    if (!showSearch) {
      return;
    }

    if (!searched && !companies.length) {
      return;
    }

    if (searched && !companies.length && !EDU_MODE) {
      return (
        <CommonPanel className={classes.panel}>
          <Typography variant="subtitle1">
            {t('Įmonės su nurodytu įmonės kodu sistemoje nėra. Galite užregistruoti įmonę jei turite įmonės atstovavimo teises.')}
          </Typography>

          <div style={{ marginTop: 10 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.showRegisterCompanyModal()}
            >
              {t('Registruoti naują įmonę')}
            </Button>
          </div>
        </CommonPanel>
      );
    }

    return (
      <CommonPanel className={classes.panel} noPadding>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Pavadinimas')}</TableCell>
              <TableCell>{t('Įmonės kodas')}</TableCell>
              <TableCell>{t('Adresas')}</TableCell>
              <TableCell>{t('Paskyros būsena')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!!companies.length && companies.map(company => (
              <TableRow key={company.code}>
                <TableCell>{`${company.name} ${company.department || ''}`}</TableCell>
                <TableCell>{company.code}</TableCell>
                <TableCell>{company.address}</TableCell>
                <TableCell>{this.renderAccountStatus(company)}</TableCell>
                <TableCell>{this.renderAccountButton(company)}</TableCell>
              </TableRow>
            ))}

            {!companies.length && (
              <TableRow>
                <TableCell colSpan={5} align="center">{t('Nerasta')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {searched && !!EDU_MODE && (
          <div style={{ margin: 10, textAlign: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.showRegisterCompanyModal()}
            >
              {t('Registruoti naują įmonę/padalinį')}
            </Button>
          </div>
        )}
      </CommonPanel>
    )
  }

  render() {
    const { classes, t } = this.props;
    const { isIndividual, selectedCompany } = this.state;

    const user = getCurrentUser();

    return (
      <Companies
        title={t('Naujos paskyros registracija')}
        breadcrumbs={[
          { title: t('Paskyros registracija') },
        ]}
      >
        
        <div className={classes.content}>
          {this.renderForm()}

          {this.renderCompany()}
        </div>

        {/* Company registration form */}
        {this.state.registerCompanyModalVisible ? (
          <FormDialog
            visible={this.state.registerCompanyModalVisible}
            component={(
              <CompaniesForm
                embed
                registering
                isIndividual={this.state.isIndividual}
                prefill={{ 
                  code: /\s/g.test(this.state.code) 
                    ? null 
                    : this.state.code, 
                      type: this.state.type, 
                      email: user.email, 
                      phone: user.phone, 
                      name: isIndividual ? user.fullName : null, 
                      code: isIndividual ? `${_.kebabCase(user.fullName)}-${user.createdAt.substr(-3)}` : this.state.code,
                }}
              />
            )}
            title={isIndividual ? t('Savarankiškai dirbančio paskyros registracija') : t('Įmonės registracija')}
            formId="companiesForm"
            onClose={this.closeRegisterCompanyModal.bind(this)}
          />
        ) : null}

        {/* Company join request form */}
        {selectedCompany ? (
          <FormDialog
            visible={this.state.joinCompanyModalVisible}
            component={(
              <JoinRequest
                onSubmit={this.onJoinCompanySubmit.bind(this)}
              />
            )}
            title={`${t('Prašymas prisijungti prie įmonės')} ${selectedCompany.name.toUpperCase()}`}
            formId="companyJoinForm"
            onClose={this.closeJoinCompanyModal.bind(this)}
          />
        ) : null}
      </Companies>
    );
  }
}

const mapStateToProps = ({ companiesSearch, dispatch }) => {
  return { 
    ...companiesSearch,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(CompaniesSearch);