import ServicePackageList from './components/ServicePackageList';
import ServicePackageForm from './components/ServicePackageForm';

export default [
  {
    key: 'list',
    component: ServicePackageList,
    path: '/service-packages',
    public: false,
    canReloadOnLinkClick: false,
  },
  {
    key: 'create',
    component: ServicePackageForm,
    path: '/service-packages/create',
    public: false,
  },
  {  
    key: 'edit',
    component: ServicePackageForm,
    path: '/service-packages/:id',
    public: false,
  }
];