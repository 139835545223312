export default theme => ({
  root: {},
  fields: {
    marginTop: theme.spacing(2)
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing(2)
    }
  },
  SignUpButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
});