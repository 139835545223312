import palette from '../palette';

export default {
  root: {
    '&:hover:not($disabled)': {
      backgroundColor: palette.background.default
    },
  },
  inputMarginDense: {
    paddingTop: '9.5px',
    paddingBottom: '8.5px',
  }
};
