import { observable, action, decorate } from 'mobx';
import _ from 'lodash';
import {
  getAll as getAllAccounts,
  getOne as getOneAccount,
  addLicence,
  removeLicence,
} from 'services/accounts';
import { 
  getServices,
  getOrders,
  getServicePackages,
} from 'services/companies';

class Store {
  // Observable
  data = {
    servicePackages: [],
    services: [],
    accounts: [],
    orders: [],
  }

  // Observable
  singleAccount = null;

  // Observable
  company = null;

  // Observable
  account = null;

  // Observable
  orderItemDetails = null;

  // Observable
  multipleOrderItemDetails = [];

  // Observable
  loading = false

  // Action
  reset = () => {
    this.data = {
      servicePackages: [],
      services: [],
      accounts: [],
      orders: [],
    };
    this.loading = false;
    this.singleAccount = null;
    this.company = null;
    this.account = null;
    this.orderItemDetails = null;
    this.multipleOrderItemDetails = [];
  }

  // Action
  setData = data => {
    this.data = data;
  }

  // Action
  setSingleAccount = account => {
    this.singleAccount = account;
  }

  // Action
  setAccount = data => {
    this.account = data;
  }

  // Action
  setLoading = (loading = true) => {
    this.loading = loading;
  }

  // Action
  setCompany = data => {
    this.company = data;
  }

  // Action
  setOrderItemDetails = _id => {
    this.orderItemDetails = this.getOrderItem(_id);
  }

  // Action
  resetMultipleOrderItemDetails = () => {
    this.multipleOrderItemDetails = [];
  }

  // Action
  setMultipleOrderItemDetails = (service, orderItem, order, _id) => {
    if (this.multipleOrderItemDetails.length > 0) {
      const index = this.multipleOrderItemDetails.findIndex(details => JSON.stringify(details) == JSON.stringify({service, order, orderItem, _id}));

      if (index == -1) {
        this.multipleOrderItemDetails.push({service, order, orderItem, _id});
      } else {
        this.multipleOrderItemDetails.splice(index, 1);
      }
    } else {
      this.multipleOrderItemDetails.push({service, order, orderItem, _id});
    }
  }

  getOrderItem = _id => {
    return _.find(this.data.servicePackages, { _id });
  }

  createAccountLicence = async ({ service, orderItem }) => {
    const account = this.account._id;
    const order = this.orderItemDetails.order._id; 

    const data = { account, order, service, orderItem };
    await addLicence(account, data);

    await this.loadData();
  }

  createMultipleAccountLicences = async () => {
    const account = this.account._id;
    for(const servicePackage of this.multipleOrderItemDetails) {
      const data = { account, order: servicePackage.order, service: servicePackage.service, orderItem: servicePackage.orderItem };
      await addLicence(account, data);
    }

    await this.loadData();
  }

  removeAccountLicence = async (accountId, licenceId) => {
    await removeLicence(accountId, licenceId);
    
    await this.loadData();
  }

  loadData = async (data) => {
    const orderId = data ? data.orderId : null;

    this.setLoading();

    const params = { licences: true, orderId };

    let accounts;
    if (!this.singleAccount) {
      accounts = await getAllAccounts(params);
    } else {
      accounts = [await getOneAccount(this.singleAccount, params)];
    }
    
    this.setData({
      ...this.data,
      accounts,
    });

    this.setLoading(false);
  }

  loadServices = async () => {
    if (!this.company) {
      console.log('Company not set');
      return;
    }

    this.setLoading();

    const services = await getServices(this.company);
    this.setData({ ...this.data, services });

    this.setLoading(false);
  }

  loadServicePackages = async (service) => {
    if (!this.company) {
      console.log('Company not set');
      return;
    }

    this.setLoading();

    this.setOrderItemDetails(null);

    const servicePackages = await getServicePackages(this.company, service);
    this.setData({ ...this.data, servicePackages });

    this.setLoading(false);
  }

  loadOrders = async (service) => {
    if (!this.company) {
      console.log('Company not set');
      return;
    }

    this.setLoading();

    const orders = await getOrders(this.company, { service });
    this.setData({ ...this.data, orders });

    this.setLoading(false);
  }

  accountHasService = (serviceId) => {
    const { account } = this;
    
    if (!account.licences) return false;

    for (const licence of account.licences) {
      if (licence.service._id === serviceId) {
        return true;
      }
    }

    return false;
  }
}

decorate(Store, {
  data: observable,
  company: observable,
  singleAccount: observable,
  account: observable,
  orderItemDetails: observable,
  multipleOrderItemDetails: observable,
  loading: observable,
  reset: action,
  setData: action,
  setSingleAccount: action,
  setOrderItemDetails: action,
  setMultipleOrderItemDetails: action,
  resetMultipleOrderItemDetails: action,
  setCompany: action,
  serAccount: action,
  setLoading: action,
});

export default new Store;