import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { FormField2 } from 'components';
import { getVersions } from 'services/norms';

const NormVersionPicker = (props) => {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  const { name, label, style, placeholder, value: propsValue, disabled } = props;
  let displayValue;
  if (value || propsValue) {
    const selected = options.find(o => _.get(o, 'value.code') == (value ? value : propsValue));
    
    if(selected) displayValue = selected.value;
  }

  const onChange = ({ name, value: newValue }) => {
    setValue(newValue?.code);
    if (!newValue?.code) return;

    return props.onChange({ name, value: newValue });
  };

  const fetchOptions = async () => {
    const versions = await getVersions({ sourceType: props.type, variant: props.variant });

    const newOptions = _.map(versions, version => ({ value: version, label: version.version }));
    setOptions(newOptions);

    // if ((!propsValue || (propsValue && !value)) && newOptions.length) onChange({ name, value: newOptions[0]?.value });
  };

  useEffect(() => {
    if (!options.length) fetchOptions();
  }, []);

  useEffect(() => {
    const setInitialValue = () => {
      if (propsValue || options[0].value.code) {
        onChange({ name, value: propsValue || options[0]?.value });
      } else {
        const firstAccessibleVersion = options.find(option => option.value.code != null);
        onChange({ name, value: firstAccessibleVersion?.value });
      }
    }
    if (options.length && !value) setInitialValue();
  }, [options]);

  return (
    <FormField2
      type="select"
      options={options}
      label={label}
      placeholder={placeholder}
      name={name}
      key={name}
      value={displayValue}
      onChange={onChange}
      style={style}
      disabled={disabled}
    />
  )
};

export default NormVersionPicker;