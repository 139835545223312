import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import NestedMenuItem from 'material-ui-nested-menu-item';

const useStyles = makeStyles(theme => ({
  root: {},
  itemMenu: {
    '& div': {
      minWidth: '120px',
    },
    '& svg': {
      right: '4px',
      position: 'absolute',
    },
  },
}));

const TreeTablebActionMenu = ({ item, actions = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = async (action) => {
    handleClose();

    action.handler({ item });
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        disableRipple
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        variant="menu"
        className={classes.itemMenu}
        // anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {actions.map((action) => !action.childs.length ? (
          <MenuItem
            key={action.key}
            onClick={!action.childs.length ? () => handleSelect(action) : undefined}
            disabled={action.disabled}
          >
            {action.title}
          </MenuItem>
        ) : (
          <NestedMenuItem
            key={action.key}
            disabled={action.disabled}
            label={action.title}
            parentMenuOpen={!!open}
          >
            {action.childs.map((child) => (
              <MenuItem
                key={`child-${child.key}`}
                onClick={() => handleSelect(child.action)}
                disabled={child.disabled}
              >
                {child.title}
              </MenuItem>
            ))}
          </NestedMenuItem>
        ))}
      </Menu>
    </>
  );
}

export default TreeTablebActionMenu;