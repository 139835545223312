import React, { Component } from 'react';
import Container from './NormCalcTreeTableContainer';
import store from './_store';

class NormCalcTreeTable extends Component {

  render() {
    return <Container store={store} {...this.props} />
  }
}

export default NormCalcTreeTable;