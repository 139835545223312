import React, { Component } from 'react';
import _ from 'lodash';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';

import styles from './styles';
import {
  withStyles,
  IconButton,
  Menu,
  MenuItem,
  Button
} from '@material-ui/core';
import {
  HelpOutlineOutlined as HelpIconOutline,
  HelpRounded as HelpIcon
} from '@material-ui/icons';

class HelpMenu extends Component {
  state = {
    anchorEl: null,
    isOpen: false
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget, isOpen: true });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, isOpen: false });
  };

  handleClick = url => {
    this.handleMenuClose();
    window.location.href = url;
  };

  render() {
    const { anchorEl, isOpen } = this.state;
    const { classes, t, className } = this.props;

    return (
      <div className={className}>
        {/* <IconButton
          aria-controls="menu-appbar-2"
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="primary"
          title={t('Pagalba')}>
          <HelpIcon />
        </IconButton> */}
        <Button
          endIcon={<HelpIconOutline />}
          color="primary"
          onClick={this.handleMenu}>
          {t('Pagalba')}
        </Button>
        <Menu
          id="menu-appbar-2"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={isOpen}
          onClose={this.handleMenuClose}>
          <MenuItem
            onClick={() => this.handleClick('/help/projekto-instrukcija.pdf')}>
            {t('Projekto ir skaičiavimų objekto sukūrimas')}
          </MenuItem>
          <MenuItem
            onClick={() => this.handleClick('/help/samatos-instrukcija.pdf')}>
            {t('Statinių statybos sąmatų skaičiavimai')}
          </MenuItem>
          <MenuItem
            onClick={() => this.handleClick('/help/ntk-instrukcija.pdf')}>
            {t('Statinių kainynas NTK. Atkuriamosios vertės skaičiavimai')}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(HelpMenu);
