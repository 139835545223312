import React, { useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
// import {
//   Save as SaveIcon,
// } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { useTable } from 'react-table';
import { FormField2 as Field } from 'components';
import { toast, twoOrMoreDecimals } from 'utils/helpers';
import api from 'utils/api';
import { EstimateContext } from './EstimateObjectForm';
import EditableCell from './EditableCell';

const FILTER = {
  OBJECT: 'object',
  ESTIMATE: 'estimate',
  PART: 'part',
  CHAPTER: 'chapter',
  SUBCHAPTER: 'subchapter',
}

const ResourceManager = ({ resourceType, onReload }) => {
  const { t } = useTranslation();
  const { record, loading, setLoading } = useContext(EstimateContext);
  const [resources, setResources] = useState([]);
  const [resourceGroups, setResourceGroups] = useState([]);
  const [touched, setTouched] = useState(null);
  const [updates, setUpdates] = useState([]);
  const [priceCoefficient, setPriceCoefficient] = useState(record.resourcePriceCoefficients[resourceType]);
  const [filter, setFilter] = useState(FILTER.OBJECT);

  const renderNumberCell = ({ value }) => <div style={{ textAlign: 'right' }}>{twoOrMoreDecimals(value)}</div>;

  // Note: table column width defined in CSS
  const columns = useMemo(() => [
    { Header: t('Kodas'), accessor: 'code' },
    { Header: t('Pavadinimas'), accessor: 'title', width: 400, Cell: EditableCell },
    { Header: t('Mat. vnt.'), accessor: 'unit' },
    { Header: t('Kiekis'), accessor: 'quantity', Cell: renderNumberCell },
    { Header: t('Kaina, EUR'), accessor: 'price', Cell: EditableCell },
    { Header: t('Suma, EUR'), accessor: 'amount', Cell: renderNumberCell },
  ], []);

  const resetDefaultPrices = async () => {
    setLoading(true);

    try {
      const payload = {
        filter,
        version: record.version.code,
      };

      await api.put(`/estimates/${record._id}/resources/${resourceType}/defaults`, payload);
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  const fetchResources = async () => {
    setLoading(true);

    try {
      const { data } = await api.get(`/estimates/${record._id}/resources/${resourceType}?filter=${filter}`);

      setResources(data);
      setUpdates([]);
      setTouched(false);
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  const fetchResourceGroups = async () => {
    setLoading(true);

    try {
      const { data } = await api.get(`/estimates/resource-groups`);

      setResourceGroups(data.sort((a, b) => Number(a.code) - Number(b.code)));
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!resourceGroups.length) fetchResourceGroups().then(() => fetchResources());
    if (resourceGroups.length) fetchResources();
  }, [filter]);

  const onCellChange = ({ resource, name, value }) => {
    setTouched(true)

    resource[name] = value;
    setResources([...resources]);

    const newUpdates = [...updates];
    let existing = newUpdates.find(r => {
      if (resource.estimateId && resource.chapterId) {
        return r.code === resource.code
          && r.estimateId === resource.estimateId
          && r.chapterId === resource.chapterId;
      }

      if (resource.estimateId) {
        return r.code === resource.code
          && r.estimateId === resource.estimateId
      }

      return r.code === resource.code;
    });

    if (existing) {
      existing[name] = value;
    } else {
      newUpdates.push({ ...resource });
    }

    setUpdates(newUpdates);
  };

  const onPriceCoefficientChange = ({ value }) => {
    setPriceCoefficient(value);
  }

  const onSubmit = async () => {
    setLoading(true);

    try {
      // Update and recalc resources and whole object
      await api.put(`/estimates/${record._id}/resources/${resourceType}/batch`, { updates, filter });

      // Refresh data
      await fetchResources();

      setTouched(false);
      setUpdates([]);
      onReload(record._id);
      toast(t('Perskaičiuota'), 'success');
    } catch (err) {
      toast(t('Klaida apdorojant'), 'error');
      console.error(err);
    }

    setLoading(false);
  }

  const onSubmitPriceCoefficient = async () => {
    if (!window.confirm(t('Ar tikrai perskaičiuoti kainas su koeficientu?'))) return;

    setLoading(true);

    try {
      // Update and recalc resources and whole object
      await api.put(`/estimates/${record._id}/resources/${resourceType}/coefficient`, { priceCoefficient });

      // Refresh data
      await fetchResources();

      setTouched(false);
      setUpdates([]);
      onReload(record._id);
      toast(t('Perskaičiuota'), 'success');
    } catch (err) {
      toast(t('Klaida apdorojant'), 'error');
      console.error(err);
    }

    setLoading(false);
  };

  const onReset = async () => {
    await fetchResources();

    setTouched(false);
    setUpdates([]);
  };

  const onResetDefaults = async () => {
    await resetDefaultPrices();
    await fetchResources();

    setTouched(false);
    setUpdates([]);
    onReload(record._id);
    toast(t('Įkeltos kainos iš kainyno'), 'success');
  }

  const onFilterChange = async ({ value }) => {
    setFilter(value);
  }

  const renderGroupedResourceRows = ({ groupResources, resourceGroup }) => {
    const rows = [];
    if (!groupResources?.length) return rows;

    rows.push(
      <tr key={`${resourceGroup.code}-${groupResources[0]._id}`} className={'group-row'}>
        <td colSpan={6}>
          {resourceGroup.code}. {resourceGroup.title}
        </td>
      </tr>
    )

    for (const resource of groupResources) {
      const dirty = !!updates.find(u => u._id === resource._id);

      rows.push(
        <tr key={resource._id} className={`item-row ${dirty ? 'dirty' : ''}`}>
          <td>
            {resource.code}
          </td>
          <td>
            <Field
              name="title"
              onChange={({ name, value }) => onCellChange({ resource, name, value })}
              value={resource.title}
              className="field"
              naked
            />
          </td>
          <td align="center">
            {resource.unit}
          </td>
          <td align="right">
            {twoOrMoreDecimals(resource.quantity)}
          </td>
          <td>
            <Field
              name="price"
              type="number"
              onChange={({ name, value }) => onCellChange({ resource, name, value })}
              value={resource.price}
              className="field field-price"
              naked
            />
          </td>
          <td align="right">
            {twoOrMoreDecimals(resource.amount)}
          </td>
        </tr>
      )
    }

    return rows;
  }

  const renderResourceLevelRows = (item) => {
    const rows = [];
    if (!item.resources?.length) return rows;

    const ungroupedResources = item.resources.filter(r => !r.resourceGroupCode);
    if (ungroupedResources.length) {
      rows.push(...renderGroupedResourceRows({ resourceGroup: { code: '0', title: t('NESUGRUPUOTA') }, groupResources: ungroupedResources }));
    }

    for (const resourceGroup of resourceGroups) {
      const groupResources = item.resources.filter(r => r.resourceGroupCode === resourceGroup.code);
      if (!groupResources.length) continue;

      rows.push(...renderGroupedResourceRows({ resourceGroup, groupResources }));
    }

    // Totals
    rows.push(
      <tr key={`${item.code}-${item._id}`} className={`totals-${filter} ${touched ? 'touched' : ''}`}>
        <td colSpan={5} />
        {/* <td className="total" align="right">
          {twoOrMoreDecimals(item.totals.quantity)}
        </td> */}
        {/* <td /> */}
        <td className="total" align="right">
          {twoOrMoreDecimals(item.totals.amount)}
        </td>
      </tr>
    )

    return rows;
  }

  const renderItemRow = (estimate) => {
    const rows = [];

    rows.push(
      <tr key={`estimate-${estimate._id}`} className={`item-row type-estimate ${touched ? 'touched' : ''}`}>
        <td>{estimate.code}</td>
        <td>{estimate.title}</td>
        <td />
        <td className="total" align="right">
          {twoOrMoreDecimals(estimate.totals.quantity)}
        </td>
        <td />
        <td className="total" align="right">
          {twoOrMoreDecimals(estimate.totals.amount)}
        </td>
      </tr>
    )

    if (estimate?.parts?.length) {
      for (const part of estimate.parts) {

        rows.push(
          <tr key={`estimate-${estimate._id}-part-${part._id}`} className={`item-row type-part ${touched ? 'touched' : ''}`}>
            <td>{part.code}</td>
            <td>{part.title}</td>
            <td />
            <td className="total" align="right">
              {twoOrMoreDecimals(part.totals.quantity)}
            </td>
            <td />
            <td className="total" align="right">
              {twoOrMoreDecimals(part.totals.amount)}
            </td>
          </tr>
        )

        if (part?.chapters?.length) {
          for (const chapter of part.chapters) {
            rows.push(
              <tr key={`estimate-${estimate._id}-part-${part._id}-chapter-${chapter._id}`} className={`item-row type-chapter ${touched ? 'touched' : ''}`}>
                <td>{chapter.code}</td>
                <td>{chapter.title}</td>
                <td />
                <td className="total" align="right">
                  {twoOrMoreDecimals(chapter.totals.quantity)}
                </td>
                <td />
                <td className="total" align="right">
                  {twoOrMoreDecimals(chapter.totals.amount)}
                </td>
              </tr>
            )

            if (chapter?.subchapters?.length) {
              for (const subchapter of chapter.subchapters) {
                rows.push(
                  <tr key={`estimate-${estimate._id}-part-${part._id}-chapter-${chapter._id}-subchapter-${subchapter._id}`} className={`item-row type-subchapter ${touched ? 'touched' : ''}`}>
                    <td>{subchapter.code}</td>
                    <td>{subchapter.title}</td>
                    <td />
                    <td className="total" align="right">
                      {twoOrMoreDecimals(subchapter.totals.quantity)}
                    </td>
                    <td />
                    <td className="total" align="right">
                      {twoOrMoreDecimals(subchapter.totals.amount)}
                    </td>
                  </tr>
                )

                rows.push(...renderResourceLevelRows(subchapter));
              }
            } else {
              rows.push(...renderResourceLevelRows(chapter));
            }
          }
        } else {
          rows.push(...renderResourceLevelRows(part));
        }
      }
    } else {
      rows.push(...renderResourceLevelRows(estimate));
    }

    // if (chapters) {
    //   for (const chapter of chapters) {
    //     rows.push(
    //       <tr key={`chapter-${chapter._id}`} className={`item-row type-chapter ${touched ? 'touched' : ''}`}>
    //         <td>{chapter.code}</td>
    //         <td>{chapter.title}</td>
    //         <td />
    //         <td className="total" align="right">
    //           {twoOrMoreDecimals(chapter.totals.quantity)}
    //         </td>
    //         <td />
    //         <td className="total" align="right">
    //           {twoOrMoreDecimals(chapter.totals.amount)}
    //         </td>
    //       </tr>
    //     )

    //     rows.push(...renderResourceLevelRows(chapter));
    //   }
    // } else {
    //   rows.push(...renderResourceLevelRows(estimate));
    // }

    return rows;
  }

  const renderRowsByType = (item) => {
    const rows = [];

    if (filter === FILTER.OBJECT) {
      // Filter: object

      rows.push(...renderResourceLevelRows(item));
    } else {
      rows.push(...renderItemRow(item));
    }
    // } else if (filter === FILTER.ESTIMATE) {
    //   // Filter: estimate

    //   rows.push(...renderItemRow(item));
    // } else if (item.chapters) {
    //   // Filter: chapter

    //   rows.push(...renderItemRow(item, item.chapters));
    // }

    return rows;
  }

  // !!! might prevent rerendering
  // const data = useMemo(() => resources, [resou]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
  } = useTable({
    columns,
    data: resources,
  });

  return (
    <div>
      <Grid container justifyContent="space-between" spacing={6} xs={12}>
        <Grid item style={{ padding: '24px 6px 24px 24px' }}>
          <div style={{ display: 'inline-flex' }}>
            <Field
              label={t('Detalizavimas')}
              name="filter"
              value={filter}
              type="select"
              options={[
                { value: FILTER.OBJECT, label: t('Objektas') },
                { value: FILTER.ESTIMATE, label: t('Sąmata') },
                { value: FILTER.PART, label: t('Sąmatos dalis') },
                { value: FILTER.CHAPTER, label: t('Skyrius') },
                { value: FILTER.SUBCHAPTER, label: t('Poskyris') },
              ]}
              style={{ width: 140 }}
              onChange={onFilterChange}
            />

            <Field
              name="priceCoefficient"
              onChange={onPriceCoefficientChange}
              value={priceCoefficient}
              label={t('Kainos koeficientas')}
              style={{ width: 130, marginTop: 8, paddingLeft: 16 }}
              shrink
            />

            <Button
              color="primary"
              variant="contained"
              onClick={onSubmitPriceCoefficient}
              size="small"
              style={{ height: 28, marginTop: 10, marginLeft: 16 }}
            >
              {t('Pritaikyti')}
            </Button>
          </div>
        </Grid>

        <Grid item style={{ paddingRight: 0, marginRight: -24 }}>
          <div style={{ margin: '8px 0px', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ width: 130 }}>
              <Typography style={{ fontWeight: 600 }}>{t('Suma, EUR be PVM')}</Typography>
              <Typography>{record.totals.amount}</Typography>
            </div>
            <div style={{ width: 130, paddingLeft: 10 }}>
              <Typography style={{ fontWeight: 600 }}>{t('Suma, EUR su PVM')}</Typography>
              <Typography>{record.totals.grandTotal}</Typography>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ fontSize: '10px', marginTop: '5px' }}
                onClick={() => setShowObjectSummaryModal(true)}>
                {t('Kainos sudėtis')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="resources-table-wrapper">
        <table {...getTableProps()} className="resources-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    width={column.width}
                    {...column.getHeaderProps({ className: `column-type-${column.id}` })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {resources.map(resource => renderRowsByType(resource))}
          </tbody>
        </table>
      </div>

      {touched && (
        <div style={{ marginTop: 20 }}>
          <Alert severity="warning">
            <AlertTitle>{t('Dėmesio')}</AlertTitle>
            {filter === 'object'
              ? <Trans t={t}>Resursų pakeitimai bus pritaikyti visam <b>objektui</b></Trans>
              : (
                <Trans t={t}>Resursų pakeitimai bus pritaikyti visam objektui <b>{`${filter === 'estimate' ? 'sąmatos' : (filter === 'chapter' ? 'skyriaus' : '-')}`}</b> lygyje</Trans>
              )
            }
          </Alert>
        </div>
      )}

      <div
        style={{ marginTop: 20, textAlign: 'right' }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={onResetDefaults}
          size="small"
          disabled={loading}
        >
          {t('Atstatyti kainas iš kainyno')}
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={!touched || loading}
          size="small"
          style={{ marginLeft: 10 }}
        >
          {t('Perskaičiuoti resursus')}
        </Button>

        <Button
          color="primary"
          variant="outlined"
          onClick={onReset}
          disabled={!touched || loading}
          style={{ marginLeft: 10 }}
        >
          {t('Atšaukti')}
        </Button>
      </div>
    </div>
  );
}

export default ResourceManager;