import api from 'utils/api';

export const downloadCollection = async (code, version) => {
    const params = {code, version};
    try {
        const response = await api.post('/services/norms/download-collection', params);
        const file = response.data;

        return file;
    } catch (err) {
        console.log(err);
    }
}