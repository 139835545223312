import * as dataService from '../../shared/_services';
import {
  GETALL_SUCCESS,
  GETALL_FAILURE,
  SENDING_REQUEST,
} from './_constants';

export const getAll = (params) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const records = await dataService.getAll(params);

    dispatch({ type: GETALL_SUCCESS, records });
  } catch (error) {
    dispatch({ type: GETALL_FAILURE, error });
  }
}
