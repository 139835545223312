import React, { useState, useEffect, createContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  Tabs,
  Tab,
  Paper,
  Grid,
  Button,
} from '@material-ui/core';

import api from 'utils/api';
import { Dashboard as DashboardLayout } from 'layouts';
import SourceSelector from './SourceSelector';
import FilterManager from './FilterManager';
import MappingManager from './MappingManager';
import GroupingManager from './GroupingManager';
import StructureManager from './StructureManager';
import ImportPreview from './ImportPreview';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    minHeight: 'calc(100vh - 80px)',
    // maxWidth: '1200px',
  }
}));

export const ImportContext = createContext(null);

const defaultRecord = {
  uploadId: null,
  source: {},
  // filters: [],
  headers: [],
  mapping: [],
  // sample: [],
  // grouping: [
  //   // FOR TESTING ONLY
  //   // {
  //   //   "codeField": "-",
  //   //   "groupBy": "1",
  //   //   "titleFields": [
  //   //     "1"
  //   //   ],
  //   //   "sumFields": [
  //   //     "quantity"
  //   //   ],
  //   //   "type": "chapter"
  //   // },
  //   // {
  //   //   "codeField": "-",
  //   //   "groupBy": "4",
  //   //   "titleFields": [
  //   //     "4",
  //   //     "22"
  //   //   ],
  //   //   "sumFields": [
  //   //     "length",
  //   //     "width",
  //   //     "height",
  //   //     "area",
  //   //     "volume",
  //   //     "weight"
  //   //   ],
  //   //   "type": "group"
  //   // }
  //   // FOR TESTING ONLY
  // ],
  rowCount: 0,
}

const defaultConfig = {
  targetFields: [
    // { value: 'code', label: 'Kodas', canSum: false },
    { value: 'title', label: 'Pavadinimas', canSum: false },
    // { value: 'unit', label: 'Mat. vnt.', canSum: false },
    // { value: 'quantity', label: 'Kiekis', canSum: true },
    { value: 'len', label: 'Ilgis', canSum: true },
    { value: 'width', label: 'Plotis', canSum: true },
    { value: 'height', label: 'Aukštis', canSum: true },
    { value: 'area', label: 'Plotas', canSum: true },
    { value: 'volume', label: 'Tūris', canSum: true },
    { value: 'weight', label: 'Svoris', canSum: true },
  ],
  estimateLevels: [
    { value: 'estimate', label: 'Sąmata' },
    { value: 'part', label: 'Sąmatos dalis' },
    { value: 'chapter', label: 'Skyrius' },
    { value: 'subchapter', label: 'Poskyris' },
    { value: 'group', label: 'Elementų grupė' },
    // { value: 'element', label: 'Elementas' },
  ],
  autoMapping: [
    // TODO just an example of automapping
    // { source: 'ifc entity', target: 'title' },
    // { source: 'code', target: 'code' },
    // { source: 'count', target: 'quantity' },
    // { source: 'net area', target: 'area' },
    // { source: 'length', target: 'len' },
    // { source: 'volume (net)', target: 'volume' },
  ],
}

const TABS = {
  SOURCE: 0,
  MAPPING: 1,
  STRUCTURE: 2,
  PREVIEW: 3,
};

const ImportManager = () => {
  const [loading, setLoading] = useState(false);
  const [object, setObject] = useState();
  const [activeTab, setActiveTab] = useState(TABS.SOURCE);
  const [record, setRecord] = useState(_.cloneDeep(defaultRecord));
  const [config, setConfig] = useState(_.cloneDeep(defaultConfig));
  // const [sample, setSample] = useState();
  const [classifiers, setClassifiers] = useState();

  const { t } = useTranslation();
  const history = useHistory();
  const { objectId } = useParams();
  const classes = useStyles();

  const fetchData = async () => {
    setLoading(true);

    try {
      const { data } = await api.get(`/estimates/${objectId}/import/describe`);
      setObject(data);
    } catch (err) {
      console.error(err);
      window.alert(`${t('Klaida:')} ${err.message}`);
    }

    setLoading(false);
  };

  const fetchClassifiers = async () => {
    try {
      const { data } = await api.get('/classifiers?lite=true');

      setClassifiers(data);
    } catch (err) {
      console.err(err);
    }
  };

  useEffect(() => {
    fetchData();
    fetchClassifiers();
  }, [])

  const uploadSource = async (sourceData) => {
    let success = false;
    setLoading(true);

    try {
      const fd = new FormData();
      fd.append('file', sourceData.file);
      fd.append('columnNames', sourceData.columnNames);
      fd.append('createNewEstimate', sourceData.createNewEstimate);
      fd.append('estimateItemId', !sourceData.createNewEstimate ? sourceData.estimateItemId : null);

      const { data } = await api({
        method: 'post',
        url: `/estimates/${objectId}/import/process-source`,
        data: fd,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setRecord({
        ...record,
        source: sourceData,
        headers: data.headers,
        sample: data.sample,
        rowCount: data.rowCount,
        uploadId: data.uploadId,
        // rows: data.rows,
      });

      success = true;
    } catch (err) {
      console.error(err);
      window.alert(err.message);
    }

    setLoading(false);
    return success;
  }

  // const fetchPreview = async (data) => {
  //   setLoading(true);

  //   try {
  //     const { data: preview } = await api.post(`/estimates/${objectId}/import/preview`, data);
  //     setSample(preview);
  //   } catch (err) {
  //     console.error('Unable to fetch preview', err);
  //     // window.alert('Nenumatyta klaida');
  //     setSample(null);
  //   }

  //   setLoading(false);
  // }

  const submitImport = async () => {
    setLoading(true);

    try {
      // await api.post(`/estimates/${objectId}/import`, record);
      await api.post(`/estimates/${objectId}/import`, record);

      history.push(`/projects/${object.project._id}/objects/${object._id}`);
    } catch (err) {
      console.error(err);
      window.alert(t('Nepavyko importuoti'));
    }

    setLoading(false);
  };

  const importActions = {
    getClassifierFullname: (value) => {
      if (!value) return t('Nepriskirta reikšmė');

      const classifier = classifiers.find(({ code }) => code == value) ?? null;
      if (!classifier) return value;

      return `${classifier.code} - ${classifier.title}`;
    },
    setSourceResult: async (sourceData) => {
      await uploadSource(sourceData);

      // setSample(null);

      setActiveTab(TABS.MAPPING);
    },
    setMappingResult: async (mappingResult) => {
      const payload = { ...record, mapping: mappingResult };
      setRecord(payload);

      // fetchPreview(payload);
    },
    setStructureResult: async (structureResult) => {
      const payload = { ...record, structure: structureResult };
      setRecord(payload);

      // fetchPreview(payload);
    },
  }

  const importContextValue = useMemo(() => ({
    loading,
    record,
    setLoading,
    importActions,
    config,
    // sample,
    object,
    classifiers,
  }), [
    // activeTab,
    record,
    loading,
    config,
    // sample,
    object,
  ]);

  return (
    <DashboardLayout
      isContentLoading={loading}
      title={t('Duomenų importavimas')}
      breadcrumbs={[
        { title: t('Projektai'), to: '/projects/' },
        { title: object?.project?.title || '-', to: `/projects/${object?.project?._id}/dashboard` },
        { title: t('Objektai') },
        { title: object?.title || '-', to: `/projects/${object?.project?._id}/objects/${object?._id}` },
        { title: t('Duomenų importavimas') },
      ]}
    >
      <ImportContext.Provider value={importContextValue}>
        <div className={classes.root}>
          <Grid container spacing={2} style={{ marginBottom: 10 }}>
            <Grid item xs={6}>
              <Paper>
                <Tabs
                  value={activeTab}
                  onChange={(_e, newVal) => setActiveTab(newVal)}
                  scrollButtons="auto"
                  variant="scrollable"
                >
                  <Tab label={t('Duomenų šaltinis')} disabled={loading} />
                  <Tab label={t('Duomenų priskyrimas')} disabled={loading || !record.uploadId} />
                  <Tab label={t('Struktūros formavimas')} disabled={loading || !record.uploadId} />
                  <Tab label={t('Peržiūra')} disabled={loading || !record.uploadId} />
                </Tabs>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <div style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => submitImport()}
                  disabled={loading || !record.uploadId || !record.structure?.length}
                >
                  {t('Importuoti')}
                </Button>
              </div>
            </Grid>
          </Grid>

          <>
            {/* {!record.uploadId && <SourceSelector />}
            {!!record.uploadId && <MappingManager />} */}
            {activeTab === TABS.SOURCE && <SourceSelector />}
            {/* {activeTab === TABS.FILTERS && <FilterManager />} */}
            {activeTab === TABS.MAPPING && <MappingManager />}
            {activeTab === TABS.STRUCTURE && <StructureManager />}
            {/* {activeTab === TABS.GROUPING && <GroupingManager />} */}
            {activeTab === TABS.PREVIEW && <ImportPreview />}
          </>

        </div>
      </ImportContext.Provider>
    </DashboardLayout>
  )
}

export default ImportManager;