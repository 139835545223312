import React, { Component } from 'react';
import _ from 'lodash';
import { withStyles, MenuItem, FormControlLabel } from '@material-ui/core';
import { TextField, Select, Checkbox } from 'final-form-material-ui';
import { Field } from 'react-final-form'

import SelectMulti from './components/SelectMulti';
import NtkFullTreeSelect from './components/NtkFullTreeSelect';
import styles from './styles';

class FormField extends Component {
  _isMounted = false;

  state = {
    options: null,
  }

  async componentDidMount() {
    this._isMounted = true;

    if (!this.props.options) {
      return;
    }

    const fieldOptions = await this.props.options();

    const options = _.map(fieldOptions, ({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ));

    if (this._isMounted) {
      this.setState({ options });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, ...rest } = this.props;
    delete rest.options;

    if (rest.type === 'ntk-full-tree-select') {
      return (
        <NtkFullTreeSelect
          name={rest.name}
          buildingType={rest.value}
          onChange={(e) => console.log({ e })}
        />
      );
    }
    
    if (rest.type === 'checkbox') {
      return (
        <FormControlLabel
          control={
            <Field
              // className={classes.textField}
              // fullWidth
              component={Checkbox}
              {...rest}
            />
          }
          label={rest.label}
          labelPlacement="end"
        />
          
      );
    }

    if (this.state.options) {
      const isMultiple = rest.multiple;

      return (
        <Field
          name={rest.name}
          label={rest.label}
          formControlProps={{
            className: classes.selectField,
            fullWidth: true,
          }}
          component={isMultiple ? SelectMulti : Select}
        >
          {this.state.options}
        </Field>
      );
    }

    return (
      <Field
        className={classes.textField}
        fullWidth
        component={TextField}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(FormField);