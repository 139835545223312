import _ from 'lodash';
import * as projectService from 'views/Projects/shared/_services';
import { getAll as getAllAccounts } from 'services/accounts';
// import * as classifierService from 'views/Classifier/shared/_services';
import { canAccess } from 'utils/helpers';
// import * as dataService from 'views/Classifier/shared/_services';
// import * as groupService from 'views/ClassifierGroups/shared/_services';


const parentCodeOptions = async () => {
  const records = await projectService.getAll();

  return _.map(records, record => ({ 
    value: record._id, 
    label: `${record.code} - ${record.title}`,
  }));
};

const ownerOptions = async () => {
  const accounts = await getAllAccounts();

  return _.map(accounts, account => ({
    value: account._id,
    label: account.user ? `${account.user.fullName} - ${account.position}${account.company ? `, ${account.company.name}` : ''}` : '-',
  }));
};

// const stageOptions = async () => {
//   const classifiers = await classifierService.search('ST.');

//   return _.map(classifiers, classifier => ({
//     value: classifier.code,
//     label: classifier.title,
//   }));
// };

// const statusOptions = async () => {
//   const classifiers = await classifierService.search('N.S');

//   return _.map(classifiers, classifier => ({
//     value: classifier.code,
//     label: classifier.title,
//   }));
// };

// const countryOptions = async () => {
//   const classifiers = await classifierService.search('BS.CN.');

//   return _.map(classifiers, classifier => ({
//     value: classifier.code,
//     label: classifier.title,
//   }));
// };

// const unitOptions = async () => {
//   const classifiers = await classifierService.search('PR.UM.');

//   return _.map(classifiers, classifier => ({
//     value: classifier.code,
//     label: classifier.title,
//   }));
// };

const getFields = () => {
  const fields = [
    {
      name: 'code',
      label: 'Kodas',
      type: 'text',
    },
    {
      name: 'parent',
      label: 'Priklauso kodui',
      type: 'select',
      options: parentCodeOptions,
    },
    {
      name: 'title',
      label: 'Pavadinimas',
      type: 'text',
    },
    // {
    //   name: 'type',
    //   label: 'Paskirtis',
    //   type: 'ntk-full-tree-select',
    // },
    // {
    //   name: 'stage',
    //   label: 'Stadija',
    //   type: 'select',
    //   options: stageOptions,
    // },
    // {
    //   name: 'status',
    //   label: 'Būsena',
    //   type: 'select',
    //   options: statusOptions,
    // },
    // {
    //   name: 'country',
    //   label: 'Šalis',
    //   type: 'select',
    //   options: countryOptions,
    // },
    // {
    //   name: 'city',
    //   label: 'Miestas',
    //   type: 'text',
    // },
    {
      name: 'address',
      label: 'Adresas / vieta',
      type: 'text',
    },
    // {
    //   name: 'amount',
    //   label: 'Kiekis',
    //   type: 'text',
    // },
    // {
    //   name: 'units',
    //   label: 'Vienetai',
    //   type: 'select',
    //   options: unitOptions,
    // },
    // {
    //   name: 'totalPrice',
    //   label: 'Visa kaina',
    //   type: 'text',
    // },
    {
      name: 'comment',
      label: 'Pastabos',
      type: 'text',
    },
  ];

  if (canAccess('administrator.layout')) {
    fields.push({
      name: 'owner',
      label: 'Projektą sukūrė',
      type: 'select',
      options: ownerOptions,
    });
  }

  return fields;
};

export default getFields;