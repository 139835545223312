import * as dataService from '../../shared/_services';
import {
  SENDING_REQUEST,
  FAILURE,
  GET_TREE_SUCCESS,
} from './_constants';

export const getTree = () => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const tree = await dataService.getTree();

    dispatch({ type: GET_TREE_SUCCESS, tree });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }
}
