import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import api from 'utils/api';
import { Dashboard as DashboardLayout } from 'layouts';
import userConstants from 'views/Users/_constants';
import appConstants from 'views/App/_constants';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    // maxWidth: '1200px',
  },
  wrap: {
    textAlign: 'center',
  },
}));

const Manage = () => {
  const { id: userId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const reAuth = async () => {
    setLoading(true);

    try {
      const { data: { user, lastAccount, token } } = await api.get(`/users/${userId}/manage`);
      
      localStorage.setItem('jwt', token);
      
      dispatch({ type: userConstants.LOGIN_SUCCESS, user });
      dispatch({ type: appConstants.SET_ACCOUNT_SUCCESS, account: lastAccount });

      history.push('/');
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    reAuth();
  }, []);

  return (
    <DashboardLayout 
      isContentLoading={loading}
      title={t('Vartotojo valdymas')}
    >
      <div className={classes.root}>
        <div className={classes.wrap}>
          <Typography variant="h3">
            {t('Prijungiama...')}
          </Typography>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Manage;