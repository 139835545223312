import {
  REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
} from './_constants';
import * as dataService from 'views/Projects/shared/_services';

export const getProject = (id) => async (dispatch) => {
  dispatch({ type: REQUEST });

  try {
    const project = await dataService.getOne(id);

    dispatch({ type: GET_PROJECT_SUCCESS, project });
  } catch (error) {
    dispatch({ type: GET_PROJECT_FAILURE, error });
  }
}

