export default () => ({
  root: {
    display: 'flex',
    boxShadow: 'none',
  },
  rootInitial: {
    backgroundColor: '#f8fafc',
  },
  rootChanged: {
    backgroundColor: 'rgb(184, 212, 247)',
  },
  iconButton: {
    padding: '0px 5px',
  },
  iconButtonInitial: {
    visibility: 'hidden',
  },
  iconButtonChanged: {
    visibility: 'visible',
  },
  textField: {
    color: 'black',
    paddingLeft: '5px',
    paddingRight: '5px',
    flex: 1,
  },
});
