import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { DataTable, CommonPanel } from 'components';
import _ from 'lodash';
import moment from 'moment';

import styles from './styles';
import { getCurrentAccount } from 'utils/helpers';
import { history } from 'utils/history';
import { getRecords, clearData, batchDelete } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { recoveryPriceList } from './_reducers';
store.injectReducer('recoveryPriceList', recoveryPriceList);

class RecoveryPriceTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      searchText: null
    };

    this.onSearchChange = _.debounce(this.onSearchChange, 500);
  }

  componentDidMount() {
    const { dispatch, projectId } = this.props;
    dispatch(getRecords(projectId ? { project: projectId } : null));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearData());
  }

  onSearchChange(page, pageSize, sort, search) {
    if (search?.length > 0) {
      this.setState({
        searchText: search
      });
    } else this.setState({ searchText: '' });
    this.handleTableChange(page, pageSize, sort, search);
  }

  createNew() {
    const account = getCurrentAccount();
    const { records, projectId, t } = this.props;

    if (account.isDefault && records.length) {
      window.alert(t('Pasiektas DEMO aplinkos atk. vertės įrašų limitas'));
      return;
    }

    const createUrl = `/recovery-price/create${
      projectId ? '?project=' + projectId : ''
    }`;
    history.push(createUrl);
  }

  prepareTableColumns(projectId) {
    const { t } = this.props;

    const columns = [
      'ID',
      'LINK',
      t('Skaičiavimo pavadinimas'),
      ...(!projectId ? [t('Projektas')] : []),
      t('Tipas'),
      t('Versija'),
      t('Kiekis'),
      t('Mat. vnt.'),
      t('Visa vertė (su PVM)'),
      t('Pastabos'),
      t('Sukūrė'),
      t('Sukūrimo data'),
      t('Atnaujino'),
      t('Atnaujinimo data')
    ];

    return columns;
  }

  prepareTableData(records, projectId) {
    const { t } = this.props;

    const data = _.map(records, record => {
      let row = [];

      row.push(record.id);
      row.push(`/recovery-price/${record._id}`);
      row.push(record.title || 'Nenurodytas');
      if (!projectId) {
        row.push(record.project ? record.project.title : '-');
      }
      row.push(record.type);
      row.push(record?.pricing?.version || '-');
      row.push(record.amount);
      row.push(record.unit);
      row.push(
        record.totals.totalRevisedPrice
          ? `${record.totals.totalRevisedPrice} EUR`
          : '-'
      );
      row.push(this.renderDescriptionValue(record.description));
      row.push(
        record.owner && record.owner.user
          ? record.owner.user.fullName
          : t('Neegzistuoja')
      );
      row.push(
        record.createdAt
          ? moment(record.createdAt)
              .utc()
              .format('YYYY-MM-DD')
          : null
      );
      row.push(this.renderUpdatedByValue(record));
      row.push(
        record.updatedAt
          ? moment(record.updatedAt)
              .utc()
              .format('YYYY-MM-DD')
          : null
      );
      return row;
    });

    return data;
  }

  onDelete(indexes) {
    const { dispatch, records } = this.props;

    const ids = _.map(indexes, index => records[index]._id);

    if (ids.length) {
      dispatch(batchDelete(ids));
    }
  }

  renderUpdatedByValue({ updatedBy }) {
    try {
      return updatedBy ? updatedBy.user.fullName : null;
    } catch (err) {
      return null;
    }
  }

  renderDescriptionValue(description) {
    if (!description) {
      return null;
    }

    return (
      <span title={description}>{_.truncate(description, { length: 60 })}</span>
    );
  }

  handleTableChange(page, pageSize, sort, search) {
    const { dispatch, projectId } = this.props;
    const filter = { page, pageSize, sort, search };
    if (projectId) filter.project = projectId;

    dispatch(getRecords(filter));
  }

  renderTable() {
    const { classes, isLoading, records, projectId, t } = this.props;

    const columns = this.prepareTableColumns(projectId);
    const data = this.prepareTableData(records, projectId);

    const tableOptions = {
      serverSide: true,
      count: this.props.totalRecords || 0,
      searchText: this.state.searchText,
      pagination: isLoading ? false : true,
      textLabels: {
        body: {
          noMatch: isLoading ? t('Įkeliama') : t('Įrašų nerasta')
        }
      },
      // TODO search needs crafting...
      // onSearchChange: (q) => this.handleTableChange(null, null, null, q),
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changeRowsPerPage':
          case 'changePage':
          case 'search':
            this.onSearchChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortOrder,
              tableState.searchText
            );
            break;
          case 'sort':
            this.handleTableChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortOrder,
              tableState.searchText
            );
            break;
          default:
            // Not handling other actions
            break;
        }
      }
    };

    return (
      <DataTable
        title={
          projectId ? t('Susiję atkuriamųjų kaštų skaičiavimai') : undefined
        }
        columns={columns}
        data={isLoading ? [] : data}
        tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
        createHandler={this.createNew.bind(this)}
        onDeleteBatch={indexes => this.onDelete(indexes)}
        onDelete={index => this.onDelete([index])}
        options={tableOptions}
      />
    );
  }

  render() {
    return <CommonPanel noPadding>{this.renderTable()}</CommonPanel>;
  }
}

const mapStateToProps = ({ recoveryPriceList, dispatch }) => {
  return {
    ...recoveryPriceList,
    dispatch
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation()
)(RecoveryPriceTable);
