import NewsList from './NewsList';
import NewsForm from './NewsForm';

export default [
  {
    key: 'list',
    component: NewsList,
    path: '/news',
    public: false,
    canReloadOnLinkClick: false,
  },
  {
    key: 'create',
    component: NewsForm,
    path: '/news/create',
    public: false,
  },
  {  
    key: 'edit',
    component: NewsForm,
    path: '/news/:id',
    public: false,
  },
];