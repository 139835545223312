import _ from 'lodash';
import { toast } from 'views/App/_actions';
import * as groupService from 'views/PermissionGroups/shared/_services';
import * as categoryService from 'views/PermissionCategories/shared/_services';
import {
  SENDING_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  SET_VALUE,
  FAILURE,
} from './_constants';

export const getGroupsAndCategories = () => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const groups = await groupService.getAll();
    dispatch({ type: GET_GROUPS_SUCCESS, groups });

    const categories = await categoryService.getAll();
    dispatch({ type: GET_CATEGORIES_SUCCESS, categories });

    dispatch(setSelected());
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }
}

export const setSelected = (modifiedSelection) => async (dispatch, getState) => {
  if (modifiedSelection) {
    dispatch({ type: SET_VALUE, data: { name: 'selected', value: modifiedSelection }});
    return;
  }

  const { groups } = getState().permissionMatrix;
  
  // Collect permissions assigned to permission groups
  // Structure: { permissionGroupId: [permissionIds] }
  let selected = {};
  _.forEach(groups, group => {
    if (group.permissions.length) {
      selected[group._id] = _.map(group.permissions, permission => permission._id);
    }
  })
  console.log('action', { selected, groups });
  dispatch({ type: SET_VALUE, data: { name: 'selected', value: selected }});
}

export const getGroups = () => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const groups = await groupService.getAll();

    dispatch({ type: GET_GROUPS_SUCCESS, groups });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }
}

export const getCategories = () => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const categories = await categoryService.getAll();

    dispatch({ type: GET_CATEGORIES_SUCCESS, categories });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }
}

export const batchUpdateGroups = (modifiedGroups) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const groups = await groupService.updateBatch(modifiedGroups);

    dispatch({ type: GET_GROUPS_SUCCESS, groups });
    dispatch(setSelected());

    toast('Išsaugota', 'success');
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }
}
