import React, { Component } from 'react';
import NormPackageListContainer from './NormPackageListContainer';
import store from './_store';

class NormPackageList extends Component {

  render() {
    return <NormPackageListContainer store={store} />
  }
}

export default NormPackageList;