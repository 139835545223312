import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { PrivateRoute } from 'components';

// Views
import Dashboard from 'views/Dashboard';
import SignIn from './views/SignIn';
import SignUp from 'views/SignUp';
import RecoveryPriceClone from 'views/RecoveryPrice/components/RecoveryPriceClone/RecoveryPriceClone';
import PasswordReset from 'views/PasswordReset';
import projectRoutes from 'views/Projects/_routes';
import documentRoutes from 'views/Documents/_routes';
import accountRoutes from 'views/Accounts/_routes';
import userRoutes from 'views/Users/_routes';
import companyRoutes from 'views/Companies/_routes';
import permissionRoutes from 'views/Permissions/_routes';
import permissionGroupRoutes from 'views/PermissionGroups/_routes';
import permissionCategoriesRoutes from 'views/PermissionCategories/_routes';
import permissionMatrixRoutes from 'views/PermissionMatrix/_routes';
import recoveryPriceRoutes from 'views/RecoveryPrice/_routes';
import investmentViewerRoutes from 'views/InvestmentViewer/_routes';
import ntkViewerRoutes from 'views/NtkViewer/_routes';
import normsRoutes from 'views/Norms/_routes';
import normsCalcRoutes from 'views/NormsCalc/_routes';
import normPackagesRoutes from 'views/NormPackages/_routes';
import classifierGroupsRoutes from 'views/ClassifierGroups/_routes';
import classifierRoutes from 'views/Classifier/_routes';
import templatesRoutes from 'views/Templates/_routes';
import treeTemplatesRoutes from 'views/TreeTemplates/_routes';
import servicePackages from 'views/ServicePackages/_routes';
import services from 'views/Services/_routes';
import orders from 'views/Orders/_routes';
import discountCodes from 'views/DiscountCodes/_routes';
import accountLicenceLogs from 'views/AccountLicenceLogs/_routes';
import news from 'views/News/_routes';


class Routes extends Component {

  renderRoutes(routes) {
    return _.map(routes, (route) => {
      if (route.public) {
        return (
          <Route
            exact
            {...route}
          />
        )
      } else {
        return (
          <PrivateRoute
            exact
            {...route}
          />
        )
      }
    });
  }

  render() {
    const { isLoading } = this.props;

    return (
      <div>
        <Switch>
          <Redirect
            exact
            from="/"
            to="dashboard"
          />
          {/* TODO: refactor to GuestRoute that redirects to dashboard if already authenticated */}
          <Route
            component={SignIn}
            exact
            path="/login"
          />
          {/* TODO: refactor to GuestRoute that redirects to dashboard if already authenticated */}
          <Route
            component={SignUp}
            exact
            path="/register"
          />
          <Route
            component={SignUp}
            exact
            path="/activate/:hash"
          />
          <Route
            component={PasswordReset}
            exact
            path="/password-reset/:hash?"
          />
          <PrivateRoute
            component={Dashboard}
            exact
            path="/dashboard"
          />
          
          {_.map([
            userRoutes,
            accountRoutes,
            projectRoutes,
            companyRoutes,
            documentRoutes,
            permissionRoutes,
            permissionGroupRoutes,
            recoveryPriceRoutes,
            investmentViewerRoutes,
            ntkViewerRoutes,
            normsRoutes,
            normsCalcRoutes,
            normPackagesRoutes,
            classifierGroupsRoutes,
            permissionCategoriesRoutes,
            permissionMatrixRoutes,
            classifierRoutes,
            templatesRoutes,
            treeTemplatesRoutes,
            servicePackages,
            services,
            orders,
            discountCodes,
            accountLicenceLogs,
            news,
          ], (routes) => {
            return this.renderRoutes(routes);
          })}
          
        </Switch>

        <Switch>
          <PrivateRoute
            component={RecoveryPriceClone}
            exact
            path="/recovery-price/:id/clone"
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return { ...app };
}

Routes.propTypes = {
  isLoading: PropTypes.bool,
};


export default connect(mapStateToProps)(Routes);