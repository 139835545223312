import React from 'react';
import { compose } from 'recompose';
import {
  withStyles,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import { Form, NtkMaterialPickerHorizontal } from 'components';

import styles from './styles';

class OptionsBlock extends Form {

  render() {
    const { record, options } = this.props.store;

    if (!record.buildingType) return null;

    return (
      <NtkMaterialPickerHorizontal
        materials={options.materials}
        initialMaterials={options.initialMaterials}
        handleChange={this.onChange.bind(this)}
      />
    )
  }
}

export default compose(withStyles(styles), inject('store'))(observer(OptionsBlock));