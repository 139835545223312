export default theme => ({
  closeBtn: {
    position: 'absolute',
    right: '10px',
    top: 0,
  },

  loaderWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,0.35)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 9999,
  },

  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    flexGrow: 1,
  }
});