import React, { Component } from 'react';
import { Provider } from 'react-redux';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/scss/index.scss';
import 'react-toastify/dist/ReactToastify.css';

import { store } from 'utils/store';

import App from 'views/App';

export default class AppWrapper extends Component {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}