import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

// Shared components
import { CommonPanel } from 'components';

// Component styles
import styles from './styles';

class MaterialChooser extends Component {
  state = {
    selectedMaterials: []
  };

  componentWillMount() {
    const { recordMaterials } = this.props;

    if (recordMaterials.length) {
      this.setState({ selectedMaterials: [...recordMaterials] });
    }
  }

  onChange({ target }) {
    const { handleChange } = this.props;
    const [type, value] = target.value.split('|');
    const { selectedMaterials } = this.state;
    const existing = _.find(selectedMaterials, { type });

    if (target.checked) {
      // Add to selection

      if (existing) {
        // Type was already selected
        existing.values = [...existing.values, value];
      } else {
        // Type wasn't selected yet
        selectedMaterials.push({ type, values: [value] });
      }
    } else {
      // Remove from selection
      _.remove(existing.values, val => val === value);
      _.remove(selectedMaterials, material => material.values.length === 0);
    }

    this.setState({
      selectedMaterials
    });

    handleChange({
      name: 'materials',
      value: selectedMaterials
    });
  }

  renderMaterials() {
    const { materials, initialMaterials, recordMaterials } = this.props;

    return _.map(initialMaterials, ({ type, values }) => {
      // Collect material options (combine type with value for actual control value)
      const checkboxes = _.map(values, value => {
        // Disable all unchecked inputs if there's no available materials left
        // otherwise disable inputs by material availability
        const material = _.find(
          materials.length ? materials : recordMaterials,
          { type }
        );
        const disabled = material
          ? material.values.indexOf(value) === -1
          : true;
        const joinedValue = [type, value].join('|');

        return (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                onChange={this.onChange.bind(this)}
                value={joinedValue}
                checked={
                  _.find(
                    recordMaterials,
                    rec => rec.type == type && rec.values.indexOf(value) >= 0
                  )
                    ? true
                    : false
                }
              />
            }
            label={value}
            disabled={disabled}
          />
        );
      });

      // Render material column with options
      return (
        <Grid key={type} item sm={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{type}</FormLabel>
            <FormGroup>{checkboxes}</FormGroup>
          </FormControl>
        </Grid>
      );
    });
  }

  render() {
    const { t } = this.props;

    return (
      <CommonPanel title={t('Statinio parametrai')}>
        <Grid container>{this.renderMaterials()}</Grid>
      </CommonPanel>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(MaterialChooser);
