import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Button,
  Grid,
  Chip,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import moment from 'moment';

import { CommonDialog, FormField2 } from 'components';
import { timestampToDate } from 'utils/helpers';


import styles from './styles';

class Container extends React.Component {
  state = {
    showModal: false,
    record: {},
  }

  componentDidMount() {
    const { account, company, order } = this.props;
    if (account) this.props.store.setSingleAccount(account);

    this.props.store.setCompany(company);
    this.props.store.loadData({ orderId: order });
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  onShowModal(idx) {
    const account = this.props.store.data.accounts[idx];
    this.props.store.setAccount(account);
    this.props.store.loadServices();

    this.setState({ showModal: true });
  }

  onModalClose() {
    this.props.store.setOrderItemDetails(null);
    this.props.store.resetMultipleOrderItemDetails();

    this.setState({ showModal: false, record: {} });
  }

  onModalSave() {
    if (this.props.store.multipleOrderItemDetails.length > 0) {
      this.props.store.createMultipleAccountLicences();
    } else {
      this.props.store.createAccountLicence({ ...this.state.record });
    }
    this.onModalClose();
  }

  async onRemoveLicence(accountId, licenceId) {
    const { t } = this.props;
    if (window.confirm(t('Ar tikrai norite pašalinti priskirtą darbo vietą?'))) {
      await this.props.store.removeAccountLicence(accountId, licenceId);
    }
  }

  onAllServiceChange({ name, value, orderItem, order, service }) {
    const record = this.state.record || {};
    record[name] = value;

    switch (name) {
      case 'service':
        this.props.store.setOrderItemDetails(null);
        this.props.store.resetMultipleOrderItemDetails();
        this.props.store.loadServicePackages(value);
        break;

      case 'orderItem':
        this.props.store.setMultipleOrderItemDetails(service, orderItem, order, value);
        break;

      default:
        break;
    }

    this.setState({
      record,
    });
  }

  onChange({ name, value }) {
    const record = this.state.record || {};
    record[name] = value;

    switch (name) {
      case 'service':
        this.props.store.setOrderItemDetails(null);
        this.props.store.resetMultipleOrderItemDetails();
        this.props.store.loadServicePackages(value);
        break;

      case 'orderItem':
        this.props.store.setOrderItemDetails(value);
        break;

      default:
        break;
    }

    this.setState({
      record,
    });
  }

  renderFormField({ name, ...field }) {
    const { record } = this.state;
    
    return (
      <FormField2
        {...field}
        name={name}
        key={name}
        value={record ? record[name] : (field.multiple ? [] : '')}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderPackageSelector() {
    const { classes, store, t } = this.props;
    
    if (!store.data.servicePackages.length) return null;

    return (
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2} />
              <TableCell rowSpan={2} align="center">{t('Paslaugos paketas')}</TableCell>
              <TableCell colSpan={2} align="center">{t('Darbo vietos')}</TableCell>
              <TableCell rowSpan={2} align="center" width={100}>{t('Galioja iki')}</TableCell>
              <TableCell rowSpan={2} align="center">{t('Funkcionalumas')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" width={70}>{t('Laisvos')}</TableCell>
              <TableCell align="center" width={70}>{t('Viso')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.data.servicePackages?.map(pkg => (
              <TableRow key={pkg._id}>
                <TableCell align="center">
                  <input type="radio" name="orderItem" value={pkg._id} onChange={e => this.onChange({ name: 'orderItem', value: e.target.value })} />
                </TableCell>
                <TableCell>{pkg.title}</TableCell>
                <TableCell align="center">{this.renderFreeLicences(pkg.freeLicences)}</TableCell>
                <TableCell align="center">{pkg.licences}</TableCell>
                <TableCell align="center">{moment(pkg.expiresAt).utc().format('YYYY-MM-DD')}</TableCell>
                <TableCell>{pkg.ruleTitles?.join('. ')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  renderAllPackageSelector() {
    const { classes, store, t } = this.props;
    
    if (!store.data.servicePackages.length) return null;

    return (
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2} />
              <TableCell rowSpan={2} align="center">{t('Paslaugos paketas')}</TableCell>
              <TableCell colSpan={2} align="center">{t('Darbo vietos')}</TableCell>
              <TableCell rowSpan={2} align="center" width={120}>{t('Galioja iki')}</TableCell>
              <TableCell rowSpan={4} align="center" width={180}>{t('Užsakymas')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" width={70}>{t('Laisvos')}</TableCell>
              <TableCell align="center" width={70}>{t('Viso')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.data.servicePackages?.map(pkg => (
              <>
                <TableRow key={pkg.title}>
                  <TableCell colSpan={6} align="center" height={40}><b>{pkg.title}</b></TableCell>
                </TableRow>
                {store.accountHasService(pkg.service) ? 
                  <TableRow>
                    <TableCell colSpan={6} style={{textAlign: 'right', color: 'red'}}>
                      {`* ${t('Paslauga šiai paskyrai jau yra priskirta')}`}
                    </TableCell>
                  </TableRow>
                : null}
                {pkg?.packages?.map(servicePackage => (
                  <TableRow key={servicePackage._id}>
                    <TableCell align="center">
                      <input 
                        type="checkbox" 
                        name={`orderItem-${pkg.service}`} 
                        value={servicePackage._id} 
                        disabled={servicePackage.freeLicences == 0}
                        onChange={e => this.onAllServiceChange({ name: 'orderItem', service: pkg.service, orderItem: servicePackage._id, order: servicePackage.order._id, value: e.target.value })} 
                      />
                    </TableCell>
                    <TableCell>{servicePackage.title}</TableCell>
                    <TableCell align="center">{this.renderFreeLicences(servicePackage.freeLicences)}</TableCell>
                    <TableCell align="center">{servicePackage.licences}</TableCell>
                    <TableCell align="center">{moment(servicePackage.expiresAt).utc().format('YYYY-MM-DD')}</TableCell>
                    <TableCell>
                      <Link to={`/orders/${servicePackage.order._id}`} target="_blank">
                        {servicePackage.order.number}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
                <br />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  renderFreeLicences(licences) {
    const color = licences > 0 ? '#19ca19' : 'inherit';

    return (
      <Typography variant="body1" style={{ color, fontWeight: 'bold' }}>
        {licences}
      </Typography>
    )
  }

  renderModalForm() {
    const { classes, store, t } = this.props;
    const { record } = this.state;
    const { data } = store;

    const serviceOptions = _.map(data.services, ({ title, _id }) => ({
      label: title, 
      value: _id,
    }));

    serviceOptions.push({
      label: t("Visos paslaugos"),
      value: 'all',
    });

    return (
      <div style={{ padding: '0px 10px 10px' }}>
        <Grid container spacing={1}>
          <Grid item xs>
            {this.renderFormField({ name: 'service', label: t('Paslauga'), type: 'select', options: serviceOptions })}

            {record.service && store.accountHasService(record.service) && record.service != 'all' ? (
              <Typography
                variant="body2"
                style={{
                  padding: 10,
                  fontWeight: 'bold',
                  backgroundColor: '#ffc107',
                  marginBottom: 5,
                  borderRadius: 5,
                }}
              >
                {t('Paslauga šiai paskyrai jau yra priskirta')}
              </Typography>
            ) : null}

            {/* {record.service && record.service != 'all' && data.servicePackages.length ? this.renderPackageSelector() : null} */}
            {record.service && data.servicePackages.length ? record.service != 'all' ? this.renderPackageSelector() : this.renderAllPackageSelector() : null}

            {record.service && !data.servicePackages.length ? (
              <Typography variant="body1" style={{ marginTop: 10, textAlign: 'center'}}>
                {t('Nėra galiojančių licencijų pasirinktai paslaugai.')}
                <Link to="/orders/create" className={classes.link}>Užsakyti</Link>
              </Typography>
            ) : null}
          </Grid>

        </Grid>
      </div>
    )
  }

  renderModal() {
    const { showModal } = this.state;
    const { t, store } = this.props;
    const { orderItemDetails, multipleOrderItemDetails, account } = store;

    let actions = [];

    if (orderItemDetails && orderItemDetails.freeLicences > 0) {
      actions.push(
        <Button key="cancel" onClick={this.onModalSave.bind(this)} color="primary" variant="contained">
          {t('Priskirti darbo vietą')}
        </Button>
      );
    }

    if (multipleOrderItemDetails.length > 0) {
      actions.push(
        <Button key="cancel" onClick={this.onModalSave.bind(this)} color="primary" variant="contained">
          {t('Priskirti darbo vietas')}
        </Button>
      );
    }

    actions.push(
      <Button key="save" onClick={this.onModalClose.bind(this)} color="primary">
        {t('Atšaukti')}
      </Button>
    );

    return (
      <CommonDialog
        visible={showModal}
        title={`${t('Darbuotojas')} ${account ? account.user.fullName : '-'}`}
        onClose={() => this.onModalClose()}
        actions={actions}
        maxWidth="sm"
      >
        {this.renderModalForm()}
      </CommonDialog>
    )
  }

  renderOrderNumber({ order }) {
    const { t } = this.props;

    const orderNumber = (
      <Link to={`/orders/${order._id}`}>
        {order.number}
      </Link>
    );

    if (!_.get(order, 'trialServiceCode')) return orderNumber;

    return (
      <div style={{ textAlign: 'center' }}>
        {orderNumber}

        <div style={{ marginTop: 5 }}>
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={t('Bandomoji versija')}
            style={{ fontSize: '11px' }}
          />
        </div>
      </div>
    )
  }

  renderTable() {
    const { classes, t } = this.props;
    const { accounts } = this.props.store.data;

    if (!accounts.length) return null;

    let rows = [];

    _.forEach(accounts, (account, idx) => {
      const accountHeaderRow = (
        <TableRow key={`account-${account._id}`}>
          <TableCell colSpan={4} className={classes.accountHeaderRow}>
            {_.get(account, 'user.fullName', '-')} <span>({_.get(account, 'user.email', '-')})</span>
          </TableCell>
          <TableCell className={classes.accountHeaderRow} style={{ textAlign: 'right' }}>
            <Button key="cancel" onClick={() => this.onShowModal(idx)} color="primary" size="small">
              {t('Pridėti')}
            </Button>
          </TableCell>
          <TableCell className="actions" />
        </TableRow>
      )

      rows.push(accountHeaderRow);

      const accountLicenceRows = _.map(account.licences, licence => (
        <TableRow key={licence._id}>
          <TableCell>{licence.service.title}</TableCell>
          <TableCell>{licence.servicePackage ? licence.servicePackage.title : t('Individualus')}</TableCell>
          <TableCell>{licence.description}</TableCell>
          <TableCell>{this.renderOrderNumber(licence)}</TableCell>
          <TableCell>{timestampToDate(licence.expiresAt)}</TableCell>
          <TableCell className="actions">
            <Button onClick={() => this.onRemoveLicence(account._id, licence._id)} color="primary" size="small">
              {t('Pašalinti')}
            </Button>
          </TableCell>
        </TableRow>
      ));

      rows.push(accountLicenceRows);
    });

    return (
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('Paslauga')}</TableCell>
              <TableCell>{t('Paketas')}</TableCell>
              <TableCell>{t('Funkcionalumas')}</TableCell>
              <TableCell style={{ width: '170px' }}>{t('Užsakymas')}</TableCell>
              <TableCell>{t('Galioja iki')}</TableCell>
              <TableCell className="actions" style={{ width: '80px' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {

    return (
      <>
        {this.renderTable()}

        {this.renderModal()}
      </>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(Container));