import DataViewer from './components/DataViewer';

export default [
  {
    key: 'viewer',
    component: DataViewer,
    path: '/investments',
    public: false,
    canReloadOnLinkClick: true,
  },
];