import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Formik as Form, Field } from 'formik';
import * as Yup from 'yup';
import { deleteOne } from 'services/discountCodes'
import _ from 'lodash'

import { getOne, createOne, updateOne } from 'services/discountCodes';
import { toast } from 'utils/helpers';
import { Dashboard as DashboardLayout } from 'layouts';
import { FormField2 as FormField, CommonPanel } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    maxWidth: '1200px',
  }
}));

const DiscountCodesForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);
  // Take ID from URL parameter :id described in _routes.js file
  // If URL ends with `/create` the ID will be undefined
  const { id } = useParams();
  

  const loadRecord = async (id) => {
    setLoading(true);
    
    try {
      const data = await getOne(id);
      setRecord(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašą'), 'error');
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!loading && !record._id && id) loadRecord(id);
  }, []);

  const handleFormSubmit = async (values, actions) => {
    try {
      if (!id) {
        await createOne({ ...values });
      } else {
        await updateOne(id, { ...values });
      }

      toast(t('Išsaugota'), 'success');
      history.push('/discount-codes');
    } catch (e) {
      console.error(e);
      toast(t('Nepavyko išsaugoti'), 'error');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleChange = ({ name, value }) => {
    setRecord({
      ...record,
      [name]: value,
    });
  };

  const onDeleteDiscount = async (recordId) => {
    if (window.confirm(t('Ar tikrai norite pašalinti nuolaidos kodą?'))) {
      try {
        await deleteOne(recordId);
        
        toast(t('Pašalinta'), 'success');
        history.push('/discount-codes');
      } catch (err) {
        console.log(err);
      }
    }
  }

  const validationSchema = Yup.object().shape({
      code: Yup.string()
        .min(4, t('Kodas per trumpas'))
        .required(t('Užpildykite lauką')),
      maxLicences: Yup.number()
        .required(t('Užpildykite lauką'))
        .positive().integer(),
      orderDuration: Yup.number()
        .required(t('Užpildykite lauką'))
        .positive().integer(),
      discountPercent: Yup.number().when('discountAmount', {
          is: (discountAmount) => !discountAmount || discountAmount < 1,
          then: Yup.number().required(t('Užpildykite vieną iš nuolaidos laukų')).positive().integer(),
          otherwise: Yup.number()
        }),
      discountAmount: Yup.number().when('discountPercent', {
        is: (discountPercent) => !discountPercent || discountPercent < 1,
        then: Yup.number().required(t('Užpildykite vieną iš nuolaidos laukų')).positive().integer(),
        otherwise: Yup.number()
        }),
      maxUses: Yup.number()
        .required(t('Užpildykite lauką'))
        .positive().integer(),
      expiresAt: Yup.date()
        .required(t('Užpildykite lauką')),
      }, 
      [ [ 'discountAmount', 'discountPercent' ] ]
    )

  const renderFormikForm = ({
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    submitForm,
  }) => (
    <CommonPanel
      footer={(
        <div>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={submitForm}
          >
            {t('Išsaugoti')}
          </Button>
          {id ? 
            <Button
            visibility={'hidden'}
            style={{ 'marginLeft': '25px', 'backgroundColor': '#cb1a31' }}
            color="primary"
            variant="contained"
            onClick={() => onDeleteDiscount(record._id)}
            >
              {t('Ištrinti')}
            </Button>
          : null}
        </div>
      )}
    >
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Field
              error={errors.code && touched.code}
              helperText={errors.code && touched.code ? errors.code : null}
              component={FormField}
              fullWidth
              label={t('Kodas')}
              name={'code'}
              value={values.code || ''}
              onChange={handleChange}
            />
            <Field
              error={errors.maxLicences && touched.maxLicences}
              helperText={errors.maxLicences && touched.maxLicences ? errors.maxLicences : null}
              component={FormField}
              fullWidth
              label={t('Darbo vietų kiekis')}
              name={'maxLicences'}
              value={values.maxLicences || ''}
              onChange={handleChange}
              type={'number'}
              min={0}
              step={1}
            />
            <Field
              error={errors.orderDuration && touched.orderDuration}
              helperText={errors.orderDuration && touched.orderDuration ? errors.orderDuration : null}
              component={FormField}
              type={'select'}
              select
              fullWidth
              label={t('Paslaugų galiojimo intervalas')}
              name={'orderDuration'}
              value={values.orderDuration || ''}
              onChange={handleChange}
              options={[{value: "1", label: "1"},
                {value: "2", label: "2"},
                {value: "3", label: "3"},
                {value: "6", label: "6"},
                {value: "9", label: "9"},
                {value: "12", label: "12"},
                {value: "24", label: "24"},
              ]}
            />
            <Field
              error={errors.discountPercent && touched.discountPercent}
              helperText={errors.discountPercent && touched.discountPercent ? errors.discountPercent : null}
              component={FormField}
              fullWidth
              label={t('Nuolaida, %')}
              name={'discountPercent'}
              value={values.discountPercent || ''}
              onChange={handleChange}
              disabled={values.discountAmount > 0}
              type={'number'}
              min={0}
              max={100}
              step={1}
            />
            <Field
              error={errors.discountAmount && touched.discountAmount}
              helperText={errors.discountAmount && touched.discountAmount ? errors.discountAmount : null}
              component={FormField}
              fullWidth
              label={t('Nuolaida, EUR')}
              name={'discountAmount'}
              value={values.discountAmount || ''}
              onChange={handleChange}
              disabled={values.discountPercent > 0}
              type={'number'}
              min={0}
              step={1}
            />
            <Field
              error={errors.maxUses && touched.maxUses}
              helperText={errors.maxUses && touched.maxUses ? errors.maxUses : null}
              component={FormField}
              fullWidth
              label={t('Nuolaidos kodo panaudojimų limitas')}
              name={'maxUses'}
              value={values.maxUses || ''}
              onChange={handleChange}
              type={'number'}
              min={1}
              step={1}
            />
            <Field
              error={errors.expiresAt && touched.expiresAt}
              helperText={errors.expiresAt && touched.expiresAt ? errors.expiresAt : null}
              component={FormField}
              fullWidth
              label={t('Galioja iki')}
              name={'expiresAt'}
              value={values.expiresAt || ''}
              onChange={handleChange}
              type={'date'}
              format={'yyyy-MM-dd'}
              shrink={true}
            />
            <Field
              error={errors.comment && touched.comment}
              helperText={errors.comment && touched.comment ? errors.comment : null}
              component={FormField}
              fullWidth
              label={t('Pastabos')}
              name={'comment'}
              value={values.comment || ''}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </form>
    </CommonPanel>
  );

  const renderForm = () => {

    return (
      <Form
        enableReinitialize
        validateOnChange={false}
        initialValues={record}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
        render={renderFormikForm}
      />
    )
  }

  return (
    <DashboardLayout 
      isContentLoading={loading}
      title={t('Nuolaidų kodai')}      
      breadcrumbs={[
        { title: t('Administravimas') },
        { title: t('Nuolaidų kodai'), to: '/discount-codes' },
      ]}
    >
      <div className={classes.root}>
        {renderForm()}
      </div>
    </DashboardLayout>
  )
};

export default DiscountCodesForm;