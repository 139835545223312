import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';

import styles from './styles';

import { getPermissions, resetForm } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { permissionPicker } from './_reducers';
store.injectReducer('permissionPicker', permissionPicker);

class PermissionPicker extends Component {
  
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getPermissions());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetForm());
  }

  handleChange(event) {
    const { onChange, selected } = this.props;
    const { value, checked } = event.target;
    let currentSelection = selected || [];

    if (checked) {
      currentSelection.push(value);
    } else {
      currentSelection = _.pull(currentSelection, value);
    }

    onChange(_.uniq(currentSelection));
  }

  renderPermissionGroups() {
    const { classes, permissionGroups, selected, t } = this.props;
    const groupsWithPermissions = _.filter(permissionGroups, group => group.permissions && group.permissions.length);

    if (!groupsWithPermissions.length) {
      return (
        <Typography variant="h6">
          {t('Nėra teisių kategorijų su priskirtomis prieigos teisėmis')}
        </Typography>
      )
    }

    return _.map(groupsWithPermissions, group => (
      // Group
      <FormControl key={group._id} component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{group.title}</FormLabel>

        {_.map(group.permissions, ({ _id, title }) => (
          // Permission
          <FormGroup key={_id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={_.includes(selected, _id)}
                  onChange={this.handleChange.bind(this)}
                  value={_id}
                />}
              label={title}
            />
          </FormGroup>
        ))}
      </FormControl>
    ))
  }

  render() {
    const { classes, isLoading } = this.props;

    if (isLoading) {
      return <div />;
    }

    return (
      <div className={classes.root}>
        {this.renderPermissionGroups()}
      </div>
    )
  }
}

const mapStateToProps = ({ permissionPicker, dispatch }) => {
  return { 
    ...permissionPicker,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(PermissionPicker);