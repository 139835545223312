export default theme => ({
  root: {},
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '300px',
    maxWidth: '100%',
    marginRight: 0,
  },
  table: {
    border: '1px solid #DFE3E8',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#DFE3E8',
      cursor: 'pointer',
    },
  },
  tableWrap: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  selectedRow: {
    backgroundColor: '#aaaaaa',
  },
});
