import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
  Button,
} from '@material-ui/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { FormField2, CommonPanel } from 'components';
import NormPackages from 'views/NormPackages';

import { history } from 'utils/history';
import { toast } from 'utils/helpers';
import styles from './styles';

class NormPackageFormContainer extends React.Component {

  componentDidMount() {
    const { match, store } = this.props;

    store.getCollectionOptions();

    if (match && match.params.id) {
      store.getOne(match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  onChange({ name, value }) {
    const { store } = this.props;

    this.props.store.setRecord({
      ...store.record,
      [name]: value,
    });
  }

  async onDelete() {
    const { t } = this.props;
    if (!window.confirm(t('Ar tikrai norite pašalinti?'))) return;

    try {
      await this.props.store.delete();
      
      toast(t('Pašalinta'), 'success');
      history.push('/norm-packages');
    } catch(err) {
      const msg = _.get(err, 'response.data.error', t('Nepavyko pašalinti'));
      toast(msg, 'error');
    }
  }

  submitForm() {
    const { store, t } = this.props;

    if (!store.record || (!store.record.code || !store.record.title)) {
      toast(t('Kodas ir pavadinimas turi būti užpildyti'), 'warning');
      return;
    }

    if (store.record._id) {
      store.update();
    } else {
      store.create();
    }
  }

  renderFormField({ name, ...field }) {
    const { record } = this.props.store;
    
    return (
      <FormField2
        {...field}
        name={name}
        // autoComplete={`project-${name}`}
        key={name}
        value={record ? record[name] : (field.multiple ? [] : '')}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderForm() {
    const { t } = this.props;

    return (
      <div>

        {this.renderFormField({ name: 'code', label: t('Kodas'), type: 'text' })}

        {this.renderFormField({ name: 'title', label: t('Pavadinimas'), type: 'text' })}

        {this.renderFormField({ name: 'collections', label: t('Rinkiniai'), type: 'select', multiple: true, options: toJS(this.props.store.collectionOptions) })}
        
      </div>
    )
  }
  
  render() {
    const { classes, store, t } = this.props;
    const title = store.record ? `${t('Paketas')} ${store.record.title || '-'}` : t('Normatyvų paketo redagavimas');

    return (
      <NormPackages
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Normatyvų paketai'), to: '/norm-packages' },
          { title }
        ]}
      >
        <div className={classes.wrap}>
          
          <CommonPanel
            footer={(
              <>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.submitForm.bind(this)}
                >
                  {t('Išsaugoti')}
                </Button>

                {store.record ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.onDelete.bind(this)}
                    style={{ marginLeft: 10 }}
                  >
                    {t('Pašalinti')}
                  </Button>
                ) : null}
              </>
            )}
          >
            {this.renderForm()}
          </CommonPanel>

        </div>
      </NormPackages>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(NormPackageFormContainer));