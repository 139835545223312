const constants = {
  ACCOUNT_DIALOG_OPEN: 'APP_ACCOUNT_DIALOG_OPEN',
  ACCOUNT_DIALOG_CLOSED: 'APP_ACCOUNT_DIALOG_CLOSED',

  SET_ACCOUNT_REQUEST: 'ACCOUNTS_SET_ACCOUNT_REQUEST',
  SET_ACCOUNT_SUCCESS: 'ACCOUNTS_SET_ACCOUNT_SUCCESS',
  SET_ACCOUNT_FAILURE: 'ACCOUNTS_SET_ACCOUNT_FAILURE',

  SET_LOCALE: 'ACCOUNTS_SET_LOCALE',
  SET_USER: 'ACCOUNTS_SET_USER',
};

export default constants;