import TemplatesList from './components/TemplatesList';
import TemplatesForm from './components/TemplatesForm';

export default [
  {
    key: 'list',
    component: TemplatesList,
    path: '/templates',
    public: false,
  },
  {
    key: 'create',
    component: TemplatesForm,
    path: '/templates/create',
    public: false,
  },
  {
    key: 'edit',
    component: TemplatesForm,
    path: '/templates/:id',
    public: false,
  }
];