import { observable, action, decorate } from 'mobx';
import { getOne as getOneOrder, distribute } from 'services/orders';
import { getAll as getAllCompanies } from 'services/companies';

class Store {

  companies = [];
  order = null;

  reset() {
    this.companies = [];
    this.order = null;
  }

  setOrder(order) {
    this.order = order;
  }

  setCompanies(companies) {
    this.companies = companies;
  }

  async loadCompanies() {
    const companies = await getAllCompanies({ accounts: true, owner: true, orders: true });

    this.setCompanies(companies);
  }

  async loadOrder(orderId) {
    const order = await getOneOrder(orderId);

    this.setOrder(order);
    
    await this.loadCompanies();
  }

  async submit() {
    const { companies, order } = this;
    let data = [];

    companies.forEach(company => {
      if (!company.selected) return;

      data.push({
        company: company._id,
        licences: company.deployLicenceCount,
        deployLicences: company.deployLicences,
        months: company.deployMonths,
      });
    });

    await distribute(order._id, data);
  }
}

decorate(Store, {
  companies: observable,
  order: observable,
  setOrder: action,
  setCompanies: action,
  reset: action,
});

export default new Store;