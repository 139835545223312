import React from 'react';
// import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';

const defaultSearchStyles = theme => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    marginTop: '7px',
    marginRight: '8px',
  },
  searchText: {
    marginTop: '3px',
    flex: '0.8 0',
    '& input': {
      padding: '8px 14px'
    },
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

class TableSearch extends React.Component {
  handleTextChange = (event, clear = false) => {
    const value = clear ? null : event.target.value;
    this.props.onSearch(value);
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = event => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  onClear() {
    this.handleTextChange(null, true);
  }

  render() {
    const { classes, options, searchText } = this.props;

    return (
      <div className={classes.main} ref={el => (this.rootRef = el)}>
        <SearchIcon className={classes.searchIcon} />
        <TextField
          className={classes.searchText}
          autoFocus={false}
          InputProps={{
            'data-test-id': options.textLabels.toolbar.search,
            'aria-label': options.textLabels.toolbar.search,
          }}
          value={searchText || ''}
          onChange={this.handleTextChange}
          fullWidth
          inputRef={el => (this.searchField = el)}
          placeholder={options.searchPlaceholder}
          variant="outlined"
        />
        <IconButton className={classes.clearIcon} onClick={this.onClear.bind(this)}>
          <ClearIcon />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(defaultSearchStyles, { name: 'MUIDataTableSearch' })(TableSearch);