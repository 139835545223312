import React, { useState, useEffect, useContext, useRef, createContext } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table, Form, Input } from 'antd';

import { scrollToElement, setElementHighlight } from 'utils/helpers';

import 'antd/dist/antd.css';

const { TextArea } = Input;

const TreeTableEditableContext = createContext(null);

const TreeTable = (props) => {
  const {
    loading,
    expandedRowKeys = [],
    idToHighlight,
    onExpandedRowChange,
    onCellChange,
    columns: defaultColumns,
    locale,
    data = [],
    footer,
    scroll = {
      x: 1100,
      // y: window.innerHeight - 400,
      // y: 240,
    },
  } = props;

  // Trigger scroll-to and highlight of new row
  useEffect(() => {
    if (idToHighlight) {
      setTimeout(function () {
        const el = document.querySelector(`[data-row-key="${idToHighlight}"`);
        if (!el) return;
        scrollToElement(el);
        setElementHighlight(el, true);
      }, 300);
    } else {
      setElementHighlight(null, false);
    }

  }, [idToHighlight]);

  const onExpand = (expanded, { key }) => {
    const expandedNow = [...expandedRowKeys];
    if (!expanded && expandedNow.includes(key)) {
      _.remove(expandedNow, item => item === key);
    } else {
      expandedNow.push(key);
    }

    onExpandedRowChange(expandedNow);
  };

  const components = {
    body: {
      row: TreeTableEditableRow,
      cell: TreeTableEditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: _.isFunction(col.editable) ? col.editable({ column: col, record }) : col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: onCellChange,
        cellClasses: col.cellClasses,
        initialValue: col.initialValue ? (_.isFunction(col.initialValue) ? col.initialValue({ column: col, record }) : col.initialValue) : undefined,
      }),
    };
  });

  return (
    <>
      <Table
        components={components}
        className="estimate-tree-table"
        size="small"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        bordered
        locale={locale}
        expandable={{
          indentSize: 2,
          expandedRowKeys,
          onExpand,
        }}
        tableLayout="fixed"
        scroll={scroll}
        footer={footer}
        rowClassName={(record) => `row-type-${record.type}`}
      />
    </>
  )
}

const TreeTableEditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <TreeTableEditableContext.Provider value={form}>
        <tr {...props} />
      </TreeTableEditableContext.Provider>
    </Form>
  );
};

const TreeTableEditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  cellClasses = '',
  ...restProps
}) => {
  // console.log({ initialValue: restProps.initialValue })
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(TreeTableEditableContext);

  useEffect(() => {
    if (editing && inputRef && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = (close = false) => {
    // Prevent closing edit mode when clicked on focused input
    if (!close && editing) return;

    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const rawValues = form.getFieldsValue(true);
      const values = await form.validateFields();

      if (!dataIndex) {
        console.warn('Missing data index');
        return;
      }

      if (['structure'].includes(dataIndex)) {
        values[dataIndex] = rawValues[dataIndex];
      }

      // const oldValue = record[dataIndex];
      // const newValue = values[dataIndex];
      const oldValue = _.get(record, dataIndex);
      const newValue = _.get(values, dataIndex);

      const changed = oldValue != newValue;
      if (!changed) {
        toggleEdit(true);
        return;
      };

      record = _.set(record, dataIndex, newValue);
      try {
        await handleSave(dataIndex, record, newValue);
      } catch (err) {
        record = _.set(record, dataIndex, oldValue);
        console.error(`Cannot update: ${err.message}`);
      }

      toggleEdit(true);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  const rules = [];
  if (dataIndex === 'title') rules.push({ required: true, message: t('Pavadinimas privalomas') });

  let input = null;
  let addon = null;

  switch (dataIndex) {
    case 'title':
      input = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={rules}
        >
          <TextArea rows={3} ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
      break;

    default:
      input = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          className={`form-item-${record?.type}`}
          rules={rules}
          initialValue={restProps.initialValue}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
  }

  const cellProps = { ...restProps };
  cellProps.className = `${cellProps.className || ''} cell-type-${dataIndex}`;
  cellProps.className = `${cellProps.className} ${editable ? 'cell-editable' : 'cell-not-editable'}`;
  cellProps.className = `${cellProps.className} ${cellClasses}`;

  if (editable) {
    // Set onclick handler on whole cell
    cellProps.onClick = () => toggleEdit();

    childNode = editing ? input : children;
  }

  return <td {...cellProps}>{childNode} {addon}</td>;
};

export default TreeTable;