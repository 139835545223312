import * as classifierService from 'views/Classifier/shared/_services';
import * as templateService from 'views/Templates/shared/_services';
import {
  GET_CLASSIFIERS,
  GET_TEMPLATES,
  FAILURE,
  SENDING_REQUEST,
  RESET_FORM,
} from './_constants';

export const getClassifiers = () => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const classifiers = await classifierService.getAll();

    dispatch({ type: GET_CLASSIFIERS, classifiers });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }

}

export const getTemplates = () => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const templates = await templateService.getAll();

    dispatch({ type: GET_TEMPLATES, templates });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }

}

export const resetForm = () => dispatch => {
  dispatch({ type: RESET_FORM });
}