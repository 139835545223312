import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import PermissionTable from './components/PermissionTable';

import { menuTitle, menu } from './_menu';
import { getGroupsAndCategories, setSelected, batchUpdateGroups } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { permissionMatrix } from './_reducers';
store.injectReducer('permissionMatrix', permissionMatrix);

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  item: {
    height: '100%'
  }
});

class PermissionMatrix extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getGroupsAndCategories());
  }

  onPermissionChange({ permission, group, isAdding }) {
    let { dispatch, selected } = this.props;

    if (!selected[group]) {
      selected[group] = isAdding ? [permission] : [];
    } else if(isAdding) {
      selected[group].push(permission);
    } else {
      selected[group] = _.pull(selected[group], permission);
    }

    console.log({ selected });
    dispatch(setSelected({ ...selected }));
  }

  onSubmit() {
    let { dispatch, selected } = this.props;

    dispatch(batchUpdateGroups(selected));
  }

  renderMatrix() {
    const { groups, categories, selected } = this.props;

    return (
      <PermissionTable
        groups={groups}
        categories={categories}
        selected={selected}
        onPermissionChange={this.onPermissionChange.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
      />
    )
  }

  render() {
    const { isLoading, classes, t } = this.props;

    return (
      <DashboardLayout 
        specialMenu={menu}
        specialMenuTitle={menuTitle}
        title={t('Teisių valdymas')}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Teisių matrica') },
        ]}
        isContentLoading={isLoading}
      >

        <div className={classes.root}>
          {!isLoading && this.renderMatrix()}
        </div>

      </DashboardLayout>
    );
  }
}

const mapStateToProps = ({ permissionMatrix, dispatch }) => {
  return { 
    ...permissionMatrix,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(PermissionMatrix);