import AccountList from './components/AccountList';
import AccountForm from './components/AccountForm';

export default [
  {
    key: 'list',
    component: AccountList,
    path: '/accounts',
    public: false,
  },
  {
    key: 'create',
    component: AccountForm,
    path: '/accounts/create',
    public: false,
  },
  {
    key: 'edit',
    component: AccountForm,
    path: '/accounts/:id/:action',
    public: false,
  },
  {
    key: 'edit',
    component: AccountForm,
    path: '/accounts/:id',
    public: false,
  }
];