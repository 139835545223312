import {
  REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
} from './_constants';

const initialState = {
  isLoading: false,
  error: null,
  project: null,
};

export const projectsDashboard = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        project: action.project,
      }

    case GET_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        project: null,
      }

    default:
      return state;
  }
}