import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/accounts';

export const getAll = async (params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.get(`${servicePath}${query ? '?' + query : ''}`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getOne = async (id, details) => {
  try {
    const response = await api.get(`/accounts/${id}`, details ? { params: details } : null);
    const account = response.data;

    return account;
  } catch (err) {
    console.log(err);
  }
}

export const createOne = async (details) => {
  try {
    const response = await api.post('/accounts', details);
    const { user } = response.data;

    return user;
  } catch (err) {
    console.log(err);
  }
}

export const updateOne = async (id, details) => {
  try {
    const response = await api.put(`/accounts/${id}`, details);
    const { user } = response.data;

    return user;
  } catch (err) {
    console.log(err);
  }
}

export const checkAccess = async () => {
  try {
    const { data } = await api.get(`${servicePath}/check-access`);
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const addLicence = async (id, details) => {
  try {
    const { data } = await api.post(`${servicePath}/${id}/licences`, details);
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const removeLicence = async (id, licenceId) => {
  try {
    const { data } = await api.delete(`${servicePath}/${id}/licences/${licenceId}`);
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const getDetails = async (id) => {
  try {
    const { data } = await api.get(`${servicePath}/${id}/details`);
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const setActive = async (id) => {
  try {
    const response = await api.get(`/accounts/${id}/set`);
    const account = response.data;

    return account;
  } catch (err) {
    console.log(err);
  }
}

export const deleteOne = async (id) => {
  try {
    await api.delete(`/accounts/${id}`);
  } catch (err) {
    console.log(err);
  }
}

export const getStatusTypes = async () => {
  const types = {
    inactive: 'Neaktyvi',
    active: 'Aktyvi',
    pending: 'Laukiama patvirtinimo',
    declined: 'Atmesta',
  };

  return types;
}