import api from 'utils/api';

const servicePath = '/projects';

export const getAll = async (params) => {
  const response = await api.get(servicePath, { params });
  const records = response.data;

  return records;
}

export const getOne = async (id, params) => {
  const response = await api.get(`${servicePath}/${id}`, { params });
  const record = response.data;

  return record;
}


export const createOne = async (details) => {
  const response = await api.post(servicePath, details);
  const record = response.data;

  return record;
}

export const updateOne = async (details) => {
  const response = await api.put(`${servicePath}/${details._id}`, details);
  const record = response.data;

  return record;
}
