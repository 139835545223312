import PermissionCategoriesList from './components/PermissionCategoriesList';
import PermissionCategoriesForm from './components/PermissionCategoriesForm';

export default [
  {
    key: 'list',
    component: PermissionCategoriesList,
    path: '/permission-categories',
    public: false,
  },
  {
    key: 'create',
    component: PermissionCategoriesForm,
    path: '/permission-categories/create',
    public: false,
  },
  {
    key: 'edit',
    component: PermissionCategoriesForm,
    path: '/permission-categories/:id',
    public: false,
  }
];