import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';
import { twoOrMoreDecimals } from 'utils/helpers';
import { FormField2 } from 'components';


const useStyles = makeStyles({
  root: {},
  bold: {
    fontWeight: 'bold',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 12,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',    

    '& thead tr': {
      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& tbody tr': {
      '&:not(.no-hover):hover': {
        backgroundColor: '#d9d9d9',
        cursor: 'pointer',
      },

      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& input[type="number"], input[type="text"]': {
      textAlign: 'right',
      border: '1px solid #316ac5',
      borderRadius: '4px',
      margin: '2px 0px',

      '&[disabled]': {
        border: '1px solid #cccccc',
      }
    },

    '& input[type="text"]': {
      borderColor: '#c4c4c4',
      padding: '6px 6px 7px',
      color: '#12161b',
      textAlign: 'left',
    },
  },
}); 

const DISABLE_INPUT_FROM_ESTIMATE = [
  'pdu',
  'sd',
  'pmed',
  'pmech',
  'sti',
  'pr',
  'p',
  'pvm',
];

const NormPriceSettings = ({ item, onChange, onlyEditable, fromEstimate = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onInputChange = (name, value, idx) => {
    item.details.priceSettings[idx][name] = value;

    onChange(item.details);
  }

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      onChange(item.details, true);
    }
  }

  const unitToSymbol = (unit) => {
    switch (unit) {
      case 'percent':
        return '%';
      case 'coef':
        return '';
      case 'amount':
        return 'EUR';
      default:
        return '';
    }
  }

  if (!_.get(item, 'details')) return null;

  return (
    <>
      <table className={classes.table}>
        <thead>
          <tr>
            <th style={{ width: '12%' }}>{t('Kodas')}</th>
            <th>Pavadinimas</th>
            <th style={{ width: '12%' }}>{t('Matas')}</th>
            <th style={{ width: '12%' }}>{t('Rodiklio reikšmė')}*</th>
            <th style={{ width: '12%' }}>{t('Suma')}, EUR <br /> <span style={{ fontWeight: 'normal' }}>({t('apskaičiuota')})</span></th>                    
          </tr>
        </thead>
        <tbody>
          {_.map(item.details.priceSettings, (setting, settingIdx) => (
            <tr key={setting.code}>
              <td style={{ textAlign: 'center' }}>{setting.code ? setting.code.toUpperCase() : '-'}</td>
              <td style={{ padding: '2px 8px' }}>
                {fromEstimate ? setting.titleCalc : (
                  <FormField2
                    type="text"
                    name="titleCalc"
                    value={setting.titleCalc}
                    onChange={(e) => {
                      const needsRecalc = e.value === '';
                      onInputChange(e.name, e.value, settingIdx, needsRecalc);
                    }}
                    onKeyDown={e => onInputKeyDown(e, settingIdx)}
                    placeholder={`${t('Rodiklio pavadinimas')}...`}
                    disabled={onlyEditable && !onlyEditable.includes(setting.code.toLowerCase())}
                    naked
                  />
                )}
              </td>
              <td style={{ padding: '2px 8px' }}>
                <FormField2
                  type="select"
                  options={[
                    { label: '%', value: 'percent' }, 
                    { label: t('Koeficientas'), value: 'coef' }, 
                    { label: `${t('Suma')}, EUR`, value: 'amount' }
                  ]}
                  name="unitCalc"
                  value={setting.unitCalc}
                  onChange={(e) => {
                    const valBefore = setting.unitCalc;
                    const valNew = e.value;
                    
                    if (valBefore === 'percent' && valNew === 'coef') {
                      setting.valueCalc = setting.valueCalc / 100;
                    } else if (valBefore === 'coef' && valNew === 'percent') {
                      setting.valueCalc = setting.valueCalc * 100;
                    } else if (valNew === 'amount') {
                      setting.valueCalc = 0;
                    }

                    onInputChange(e.name, valNew, settingIdx);
                  }}
                  naked
                  style={{ margin: 0 }}
                  disabled={(onlyEditable && !onlyEditable.includes(setting.code.toLowerCase())) || DISABLE_INPUT_FROM_ESTIMATE.includes(setting.code)}
                />
              </td>
              <td style={{ padding: '2px 8px' }}>
                <FormField2
                  type="number"
                  name="valueCalc"
                  value={setting.valueCalc}
                  onChange={(e) => onInputChange(e.name, e.value, settingIdx)}
                  onKeyDown={e => {
                    onInputKeyDown(e, settingIdx);
                  }}
                  min={0}
                  step={0.0001}
                  naked
                  disabled={(onlyEditable && !onlyEditable.includes(setting.code.toLowerCase())) || DISABLE_INPUT_FROM_ESTIMATE.includes(setting.code)}
                />

                {setting.subValue && setting.subValue != 0 ? (
                  <div style={{ textAlign: 'center' }}>
                    <small>{setting.code && setting.code.toUpperCase()} = {twoOrMoreDecimals(setting.subValue)} {unitToSymbol(setting.unitCalc)}</small>
                  </div>
                ) : null}
              </td>
              <td className={classes.textRight}>
                {twoOrMoreDecimals(setting.amount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Typography variant="caption" display="block" style={{ marginTop: 10, marginRight: 5, textAlign: 'right' }}>
        * {t('Norėdami atstatyti originalią rodiklio reikšmę išvalykite reikšmės įvedimo lauką')}
      </Typography>
    </>
  )
}

export default NormPriceSettings;