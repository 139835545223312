import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';
import { twoOrMoreDecimals } from 'utils/helpers';
import { FormField2 } from 'components';


const useStyles = makeStyles({
  root: {},
  bold: {
    fontWeight: 'bold',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 12,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',    

    '& thead tr': {
      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& tbody tr': {
      '&:not(.no-hover):hover': {
        backgroundColor: '#d9d9d9',
        cursor: 'pointer',
      },

      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& input[type="number"], input[type="text"]': {
      textAlign: 'right',
      border: '1px solid #316ac5',
      borderRadius: '4px',
      margin: '2px 0px',

      '&[disabled]': {
        border: '1px solid #cccccc',
      }
    },

    '& input[type="text"]': {
      borderColor: '#c4c4c4',
      padding: '6px 6px 7px',
      color: '#12161b',
      textAlign: 'left',
    },
  },
});

const EstimatePriceSettings = ({ isRate, priceSettings, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const settingsConfig = {
    pdu: { hideForRate: true, title: t('Papildomas darbo užmokestis') },
    sd: { hideForRate: true, title: t('Socialinis draudimas') },
    k9: { hideForRate: false, title: t('Sezoniniai darbai') },
    k8: { hideForRate: false, title: t('Specifiniai darbai') },
    pmed: { hideForRate: true, title: t('Papildoma medžiagų vertė') },
    pmech: { hideForRate: true, title: t('Papildoma mechanizmų vertė') },
    sti: { hideForRate: true, title: t('Statybvietės išlaidos') },
    pr: { hideForRate: true, title: t('Pridėtinės išlaidos') },
    p: { hideForRate: true, title: t('Pelnas') },
    k11: { hideForRate: false, title: t('Darbo užmokesčio pataisos rodiklis') },
    k21: { hideForRate: false, title: t('Mechanizmų vertės pataisos rodiklis') },
    k31: { hideForRate: false, title: t('Medžiagų vertės pataisos rodiklis') },
    k51: { hideForRate: false, title: t('Visos kainos pataisos rodiklis') },
    pvm: { hideForRate: true, title: t('PVM') },
  };

  const onInputChange = (name, value, idx) => {
    priceSettings[idx][name] = value || 0;

    onChange(priceSettings);
  }

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      onChange(priceSettings);
    }
  }

  const unitToSymbol = (unit) => {
    switch (unit) {
      case 'percent':
        return '%';
      case 'coef':
        return '';
      case 'amount':
        return 'EUR';
      default:
        return '';
    }
  }

  return (
    <>
      <table className={classes.table}>
        <thead>
          <tr>
            <th style={{ width: '12%' }}>{t('Kodas')}</th>
            <th>Pavadinimas</th>
            <th style={{ width: '12%' }}>{t('Matas')}</th>
            <th style={{ width: '12%' }}>{t('Rodiklio reikšmė')}*</th>
            {isRate && (
              <th style={{ width: '12%' }}>
                {t('Suma')}, EUR <br /> <span style={{ fontWeight: 'normal' }}>({t('apskaičiuota')})</span>
              </th>
            )}                  
          </tr>
        </thead>
        <tbody>
          {_.map(priceSettings, (setting, settingIdx) => (
            <tr key={setting.code}>
              <td style={{ textAlign: 'center' }}>{setting.code ? setting.code.toUpperCase() : '-'}</td>
              <td style={{ padding: '2px 8px' }}>
                {settingsConfig[setting.code]?.title || setting.code}
              </td>
              <td style={{ padding: '2px 8px' }}>
                <FormField2
                  type="select"
                  options={[
                    { label: '%', value: 'percent' }, 
                    { label: t('Koeficientas'), value: 'coef' }, 
                    { label: `${t('Suma')}, EUR`, value: 'amount' }
                  ]}
                  name="unit"
                  value={setting.unit}
                  onChange={(e) => {
                    const valBefore = setting.unit;
                    const valNew = e.value;
                    
                    if (valBefore === 'percent' && valNew === 'coef') {
                      setting.value = setting.value / 100;
                    } else if (valBefore === 'coef' && valNew === 'percent') {
                      setting.value = setting.value * 100;
                    } else if (valNew === 'amount') {
                      setting.value = 0;
                    }

                    onInputChange(e.name, valNew, settingIdx);
                  }}
                  naked
                  style={{ margin: 0 }}
                  disabled={isRate && settingsConfig[setting.code]?.hideForRate}
                />
              </td>
              <td style={{ padding: '2px 8px' }}>
                <FormField2
                  type="number"
                  name="value"
                  value={setting.value}
                  onChange={(e) => onInputChange(e.name, e.value, settingIdx)}
                  onKeyDown={e => {
                    onInputKeyDown(e, settingIdx);
                  }}
                  min={0}
                  step={0.0001}
                  naked
                  disabled={isRate && settingsConfig[setting.code]?.hideForRate}
                />

                {setting.subValue && setting.subValue != 0 ? (
                  <div style={{ textAlign: 'center' }}>
                    <small>{setting.code && setting.code.toUpperCase()} = {twoOrMoreDecimals(setting.subValue)} {unitToSymbol(setting.unit)}</small>
                  </div>
                ) : null}
              </td>
              {isRate && (
                <td className={classes.textRight}>
                  {twoOrMoreDecimals(setting.amount)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default EstimatePriceSettings;