import _ from 'lodash';
import {
  REQUEST,
  DELETE_SUCCESS,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  CLEAR_DATA,
} from './_constants';
import { getAll, deleteOne } from 'views/RecoveryPrice/shared/_services';

export const getRecords = (filters) => async (dispatch) => {
  dispatch({ type: REQUEST });
  
  try {
    const records = await getAll(filters);

    dispatch({ type: GET_ALL_SUCCESS, records });
  } catch (error) {
    dispatch({ type: GET_ALL_FAILURE, error });
  }
}

export const clearData = () => (dispatch) => {
  dispatch({ type: CLEAR_DATA });
}

export const batchDelete = (ids) => async (dispatch) => {
  dispatch({ type: REQUEST });
  
  try {
    _.forEach(ids, async (id) => {
      await deleteOne(id);
    });

    dispatch({ type: DELETE_SUCCESS });
  } catch (error) {
    dispatch({ type: GET_ALL_FAILURE, error });
  }
}
