import NormPackageList from './components/NormPackageList';
import NormPackageForm from './components/NormPackageForm';

export default [
  {
    key: 'list',
    component: NormPackageList,
    path: '/norm-packages',
    public: false,
    canReloadOnLinkClick: false,
  },
  {
    key: 'create',
    component: NormPackageForm,
    path: '/norm-packages/create',
    public: false,
  },
  {  
    key: 'edit',
    component: NormPackageForm,
    path: '/norm-packages/:id',
    public: false,
  }
];