import ClassifierList from './components/ClassifierList';
import ClassifierForm from './components/ClassifierForm';

export default [
  {
    key: 'list',
    component: ClassifierList,
    path: '/classifier',
    public: false,
  },
  {
    key: 'create',
    component: ClassifierForm,
    path: '/classifier/create',
    public: false,
  },
  {
    key: 'edit',
    component: ClassifierForm,
    path: '/classifier/:id',
    public: false,
  }
];