export const menuTitle = 'Prieigos teisės';
export const menu = [
  {
    layoutName: 'permissions.layout',
    label: 'Teisės',
    labelId: 'Permissions',
    to: '/permissions',
  },

  {
    layoutName: 'permission-groups.layout',
    label: 'Grupės',
    labelId: 'PermissionGroups',
    to: '/permission-groups',
  },

  {
    layoutName: 'permission-categories.layout',
    label: 'Kategorijos',
    labelId: 'PermissionCategories',
    to: '/permission-categories',
  },
  
  {
    layoutName: 'permission-matrix.layout',
    label: 'Matrica',
    labelId: 'PermissionsMatrix',
    to: '/permission-matrix',
  },
];
