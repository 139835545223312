import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import Orders from 'views/Orders';
import { history } from 'utils/history';
import { toast } from 'utils/helpers';
import { CommonPanel, FormField2 } from 'components';
import styles from './styles';

class Container extends React.Component {

  componentDidMount() {
    const orderId = this.props.match.params.id;

    if (!orderId) {
      history.push('/');
      return;
    }

    this.props.store.loadOrder(orderId);
  }

  async submit() {
    const { t } = this.props;
    if (!window.confirm(t('Ar tikrai norite atlikti veiksmą?'))) return;

    try {
      await this.props.store.submit();

      toast(t('Atlikta'), 'success');
      history.push(`/orders/${this.props.store.order._id}`);
    } catch (err) {
      toast(t('Klaida. Veiksmo atlikti nepavyko'), 'error');
    }
  }

  renderForm() {
    const { t, store: { order } } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Typography variant="body1" style={{ marginBottom: 5 }}>
            <strong>{t('Užsakymas')}:</strong> {order ? (
              <Link to={`/orders/${order._id}`}>{order.number}</Link>
            ) : '-'}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  renderCompanyTable() {
    const { classes, store, t } = this.props;
    const { companies, order } = store;

    return (
      <table className={classes.table}>
        <thead>
          <tr>
            <th style={{ width: '5%' }} />
            <th>
              {t('Įmonė')}
            </th>
            <th>
              {t('Įm. admin.')}
            </th>
            <th style={{ width: '5%' }}>
              {t('Viso paskyrų')}
            </th>
            <th>
              {t('Jau suteikta licencijų')}
            </th>
            <th style={{ width: '10%' }}>
              {t('Licencijų kiekis')}
            </th>
            <th style={{ width: '10%' }}>
              {t('Terminas')}
            </th>
            <th style={{ width: '10%' }}>
              {t('Išdalinti licencijas')}
            </th>
          </tr>
        </thead>
        <tbody>
          {_.map(companies, company => {
            const currentLicenceCount = company.orders
              .filter(o => o.clonedFromOrder === order._id)
              .map(o => _.toNumber(o.totalLicences))
              .reduce((a, b) => a + b, 0);

            return (
              <tr key={company._id}>
                <td style={{ textAlign: 'center' }}>
                  <input
                    type="checkbox"
                    onChange={() => {
                      company.selected = !company.selected;
                      company.deployLicenceCount = company.accounts.length;
                      company.deployLicences = company.selected;
                    }}
                    checked={company.selected}
                  />
                </td>
                <td>
                  <Link to={`/companies/${company._id}`}>{company.name}</Link>
                </td>
                <td>
                  {_.get(company, 'owner.user.fullName', '-')} ({_.get(company, 'owner.user.email', '-')})
                </td>
                <td style={{ textAlign: 'center' }}>
                  {company.accounts ? company.accounts.length : '-'}
                </td>
                <td style={{ textAlign: 'center', color: currentLicenceCount > 0 ? 'red' : 'inherit' }}>
                  {currentLicenceCount}
                </td>
                <td>
                  <FormField2
                    type="number"
                    value={company.deployLicenceCount}
                    onChange={({ value }) => company.deployLicenceCount = value}
                    naked
                  />
                </td>
                <td>
                  <FormField2
                    type="select"
                    value={company.deployMonths || order.months}
                    onChange={({ value }) => company.deployMonths = value}
                    options={[
                      { label: `1 ${t('mėn.')}`, value: 1 },
                      { label: `2 ${t('mėn.')}`, value: 2 },
                      { label: `3 ${t('mėn.')}`, value: 3 },
                      { label: `6 ${t('mėn.')}`, value: 6 },
                      { label: `9 ${t('mėn.')}`, value: 9 },
                      { label: `12 ${t('mėn.')}`, value: 12 },
                    ]}
                    naked
                  />
                </td>
                <td style={{ textAlign: 'center' }}>
                  <input
                    type="checkbox"
                    onChange={() => {
                      company.deployLicences = !company.deployLicences;
                    }}
                    checked={company.deployLicences}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  render() {
    const { classes, store, t } = this.props;
    const title = t('Užsakymo klonavimas');

    return (
      <Orders
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Paslaugų užsakymai'), to: '/orders' },
          { title: `${t('Užsakymas')} ${_.get(store, 'order.number', '-')}`, to: `/orders/${_.get(store, 'order.number', '-')}` },
          { title: title }
        ]}
      >
        <div className={classes.wrap}>
          
          <CommonPanel
            footer={[
              <Button
                key="save"
                color="primary"
                variant="contained"
                onClick={() => this.submit()}
                style={{ margin: '0px 5px' }}
              >
                {t('Vykdyti')}
              </Button>
            ]}
          >
            {this.renderForm()}

            {this.renderCompanyTable()}

          </CommonPanel>

        </div>
      </Orders>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(Container));