import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Popper } from '@material-ui/core';
import api from 'utils/api';

const useStyles = makeStyles(theme => ({
  popper: {
    width: '400px',
  },
}));

const ClassifierPicker = ({ onChange, inputRef, value: propValue, codeBegins, style = {}, disabled = false, name, preloadedClassifiers }) => {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchOptions = async () => {
    setLoading(true);

    try {
      let data = preloadedClassifiers;

      if (!data) {
        const resp = await api.get(`/classifiers?lite=true&codeBegins=${codeBegins || ''}`);
        data = resp.data;
      }

      setOptions(data);

      if (!value && propValue) {
        setValue(data.find(o => o.code === propValue));
      }
    } catch (err) {
      console.err(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (!value) return;

    console.log('Value changed', value);

    onChange({ code: value?.code, title: value?.title });
  }, [value]);

  return (
    <Autocomplete
      PopperComponent={CustomPopper}
      getOptionLabel={(option) => `${option.code} - ${option.title}`}
      size="small"
      onChange={(e, val) => setValue(val)}
      openOnFocus={true}
      disabled={disabled}
      fullWidth
      // onClose={() => onChange()}
      value={options.find(o => o.code === value?.code) || null}
      renderInput={(params) => {

        return (
          <div
            ref={params.InputProps.ref}
          >
            <input
              {...params.inputProps}
              style={style}
              // ref={inputRef}
              type="text"
              disabled={loading || disabled}
            />
          </div>
        )
      }}
      options={options}
    />
  );
}

const CustomPopper = function (props) {

  return (
    <Popper
      {...props}
      style={{ width: '300px' }}
      placement="bottom-end"
    />
  );
};

export default ClassifierPicker;