import PermissionList from './components/PermissionList';
import PermissionDetails from './components/PermissionDetails';

export default [
  {
    key: 'list',
    component: PermissionList,
    path: '/permissions',
    public: false,
  },
  {
    key: 'create',
    component: PermissionDetails,
    path: '/permissions/create',
    public: false,
  },
  {
    key: 'edit',
    component: PermissionDetails,
    path: '/permissions/:id',
    public: false,
  }
];