import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import { DataTable } from 'components';

import Companies from 'views/Companies';
import { getAll } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { companiesList } from './_reducers';
store.injectReducer('companiesList', companiesList);


class CompaniesList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  prepareTableColumns() {
    const { t } = this.props;
    const columns = [
      'ID',
      'LINK',
      t('Pavadinimas'),
      t('Būsena'),
      t('Įm. kodas'),
      t('Padalinys'),
      t('Adresas'),
      t('Užregistravo'),
      t('Užregistruota'),
    ];

    return columns;
  }

  prepareTableData(records) {
    const { t } = this.props;
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/companies/${record._id}`);
      row.push(record.name);
      row.push(record.isActive ? t('aktyvuota') : t('neaktyvuota'));
      row.push(record.code);
      row.push(record.department);
      row.push(record.address);
      row.push(this.renderOwnerCell(record));
      row.push(moment(record.createdAt).utc().format('YYYY-MM-DD'));
      
      return row;
    });

    return data;
  }

  renderOwnerCell({ registeredBy }) {
    if (!registeredBy || !registeredBy.email) {
      return;
    }

    const { _id, email, fullName } = registeredBy;

    return (
      <a href={`/users/${_id}`} title={email}>{fullName}</a>
    )
  }

  render() {
    const { classes, records, isLoading, t } = this.props;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    return (
      <Companies
        title={t('Įmonės')}
        isLoading={isLoading}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Įmonės') },
        ]}
      >
        
        <div className={classes.content}>
          {!isLoading && (
            <DataTable 
              columns={columns}
              data={data}
              tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
              createUrl="/companies/create"
              // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
              // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
            />
          )}
        </div>

      </Companies>
    );
  }
}

const mapStateToProps = ({ companiesList, dispatch }) => {
  return { 
    ...companiesList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(CompaniesList);