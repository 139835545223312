import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/settings';

export const getSettings = async (params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.get(`${servicePath}${query ? '?' + query : ''}`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}