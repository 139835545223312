const constants = {
  GETALL_REQUEST: 'DOCUMENTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'DOCUMENTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'DOCUMENTS_GETALL_FAILURE',

  GETONE_REQUEST: 'DOCUMENTS_GETONE_REQUEST',
  GETONE_SUCCESS: 'DOCUMENTS_GETONE_SUCCESS',
  GETONE_FAILURE: 'DOCUMENTS_GETONE_FAILURE',

  UPDATE_REQUEST: 'DOCUMENTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DOCUMENTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DOCUMENTS_UPDATE_FAILURE',

  CREATE_REQUEST: 'DOCUMENTS_CREATE_REQUEST',
  CREATE_SUCCESS: 'DOCUMENTS_CREATE_SUCCESS',
  CREATE_FAILURE: 'DOCUMENTS_CREATE_FAILURE',

  RESET_FORM: 'DOCUMENTS_RESET_FORM',
};

export default constants;