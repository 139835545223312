import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import { DataTable } from 'components';

import Templates from 'views/Templates';
import { getAll } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { templatesList } from './_reducers';
store.injectReducer('templatesList', templatesList);


class TemplatesList extends Component {

  
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  prepareTableColumns() {
    const { t } = this.props;

    const columns = [
      'ID',
      'LINK',
      t('Kodas'),
      t('Pavadinimas'),
      t('Matomumas'),
      t('Laukų kiekis šablone'),
    ];

    return columns;
  }

  prepareTableData(records) {
    const { t } = this.props;
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/templates/${record._id}`);
      row.push(record.code);
      row.push(record.title);
      row.push(record.public ? t('Visiems') : 'Admin');
      row.push(record.template.length);
      
      return row;
    });

    return data;
  }

  render() {
    const { classes, records, isLoading, t } = this.props;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    return (
      <Templates
        title={t('Šablonai')}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Šablonai') },
        ]}
        isLoading={isLoading}
      >
        
        <div className={classes.content}>
          {!isLoading && (
            <DataTable 
              columns={columns}
              data={data}
              tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
              createUrl="/templates/create"
              // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
              // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
            />
          )}
        </div>

      </Templates>
    );
  }
}

const mapStateToProps = ({ templatesList, dispatch }) => {
  return { 
    ...templatesList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(TemplatesList);