export default theme => ({
  root: {
    padding: theme.spacing(3),
    borderTop: '3px solid ' + theme.palette.primary.main,
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  title: {
    marginTop: theme.spacing()
  },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  difference: {
    alignItems: 'center',
    color: theme.palette.danger.dark,
    display: 'inline-flex',
    fontWeight: 700
  },
  caption: {
    // ...
  }
});
