import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles, Button } from '@material-ui/core';
import { Form } from 'react-final-form';

import styles from './styles';
import getFields from './fields';

import { CommonPanel } from 'components';
import { FormField } from 'components';
import Templates from 'views/Templates';
import TemplateBuilder from './components/TemplateBuilder';
import { getOne, updateOne, createOne, resetForm, setValue } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { templatesForm } from './_reducers';
store.injectReducer('templatesForm', templatesForm);

const fields = getFields();

class TemplatesForm extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;

    if (match.params.id) {
      dispatch(getOne(match.params.id));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetForm());
  }


  validate(values) {
    const { t } = this.props;
    let errors = {};

    if (!values.code) {
      errors.code = t('Privaloma');
    }

    if (!values.title) {
      errors.title = t('Privaloma');
    }

    return errors;
  }

  onSubmit(values) {
    const { dispatch, template } = this.props;

    if (template) {
      values.template = template;
    }

    if (values._id) {
      // Update
      dispatch(updateOne(values));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  submitForm() {
    document
      .getElementById('templatesForm')
      .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }
  
  onTemplateChange(template) {
    const { dispatch } = this.props;
    dispatch(setValue({ name: 'template', value: template }));
  }

  render() {
    const { classes, record, template, t } = this.props;
    const title = record ? t('Šablonas') : t('Naujas šablonas');

    return (
      <Templates
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Šablonai'), to: '/templates' },
          { title }
        ]}
      >
        
        <CommonPanel
          footer={(
            <Button
              color="primary"
              variant="contained"
              onClick={event => this.submitForm(event)}
            >
              {t('Išsaugoti')}
            </Button>
          )}
        >
          <Form
            initialValues={record}
            onSubmit={this.onSubmit.bind(this)}
            validate={this.validate.bind(this)}
            render={({ handleSubmit }) => (
              <form
                id="templatesForm"
                className={classes.form}
                onSubmit={handleSubmit}
              >
                
                {_.map(fields, field => <FormField key={field.name} {...field}/>)}
                
              </form>
            )}
          />

          <TemplateBuilder
            onChange={this.onTemplateChange.bind(this)}
            records={template || []}
          />

        </CommonPanel>

      </Templates>
    );
  }
}

const mapStateToProps = ({ templatesForm, dispatch }) => {
  return { 
    ...templatesForm,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(TemplatesForm);