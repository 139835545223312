import React, { Component } from 'react';
import classNames from 'classnames';
import {
  withStyles,
  IconButton,
} from '@material-ui/core';
import {
  ExpandLess as CollapseIcon,
  ExpandMore as ExpandIcon,
} from '@material-ui/icons';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
  PortletToolbar,
} from 'components';

import styles from './styles';

class CommonPanel extends Component {

  state = {
    collapsed: false,
  };

  componentDidMount() {
    const { collapsed } = this.props;

    if (collapsed) {
      this.setState({ collapsed });
    }
  }

  componentWillReceiveProps(newProps) {
    const { collapsed } = newProps;
    
    if (collapsed) {
      this.setState({ collapsed });
    }
  }

  handleCollapse() {
    const { collapsed } = this.state;
    const { onCollapse } = this.props;

    const isCollapsed = !collapsed;

    this.setState({ collapsed: isCollapsed });
    if (onCollapse) {
      onCollapse(isCollapsed);
    }
  }

  renderCollapse() {
    const { collapsible, disabled, toolbarActions = [] } = this.props;
    const { collapsed } = this.state;

    if (disabled) {
      return;
    }

    let actions = [ ...toolbarActions ];

    if (collapsible) {
      actions.push(
        <IconButton
          key="collapse"
          onClick={this.handleCollapse.bind(this)}
          variant="text"
        >
          {collapsed ? (
            <ExpandIcon />
          ) : (
            <CollapseIcon />
          )}
        </IconButton>
      )
    }

    return actions.length ? <PortletToolbar>{actions}</PortletToolbar> : null;
  }

  renderTitle() {
    const { title, subtitle, collapsible, disabled } = this.props;

    if (!title) {
      return;
    }

    return (
      <PortletHeader
        onClick={collapsible && !disabled ? this.handleCollapse.bind(this) : undefined}
      >
        <PortletLabel title={title} subtitle={subtitle} />

        {this.renderCollapse()}
      </PortletHeader>
    );
  }

  renderFooter() {
    const  { classes, footer } = this.props;

    if (!footer) {
      return;
    }

    return (
      <PortletFooter className={classes.portletFooter}>
        {footer}
      </PortletFooter>
    );
  }

  render() {
    const { classes, className, children, noPadding, style, disabled, rootStyle } = this.props;
    const { collapsed } = this.state;
    const rootClassName = classNames(classes.root, className, disabled ? 'disabled' : null);

    return (
      <Portlet
        className={rootClassName}
        style={rootStyle}
      >
        {this.renderTitle()}
        
        {!collapsed ? (
          <PortletContent noPadding={noPadding} style={style}>
            {children}
          </PortletContent>
        ) : null}

        {this.renderFooter()}
      </Portlet>
    );
  }
}

export default withStyles(styles)(CommonPanel);
