import PermissionGroupsList from './components/PermissionGroupsList'
import PermissionGroupsForm from './components/PermissionGroupsForm'

export default [
  {
    key: 'list',
    component: PermissionGroupsList,
    path: '/permission-groups',
    public: false,
  },
  {
    key: 'create',
    component: PermissionGroupsForm,
    path: '/permission-groups/create',
    public: false,
  },
  {
    key: 'edit',
    component: PermissionGroupsForm,
    path: '/permission-groups/:id',
    public: false,
  }
];