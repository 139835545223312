import * as dataService from 'views/PermissionCategories/shared/_services';
import {
  SENDING_REQUEST,
  GET_PERMISSION_GROUPS_SUCCESS,
  FAILURE,
  RESET_FORM,
} from './_constants';

export const resetForm = () => (dispatch) => {
  dispatch({ type: RESET_FORM });
}

export const getPermissions = () => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const permissionGroups = await dataService.getAll();

    dispatch({ type: GET_PERMISSION_GROUPS_SUCCESS, permissionGroups });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }
}