import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { ThemeProvider } from '@material-ui/styles';
import theme from 'theme';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/scss/index.scss';
import 'react-toastify/dist/ReactToastify.css';

import { history } from 'utils/history';
// import { SHOW_LOCALE_ERRORS } from 'utils/config';
import { GOOGLE_TRACKING_ID } from 'utils/config';

// Routes
import Routes from '../../Routes';
import RouteChangeTracker from '../../RouteChangeTracker';

if (GOOGLE_TRACKING_ID) {
  ReactGA.initialize(GOOGLE_TRACKING_ID);
} else {
  console.info('No Google Analytics tracking ID found');
}

class App extends Component {

  render() {
    
    return (
      // i18n provider wrap should go here
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Routes />

          {!!GOOGLE_TRACKING_ID && <RouteChangeTracker />}
        </Router>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return { ...app };
}

export default connect(mapStateToProps)(App);