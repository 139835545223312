import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/account-licence-logs';

export const getAll = async (params) => {
  const query = objectToQueryString(params);
  const response = await api.get(`${servicePath}${query ? '?' + query : ''}`);
  const records = response.data;

  return records;
}