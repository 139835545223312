import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  withStyles,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { userService } from 'views/Users/_services';

import styles from './styles';

class AccountDialog extends Component {
  state = {
    accounts: null
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleListItemClick = value => {
    const { onClose } = this.props;
    onClose(value);
  };

  async componentDidMount() {
    const { user } = this.props;

    if (!user) {
      return;
    }

    const accounts = await userService.getUserAccounts(user._id);
    this.setState({ accounts });
  }

  renderAccountList = () => {
    const { classes, user, currentAccount, t } = this.props;
    const { accounts } = this.state;

    if (!user) {
      return;
    }

    if (!accounts) {
      return (
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>...</Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${t('Įkeliama')}...`} />
        </ListItem>
      );
    }

    const nonDefaultAccounts = _.filter(accounts, o => !o.isDefault);

    const list = _.map(nonDefaultAccounts, ({ _id, position, company }) => (
      <ListItem
        key={_id}
        onClick={() => this.handleListItemClick(_id)}
        selected={_id == currentAccount._id}
        button>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{position.substring(0, 1)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={position}
          secondary={
            company
              ? `${company.name} ${company.department || ''}`
              : t('Įmonė nenurodyta')
          }
        />
      </ListItem>
    ));

    return list;
  };

  render() {
    const { onCreateClick, open, t } = this.props;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth>
        <DialogTitle disableTypography id="simple-dialog-title">
          <Typography variant="h4">{t('Pasirinkite paskyrą')}</Typography>
        </DialogTitle>
        <List>
          {this.renderAccountList()}

          <ListItem button onClick={() => onCreateClick()}>
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('sukurti paskyrą')} />
          </ListItem>
        </List>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ dispatch, app }) => {
  return { dispatch, ...app };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation()
)(AccountDialog);
