import _ from 'lodash';
import * as dataService from 'views/Classifier/shared/_services';



const classifierOptions = async () => {
  const records = await dataService.getAll();

  return _.map(records, record => ({ 
    value: record.code, 
    label: `${record.code} - ${record.title}`,
  }));
};

const publicOptions = () => [
  { value: true, label: 'Matomas visiems' },
  { value: false, label: 'Tik administratoriams' },
];

const getFields = () => [
  {
    name: 'code',
    label: 'Kodas',
    type: 'select',
    options: classifierOptions,
  },
  {
    name: 'title',
    label: 'Pavadinimas',
    type: 'text',
  },
  {
    name: 'public',
    label: 'Matomumas',
    type: 'select',
    options: publicOptions,
  }, 
];

export default getFields;