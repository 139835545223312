import userConstants from './_constants';
import appConstants from 'views/App/_constants';
import { userService } from './_services';
import { history } from 'utils/history';
import { toast } from 'views/App/_actions';

export const login = (email, password, redirectTo, captchaToken = null) => async (dispatch) => {
  // Login request is going to be sent
  dispatch({ type: userConstants.LOGIN_REQUEST, email });
  
  try {
    if (!captchaToken) throw 'reCaptcha token missing';

    const { user, lastAccount } = await userService.login(email, password, captchaToken);
    // Login successful
    dispatch({ type: userConstants.LOGIN_SUCCESS, user });
    dispatch({ type: appConstants.SET_ACCOUNT_SUCCESS, account: lastAccount });
    history.push(redirectTo || '/');
    toast(`Sveiki, ${user.firstName}!`, 'success');
  } catch (error) {
    let message = 'Nepavyko prisijungti';
    try {
      message = error.response.data.message;
    } catch (err) {/**/}
    // Login failed
    dispatch({ type: userConstants.LOGIN_FAILURE, error });
    toast(message, 'error');
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: userConstants.LOGOUT });
  localStorage.removeItem('jwt');
  localStorage.removeItem('state');
  history.push('/login');
};

export const validateSession = () => async (dispatch, getState) => {
  const { isAuthenticated, isCheckingSession } = getState().app;

  if (isCheckingSession) {
    return;
  }

  dispatch({ type: userConstants.SESSION_REQUEST });

  const valid = await userService.validateSession();

  if (valid) {
    dispatch({ type: userConstants.SESSION_VALID });
  } else {
    if (isAuthenticated) {
      dispatch({ type: userConstants.SESSION_INVALID });
    }
  }
};

export const checkEmail = (email) => async (dispatch) => {
  dispatch({ type: userConstants.CHECK_EMAIL_REQUEST });

  try {
    const { exists, active } = await userService.checkEmail(email);
    let canRegister = false;
    if (exists && active) {
      // Exists and is active
      toast('Toks vartotojas jau egzistuoja, prisijunkite', 'success');
      history.push('/login');
    } else if (exists && !active) {
      // Exists but not activeted
      toast('Vartotojas jau yra sukurtas, tačiau neaktyvuotas su nuoroda gauta el. paštu', 'warning');
      history.push('/login');
    } else {
      // Not exist
      canRegister = true;
    }

    dispatch({ type: userConstants.CHECK_EMAIL_SUCCESS, canRegister });    
  } catch (error) {
    dispatch({ type: userConstants.CHECK_EMAIL_FAILURE });
  }
}

export const termsAgreed = (agreed) => dispatch => {
  dispatch({ type: userConstants.TERMS_AGREED, termsAgreed: agreed });
}

export const activate = (hash) => async (dispatch) => {
  dispatch({ type: userConstants.ACTIVATION_REQUEST });

  try {
    const { activated } = await userService.activate(hash);
    if (activated) {
      // Exists and is active
      toast('Vartotojas aktyvuotas, galite prisijungti', 'success');
      history.push('/login');
    } else {
      // Exists but not activeted
      toast('Nepavyko aktyvuoti arba vartotojas jau buvo aktyvuotas', 'warning');
      history.push('/login');
    }

    dispatch({ type: userConstants.ACTIVATION_SUCCESS });    
  } catch (error) {
    dispatch({ type: userConstants.ACTIVATION_FAILURE });
  }
}

export const register = (details) => async (dispatch) => {
  dispatch({ type: userConstants.SIGNUP_REQUEST });

  try {
    await userService.register(details);

    // Signed up successfully
    dispatch({ type: userConstants.SIGNUP_SUCCESS });
    history.push('/login');
    toast('Į Jūsų įvestą registracijos el. paštą išsiųstas laiškas vartotojo patvirtinimui', 'success');
  } catch (error) {
    // Signed up failed
    dispatch({ type: userConstants.SIGNUP_FAILURE });
    toast('Registracija nepavyko. Patikrinkite duomenis ir bandykite dar kartą.', 'error');
  }
}

export const getAll = () => async (dispatch) => {
  dispatch({ type: userConstants.GETALL_REQUEST });

  try {
    const users = await userService.getAll();

    dispatch({ type: userConstants.GETALL_SUCCESS, users });
  } catch (error) {
    dispatch({ type: userConstants.GETALL_FAILURE, error });
  }
}

export const getOne = (id) => async (dispatch) => {
  dispatch({ type: userConstants.GETONE_REQUEST });

  try {
    const user = await userService.getOne(id);

    dispatch({ type: userConstants.GETONE_SUCCESS, user });
  } catch (error) {
    dispatch({ type: userConstants.GETONE_FAILURE, error });
  }
}

export const updateOne = (details, isProfile) => async (dispatch) => {
  dispatch({ type: userConstants.UPDATE_REQUEST });

  try {
    const user = await userService.updateOne(details);

    // Updated successfully
    dispatch({ type: userConstants.UPDATE_SUCCESS });
    toast('Atnaujinta', 'success');
    
    if (isProfile) {
      // Update user in app state
      dispatch({ type: appConstants.SET_USER, user });
      history.push('/');
    } else {
      history.push('/users');
    }
  } catch (error) {
    // Update failed
    dispatch({ type: userConstants.UPDATE_FAILURE });
    toast('Nepavyko atnaujinti', 'error');
  }
}

export const hardDelete = (id) => async (dispatch) => {
  dispatch({ type: userConstants.HARD_DELETE_REQUEST });

  try {
    await userService.hardDelete(id);

    dispatch({ type: userConstants.HARD_DELETE_SUCCESS });
    history.push('/users');
    toast('Vartotojas ir susiję įrašai pašalinti', 'success');
  } catch (error) {
    dispatch({ type: userConstants.HARD_DELETE_FAILURE, error });
  }
}

export const createOne = (details) => async (dispatch) => {
  dispatch({ type: userConstants.CREATE_REQUEST });

  try {
    await userService.createOne(details);

    // User created successfully
    dispatch({ type: userConstants.CREATE_SUCCESS });
    history.push('/users');
    toast('Sukurta', 'success');
  } catch (error) {
    // User create failed
    dispatch({ type: userConstants.CREATE_FAILURE });
    toast('Nepavyko sukurti', 'error');
  }
}

export const getCurrent = () => (dispatch, getState) => {
  const { user } = getState().app;

  if (user) {
    dispatch(getOne(user._id));
  }
}

export const sendRegistrationConfirmationEmail = (user) => (dispatch) => {

  dispatch({ type: userConstants.EMAIL_ACTIVATION_REQUEST });
  try {
    userService.sendRegistrationConfirmationEmail(user);
    toast('El. laiškas išsiųstas', 'success');
    dispatch({ type: userConstants.EMAIL_ACTIVATION_SUCCESS });
  } catch (error) {
    toast('Nepavyko išsiųsti el. laiško', 'error');
    dispatch({ type: userConstants.EMAIL_ACTIVATION_FAILURE });
  }
}

export const deleteTrialOrders = (user) => (dispatch) => {

  dispatch({ type: userConstants.DELETE_TRIAL_ORDERS_REQUEST });
  try {
    userService.deleteTrialOrders(user);
    toast('Bandomieji užsakymai ištrinti', 'success');
    dispatch({ type: userConstants.DELETE_TRIAL_ORDERS_SUCCESS });
  } catch (error) {
    toast('Nepavyko pašalinti bandomųjų užsakymų', 'error');
    dispatch({ type: userConstants.DELETE_TRIAL_ORDERS_FAILURE });
  }
}

export const resetForm = () => (dispatch) => {
  dispatch({ type: userConstants.RESET_FORM });
}