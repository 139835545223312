import _ from 'lodash';
import * as dataService from 'views/ClassifierGroups/shared/_services';



const parentOptions = async () => {
  const records = await dataService.getAll();

  return _.map(records, record => ({ 
    value: record._id, 
    label: `${record.code} - ${record.title}`,
  }));
};


export default [
  {
    name: 'code',
    label: 'Kodas',
    type: 'text',
  },
  {
    name: 'title',
    label: 'Pavadinimas',
    type: 'text',
  },
  {
    name: 'comment',
    label: 'Pastabos',
    type: 'text',
  },
  {
    name: 'parent',
    label: 'Priklauso grupei',
    type: 'select',
    options: parentOptions,
  },
]