export default theme => ({
  root: {
    
    '&.disabled': {
      opacity: 0.7,
    },

    '& form': {
      marginBottom: '10px',
    }
  },
  portletFooter: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px'
  }
});
