import permissionConstants from './_constants';
import { permissionService } from './_services';
import { history } from 'utils/history';
import { toast } from 'views/App/_actions';

const listPath = '/permissions';

export const getAll = () => async (dispatch) => {
  dispatch({ type: permissionConstants.GETALL_REQUEST });

  try {
    const permissions = await permissionService.getAll();

    dispatch({ type: permissionConstants.GETALL_SUCCESS, permissions });
  } catch (error) {
    dispatch({ type: permissionConstants.GETALL_FAILURE, error });
  }
}

export const getOne = (id) => async (dispatch) => {
  dispatch({ type: permissionConstants.GETONE_REQUEST });

  try {
    const permission = await permissionService.getOne(id);

    dispatch({ type: permissionConstants.GETONE_SUCCESS, permission });
  } catch (error) {
    dispatch({ type: permissionConstants.GETONE_FAILURE, error });
  }
}

export const updateOne = (details) => async (dispatch) => {
  dispatch({ type: permissionConstants.UPDATE_REQUEST });

  try {
    await permissionService.updateOne(details);

    // Updated successfully
    dispatch({ type: permissionConstants.UPDATE_SUCCESS });
    history.push(listPath);
    toast('Updated successfully', 'success');
  } catch (error) {
    // Update failed
    dispatch({ type: permissionConstants.UPDATE_FAILURE });
    toast('Failed to update', 'error');
  }
}

export const createOne = (details) => async (dispatch) => {
  dispatch({ type: permissionConstants.CREATE_REQUEST });

  try {
    await permissionService.createOne(details);

    // permission created successfully
    dispatch({ type: permissionConstants.CREATE_SUCCESS });
    history.push(listPath);
    toast('Created successfully', 'success');
  } catch (error) {
    // permission create failed
    dispatch({ type: permissionConstants.CREATE_FAILURE });
    toast('Failed to create', 'error');
  }
}

export const resetForm = () => (dispatch) => {
  dispatch({ type: permissionConstants.RESET_FORM });
}