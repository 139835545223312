import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core'
import { deleteOne } from 'services/discountCodes'

import { DataTable } from 'components';
import { getAll } from 'services/discountCodes';
import { toast } from 'utils/helpers';
import { Dashboard as DashboardLayout } from 'layouts';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    maxWidth: '1200px',
  }
}));

const DiscountCodesList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  async function loadRecords() {
    setLoading(true);
    
    try {
      const data = await getAll();
      setRecords(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašus'), 'error');
    }

    setLoading(false);
  }

  useEffect(() => {
    loadRecords();
  }, []);

  const prepareTableColumns = () => {
    const columns = [
      'ID',
      'LINK',
      t('Kodas'),
      t('Darbo vietų kiekis'),
      t('Nuolaidos kodo panaudojimų limitas'),
      t('Paslaugų galiojimo intervalas'),
      t('Nuolaida, %'),
      t('Nuolaida, EUR'),
      t('Galioja iki'),
      t(''),
    ];

    return columns;
  }

  const onDeleteDiscount = async (recordId) => {
    if (window.confirm(t('Ar tikrai norite pašalinti nuolaidos kodą?'))) {
      try {
        await deleteOne(recordId);
        await loadRecords();
      } catch (err) {
        console.log(err);
      }
    }
  }

  const prepareTableData = () => {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/discount-codes/${record._id}`);
      row.push(record.code);
      row.push(record.maxLicences);
      row.push(record.maxUses);
      row.push(record.orderDuration)
      row.push(record.discountPercent == 0 ? '-' : record.discountPercent + '%')
      row.push(record.discountAmount == 0 ? '-' : record.discountAmount)
      row.push(record.expiresAt ? record.expiresAt.split('T')[0] : null)
      row.push(
      <Button 
      color="primary"
      variant="contained"
      disableElevation
      style={{ 'backgroundColor': '#cb1a31' }}
      onClick={() => onDeleteDiscount(record._id)}>
        {t('Ištrinti')}
      </Button>)
      
      return row;
    });

    return data;
  }

  const columns = prepareTableColumns();
  const rows = prepareTableData();

  return (
    <DashboardLayout 
      isContentLoading={loading}
      title={t('Nuolaidų kodai')}      
      breadcrumbs={[
        { title: t('Administravimas') },
        { title: t('Nuolaidų kodai') },
      ]}
    >
      <div className={classes.root}>
        <DataTable 
          key={rows.length} // Hack to force component to refresh
          columns={columns}
          data={rows}
          tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
          createUrl="/discount-codes/create"
          onDelete={(idx) => console.log(`Deleting at index ${idx}`)}
          onDeleteBatch={(indexes) => console.log(`Batch deleting indexes ${indexes.join(', ')}`)}
        />
      </div>

    </DashboardLayout>
  )
};

export default DiscountCodesList;