export default (theme) => ({
  root: {},
  wrap: {
    // background: '#ffffff',
    padding: 0,
    maxWidth: '1200px',
  },
  panel: {
    padding: theme.spacing(1),
  },
});
