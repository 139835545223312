import React from 'react';
import { compose } from 'recompose';
import {
  withStyles,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import NtkResults from 'views/NtkViewer/components/NtkResults';

import styles from './styles';

class ResultBlock extends React.Component {

  render() {
    const { record, options } = this.props.store;

    if (!record.prices) return null;

    return (
      <NtkResults
        title="Rodikliai"
        files={record.files}
        type={record.type}
        materials={record.materials}
        buildingType={record.buildingType}
        data={record.prices}
        combinations={options.combinations}
        version={record.version}
      />
    )
  }
}

export default compose(withStyles(styles), inject('store'))(observer(ResultBlock));