import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  Button,
} from '@material-ui/core';

import { canAccess, toast, getCurrentAccount, getCurrentUser } from 'utils/helpers';
import styles from './styles';

import {
  CommonPanel,
} from 'components';

import Users from 'views/Users';
import UserForm from './components/UserForm';
import UserAccounts from './components/UserAccounts';
import AccountDetails from './components/AccountDetails';

import { getOne, resetForm, updateOne, createOne, getCurrent, hardDelete, sendRegistrationConfirmationEmail, deleteTrialOrders } from 'views/Users/_actions';

class UserDetails extends Component {
  state = {
    isProfile: false,
  }

  componentDidMount() {
    const { location, match, dispatch } = this.props;

    if (match.params.id) {
      // Comes from user list
      dispatch(getOne(match.params.id));
    } else if (location.pathname == '/profile') {
      this.setState({ isProfile: true });

      // Comes from current user profile link
      dispatch(getCurrent());
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetForm());
  }

  renderUserDetails(user) {

    return (
      <UserForm onSubmit={values => this.onSubmit(values)} user={user} />
    );
  }

  onSubmit(values) {
    const { dispatch } = this.props;
    if (values._id) {
      // Update
      dispatch(updateOne(values, this.state.isProfile));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  onManage() {
    const { history, single: { user }, t } = this.props;

    if (!window.confirm(t('Ar tikrai norite prisijungti kaip vartotojas?'))) return false;
    
    history.push(`/users/${user._id}/manage`);
  }

  onHardDelete() {
    const { dispatch, single, t } = this.props;
    const { email } = single.user;

    const emailConfirm = window.prompt(t('Norėdami patvirtinti ištrynima įvesite vartotojo el. pašto adresą'));

    if (email == emailConfirm) {
      dispatch(hardDelete(single.user._id));
    } else if (emailConfirm) {
      toast(t('El. pašto adresai nesutampa'), 'error');
    }
  }

  resendRegistrationConfirmationEmail() {
    const { single: { user }, t, dispatch } = this.props;

    if (!window.confirm(t('Ar tikrai norite pakartotinai siųsti registracijos patvirtinimo laišką?'))) return false;
    dispatch(sendRegistrationConfirmationEmail(user));
  }

  deleteTrialOrders() {
    const { single: { user }, t, dispatch } = this.props;

    if (!window.confirm(t('Ar tikrai norite ištrinti visus vartotojo bandomuosius užsakymus?'))) return false;
    dispatch(deleteTrialOrders(user._id));
  }

  submitForm() {
    document
      .getElementById('userForm')
      .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }

  render() {
    const { classes, isLoading, single, history, t } = this.props;
    const { user } = single;
    const currentAccount = getCurrentAccount();
    const currentUser = getCurrentUser();
    const title = user ? `${t('Vartotojas')} ${user.firstName} ${user.lastName}` : t('Naujas vartotojas');

    return (
      <Users
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Vartotojai'), to: '/users' },
          { title },
        ]}
      >
        
        <div className={classes.content}>
          <div className={classes.wrap}>
            {/* User form */}
            <CommonPanel 
              noPadding
              footer={(
                <React.Fragment>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={event => this.submitForm(event)}
                  >
                    {t('Išsaugoti')}
                  </Button>

                  {user && canAccess('administrator.layout') ? (
                    <>
                      {!user.isActivated ?
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => this.resendRegistrationConfirmationEmail()}
                          style={{ marginLeft: 10, backgroundColor: '#f5c71a', float: 'right' }}
                        >
                          {t('Siųsti paskyros patvirtinimo laišką')}
                        </Button>
                        : null
                      }

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.onManage.bind(this)}
                        style={{ marginLeft: 10, backgroundColor: 'orange', float: 'right' }}
                      >
                        {t('Prisijungti kaip')}
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.onHardDelete.bind(this)}
                        style={{ marginLeft: 10, backgroundColor: '#8b0000', float: 'right' }}
                      >
                        {t('Pašalinti vartotoją ir susijusius įrašus')}
                      </Button>

                      {!!user.trialOrderCount ?
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.deleteTrialOrders.bind(this)}
                          style={{ marginLeft: 10, backgroundColor: '#8b0000', float: 'right' }}
                        >
                          {t('Pašalinti trial užsakymus')}
                        </Button>
                        : null
                      }
                    </>
                  ) : null}
                </React.Fragment>
              )}
            >
              {this.renderUserDetails(user)}
            </CommonPanel>

            {user && user._id === currentUser._id && (
              <div className={classes.accountsPanel}>
                <AccountDetails accountId={currentAccount._id} />
              </div>
            )}

            {/* Accounts (related) */}
            {user ? (
              <div className={classes.accountsPanel}>
                <UserAccounts
                  current={user}
                  records={[...user.accounts]}
                />
              </div>
            ) : null}

            {user ? (
              <div className={classes.btnJoin}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => history.push('/companies/join')}
                >
                  {t('Prisijungti prie įmonės / Registruoti įmonę')}
                </Button>
              </div>
            ) : null}
          </div>
        </div>

      </Users>
    )
  }
}

const mapStateToProps = ({ users, dispatch }) => {
  return {
    ...users,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withRouter,
  withTranslation(),
)(UserDetails);