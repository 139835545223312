import api from 'utils/api';

export const login = async (email, password) => {
  try {
    const response = await api.post('/auth', { email, password });
    const { token, user, lastAccount } = response.data;

    // Store JWT token in local storage
    localStorage.setItem('jwt', token);

    return { user, lastAccount };
  } catch (error) {
    return Promise.reject(error);
  }
}

export const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

export const validateSession = async () => {
  try {
    await api.get('/session');
    return true;
  } catch (error) {
    return false;
  }
}

export const register = async (details) => {
  try {
    const response = await api.post('/register', details);
    const { user } = response.data;

    return user;
  } catch (err) {
    console.log(err);
  }
}

export const createOne = async (details) => {
  try {
    const response = await api.post('/users', details);
    const { user } = response.data;

    return user;
  } catch (err) {
    console.log(err);
  }
}

export const updateOne = async (details) => {
  try {
    const response = await api.put(`/users/${details._id}`, details);
    const user = response.data;

    return user;
  } catch (err) {
    console.log(err);
  }
}

export const getAll = async () => {
  try {
    const response = await api.get('/users');
    const users = response.data;

    return users;
  } catch (err) {
    console.log(err);
  }
}

export const getOne = async (id) => {
  try {
    const response = await api.get(`/users/${id}`);
    const user = response.data;

    return user;
  } catch (err) {
    console.log(err);
  }
}

export const getUserAccounts = async (id) => {
  try {
    const response = await api.get(`/users/${id}/accounts`);
    const user = response.data;

    return user;
  } catch (err) {
    console.log(err);
  }
}

// TODO move to account services
export const getNotifications = async (id) => {
  try {
    const response = await api.get(`/accounts/${id}/notifications`);
    const notifications = response.data;

    return notifications;
  } catch (err) {
    console.log(err);
  }
}

// TODO move to account services
export const setNotificationOpened = async (id, notificationId) => {
  try {
    await api.get(`/accounts/${id}/notifications/${notificationId}`);
  } catch (err) {
    console.log(err);
  }
}

export const checkEmail = async (email) => {
  try {
    const response = await api.post('/check-email', { email });
    const data = response.data;

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const hardDelete = async (id) => {
  try {
    return await api.delete(`/users/${id}/hard`);
  } catch (err) {
    console.log(err);
  }
}

export const activate = async (hash) => {
  try {
    const response = await api.post('/activate', { hash });
    const data = response.data;

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const resetPassword = async (params) => {
  try {
    const response = await api.post('/password-reset', params);
    const data = response.data;

    return data;
  } catch (err) {
    console.log(err);
  }
}