import { observable, action, decorate, toJS } from 'mobx';
import _ from 'lodash';
import { toast } from 'utils/helpers';
import { history } from 'utils/history';
import {
  getOne,
  create,
  update,
  remove,
} from 'services/services';
import { getAll as getAllServicePackages } from 'services/servicePackages';
import { getAll as getAllNormPackages } from 'services/normPackages';
import { getSettings } from 'services/settings';

const DEFAULT_RECORD = {
  rules: [],
  packages: [],
}

const DEFAULT_OPTIONS = {
  servicePackages: [],
  normPackages: [],
}

class Store {
  record = { ...DEFAULT_RECORD }
  options = { ...DEFAULT_OPTIONS }
  settings = {};

  // Action
  reset = () => {
    this.record = { ...DEFAULT_RECORD };
    this.options = { ...DEFAULT_OPTIONS };
    this.settings = {};
  }

  // Action
  setRecord = record => {
    this.record = record;
  }

  // Action
  setSettings = settings => {
    this.settings = settings;
  }

  // Action
  setOptions = data => {
    this.options = data;
  }

  loadOptions = async () => {
    let options = {};

    const servicePackages = await getAllServicePackages();
    options.servicePackages = _.map(servicePackages, ({ _id, title }) => ({ label: title, value: _id }));

    const normPackages = await getAllNormPackages();
    options.normPackages = _.map(normPackages, ({ _id, title, code }) => ({ label: `${code} ${title}`, value: _id }));

    this.setOptions(options);
  }

  loadData = async () => {
    const settings = await getSettings();

    this.setSettings(settings);
  }

  getOne = async (id) => {
    const data = await getOne(id);

    this.setRecord(data);
  }

  create = async () => {
    const record = toJS(this.record);

    try {
      await create(record);
      toast('Įrašas sukurtas', 'success');
      history.push('/services');
    } catch (err) {
      toast('Nepavyko išsaugoti', 'danger');
    }
  }

  update = async () => {
    const record = toJS(this.record);
    
    try {
      await update(record._id, record);
      toast('Įrašas atnaujintas', 'success');
      history.push('/services');
    } catch (err) {
      toast('Nepavyko išsaugoti', 'danger');
    }
  }

  remove = async () => {
    try {
      await remove(this.record._id);
      toast('Įrašas pašalintas', 'success');
      history.push('/services');
    } catch (err) {
      toast('Nepavyko pašalinti', 'danger');
    }
  }
}

decorate(Store, {
  record: observable,
  options: observable,
  settings: observable,
  setRecord: action,
  setSettings: action,
  setOptions: action,
  reset: action,
});

export default new Store;