import React, { useState, useEffect, createRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Paper, Button } from '@material-ui/core';

import { FormField2 as Field } from 'components';
import { ImportContext } from './ImportManager';

const defaultRecord = {
  file: null,
  columnNames: 'first-row',
  estimateItemId: null,
  createNewEstimate: null,
};

const SourceSelector = () => {
  const {
    loading,
    record: contextRecord,
    object: contextObject,
    importActions,
  } = useContext(ImportContext);

  const { t } = useTranslation();
  const [record, setRecord] = useState({ ...(_.cloneDeep(defaultRecord)), ...contextRecord.source });
  const fileRef = createRef();

  const onFileChange = e => {
    const file = e.target.files[0];
    setRecord({ ...record, file });
  };

  const onRecordChange = ({ name, value }) => {
    setRecord({ ...record, [name]: value });
  };

  const onRecordReset = () => {
    setRecord(_.cloneDeep(defaultRecord));
  };

  const onSubmit = () => {
    importActions.setSourceResult(record);
  };

  const canProceed = record?.file && (record?.createNewEstimate || record?.estimateItemId);

  return (
    <Paper style={{ maxWidth: 300, margin: '20px auto 0px', padding: '10px 20px' }}>
      {!record?.file ? (
        <>
          <input
            type="file"
            ref={fileRef}
            onChange={onFileChange}
          />
        </>
      ) : (
        <>
          <Field
            label={t('Pasirinktas failas')}
            value={record?.file?.name}
          />

          <Button
            variant="outlined"
            color="primary"
            onClick={onRecordReset}
            fullWidth
            style={{ marginBottom: 20 }}
          >
            {t('Pasirinkti kitą failą')}
          </Button>

          <Field
            label={t('Laukų pavadinimai')}
            type="select"
            options={[
              { value: 'first-row', label: t('Pirmoje eilutėje') },
              { value: 'none', label: t('Nėra (įvardinti raidėmis A...Z)') },
            ]}
            name="columnNames"
            onChange={onRecordChange}
            value={record?.columnNames}
          />

          {!record?.createNewEstimate && (
            <Field
              label={t('Įkelti į sąmatą')}
              type="select"
              options={contextObject.items.map(item => ({ value: item._id, label: [item.code, item.title].join(' ') }))}
              name="estimateItemId"
              onChange={onRecordChange}
              value={record?.estimateItemId}
            />
          )}

          <Field
            label={t('Sukurti ir įkelti į naują sąmatą')}
            type="checkbox"
            name="createNewEstimate"
            onChange={onRecordChange}
            value={!!record?.createNewEstimate}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            fullWidth
            disabled={!canProceed || loading}
          >
            {t('Tęsti')}
          </Button>
        </>
      )}
    </Paper>
  )
}

export default SourceSelector;