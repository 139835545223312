import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/companies';

// TODO migrate other methods from Views/Companies

export const getAll = async (params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.get(`${servicePath}${query ? '?' + query : ''}`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getOne = async (id) => {
  try {
    const response = await api.get(`/companies/${id}`);
    const company = response.data;

    return company;
  } catch (err) {
    console.log(err);
  }
}

export const getDetails = async (id) => {
  try {
    const response = await api.get(`/companies/${id}/details`);
    const company = response.data;

    return company;
  } catch (err) {
    console.log(err);
  }
}

export const getServices = async (id, params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.get(`${servicePath}/${id}/services${query ? '?' + query : ''}`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getServicePackages = async (id, service) => {
  try {
    const response = await api.get(`${servicePath}/${id}/services/${service}/packages`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getOrders = async (id, params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.get(`${servicePath}/${id}/orders${query ? '?' + query : ''}`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}