import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import { DataTable } from 'components';

import TreeTemplates from 'views/TreeTemplates';
import { getAll } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { treeList } from './_reducers';
store.injectReducer('treeList', treeList);


class List extends Component {

  
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  prepareTableColumns() {
    const { t } = this.props;

    const columns = [
      'ID',
      'LINK',
      t('Pavadinimas'),
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/tree-templates/${record._id}`);
      row.push(record.title);
      
      return row;
    });

    return data;
  }

  render() {
    const { classes, records, isLoading, t } = this.props;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    return (
      <TreeTemplates
        title={t('Duomenų struktūros šablonai')}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Duomenų struktūros šablonai') },
        ]}
        isLoading={isLoading}
      >
        
        <div className={classes.content}>
          {!isLoading && (
            <DataTable 
              columns={columns}
              data={data}
              tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
              createUrl="/tree-templates/create"
              // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
              // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
            />
          )}
        </div>

      </TreeTemplates>
    );
  }
}

const mapStateToProps = ({ treeList, dispatch }) => {
  return { 
    ...treeList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(List);