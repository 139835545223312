export default theme => ({
  link: {
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: 18,
    width: 18,
    height: 18,
  }
});
