import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/discount-codes';

// TODO migrate other methods from Views/Companies

export const getAll = async (params) => {
  const query = objectToQueryString(params);
  const response = await api.get(`${servicePath}${query ? '?' + query : ''}`);
  const records = response.data;

  return records;
}

export const getOne = async (id) => {
  const response = await api.get(`${servicePath}/${id}`);
  const item = response.data;

  return item;
}

export const createOne = async (details) => {
  const response = await api.post(servicePath, details);

  return response.data;
}

export const updateOne = async (id, details) => {
  const response = await api.put(`${servicePath}/${id}`, details);
  const record = response.data;

  return record;
}

export const deleteOne = async (id) => {
  const response = await api.delete(`${servicePath}/${id}`);
  const record = response.data;

  return record;
}