import {
  SENDING_REQUEST,
  CREATE_SUCCESS,
  REGISTER_SUCCESS,
  SET_ACTIVE_SUCCESS,
  SET_VALUE,
  FAILURE,
  UPDATE_SUCCESS,
  RESET_FORM,
} from './_constants';

const initialState = {
  isLoading: false,
  record: null,
};

export const companiesForm = (state = initialState, action) => {
  switch (action.type) {
    
    case SET_VALUE:
      return {
        ...state,
        record: {
          ...state.record,
          [action.data.name]: action.data.value,
        }
      };

    case RESET_FORM:
    case REGISTER_SUCCESS:
    case SET_ACTIVE_SUCCESS:
      return {
        ...initialState,
      };

    case SENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        record: action.record,
      };

    case FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state;
  }
}