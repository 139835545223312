import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/orders';

export const getAll = async (params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.get(`${servicePath}${query ? '?' + query : ''}`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getOne = async (id) => {
  try {
    const response = await api.get(`${servicePath}/${id}`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getDocument = async (id, documentType) => {
  try {
    const response = await api.get(`${servicePath}/${id}/documents/${documentType}`);
    const data = response.data;

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const create = async (details, params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.post(`${servicePath}${query ? '?' + query : ''}`, details);
    const record = response.data;

    return record;
  } catch (err) {
    console.log(err);
  }
}

export const update = async (id, details, params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.put(`${servicePath}/${id}${query ? '?' + query : ''}`, details);
    const record = response.data;

    return record;
  } catch (err) {
    console.log(err);
  }
}

export const calculate = async (details) => {
  const response = await api.post(`${servicePath}/calculate`, details);
  const record = response.data;

  return record;
}

export const getSummary = async (params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.get(`${servicePath}/summary${query ? '?' + query : ''}`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const distribute = async (id, details, params) => {
  try {
    const query = objectToQueryString(params);
    const response = await api.post(`${servicePath}/${id}/distribute${query ? '?' + query : ''}`, details);
    const record = response.data;

    return record;
  } catch (err) {
    console.log(err);
  }
}

export const createTrial = async (details) => {
  try {
    const response = await api.post(`${servicePath}/trial`, details);
    const record = response.data;

    return record;
  } catch (err) {
    console.log(err);
  }
}

export const trialActivated = async (details) => {
  try {
    const response = await api.post(`${servicePath}/trial/activated`, details);
    const record = response.data;

    return record;
  } catch (err) {
    console.log(err);
  }
}

export const renewal = async (id) => {
  const response = await api.post(`${servicePath}/${id}/renewal`);
  const record = response.data;

  return record;
}