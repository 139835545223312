export const GET_PROJECTS_SUCCESS = 'RECOVERYPRICE_GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'RECOVERYPRICE_GET_PROJECTS_FAILURE';
export const SELECT_PROJECT_SUCCESS = 'RECOVERYPRICE_SELECT_PROJECT_SUCCESS';
export const SELECT_PROJECT_FAILURE = 'RECOVERYPRICE_SELECT_PROJECT_FAILURE';
export const GET_TYPES_SUCCESS = 'RECOVERYPRICE_GET_TYPES_SUCCESS';
export const GET_TYPES_FAILURE = 'RECOVERYPRICE_GET_TYPES_FAILURE';
export const GET_VERSIONS_SUCCESS = 'RECOVERYPRICE_GET_VERSIONS_SUCCESS';
export const GET_VERSIONS_FAILURE = 'RECOVERYPRICE_GET_VERSIONS_FAILURE';
export const GET_HIERARCHY_SUCCESS = 'RECOVERYPRICE_GET_HIERARCHY_SUCCESS';
export const GET_HIERARCHY_FAILURE = 'RECOVERYPRICE_GET_HIERARCHY_FAILURE';
export const GET_MATERIALS_SUCCESS = 'RECOVERYPRICE_GET_MATERIALS_SUCCESS';
export const GET_MATERIALS_FAILURE = 'RECOVERYPRICE_GET_MATERIALS_FAILURE';
export const SELECT_MATERIALS_SUCCESS = 'RECOVERYPRICE_SELECT_MATERIALS_SUCCESS';
export const SELECT_MATERIALS_FAILURE = 'RECOVERYPRICE_SELECT_MATERIALS_FAILURE';
export const GET_COMBINATIONS_SUCCESS = 'RECOVERYPRICE_GET_COMBINATIONS_SUCCESS';
export const GET_COMBINATIONS_FAILURE = 'RECOVERYPRICE_GET_COMBINATIONS_FAILURE';
export const GET_CALCULATIONS_SUCCESS = 'RECOVERYPRICE_GET_CALCULATIONS_SUCCESS';
export const GET_CALCULATIONS_FAILURE = 'RECOVERYPRICE_GET_CALCULATIONS_FAILURE';
export const UPDATE_CALCULATIONS_SUCCESS = 'RECOVERYPRICE_UPDATE_CALCULATIONS_SUCCESS';
export const UPDATE_CALCULATIONS_FAILURE = 'RECOVERYPRICE_UPDATE_CALCULATIONS_FAILURE';
export const GET_RECORD_SUCCESS = 'RECOVERYPRICE_GET_RECORD_SUCCESS';
export const GET_RECORD_FAILURE = 'RECOVERYPRICE_GET_RECORD_FAILURE';
export const START_LOADING_RECORD = 'RECOVERYPRICE_START_LOADING_RECORD';
export const FINISH_LOADING_RECORD = 'RECOVERYPRICE_FINISH_LOADING_RECORD';
export const GET_REPORT_SUCCESS = 'RECOVERYPRICE_GET_RECORD_SUCCESS';
export const GET_REPORT_FAILURE = 'RECOVERYPRICE_GET_RECORD_FAILURE';

export const SENDING_REQUEST = 'RECOVERYPRICE_SENDING_REQUEST';
export const CREATE_SUCCESS = 'RECOVERYPRICE_CREATE_SUCCESS';
export const CREATE_FAILURE = 'RECOVERYPRICE_CREATE_FAILURE';
export const UPDATE_SUCCESS = 'RECOVERYPRICE_UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'RECOVERYPRICE_UPDATE_FAILURE';
export const VALUE_CHANGED = 'RECOVERYPRICE_VALUE_CHANGED';
export const RESET_FORM = 'RECOVERYPRICE_RESET_FORM';
export const RESET_USER_INPUT = 'RECOVERYPRICE_RESET_USER_INPUT';
export const TOGGLE_MATERIALS = 'RECOVERYPRICE_TOGGLE_MATERIALS';
export const SKIP_MATERIALS = 'RECOVERYPRICE_SKIP_MATERIALS';
export const TOGGLE_RESULTS = 'RECOVERYPRICE_TOGGLE_RESULTS';
export const PROJECT_CLEARED = 'RECOVERYPRICE_PROJECT_CLEARED';