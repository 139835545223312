export default {
  root: {
    // height: '22px',
    // width: '22px',
    // fontSize: '22px'
    height: '18px',
    width: '18px',
    fontSize: '18px'
  }
};
