export default () => ({
  root: {
    marginTop: '10px',
  },
  table: {

    '& td, th': {
      border: '1px solid #DFE3E8',

      '&.actions': {
        border: '1px solid #ffffff',
      }
    }
  },
});
