import React, { useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import {
  Button,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { EstimateContext } from './EstimateObjectForm';

const PrintManager = () => {
  const { t } = useTranslation();
  const { record, loading, print } = useContext(EstimateContext);

  const reports = [
    { formNumber: 'LS1', title: t('Lokalinė sąmata'), printVariant: t('suminė pagal skyrius'), type: 'localEstimateChaptersObject', description: t('Informacija sąmatoje pateikiama suminėmis eilutėmis pagal kiekvieną sąmatos skyrių') },
    { formNumber: 'LS11', printVariant: t('suminė pagal skyrius su darbų sudėtimis'), type: 'localEstimateChaptersObjectWithWorkComposition', description: t('Informacija sąmatoje pateikiama suminėmis eilutėmis pagal kiekvieną sąmatos skyrių. Prie skyriaus eilutės pateikiama darbų sudėtis. Pateikimo formatas--platus (landscape)') },

    { formNumber: 'LS2', title: t('Lokalinė sąmata'), printVariant: t('pagal skyrius-įkainius su priskaitymais'), type: 'localEstimateRatesAdditionsObject', description: t('Informacija sugrupuota pagal skyrius. Kiekviename skyriuje pateikiami įkainiai, darbo kiekis, vieneto kaina ir kaina viso su priskaitymais') },
    { formNumber: 'LS21', printVariant: t('pagal skyrius-įkainius su priskaitymais ir darbų sudėtimis'), type: 'localEstimateRatesAdditionsObjectWithWorkComposition', description: t('Informacija sugrupuota pagal skyrius. Kiekviename skyriuje pateikiami įkainiai, darbo kiekis, vieneto kaina ir kaina viso su priskaitymais. Prie skyriaus ir įkainio eilutės pateikiama darbų sudėtis. Pateikimo formatas--platus (landscape)') },

    { formNumber: 'LS3', title: t('Lokalinė sąmata'), printVariant: t('pagal skyrius-įkainius'), type: 'localEstimateRatesObject', description: t('Informacija sugrupuota pagal skyrius. Kiekviename skyriuje pateikiami vienetiniai įkainiai, kainos detalizacija pagal išlaidų medžiagoms, mechanizmams, darbo užmokesčiui straipsnius. Papildomi priskaitymai pateikiami kiekvieno skyriaus, sąmatos gale.') },
    { formNumber: 'LS31', printVariant: t('pagal skyrius-įkainius su darbų sudėtimis'), type: 'localEstimateRatesObjectWithWorkComposition', description: t('Informacija sugrupuota pagal skyrius. Kiekviename skyriuje pateikiami vienetiniai įkainiai, kainos detalizacija pagal išlaidų medžiagoms, mechanizmams, darbo užmokesčiui straipsnius. Papildomi priskaitymai pateikiami kiekvieno skyriaus, sąmatos gale. Prie skyriaus ir įkainio eilutės pateikiama darbų sudėtis. Pateikimo formatas--platus (landscape)') },

    { formNumber: 'LS4', title: t('Lokalinė sąmata'), printVariant: t('pagal skyrius-įkainius su resursais'), type: 'localEstimateResourcesObject', description: t('Informacija sugrupuota pagal skyrius. Kiekviename skyriuje pateikiami vienetiniai darbų įkainiai, darbo kiekis, įkainio resursų (medžiagų, mechanizmų, darbo sąnaudų) kiekiai, vieneto kaina ir kaina viso. Papildomi priskaitymai pateikiami kiekvieno skyriaus, sąmatos gale.') },
    { formNumber: 'LS41', printVariant: t('pagal skyrius-įkainius su resursais ir darbų sudėtimis'), type: 'localEstimateResourcesObjectWithWorkComposition', description: t('Informacija sugrupuota pagal skyrius. Kiekviename skyriuje pateikiami vienetiniai darbų įkainiai, darbo kiekis, įkainio resursų (medžiagų, mechanizmų, darbo sąnaudų) kiekiai, vieneto kaina ir kaina viso. Papildomi priskaitymai pateikiami kiekvieno skyriaus, sąmatos gale. Prie skyriaus ir įkainio eilutės pateikiama darbų sudėtis. Pateikimo formatas--platus (landscape)') },

    { formNumber: 'LS5', title: t('Objekto lokalinės sąmatos'), printVariant: t('suminė pagal skyrius su tiesioginėmis ir netiesioginėmis išlaidomis'), type: 'localObjectEstimates', description: t('Pagal kiekvieną sąmatos skyrių pateikiamos tiesiogimės, netiesioginės ir nedetalizuotos (nepriskirtos) išlaidos') },
    { formNumber: 'LS51', printVariant: t('suminė pagal skyrius su tiesioginėmis ir netiesioginėmis išlaidomis su darbų sudėtimis'), type: 'localObjectEstimatesWithWorkComposition', description: t('Pagal kiekvieną sąmatos skyrių pateikiamos tiesiogimės, netiesioginės ir nedetalizuotos (nepriskirtos) išlaidos su darbų sudėčių aprašymais--platus (landscape)') },

    { formNumber: 'OS1', title: t('Objektinė sąmata'), printVariant: t('suminė iš visų objekto sąmatų'), type: 'objectEstimate', description: t('Pateikiama suminės kainos pagal kiekvieną objekto sąmatą') },
    { formNumber: 'OS2', printVariant: t('detalizuota iš visų objekto sąmatų'), type: 'objectDetailedEstimate', description: t('Pateikiamos suminės kainos pagal kiekvieną objekto sąmatą, su kiekvienos sąmatos detalizacija pagal skyrius išlaidų darbo užmokesčiui, medžiagoms, mechanizmams straipsnius') },

    { formNumber: 'MD1', title: t('Medžiagų žiniaraštis'), printVariant: t('suminis objektui'), type: 'materialReport', description: t('Pateikiamas medžiagų žiniaraštis su medžiagų kiekiais, kainomis ir sumomis iš visų objekto sąmatų') },
    { formNumber: 'MD2', printVariant: t('pagal sąmatas'), type: 'materialReportByEstimateForObject', description: t('Pateikiamas medžiagų žiniaraštis su medžiagų kiekiais, kainomis ir sumomis pagal kiekvieną objekto sąmatą') },

    { formNumber: 'MC1', title: t('Mechanizmų žiniaraštis'), printVariant: t('suminis objektui'), type: 'mechanismReport', description: t('Pateikiamas mechanizmų žiniaraštis su mechanizmų kiekiais, kainomis ir sumomis iš visų objekto sąmatų') },
    { formNumber: 'MC2', printVariant: t('pagal sąmatas'), type: 'mechanismReportByEstimateForObject', description: t('Pateikiamas mechanizmų žiniaraštis su mechanizmų kiekiais, kainomis ir sumomis pagal kiekvieną objekto sąmatą') },

    { formNumber: 'IR1', title: t('Įrenginių žiniaraštis'), printVariant: t('suminis objektui'), type: 'equipmentReportForObject', description: t('Pateikiamas įrenginių žiniaraštis su įrenginių kiekiais, kainomis ir sumomis iš visų objekto sąmatų') },
    { formNumber: 'IR2', printVariant: t('pagal sąmatas'), type: 'equipmentReportByEstimateForObject', description: t('Pateikiamas įrenginių žiniaraštis su įrenginių kiekiais, kainomis ir sumomis pagal kiekvieną objekto sąmatą') },

    { formNumber: 'DS1', title: t('Darbo užmokesčio žiniaraštis'), printVariant: t('pagal visas objekto sąmatas'), type: 'salaryReportForObject', description: t('Pateikiamas darbo užmokesčio žiniaraštis visoms objekto sąmatoms su darbo sąnaudų kiekiais, tarifiniais atlygiais ir sumomis pagal kiekvieną objekto sąmatą, skyrių, eilutę') },
  ]

  const onPrintLocalEstimate = async (params) => {
    const payload = {
      params: ['id'],
      values: [record._id],
      ...params,
    };

    await print(payload);
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ width: 130 }}>
          <Typography style={{ fontWeight: 600 }}>{t('Suma, EUR be PVM')}</Typography>
          <Typography>{record.totals.amount}</Typography>
        </div>
        <div style={{ width: 130, paddingLeft: 10 }}>
          <Typography style={{ fontWeight: 600 }}>{t('Suma, EUR su PVM')}</Typography>
          <Typography>{record.totals.grandTotal}</Typography>
        </div>
        <div style={{ paddingLeft: 10 }}>
          <Button
            variant="outlined"
            color="primary"
            style={{ fontSize: '10px', marginTop: '5px' }}
            onClick={() => setShowObjectSummaryModal(true)}>
            {t('Kainos sudėtis')}
          </Button>
        </div>
      </div>
      <TableContainer style={{ maxHeight: '620px' }}>
        <Table stickyHeader >
          <TableHead>
            <TableRow>
              <TableCell colSpan={1} style={{ textAlign: 'center' }}>{t('Formos Nr.')}</TableCell>
              <TableCell colSpan={2} style={{ textAlign: 'center' }}>{t('Dokumento pavadinimas')}</TableCell>
              <TableCell colSpan={2} style={{ textAlign: 'center' }}>{t('Spausdinimo variantas')}</TableCell>
              <TableCell colSpan={3} style={{ textAlign: 'center' }}>{t('Trumpas aprašymas')}</TableCell>
              <TableCell colSpan={2} style={{ textAlign: 'center' }}>{t('Spausdinimo formatas')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map(({ formNumber, title, printVariant, description, type }) => (
              <TableRow key={type}>
                <TableCell colSpan={1}><b>{formNumber}</b></TableCell>
                <TableCell colSpan={2}><b>{title}</b></TableCell>
                <TableCell colSpan={2}>{printVariant}</TableCell>
                <TableCell colSpan={3}>{description}</TableCell>
                <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                  <Button disabled={loading} variant="outlined" color="primary" style={{ marginBottom: '3px' }} onClick={() => onPrintLocalEstimate({ fileType: 'PDF', type })}>
                    PDF
                  </Button>
                  <Button disabled={loading} variant="outlined" color="primary" onClick={() => onPrintLocalEstimate({ fileType: 'XLSX', type })}>
                    XLSX
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PrintManager;