import { observable, action, decorate } from 'mobx';
// import _ from 'lodash';
import { createTrial, trialActivated } from 'services/orders';
import { getDetails as getCompanyDetails } from 'services/companies';
import { getCurrentAccount } from 'utils/helpers';

const SERVICES = {
  estimates: {
    title: 'Sąmatų skaičiavimai',    
  },
  investments: {
    title: 'Statinių palyginamieji rodikliai (kainynas)',    
  },
  ntk: {
    title: 'Statinių statybos ir atkuriamosios vertės skaičiavimai',
  },
  normsPbw: {
    title: 'Sustambintų darbų kainynas',
  },
  normsPrw: {
    title: 'Renovacijos darbų kainynas',
  },
  normsCalc: {
    title: 'Vienetinių ir sustambintų darbų kainų skaičiavimai',
  },
}

class Store {
  // Observable
  data = {}

  // Observable
  loading = false

  // Observable
  prevTrial = null;

  // Observable
  service = null;

  // Observable
  companyAdmin = null;

  // Action
  reset = () => {
    this.data = {};
    this.loading = false;
    this.companyAdmin = null;
  }

  // Action
  setData = data => {
    this.data = data;
  }

  // Action
  setCompanyAdmin = data => {
    this.companyAdmin = data;
  }

  // Action
  setPrevTrial = data => {
    this.prevTrial = data;
  }

  // Action
  setLoading = (loading = true) => {
    this.loading = loading;
  }

  // Action
  setService = serviceCode => {
    const service = SERVICES[serviceCode];
    this.service = service;
    
    return service;
  }

  submitTrial = async (service) => {
    await createTrial({ service });
  }

  getPreviousTrial = async (service) => {
    this.setLoading();

    const prevTrial = await trialActivated({ service });
    this.setPrevTrial(prevTrial);

    
    this.setLoading(false);
    
    if (prevTrial) await this.getCompanyAdmin(); 
  }

  getCompanyAdmin = async () => {
    this.setLoading();

    const { company } = getCurrentAccount();
    if (!company) {
      console.log('Company not found for account');
      return;
    }

    const { owner } = await getCompanyDetails(company._id);
    this.setCompanyAdmin(owner);

    this.setLoading(false);
  }
}

decorate(Store, {
  data: observable,
  companyAdmin: observable,
  loading: observable,
  service: observable,
  prevTrial: observable,
  reset: action,
  setData: action,
  setCompanyAdmin: action,
  setService: action,
  setPrevTrial: action,
  setLoading: action,
});

export default new Store;