import React, { Component } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid, Button, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Prompt } from 'react-router-dom';

import RecoveryPrice from 'views/RecoveryPrice';
import ProjectDetails from './components/ProjectDetails';
import SiteDetails from './components/SiteDetails';
import CalculatedResults from './components/CalculatedResults';
import MaterialChooser from './components/MaterialChooser';
import CoefficientTable from './components/CoefficientTable';
import CalculatedTable from './components/CalculatedTable';
import CorrectionValues from './components/CorrectionValues';
import {
  getTypes,
  valueChanged,
  resetForm,
  getProjects,
  saveForm,
  getRecord,
  getReport,
  updateForm,
} from './_actions';
import { getExcelExport } from 'services/ntk';

import {
  confirmDialog,
  getCurrentAccount,
  b64toBlob,
  blobToFileDownload
} from 'utils/helpers';
import { history } from 'utils/history';
import api from 'utils/api';
import { EDU_MODE } from 'utils/config';

const SERVICE_CODE = 'ntk';

// Component styles
const styles = () => ({
  root: {
    padding: '0px'
  },
  item: {
    height: '100%'
  }
});

class RecoveryPriceForm extends Component {
  state = {
    showTrialMessage: false
  };

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(resetForm());
  }

  componentWillUnmount() {
    // Remove the page leave listener
    // window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  componentDidMount() {
    if (_.includes(getCurrentAccount().trialServices, SERVICE_CODE)) {
      this.setState({ showTrialMessage: true });
    }

    // Listen for page leave attempt
    // window.addEventListener('beforeunload', this.beforeunload.bind(this));

    const { dispatch, match } = this.props;
    const recordId = match.params.id;
    if (recordId) {
      dispatch(getRecord(recordId));
    } else {
      const projectId = queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      }).project;

      dispatch(getProjects(projectId));
      dispatch(getTypes());
    }
  }

  getWallTypes() {
    const { record } = this.props;

    if (!record.materials) {
      return;
    }

    // TOTO is this {type: 'Sienos'} multilanguage proof?
    const materialGroup = _.find(record.materials, { type: 'Sienos' });
    if (!materialGroup) {
      return;
    }

    const wallMaterials = materialGroup.values.join(', ').split(', ');

    return wallMaterials;
  }

  handleChange({ name, value }) {
    if (!name) {
      return;
    }

    const { dispatch } = this.props;
    dispatch(valueChanged({ name, value }));
  }

  beforeunload(e) {
    const { record } = this.props;

    if (record.amount) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  handleRecalcVersion() {
    const { record } = this.props;

    history.push(`/recovery-price/${record._id}/clone`);
  }

  async handleDelete() {
    const { record, t } = this.props;

    if (!window.confirm(t('Ar tikrai norite pašalinti šį skaičiavimą?')))
      return;

    try {
      await api.delete(`/services/recovery-price/${record._id}`);

      history.push('/recovery-price/');
    } catch (err) {
      console.error(err);
      window.alert(
        t(
          'Nepavyko pašalinti. Patikrinkite, ar tikrai turite teises šalinti šį įrašą.'
        )
      );
    }
  }

  handleClearForm() {
    const { dispatch, record, t } = this.props;

    if (confirmDialog(t('Ar tikrai norite išvalyti skaičiavimo duomenis?'))) {
      dispatch(resetForm());
      dispatch(getProjects(record.project));
      dispatch(getTypes());

      if (record._id) {
        // Restore ID if existing record
        dispatch(valueChanged({ name: '_id', value: record._id }, true));
      }
    }
  }

  handlePrintReport = async (fileType) => {
    const { dispatch, record, t } = this.props;

    const printOptions = {
      type: 'calculationReport',
      params: ['id'],
      values: [record._id],
      fileType: fileType,
    };
  
    try {
      dispatch(getReport(printOptions));
    } catch (err) {
      console.error(err);
      window.alert(t('Įvyko klaida'));
    }
  }

  handleCreatedNewProject(newId) {
    this.props.dispatch(getProjects());

    if (newId) {
      const self = this;
      setTimeout(function() {
        self.handleChange({ name: 'project', value: newId });
      }, 2000);
    }
  }

  handleSaveForm() {
    const { dispatch, record } = this.props;

    if (record._id) {
      dispatch(updateForm());
    } else {
      dispatch(saveForm());
    }
  }

  async handleExport() {
    const { t } = this.props;

    try {
      const {
        record,
        data: { currentProject },
        data
      } = this.props;
      const { doc: base64Response, filename } = await getExcelExport({
        record,
        currentProject,
        allMaterials: data.materials
      });

      const blob = b64toBlob(
        base64Response,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );

      blobToFileDownload(`${_.snakeCase(filename)}.xlsx`, blob);
    } catch (err) {
      window.alert(t('Nenumatyta klaida eksportuojant'));
    }
  }

  renderContent() {
    const {
      classes,
      data,
      record,
      showMaterials,
      skipMaterials,
      showResults,
      isLoadingRecord,
      t
    } = this.props;

    return (
      <div
        className={classes.root}
        style={{ marginTop: '-20px', maxWidth: '1200px' }}>
        <Prompt
          when={record.amount}
          message={(location, action) => {
            // Prevents showing confirmation when no navigation out of record scope
            return location.pathname.startsWith('/recovery-price/')
              ? true
              : t(
                  'Ar tikrai norite išeiti iš skaičiavimo? Neišsaugoti duomenys bus prarasti.'
                );
          }}
        />

        <Grid container spacing={2}>
          {record.pricing && record.type && record.buildingType ? (
            <Grid item sm={12} style={{ textAlign: 'right' }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={this.handleSaveForm.bind(this)}
                // className={classes.resetButton}
              >
                {t('Išsaugoti')}
              </Button>

              <Button
                style={{ marginLeft: 10 }}
                variant="outlined"
                color="primary"
                onClick={this.handleClearForm.bind(this)}
                // className={classes.resetButton}
              >
                {t('Išvalyti skaičiavimo duomenis')}
              </Button>

              {!!record._id && (
                <Button
                  style={{ marginLeft: 10 }}
                  variant="outlined"
                  color="primary"
                  onClick={this.handleRecalcVersion.bind(this)}
                  // className={classes.resetButton}
                >
                  {t('Perskaičiuoti kita kainų versija')}
                </Button>
              )}
            </Grid>
          ) : null}

          <Grid item sm={12}>
            <ProjectDetails
              projects={data.projects}
              currentProject={data.currentProject}
              isCollapsed={isLoadingRecord || data.currentProject != null}
              handleChange={this.handleChange.bind(this)}
              onCreatedNewProject={value => this.handleCreatedNewProject(value)}
            />
          </Grid>

          <Grid item sm={5}>
            <SiteDetails
              handleChange={this.handleChange.bind(this)}
              handleClearForm={this.handleClearForm.bind(this)}
              handleSaveForm={this.handleSaveForm.bind(this)}
              types={data.types}
              versions={data.versions}
              hierarchy={data.hierarchy}
              project={record.project}
              title={record.title}
              description={record.description}
              type={record.type}
              buildingType={record.buildingType}
              unit={record.unit}
              amount={record.amount}
              pricing={record.pricing}
              style={{ display: data.currentProject ? 'block' : 'none' }}
            />
          </Grid>

          <Grid item sm={7}>
            {showMaterials ? (
              <MaterialChooser
                materials={data.materials}
                initialMaterials={data.initialMaterials}
                recordMaterials={record.materials}
                handleChange={this.handleChange.bind(this)}
              />
            ) : null}
          </Grid>

          <Grid item sm={5}>
            {(showMaterials || skipMaterials) && showResults ? (
              <div>
                <CorrectionValues
                  corrections={record.corrections}
                  handleChange={this.handleChange.bind(this)}
                />

                <CoefficientTable
                  coefficients={record.coefficients}
                  handleChange={this.handleChange.bind(this)}
                />
              </div>
            ) : null}
          </Grid>
          <Grid item sm={7}>
            {(showMaterials || skipMaterials) && showResults ? (
              <CalculatedResults
                totals={record.totals}
                unit={record.unit}
                wallType={record.wallType}
                type={record.type}
                usageStartDate={record.usageStartDate}
                handleChange={this.handleChange.bind(this)}
                wallTypes={this.getWallTypes()}
                buildingType={record.buildingType}
              />
            ) : null}
          </Grid>
          <Grid item sm={12}>
            {(showMaterials || skipMaterials) &&
            showResults &&
            record.percents.length ? (
              <CalculatedTable
                percents={record.percents}
                totals={record.totals}
                unit={record.unit}
                handleChange={this.handleChange.bind(this)}
              />
            ) : null}
          </Grid>

          {record.pricing && record.type && record.buildingType ? (
            <>
              <Grid item sm={5}>
                {record._id && (
                  <div>
                    <Button
                    // size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => this.handlePrintReport('PDF')}
                    // className={classes.resetButton}
                    >
                    {t('Spausdinti (PDF)')}
                    </Button>

                    <Button
                    style={{ marginLeft: 10 }}
                    // size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => this.handlePrintReport('XLSX')}
                    // className={classes.resetButton}
                    >
                    {t('Spausdinti (Excel)')}
                    </Button>
                  </div>
                )}
              </Grid>
              <Grid item sm={7} style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.handleSaveForm.bind(this)}
                  // className={classes.resetButton}
                >
                  {t('Išsaugoti')}
                </Button>

                <Button
                  style={{ marginLeft: 10 }}
                  variant="outlined"
                  color="primary"
                  onClick={this.handleClearForm.bind(this)}
                  // className={classes.resetButton}
                >
                  {t('Išvalyti skaičiavimo duomenis')}
                </Button>

                {record._id && (
                  <Button
                    style={{
                      marginLeft: 10,
                      backgroundColor: '#dc004e',
                      color: '#ffffff'
                    }}
                    variant="contained"
                    size="small"
                    onClick={this.handleDelete.bind(this)}
                    // className={classes.resetButton}
                  >
                    {t('Pašalinti skaičiavimą')}
                  </Button>
                )}
              </Grid>
            </>
          ) : null}
        </Grid>
      </div>
    );
  }

  renderTrialMessage() {
    const { t } = this.props;

    return EDU_MODE ? null : (
      <div style={{ margin: 10 }}>
        <Alert
          variant="filled"
          severity="info"
          // onClose={() => this.setState({ showTrialMessage: false })}
        >
          {t('Jūs naudojate bandomąją (laikinai nemokamą) paslaugos versiją.')}
        </Alert>
      </div>
    );
  }

  render() {
    const { isLoading, record, t } = this.props;
    const { isTrial, showTrialMessage } = this.state;

    return (
      <RecoveryPrice
        title={t('Atkuriamoji vertė')}
        isContentLoading={isLoading}
        breadcrumbs={[
          { title: t('Paslaugos') },
          {
            title: t('Atkuriamosios vertės skaičiavimai'),
            to: '/recovery-price'
          },
          { title: record._id ? record.title || '-' : t('Naujas skaičiavimas') }
        ]}
        beforeMainContent={
          !isTrial && showTrialMessage && this.renderTrialMessage()
        }>
        {this.renderContent()}
      </RecoveryPrice>
    );
  }
}

const mapStateToProps = ({ recoveryPriceForm, dispatch }) => {
  return {
    ...recoveryPriceForm,
    dispatch
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation()
)(RecoveryPriceForm);
