export default theme => ({
  root: {},
  textField: {
    '& .MuiInputBase-multiline, .MuiSelect-selectMenu': {
      paddingTop: '10.5px',
      paddingBottom: '10.5px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  special: {
    marginTop: theme.spacing(1),
  },
  btnClone: {
    marginTop: 2,
  },
  cloneWrap: {
    marginTop: theme.spacing(1),
  },
});
