import React, { Component } from 'react';
import NormSearchContainer from './NormSearchContainer';
import store from './_store';

class NormSearch extends Component {

  render() {
    return <NormSearchContainer store={store} {...this.props} />
  }
}

export default NormSearch;