export default theme => ({
  root: {},
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '70px',
    maxWidth: '100%',
    margin: 0,
    textAlign: 'center',
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  boldCell: {
    fontWeight: 'bold',
  },
  noTopBorder: {
    borderTop: '0px',
  },
  noBottomBorder: {
    borderBottom: '0px',
  },
  radioGroup: {
    display: 'inline',
    marginLeft: '10px',
    'label svg': {
      width: '18px',
      height: '18px',
    }
  },
});
