import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles, Button } from '@material-ui/core';
import { Form } from 'react-final-form';

import styles from './styles';
import getFields from './fields';
import { getOne, updateOne, createOne, resetForm, registerNew, setValue, setActive } from './_actions';
import { canAccess, toast } from 'utils/helpers';

import { CommonPanel } from 'components';
import { FormField } from 'components';
import Companies from 'views/Companies';
import AccountList from './components/AccountList';

// Inject reducers
import { store } from 'utils/store';
import { companiesForm } from './_reducers';
store.injectReducer('companiesForm', companiesForm);

class CompaniesForm extends Component {
  state = {
    fields: null,
    isAdmin: false,
  }

  componentDidMount() {
    const isAdmin = canAccess('administrator.layout');

    getFields().then(fields => {
      this.setState({
        fields,
        isAdmin,
      });
    });

    const { match, dispatch, prefill } = this.props;

    if (match && match.params.id) {
      dispatch(getOne(match.params.id));
      return;
    }

    if (prefill) {
      _.forEach(_.keys(prefill), key => (
        dispatch(setValue({ name: key, value: prefill[key] }))
      ));

      if (prefill.type && prefill.type == 'personal') {
        this.handlePersonalFields();
      }
    }
  }

  handlePersonalFields() {
    const personalFieldKeys = [
      // 'vat',
      'country',
      'email',
      'phone',
      'address',
      'website',
      'comment',
    ];

    let personalFields = [{
      name: 'position',
      label: this.props.t('Pareigos'),
      type: 'text',
      required: true,
    }];
    
    personalFields = _.concat(personalFields, _.filter(this.state.fields, field => _.includes(personalFieldKeys, field.name)));
    this.setState({
      fields: personalFields,
    });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetForm());

    this.setState({ fields: null });
  }

  validate(values) {
    let errors = {};

    if (values.type == 'personal') {
      return errors;
    }

    if (!values.name) {
      errors.name = this.props.t('Privalomas');
    }

    if (!values.type) {
      errors.type = this.props.t('Privalomas');
    }

    if (!values.code) {
      errors.code = this.props.t('Privalomas');
    }

    if(/\s/g.test(values.code)) {
      if (errors.code) {
        errors.code = `${this.props.t('Privalomas')}. ${this.props.t('Negali turėti tarpo simbolių')}`;
      } else {
        errors.code = this.props.t('Negali turėti tarpo simbolių');
      }
    }

    return errors;
  }

  onSubmit(values) {
    const { dispatch, registering, t } = this.props;

    if(/\s/g.test(values.code)) {
      toast(t('Įmonės kodas negali turėti tarpo simbolių'), 'error');
      return;
    }

    if (registering) {
      dispatch(registerNew(values));
      return;
    }

    if (values._id) {
      // Update
      dispatch(updateOne(values));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  submitForm(e) {
    e.preventDefault();
    // debugger;
    document
      .getElementById('companiesForm')
      .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }

  toggleActivation(id, activated) {
    const { dispatch } = this.props;

    dispatch(setActive(id, activated));
  }

  valueChanged() {
  }

  renderForm() {
    const { classes, record, isIndividual } = this.props;

    if (!this.state.fields) {
      return <div>...</div>;
    }
    
    return (
      <Form
        initialValues={record}
        onSubmit={this.onSubmit.bind(this)}
        validate={this.validate.bind(this)}
        render={({ handleSubmit, values }) => (
          <form
            id="companiesForm"
            className={classes.form}
            onSubmit={handleSubmit}
          >
            {_.map(this.state.fields, field => 
              <FormField 
                key={field.name} 
                hidden={isIndividual && values.type == 'personal' && ['code', 'department'].includes(field.name)} 
                {...['name'].includes(field.name) ? isIndividual && values.type == 'personal' ? field.label = 'Vardas Pavardė' : field.label = 'Pavadinimas' : null}
                {...field}
              />
            )}
          </form>
        )}
      />
    )
  }

  render() {
    const { embed, record, t } = this.props;
    const title = _.get(record, 'name', t('Nauja įmonė'));

    if (embed) {
      return this.renderForm();
    }

    return (
      <Companies
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Įmonės'), to: '/companies' },
          { title }
        ]}
      >
        <CommonPanel>
          {this.renderForm()}

          <Button
            color="primary"
            variant="contained"
            onClick={event => this.submitForm(event)}
          >
            {t('Išsaugoti')}
          </Button>

          {this.state.isAdmin && record && !record.isActive ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.toggleActivation(record._id, true)}
              style={{ marginLeft: 10 }}
            >
              {t('Aktyvuoti įmonę')}
            </Button>
          ) : null}

          {this.state.isAdmin && record && record.isActive ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.toggleActivation(record._id, false)}
              style={{ marginLeft: 10 }}
            >
              {t('Deaktyvuoti įmonę')}
            </Button>
          ) : null}
        </CommonPanel>

        {record && record._id ? (
          <CommonPanel title={t('Įmonės paskyros')} noPadding rootStyle={{ marginTop: 20 }}>
            <AccountList company={record._id} />
          </CommonPanel>
        ) : null}
      </Companies>
    );
  }
}

const mapStateToProps = ({ companiesForm, dispatch }) => {
  return { 
    ...companiesForm,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(CompaniesForm);