import React from 'react';

import { FormField2 } from 'components';

class Form extends React.Component {

  onChange({ name, value }) {
    this.props.store.setRecordValue(name, value);
  }

  renderFormField({ name, ...field }) {
    const { record } = this.props.store;
    
    return (
      <FormField2
        {...field}
        name={name}
        // autoComplete={`project-${name}`}
        key={name}
        value={record ? record[name] : (field.multiple ? [] : '')}
        onChange={this.onChange.bind(this)}
      />
    );
  }
  
}

export default Form;