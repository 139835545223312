import ClassifierGroupsList from './components/ClassifierGroupsList';
import ClassifierGroupsForm from './components/ClassifierGroupsForm';

export default [
  {
    key: 'list',
    component: ClassifierGroupsList,
    path: '/classifier-groups',
    public: false,
  },
  {
    key: 'create',
    component: ClassifierGroupsForm,
    path: '/classifier-groups/create',
    public: false,
  },
  {
    key: 'edit',
    component: ClassifierGroupsForm,
    path: '/classifier-groups/:id',
    public: false,
  }
];