import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { EDU_MODE } from 'utils/config';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import * as projectService from 'services/projects';
import { toast, getQueryVariable, getCurrentAccount, setActiveAccount } from 'utils/helpers';
import { Dashboard as DashboardLayout } from 'layouts';
import { FormField2 as Field, CommonPanel, TrialHandler } from 'components';
import { getCurrentUser } from 'utils/helpers';
import api from 'utils/api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    // maxWidth: '1200px',
  },
  titleCoefficients: {
    fontSize: '9px',
    padding: '0px 8px 2px',
  },
}));

const SERVICE_CODE = 'estimates';

const EstimateObjectTransferForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [transfer, setTransfer] = useState(null);
  const [accountOptions, setAccountOptions] = useState(null);
  const [projectOptions, setProjectOptions] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isNewProject, setIsNewProject] = useState(false);
  const [newProjectTitle, setNewProjectTitle] = useState(false);
  const [newProjectCode, setNewProjectCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { transferId } = useParams();
  const [showTrialMessage, setShowTrialMessage] = useState(getCurrentAccount().trialServices.includes(SERVICE_CODE));
  const [isTrial, setIsTrial] = useState(!!getQueryVariable('trial'));

  const fetchTransfer = async () => {
    try {
      const { data } = await api.get(`/transfers/${transferId}`);
      if (data.status !== 'pending') {
        toast(t('Veiksmai su šiuo perkėlimu nebegalimi'), 'error');
        history.push('/projects?estimates');
      }
      setTransfer(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašą'), 'error');
    }
  }

  useEffect(() => {
    if (isTrial) return;
    fetchTransfer();
    getAccounts();
  }, []);

  const getAccounts = async () => {
    setLoading(true);
    const accounts = getCurrentUser().accounts;
    const options = [];

    _.map(accounts, account => {
        const companyName = account.company ? account.company.name : t('Įmonė nenurodyta');
        options.push({ key: account._id, value: account._id, company: account?.company, label: `${account?.position} - ${companyName}` });
    });

    setAccountOptions(options);
    setLoading(false);
  }

  const getProjects = async (account) => {
    setLoading(true);
    setProjectOptions(null);
    const projects = await projectService.list({lite: true});
    const options = [];

    _.map(projects, project => {
        options.push({ key: project._id, value: project._id, label: project.code ? `${project.code} - ${project.title}` : project.title });
    });

    setProjectOptions(options);
    setLoading(false);
  }

  const switchAccount = async (id) => {
    const currentAcc = getCurrentAccount();
    if (currentAcc._id != id) {
      await setActiveAccount(id, true);
    }
  }

  const handleAccountSelect = async (value) => {
    try {
      setSelectedProject(null);
      setSelectedAccount({value});
      await switchAccount(value);
      await getProjects(value);
    } catch (err) {
      toast(t('Klaida keičiant paskyrą', 'error'));
    }
  };

  
  const renderTrial = () => {

    return (
      <TrialHandler
        service="estimates"
      />
    )
  }

  const renderTrialMessage = () => {

    return EDU_MODE ? null : (
      <div style={{ margin: 10 }}>
        <Alert
          variant="filled"
          severity="info"
        // onClose={() => this.setState({ showTrialMessage: false })}
        >
          {t('Jūs naudojate bandomąją (laikinai nemokamą) paslaugos versiją.')}
        </Alert>
      </div>
    )
  }

  const onAccept = async () => {
    const data = {
        newProject: isNewProject,
        projectTitle: newProjectTitle,
        projectCode: newProjectCode,
        project: selectedProject,
        account: selectedAccount,
    }

    try {
      if (!selectedAccount) {
        toast(t('Pasirinkite paskyrą'), 'error');
        return;
      }

      if (selectedAccount && !isNewProject && !selectedProject) {
        toast(t('Pasirinkite projektą'), 'error');
        return;
      }

      if (isNewProject && !newProjectTitle) {
        toast(t('Užpildykite naujo projekto pavadinimo lauką'), 'error');
        return;
      }

      await api.post(`/transfers/${transferId}/accept`, data);
      toast(t('Perkėlimas įvykdytas'), 'success');
      history.push(isNewProject ? '/projects?estimates' : `/projects/${selectedProject.value}/dashboard`);
    } catch (err) {
      console.error(err);
      toast(t('Klaida vykdant perkėlimą'), 'error');
    }
  } 

  const onReject = async () => {
    if (!window.confirm(t('Ar tikrai norite atšaukti?'))) return;

    try {
      await api.get(`/transfers/${transferId}/reject`);
      toast(t('Perkėlimas atšauktas'), 'success');
      history.push(`/projects?estimates`);
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko atšaukti'), 'error');
    }
  }

  return (
    <div>
      <DashboardLayout
        isContentLoading={loading}
        title={t('Sąmatos skaičiavimų perkėlimas')}
        beforeMainContent={!isTrial && showTrialMessage && renderTrialMessage()}
      >
        <div className={classes.root}>
          {!isTrial ? 
            <CommonPanel
              footer={(
                <Grid>
                  <Button 
                    color="primary" 
                    variant="contained"
                    onClick={() => onAccept()}
                  >
                    {t('Priimti duomenis')}
                  </Button>
                  <Button 
                    style={{marginLeft: '20px'}} 
                    color="secondary" 
                    variant="contained"
                    onClick={() => onReject()}
                  >
                    {t('Atmesti kvietimą')}
                  </Button>
                  <Button 
                    style={{marginLeft: '20px'}} 
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    {t('Grįžti')}
                  </Button>
                </Grid>
              )}
            >
              <Grid>
                <Grid>
                  <Typography variant="body1">{t('Skaičiavimų objektas')}: <strong>{transfer?.estimateObject?.title}</strong></Typography>
                </Grid>
                <Grid>
                  <Typography variant="body1" style={{marginTop: 10}}>{t('Pakvietimą išsiuntė')}: <strong>{transfer?.createdBy.fullName} - {transfer?.createdBy.email}</strong></Typography>
                </Grid>
                <Grid>
                  <Field
                    label={t('Pasirinkite paskyrą')}
                    name="account"
                    value={selectedAccount?.value}
                    placeholder={t('Pasirinkite...')}
                    type="select"
                    options={accountOptions}
                    style={{ width: 400, marginTop: 15 }}
                    onChange={({value}) => handleAccountSelect(value)}
                  />
                </Grid>
                <Grid>
                  <Field
                    label={t('Pasirinkite projektą')}
                    name="project"
                    value={selectedProject?.value}
                    placeholder={t('Pasirinkite...')}
                    type="select"
                    options={projectOptions}
                    optionStyle={{ maxWidth: 450 }}
                    selectStyle={{ maxHeight: 400 }}
                    disabled={isNewProject || !projectOptions}
                    onChange={({ value }) => setSelectedProject({ value })}
                    style={{ width: 400 }}
                  />
                </Grid>
                <Grid>
                  {isNewProject ? 
                    <div>
                      <Field 
                        name="text"
                        onChange={({ value }) => setNewProjectTitle(value)}
                        value={newProjectTitle}
                        label={t('Projekto pavadinimas')}
                        style={{ maxWidth: 300 }}
                        required={isNewProject}
                      />
                      <Field 
                        name="text"
                        onChange={({ value }) => setNewProjectCode(value)}
                        value={newProjectCode}
                        label={t('Projekto kodas')}
                        style={{ maxWidth: 300 }}
                      />
                    </div>
                  : null}
                </Grid>
                <Grid>
                  <FormControlLabel style={{marginTop: 10}}
                    control={
                      <Checkbox
                        checked={isNewProject}
                        onChange={() => setIsNewProject(!isNewProject)}
                        name="isNewProject"
                        color="primary"
                      />
                    }
                    label="Sukurti naują projektą"
                  />
                </Grid>
              </Grid>
            </CommonPanel>
          : renderTrial()}
        </div>
      </DashboardLayout>
    </div>
  );
}
export default EstimateObjectTransferForm;