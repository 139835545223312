import DiscountCodesList from './DiscountCodesList';
import DiscountCodesForm from './DiscountCodesForm';

export default [
  {
    key: 'list',
    component: DiscountCodesList,
    path: '/discount-codes',
    public: false,
  },
  {
    key: 'create',
    component: DiscountCodesForm,
    path: '/discount-codes/create',
    public: false,
  },
  {
    key: 'edit',
    component: DiscountCodesForm,
    path: '/discount-codes/:id',
    public: false,
  }
];