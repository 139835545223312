export default (theme) => ({
  root: {},
  formWrap: {
    padding: theme.spacing(),
  },
  rulesTable: {

    '& th, td': {
      border: '1px solid #DFE3E8',
    }
  },
  packageRadio: {
    marginRight: 5,
  },
  packageHeaderCell: {
    whiteSpace: 'nowrap',
    width: '120px',

    '&.active': {
      backgroundColor: '#f3f3f3',
    }
  },
  packageCell: {

    '&.active': {
      backgroundColor: '#f3f3f3',
    }
  },
  rulesTableFooter: {

    '& td': {
      fontWeight: 'bold',
    }
  },
  inputCenter: {
    textAlign: 'center',

    '& input': {
      textAlign: 'center',
      paddingRight: 0,
    },
  },
  activePriceCell: {
    border: '2px solid #1e88e5 !important',
  }
});
