import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  Tab,
  Tabs,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';
import {
  CommonDialog,
  NormPriceComposition,
  NormCoefficients,
  EstimatePriceSettings,
} from 'components';
import api from 'utils/api';

const TABS = {
  PRICE_COMPOSITION: 'price_composition',
  COEFFICIENTS: 'coefficients',
  PRICE_SETTINGS: 'price_settings',
  JOB_COMPOSITION: 'job_composition',
}

const useStyles = makeStyles({
  root: {},
  filePickerTableContainer: {
    maxWidth: 800,
  },
  footerWrap: {
    marginTop: 40,
  }
})

const ObjectSummaryModal = ({ objectId, itemId, onClose, onChange }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(TABS.PRICE_COMPOSITION);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [title, setTitle] = useState(null);
  const [hadChanges, setHadChanges] = useState(false);
  const [priceSettings, setPriceSettings] = useState(null);
  const [coefficients, setCoefficients] = useState(null);

  const fetchItem = async () => {
    setLoading(true);

    try {
      const { data } = await api.get(`/estimates/${objectId}/summary`);
      setItem({ ...data });
      setPriceSettings(data.priceSettings);
      setCoefficients(_.pick(data, ['coefficients', 'coefficientsSpec', 'customCoefficients']));

      setTitle(<><b>{data.code}</b> {data.title}</>);
    } catch (err) {
      console.error(err);
    }


    setLoading(false);
  }

  useEffect(() => {
    if (!item) fetchItem();
  }, []);

  const handleClose = () => {
    onClose(hadChanges);
  };

  const handleTabChange = (event, tabCode) => {
    setActiveTab(tabCode);
    return;
  };

  const onPriceSettingsChange = async (priceSettings) => {
    setPriceSettings([...priceSettings]);
  };

  const handleSubmit = async (type) => {
    setLoading(true);

    if (type === 'price-settings') {
      try {
        await api.put(`/estimates/${objectId}/price-settings`, priceSettings);
  
        setHadChanges(true);
  
        await fetchItem();
      } catch (err) {
        console.error(err);
      }
    } else {
      window.alert('Unknown type ' + type);
    }

    setLoading(false);
  }

  const actions = (
    <>
      {(activeTab === TABS.COEFFICIENTS || activeTab === TABS.PRICE_SETTINGS) && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(activeTab === TABS.COEFFICIENTS ? 'coefficients' : 'price-settings')}
        >
          {activeTab === TABS.COEFFICIENTS ? t('Perskaičiuoti su koeficientais') : t('Perskaičiuoti su kainos nustymais')}
        </Button>
      )}

      <Button
        color="primary"
        onClick={handleClose}
      >
        Uždaryti
      </Button>
    </>
  );

  return (
    <CommonDialog
      visible
      onClose={handleClose}
      maxWidth="md"
      style={{ position: 'relative' }}
      showClose
      actions={actions}
      disableBackdropClick
    >
      {title && (
        <Typography variant="body1">
          {title} 
        </Typography>
      )}

      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab label="Kainos sudėtis" value={TABS.PRICE_COMPOSITION} />
        {/* {isRate && <Tab label="Darbų sudėtis" value={TABS.JOB_COMPOSITION} disabled={!item?.workComposition} />}
        {isRate && <Tab label="Koeficientai" value={TABS.COEFFICIENTS} />} */}
        <Tab label="Darbo kainos nustatymai" value={TABS.PRICE_SETTINGS} />
      </Tabs>

      <div style={{ minHeight: '450px', marginTop: '10px' }}>
        {activeTab === TABS.PRICE_COMPOSITION && <NormPriceComposition hideAdditionals item={item} />}
        {/* {activeTab === TABS.JOB_COMPOSITION && isRate && <Typography variant="body1">{item?.workComposition}</Typography>} */}
        {/* {activeTab === TABS.COEFFICIENTS && isRate && <NormCoefficients onChange={onCoefficientsChange} item={item} />} */}
        {activeTab === TABS.PRICE_SETTINGS && <EstimatePriceSettings isRate={false} onChange={onPriceSettingsChange} priceSettings={priceSettings} />}
      </div>
    </CommonDialog>
  )
}

export default ObjectSummaryModal;