export default {
  shrink: {
    transform: 'translate(0, 1.5px)',
  },
  outlined: {
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px)',
      backgroundColor: '#fff',
    }
  }
};
