import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  InputBase,
  Paper,
  IconButton,
} from '@material-ui/core';
import {
  CancelOutlined as CancelButton,
} from '@material-ui/icons';

import styles from './styles';

class ValueField extends Component {
  state = {
    initialValue: '',
    currentValue: '',
  }

  componentWillMount() {
    const { value, initial } = this.props;

    this.setState({
      initialValue: initial == 0 || initial ? initial : value,
      currentValue: value,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { initial, value } = nextProps;
    const { initialValue } = this.state;
    
    this.setState({
      initialValue: initial || initialValue,
      currentValue: value,
    });
  }

  onChange(e) {
    let currentValue = e.target.value;
    currentValue = currentValue ? currentValue.replace(',', '.') : currentValue;

    // Update state with entered value
    this.setState({
      currentValue,
    });
  }

  onBlur() {
    this.handleChange(this.state.currentValue);
  }

  onKeyDown(e) {
    // Trigger onBlur if [Enter] was pressed
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  onResetClick() {
    const { initialValue } = this.state;

    // Reset to initial value
    this.setState({
      currentValue: initialValue,
    });

    this.handleChange(initialValue);
  }

  handleChange(value) {
    const { onBlur, name } = this.props;

    if (!onBlur) {
      return;
    }

    onBlur({
      field: name,
      value,
    });
  }

  render() {
    const { classes, defaultValue, style, inputStyle } = this.props;
    const { initialValue, currentValue } = this.state; 
    
    const unchanged = initialValue == currentValue;

    return (
      <Paper className={['valuefieldroot', classes.root, unchanged ? classes.rootInitial : classes.rootChanged].join(' ')} style={style}>
        <InputBase
          fullWidth
          className={[classes.textField, 'valuefieldinput'].join(' ')}
          inputProps={{style: inputStyle}}
          onChange={this.onChange.bind(this)}
          onBlur={this.onBlur.bind(this)}
          onKeyDown={this.onKeyDown.bind(this)}
          onFocus={e => e.target.select()}
          // name="title"
          value={currentValue || defaultValue}
        />
        <IconButton
          className={[classes.iconButton, unchanged ? classes.iconButtonInitial : classes.iconButtonChanged].join(' ')}
          onClick={this.onResetClick.bind(this)}
        >
          <CancelButton />
        </IconButton>
      </Paper>
    )
  }
}

export default withStyles(styles)(ValueField);