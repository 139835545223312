import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  Paper,
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

import api from 'utils/api';
import { FormField2 as Field } from 'components';
import { ImportContext } from './ImportManager';
import { TreeTable } from 'components/TreeTable';

const ImportPreview = () => {
  const {
    loading,
    config: contextConfig,
    record: contextRecord,
    importActions,
  } = useContext(ImportContext);

  const { t } = useTranslation();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [classifiers, setClassifiers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const { objectId } = useParams();
  const estimateLevels = contextConfig.estimateLevels;
  const estimateLevelMap = Object.fromEntries(estimateLevels.map(({ value, label }) => [value, label]));
  const { mapping } = contextRecord;
  estimateLevelMap['element'] = t('Elementas');

  const fetchClassifiers = async () => {
    try {
      const { data } = await api.get('/classifiers?lite=true');

      setClassifiers(data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchPreview = async (data) => {
    try {
      const { data: preview } = await api.post(`/estimates/${objectId}/import/preview`, data);
      setData(preview);
    } catch (err) {
      console.error('Unable to fetch preview', err);
      // window.alert('Nenumatyta klaida');
      setData(null);
    }
  }

  useEffect(() => {
    fetchClassifiers();
    fetchPreview(contextRecord);
  }, []);

  useEffect(() => {
    const defaultColumns = [
      { value: 'code', label: 'Kodas', fixed: 'left', width: 100, },
      { value: 'title', label: 'Pavadinimas', fixed: 'left', width: 150, },
      { value: 'quantity', label: 'Kiekis', fixed: 'left', width: 50, },
      { value: 'type', label: t('Tipas'), fixed: 'left', width: 80, },
    ];

    const classifierColumns = classifiers
      .filter(({ code }) => mapping.find(m => m.value === code))
      .map(({ code, title }) => ({
        value: `mapping.${code}`,
        label: title,
        render: (text, record) => record.mapping?.[code],
      }));

    const finalColumns = [
      ...defaultColumns,
      ...classifierColumns,
    ].map(field => createColumn(field));

    setColumns(finalColumns);
  }, [classifiers]);

  const createColumn = (field) => {
    const column = {
      title: field.label,
      dataIndex: field.value,
      key: field.value,
      // width: 100,
      // ellipsis: { showTitle: false },
      // render: (text) => <span title={text}>{text}</span>,
      // editable: true,
      // fixed: 'left',
      width: field.width || 100,
      fixed: field.fixed,
      render: field.render,
    }

    if (field.value === 'type') {
      // column.render = (text) => <span>{text ? estimateLevelMap[text] : estimateLevelMap['element']}</span>;
      column.render = (text) => <span>{text ? estimateLevelMap[text] : ''}</span>;
    }

    return column;
  }

  const old_columns = [
    { value: 'code', label: 'Kodas', fixed: 'left', width: 100, },
    { value: 'title', label: 'Pavadinimas', fixed: 'left', width: 150, },
    // { value: 'unit', label: 'Mat. vnt.' },
    { value: 'quantity', label: 'Kiekis', fixed: 'left', width: 50, },
    { value: 'type', label: t('Tipas'), fixed: 'left', width: 80, },

    // { label: 'Elemento pavadinimas', value: 'fields.elementName' },
    // { label: 'Elemento žymėjimas (TAG)', value: 'fields.elementTag' },
    // { label: 'Funkcinės sistemos tipas', value: 'fields.functionalSystemType' },
    // { label: 'Techninės sistemos tipas', value: 'fields.technicalSystemType' },
    // { label: 'Elemento klasifikavimo tipas', value: 'fields.elementClassifierType' },
    // { label: 'Projekto kodas (IFCProject)', value: 'fields.projectCode' },
    // { label: 'Vieta (IFCSite)', value: 'fields.site' },
    // { label: 'Objekto kodas (IFCBuilding)', value: 'fields.objectCode' },
    // { label: 'Aukštas (IFCFloor)', value: 'fields.floor' },
    // { label: 'Medžiagiškumas', value: 'fields.materials' },
    // { label: 'Plotas (netto)', value: 'fields.areaNetto' },
    // { label: 'Plotas (brutto) ', value: 'fields.areaBrutto' },
    // { label: 'Tūris  (netto)', value: 'fields.volumeNetto' },
    // { label: 'Tūris (brutto)', value: 'fields.volumeBrutto' },
    // { label: 'Svoris (netto)', value: 'fields.weightNetto' },
    // { label: 'Svoris (brutto)', value: 'fields.weightBrutto' },
    // { label: 'Ilgis', value: 'fields.len' },
    // { label: 'Aukštis', value: 'fields.height' },
    // { label: 'Plotis', value: 'fields.width' },
    // { label: 'Perimetras', value: 'fields.perimeter' },
    // { label: 'Storis', value: 'fields.thickness' },
    // { label: 'Sienelės storis ', value: 'fields.sideThickness' },
    // { label: 'Diametras ', value: 'fields.diameter' },
    // { label: 'Funkcinės savybės', value: 'fields.functionalQualities' },
    // { label: 'Šilumos  perdavimo koeficientas', value: 'fields.heatTransferCoef' },
    // { label: 'Varža', value: 'fields.resistance' },
    // { label: 'Gaisrinė sauga (klasė)', value: 'fields.fireSafety' },
    // { label: 'IP klasė', value: 'fields.ipClass' },
    // { label: 'Spalva', value: 'fields.color' },
    // { label: 'Kita', value: 'fields.other' },
  ].map(field => createColumn(field));

  return (
    <Paper style={{ margin: '20px auto 0px', padding: '10px 20px' }}>

      <div style={{ marginBottom: 20 }}>
        <TreeTable
          onCellChange={() => { }}
          expandedRowKeys={expandedRowKeys}
          onExpandedRowChange={(keys) => setExpandedRowKeys(keys)}
          columns={columns}
          data={data}
        />
      </div>
    </Paper>
  )
}

export default ImportPreview;