import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
// import _ from 'lodash';
import {
  withStyles,
  Button,
  Grid,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import { FormField2, CommonPanel } from 'components';
import ServicePackages from 'views/ServicePackages';

import { toast } from 'utils/helpers';
import styles from './styles';

class Container extends React.Component {

  componentDidMount() {
    const { match, store } = this.props;

    if (match && match.params.id) {
      store.getOne(match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  onChange({ name, value }) {
    const { store } = this.props;

    this.props.store.setRecord({
      ...store.record,
      [name]: value,
    });
  }

  submitForm() {
    const { store, t } = this.props;

    if (!store.record || (!store.record.code || !store.record.title)) {
      toast(t('Kodas ir pavadinimas turi būti užpildyti'), 'warning');
      return;
    }

    if (store.record._id) {
      store.update();
    } else {
      store.create();
    }
  }

  renderFormField({ name, ...field }) {
    const { record } = this.props.store;
    
    return (
      <FormField2
        {...field}
        name={name}
        // autoComplete={`project-${name}`}
        key={name}
        value={record ? record[name] : (field.multiple ? [] : '')}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderForm() {
    const { t } = this.props;

    return (
      <Grid container>
        <Grid item md={6}>
          {this.renderFormField({ name: 'code', label: t('Kodas'), type: 'text' })}

          {this.renderFormField({ name: 'title', label: t('Pavadinimas'), type: 'text' })}

          {this.renderFormField({ name: 'description', label: t('Aprašymas'), type: 'text' })}
        </Grid>
      </Grid>
    )
  }
  
  render() {
    const { classes, store, t } = this.props;
    const title = store.record ? `${t('Paketas')}: ${store.record.title || '-'}` : t('Naujas paketas');

    return (
      <ServicePackages
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Paslaugų paketai'), to: '/service-packages' },
          { title }
        ]}
      >
        <div className={classes.wrap}>
          
          <CommonPanel
            footer={(
              <Button
                color="primary"
                variant="contained"
                onClick={this.submitForm.bind(this)}
              >
                {t('Išsaugoti')}
              </Button>
            )}
          >
            {this.renderForm()}
          </CommonPanel>

        </div>
      </ServicePackages>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(Container));