import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';

import { DataTable } from 'components';
import Documents from 'views/Documents';
import { getAll } from 'views/Documents/_actions';

class DocumentList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  onDocumentsSelect(documents) {
    // eslint-disable-next-line no-console
  }

  prepareTableColumns() {
    const columns = [
      'ID',
      'LINK',
      'Pavadinimas',
      'Tipas',
      'Dydis',
      'Sukurta',
      'Sukūrė',
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/documents/${record._id}`);
      row.push(record.title);
      row.push(record.project ? record.project.title : '');
      row.push(record.type);
      row.push(record.size);
      row.push(moment(record.createdAt).utc().format('YYYY-MM-DD'));
      row.push(record.createdBy);
      
      return row;
    });

    return data;
  }

  renderDocuments() {
    const { classes, isLoading, list } = this.props;
    const { documents } = list;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(documents);

    return (
      <DataTable 
        title="Dokumentai"
        columns={columns}
        data={data}
        tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
        // createUrl="/documents/create"
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Documents
        title="Dokumentai"
        breadcrumbs={[
          { title: 'Dokumentai' }
        ]}
      >
        
        <div className={classes.content}>
          {this.renderDocuments()}
        </div>

      </Documents>
    );
  }
}

const mapStateToProps = ({ documents, dispatch }) => {
  return { 
    ...documents,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(DocumentList);