import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Paper,
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Chip,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import {
  SubdirectoryArrowRight as SubdirectoryArrowRightIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { toast } from 'utils/helpers';


import { FormField2 as Field, ClassifierPicker, AutoComplete } from 'components';
import { ImportContext } from './ImportManager';
import { TreeTable } from 'components/TreeTable';

const StructureManager = () => {
  const {
    loading,
    record: contextRecord,
    config: contextConfig,
    importActions,
  } = useContext(ImportContext);
  const { t } = useTranslation();
  const [levels, setLevels] = useState(contextRecord.structure || []);
  const DEBUG = false;
  // console.log({ contextConfig, contextRecord })

  useEffect(() => {
    importActions.setStructureResult(levels);
  }, [levels]);

  const createIndentor = (level) => {
    if (!level || level === 1) return null;

    return (
      <SubdirectoryArrowRightIcon
        style={{
          marginLeft: level * 4,
          verticalAlign: 'text-bottom',
        }}
      />
    )
  };

  const defaultColumns = [
    {
      label: 'Lygis',
      value: 'level',
      width: 60,
      fixed: 'left',
      render: (level) => <>{createIndentor(level)}{`${level} ${t('lygis')}`}</>,
    },
    {
      label: 'Lygio kodas',
      value: 'code',
      width: 120,
      render: (text, record, index) => (
        <div>
          {DEBUG && <pre>{JSON.stringify(record.codeParts)}</pre>}
          <AutoComplete

            multiple
            name='code'
            label={null}
            options={contextRecord.mapping.filter(m => !!m.selected).map(m => ({ value: m.key, title: `${m.field} [${importActions.getClassifierFullname(m.value)}]` }))}
            freeSolo={true}
            creatable={true}
            onCreate={() => { }}
            createText={t('Įterpti')}
            inputParams={{ size: 'small' }}
            value={[...record.codeParts.map(p => ({ title: p.title, value: p.value }))]}
            // style={{ height: '32px', marginTop: '8px' }}]
            onChange={(values) => {
              console.log({ values })
              if (!values) return;

              setLevels(levels.map((l, i) => i === index ? {
                ...l,
                codeParts: values.map(v => ({
                  title: v?.inputValue || v?.title,
                  isCustom: !!v?.inputValue,
                  value: v?.value || v?.inputValue,
                })),
              } : l))
            }}
          />
        </div>
      ),
    },
    {
      label: 'Lygio pavadinimas',
      value: 'title',
      width: 120,
      // fixed: 'left',
      render: (text, record, index) => (
        <div >
          {DEBUG && <pre>{JSON.stringify(record.titleParts)}</pre>}
          <AutoComplete

            multiple
            name='title'
            label={null}
            options={contextRecord.mapping.filter(m => !!m.selected).map(m => ({ value: m.key, title: `${m.field} [${importActions.getClassifierFullname(m.value)}]` }))}
            freeSolo={true}
            creatable={true}
            onCreate={() => { }}
            createText={t('Įterpti')}
            inputParams={{ size: 'small' }}
            value={[...record.titleParts.map(p => ({ title: p.title, value: p.value }))]}
            // style={{ height: '32px', marginTop: '8px' }}]
            onChange={(values) => {
              console.log({ values })
              if (!values) return;

              setLevels(levels.map((l, i) => i === index ? {
                ...l,
                titleParts: values.map(v => ({
                  title: v?.inputValue || v?.title,
                  isCustom: !!v?.inputValue,
                  value: v?.value || v?.inputValue,
                })),
              } : l))
            }}
          />
        </div>
      ),
    },
    {
      label: 'Struktūros tipas',
      value: 'structureType',
      width: 120,
      // fixed: 'left',
      render: (text, record, idx) => (
        <>
          {DEBUG && <pre>{record.structureType}</pre>}
          <Select
            style={{ width: '100%' }}
            variant='outlined'
            size='small'
            name='structureType'
            value={record.structureType}
            onChange={(e) => {
              setLevels(levels.map((l, i) => i === idx ? {
                ...l,
                structureType: e.target.value,
              } : l))
            }}
          >
            {contextConfig.estimateLevels.map((field) => (
              <MenuItem key={field.value} value={field.value}>{field.label}</MenuItem>
            ))}
          </Select>
        </>
      )
    },
    {
      label: null,
      value: 'action',
      width: 20,
      fixed: 'right',
      render: (text, record, idx) => (
        <div style={{ textAlign: 'center' }}>
          <IconButton
            variant='outlined'
            style={{ color: 'red', borderColor: 'red' }}
            size='small'
            onClick={() => {
              console.log({ deleting: idx })
              const newLevels = [...levels];
              console.log({ oldLevels: [...levels] })
              newLevels.splice(idx, 1);
              // newLevels.forEach((l, i) => l.idx = l.idx + 1);
              console.log({ newLevels })
              setLevels(newLevels);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      )
    },
  ];

  const columns = defaultColumns
    .map(field => ({
      title: field.label,
      dataIndex: field.value,
      key: field.value,
      width: field.width,
      ellipsis: field.ellipsis,
      render: field.render,
      // render: (text) => <span title={text}>{text}</span>,
      // editable: true,
      fixed: field.fixed,
    }))

  const onAddLevel = () => {
    const newLevels = [...levels, {
      id: _.uniqueId('level_'),
      level: levels.length + 1,
      code: '',
      codeType: 'text',
      codeParts: [],
      title: '',
      titleType: 'text',
      titleParts: [],
      structureType: 'group',
    }];
    console.log({ newLevels })
    setLevels([...newLevels]);
  };

  return (
    <div>

      <Paper style={{ margin: '20px auto 0px', padding: '10px 20px' }}>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginBottom: 10 }}
          onClick={onAddLevel}
        >
          Pridėti lygį
        </Button>
        <TreeTable
          onCellChange={() => { }}
          columns={columns}
          data={levels}
        />

        {DEBUG && (
          <pre style={{ fontSize: 10 }}>
            {JSON.stringify(levels, null, 2)}
          </pre>
        )}

      </Paper>
    </div >
  )
}

export default StructureManager;