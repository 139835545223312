import documentConstants from './_constants';

const initialState = {
  // Shared props
  isLoading: false,
  error: null,

  //  List of items
  list: {
    limit: 10,
    documents: [],
    selectedDocuments: [],
  },

  // Currently viewed item
  single: {
    document: null,
  }
};

export const documents = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.UPDATE_REQUEST:
    case documentConstants.CREATE_REQUEST:
    case documentConstants.GETONE_REQUEST:
    case documentConstants.GETALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case documentConstants.GETALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: {
          ...state.list,
          documents: action.documents,
        }
      };
    case documentConstants.CREATE_FAILURE:
    case documentConstants.GETONE_FAILURE:
    case documentConstants.GETALL_FAILURE:
    case documentConstants.UPDATE_FAILURE:
      return { 
        ...state,
        isLoading: false,
        error: action.error,
      };
    case documentConstants.GETONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        single: {
          ...state.single,
          document: action.document,
        }
      }
    case documentConstants.CREATE_SUCCESS:
    case documentConstants.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case documentConstants.RESET_FORM:
      return {
        ...state,
        isLoading: false,
        single: {
          ...initialState.single,
        }
      }
    default:
      return state
  }
}