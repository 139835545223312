import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import styles from './styles';

const Loader = ({ classes }) => {
  return null;
}

export default withStyles(styles)(Loader);