import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
  Typography,
  Grid,
  Link,
  Tabs,
  Tab,
} from '@material-ui/core';
import {
  Description as DescriptionIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { withRouter } from 'react-router-dom';

import Norms from 'views/Norms';
import { CommonPanel, NormSearch, NormVersionPicker, TrialHandler, TabPanel } from 'components';

import { EDU_MODE } from 'utils/config';
import { twoOrMoreDecimals, getQueryVariable, getCurrentAccount, blobToFileDownload, toast } from 'utils/helpers';
import styles from './styles';

class NormTreeTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paddingTop: 86,
      isTrial: false,
      showTrialMessage: false,
      serviceCode: null,
      tabValue: [],
    };

    this.elRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // Using type to autoexpand
    const type = _.get(this.props.match, 'params.type');
    this.props.store.setTypeToExpand(type);

    const state = this.state;
    state.serviceCode = _.camelCase(`norms ${type}`);
    console.log({ state })
    
    if (getQueryVariable('trial')) {
      state.isTrial = true;
      this.setState({ state });
      return;
    }

    if (_.includes(getCurrentAccount().trialServices, state.serviceCode)) {
      state.showTrialMessage = true;
    }

    this.setState({ ...state });

    window.addEventListener('scroll', this.handleScroll, false);
  }
  
  async loadData() {
    await this.props.store.loadData();

    // Set padding for mobile view
    try {
      const paddingTop = window.document.querySelector('.sticky-table-header').offsetHeight;
      this.setState({ paddingTop });
    } catch (err) {
      console.log(err)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
    
    this.props.store.reset();
  }

  handleScroll = _.debounce(() => {
    const node = this.elRef.current;

    if (node !== undefined) {
      const header = node.querySelector('.sticky-table-header');
      const fromViewTop = node.getBoundingClientRect().top;
      const topbarHeight = document.querySelector('.top-navbar').offsetHeight;

      node.style.paddingTop = `${header.offsetHeight}px`;

      if (fromViewTop < 0) {
        header.style.top = Math.abs(fromViewTop - topbarHeight) + 'px';
      } else {
        header.style.top = '0px';
      }
    }
  })

  onTreeRowClick(item) {
    this.props.store.handleExpand(item);
  }

  onParameterRowClick(item) {
    this.props.store.handleParameterExpand(item);
  }

  onSearchResultSelected({ codePath }) {
    
    this.props.store.expandPath(codePath.split(';'));
  }

  renderExpandArrow(code, value) {
    const { tabValue } = this.state;

    if (tabValue[code] == value) {
      return <ArrowDropUpIcon style={{ marginTop: 4 }}/>;
    } else {
      return <ArrowDropDownIcon style={{ marginTop: 4 }}/>;
    }
  }

  // renderDetailsRow({ details, level }) {
  renderDetailsRow({ code, details }) {
    const { classes, t } = this.props;
    const { tabValue } = this.state;

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    const handleTabChange = (event, newValue, code) => {
      const tabs = this.state.tabValue;
      if (this.state.tabValue[code] == newValue) {
        tabs[code] = null;
      } else {
        tabs[code] = newValue;
      }
      this.setState({ tabValue: tabs });
    };
    
    const contents = (
      <React.Fragment>
        <div style={{ marginBottom: 10, textAlign: 'center' }}>
          <Typography variant="h6">
            {details.title}
          </Typography>
        </div>

        
        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title={t('Resursai')}
            collapsible
            collapsed={details.resources.length === 0}
            disabled={details.resources.length === 0}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.resources.length ? (
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>{t('Kodas')}</th>
                    <th>{t('Pavadinimas')}</th>
                    <th>{t('Matas')}</th>
                    <th>{t('Norma')}</th>
                    <th>{t('Kaina')}</th>
                    <th>{t('Suma')}</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(details.resources, resource => (
                    <tr key={`${resource.code}-resource`}>
                      <td>{resource.code}</td>
                      <td>{resource.title}</td>
                      <td>{resource.unit}</td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(resource.norm)}</td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(resource.price)}</td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(resource.amount)}</td>
                    </tr>
                  ))}

                  {details.parameters.length ? (
                    <React.Fragment>
                      <tr>
                        <td colSpan={6} className={classes.parametersHeaderRow}>
                          {t('Pokyčio parametrai')}
                        </td>
                      </tr>

                      {_.map(details.parameters, parameter => (
                        <React.Fragment key={`${parameter.code}-parameter`}>
                          <tr onClick={() => this.onParameterRowClick(parameter)}>
                            <td className={classes.parameterCode}>{parameter.code}</td>
                            <td colSpan={5}>{parameter.title}</td>
                          </tr>
                          {parameter.resources && parameter.expanded ? (
                            _.map(parameter.resources, resource => (
                              <tr key={`${parameter.code}-${resource.code}-resource`}>
                                <td className={classes.textCenter}>{resource.code}</td>
                                <td>{resource.title}</td>
                                <td>{resource.unit}</td>
                                <td className={classes.textRight}>{twoOrMoreDecimals(resource.norm)}</td>
                                <td className={classes.textRight}>{twoOrMoreDecimals(resource.price)}</td>
                                <td className={classes.textRight}>{twoOrMoreDecimals(resource.amount)}</td>
                              </tr>
                            ))
                          ) : null}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : null}
                </tbody>
              </table>
            ) : null}
          </CommonPanel>
        </div>
        
        <div>
          <Tabs value={tabValue[details.title]} onChange={(event, newValue) => handleTabChange(event, newValue, details.title)} variant="fullWidth" style={{ minHeight: 32, maxHeight: 38 }}>
            <Tab 
              {...a11yProps(0)}
              label={t('Kainos sudėtis')}
              style={{ marginRight: 10, backgroundColor: 'white', minHeight: 32, maxHeight: 38, opacity: 1 }}
              icon={this.renderExpandArrow(details.title, 0)}
              classes={{
                wrapper: classes.iconLabelWrapper,
                labelContainer: classes.labelContainer
              }}
            >
            </Tab>
            <Tab 
              {...a11yProps(1)}
              label={t('Darbų sudėtis')}
              style={{ marginRight: 10, backgroundColor: 'white', minHeight: 32, maxHeight: 38, opacity: 1 }}
              icon={this.renderExpandArrow(details.title, 1)}
              classes={{
                wrapper: classes.iconLabelWrapper,
                labelContainer: classes.labelContainer
              }}
            />
            <Tab 
              {...a11yProps(2)}
              label={t('Koeficientai')}
              style={{ backgroundColor: 'white', minHeight: 32, maxHeight: 38, opacity: 1 }}
              icon={this.renderExpandArrow(details.title, 2)}
              classes={{
                wrapper: classes.iconLabelWrapper,
                labelContainer: classes.labelContainer
              }}
            />
          </Tabs>
          <TabPanel value={tabValue[details.title]} index={0} disabled={details.prices.length === 0}>
            {details.prices.length ? (
              <table className={this.props.classes.table}>
                <thead>
                  <tr>
                    <th>{t('Kainos sudėties aprašymas')}</th>
                    <th>{t('Kaina')}</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(details.prices, price => (
                    <tr
                      key={`${price.title}-price`}
                      className={_.some([
                        'statybos kaina',
                        'tiesioginės išlaidos',
                        'netiesioginės išlaidos',
                        'darbo vieneto kaina',
                      ], t => price.title.toLowerCase().includes(t)) ? classes.rowPriceTotal : null}
                    >
                      <td>{price.title}</td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(price.price)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </TabPanel>
          <TabPanel value={tabValue[details.title]} index={1} padding={1} disabled={!details.details} style={{ backgroundColor: 'white' }}>
            {details.details}
          </TabPanel>
          <TabPanel value={tabValue[details.title]} disabled={!details.coefficients} index={2}>
            {details.coefficients && details.coefficients.length ? (
              <>
                <Typography variant="caption" display="block" style={{ textAlign: 'right'}}>
                  K1 - {t('darbo sąnaudos')}, 
                  K2 - {t('mechanizmai')}, 
                  K3 - {t('medžiagos')}
                </Typography>

                <table className={this.props.classes.table}>
                  <thead>
                    <tr>
                      <th>{t('Koeficientų taikymo sąlygos')}</th>
                      <th>K1</th>
                      <th>K2</th>
                      <th>K3</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(details.coefficients, (coefficient, coefIdx) => (
                      <tr key={`${coefIdx}-coefficient`}>
                        <td>{coefficient.title}</td>
                        <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k1)}</td>
                        <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k2)}</td>
                        <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k3)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : null}
          </TabPanel>
        </div>

      </React.Fragment>
    );

    return (
      <tr
        key={`${code}-details`}
        className={[this.props.classes.detailsRow, 'tree-details'].join(' ')}
      >
        <td />
        <td colSpan={3}>
          {contents}
        </td>
      </tr>
    )
  }

  handleCollectionDownloadClick = async (e, code, version) => {
    const { t } = this.props;

    try {
      e.stopPropagation();
      const file = await this.props.store.downloadCollection(code, version);

      var bytes = new Uint8Array(file.content);
      const blob = new Blob([bytes], {type: file.contentType});
      const fileName = `${code} - ${file.fileName}`;
      blobToFileDownload(fileName, blob);
    } catch {
      toast(t('Failas nerastas'), 'error');
    }
  }

  renderFirstColumn({ code, lastLevel, expanded }) {
    const { classes } = this.props;
    return (
      <td className={classes.firstColumn} style={{ width: '20%' }}>
        {!lastLevel && <span className={classes.expander}>{expanded ? '-' : '+'}</span>}
        {code}
      </td>
    )
  }

  renderTableRow(item, isLast) {
    const { t } = this.props;
    let rows = [];

    const mainRow = (
      <tr
        key={item.code}
        onClick={() => this.onTreeRowClick(item)}
        className={[
          item.expanded ? [this.props.classes.expandedRow, 'tree-expanded'].join(' ') : '', 
          `code-${item.code}`.replace('#', ''), 
          `tree-node level-${item.level} ${isLast ? 'level-last' : ''}`,
        ].join(' ')}
      >
        {this.renderFirstColumn(item)}
        <td style={{ display: 'inlineBlock' }}>
          {item.title}
          {item.level === 1 && item.code[0] !== 'F' ? 
            <Link style={{ float: 'right' }} title={t('Techninė dalis')}>
              <DescriptionIcon onClick={e => this.handleCollectionDownloadClick(e, item.code, this.props.store.version.code)} fontSize='small' style={{ verticalAlign: 'top'}}/>
            </Link>
            : null
          }
        </td>
        <td style={{ width: '10%', textAlign: 'right' }}>{item.price ? twoOrMoreDecimals(item.price) : null}</td>
        <td style={{ width: '10%', textAlign: 'center' }}>{item.unit}</td>
      </tr>
    )
    rows.push(mainRow);

    if (item.expanded && !item.details) {
      const childRows = _.map(item.children, (child, idx) => this.renderTableRow(child, (idx + 1) === item.children.length && !child.expanded))
      rows = _.concat(rows, childRows);
    } else if (item.expanded && item.details) {
      const detailsRow = this.renderDetailsRow(item);
      rows.push(detailsRow);
    }

    return rows;
  }

  renderData() {
    const { data } = this.props.store;

    const rows = _.map(data, item => this.renderTableRow(item));
    // const rows = _.map(_.keys(data), key => this.renderTableRow(data[key]));

    return rows;
  }

  renderSearch() {
    const { version, type } = this.props.store;
    return (
      <NormSearch
        onSelect={this.onSearchResultSelected.bind(this)}
        inputStyle={{ marginTop: 5 }}
        version={version ? version.code : null}
        type={type}
      />
    );
  }

  renderVersions() {
    const { store, t } = this.props;

    return (
      <NormVersionPicker
        value={store.version?.code}
        name="version"
        onChange={({ value }) => store.setVersion(value)}
        label={t('Versija')}
        style={{ marginTop: 5, background: '#fff' }}
        type={store.type}
        variant={this.state.serviceCode}
      />
    );
  }

  renderService() {
    const { classes, store, t } = this.props;

    return (
      <div className={classes.wrap}>

        <div ref={this.elRef} className="sticky-table-wrap" style={{ paddingTop: this.state.paddingTop }}>
          
          <div className="sticky-table-header">
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    {this.renderSearch()}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {this.renderVersions()}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.stickyTableWrap}>
                <table className={classes.table} style={{ tableLayout: 'fixed' }}>
                  <thead>
                    <tr style={{ height: 46 }}>
                      <th style={{ width: '20%' }}>{t('Darbo kodas')}</th>
                      <th>{t('Pavadinimas')}</th>
                      <th style={{ width: '10%' }}>{t('Darbo vnt. kaina')}</th>
                      <th style={{ width: '10%' }}>{t('Matas')}</th>
                    </tr>
                  </thead>
                </table>
              </Grid>
            </Grid>
          </div>

          <div className={classes.tableWrap}>
            <table className={[classes.table, 'norms-tree'].join(' ')} style={{ tableLayout: 'fixed' }}>
              <tbody>
                {this.renderData()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  renderTrial() {
    if (!this.state.serviceCode) return null;

    return (
      <TrialHandler
        service={this.state.serviceCode}
      />
    )
  }

  renderTrialMessage() {
    const { t } = this.props;
    return EDU_MODE ? null : (
      <div style={{ margin: 10 }}>
        <Alert 
          variant="filled"
          severity="info"
          // onClose={() => this.setState({ showTrialMessage: false })}
        >
          {t('Jūs naudojate bandomąją (laikinai nemokamą) paslaugos versiją.')}
        </Alert>
      </div>
    )
  }

  render() {
    const { store, t } = this.props;
    const { isTrial, showTrialMessage } = this.state;

    return (
      <Norms
        title={store.getLayoutTitle()}
        breadcrumbs={[
          { title: t('Žinių bazė') },
          { title: store.getLayoutTitle() },
        ]}
        beforeMainContent={!isTrial && showTrialMessage && this.renderTrialMessage()}
      >
        {!this.state.isTrial ? this.renderService() : this.renderTrial()}
      </Norms>
    )
  }
}

export default compose(withStyles(styles), withRouter, withTranslation())(observer(NormTreeTableContainer));