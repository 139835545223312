import api from 'utils/api';
import _ from 'lodash';
import { getSettings } from './settings';

const servicePath = '/services/norms';

export const getPriceSettings = async (params) => {
  try {
    const response = await api.post(`${servicePath}/price-settings`, params);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getPackages = async (params) => {
  try {
    const response = await api.post(`${servicePath}/packages`, params);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getVersions = async (params) => {
  try {
    const response = await api.post(`${servicePath}/versions`, params);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getTree = async (params) => {
  try {
    const response = await api.post(`${servicePath}/tree`, params);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getParameters = async (params) => {
  try {
    const response = await api.post(`${servicePath}/parameters`, params);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getDetails = async (params) => {
  try {
    const response = await api.post(`${servicePath}/details`, params);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const doSearch = async (params) => {
  try {
    const response = await api.post(`${servicePath}/search`, params);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const doCalculate = async (params, version) => {
  try {
    const response = await api.post(`${servicePath}/calculate?version=${version}`, params);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getExcelExport = async (params) => {
  try {
    const response = await api.post(`${servicePath}/export-excel`, params);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getPackageCodesByType = async (type) => {
  try {
    const settings = await getSettings();
    
    let codes = [];

    if (type === 'pbw') {
      const code = _.get(settings, 'norms.pricelists.pbwRootCode');
      if (code) codes.push(code);
    }
    
    if (type === 'prw') {
      const code = _.get(settings, 'norms.pricelists.prwRootCode');
      if (code) codes.push(code);
    }

    return Promise.resolve(codes);
  } catch (err) {
    console.log(err);
  }
}