export default () => ({
  root: {},
  wrap: {
    padding: 0,
    maxWidth: '1200px',
    position: 'relative',
  },
  table: {

    '& td, th': {
      border: '1px solid #DFE3E8',

      '&.actions': {
        border: '1px solid #ffffff',
      }
    }
  },
  accountHeaderRow: {
    backgroundColor: '#DFE3E8',
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: 'bold',
  },
  descriptionTable: {
    
    '& td, th': {
      border: '0px',
    }
  },
  descriptionLabel: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  descriptionValue: {
    textAlign: 'right',
  },
  link: {
    fontWeight: 'bold',
    color: '#0767DB',
  },
});

