import React, { Component } from 'react';
import { ValueField } from 'components';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Checkbox,
  TableFooter,
  IconButton,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import {
  CommonPanel,
} from 'components';

import styles from './styles';

class CoefficientTable extends Component {

  state = {
    customTitle: '',
    customPercent: '',
  }

  onChange = (name, { field, value }) => {
    const { coefficients, handleChange } = this.props;

    let target = _.find(coefficients, { title: name });

    if (target) {
      // Update existing
      target[field] = value;
    } else {
      // Insert new
      coefficients.push({
        enabled: true,
        normPercent: value,
        percent: value,
        title: name,
        custom: true,
      });
    }

    handleChange({
      name: 'coefficients',
      value: [...coefficients],
    });
  };

  onAddCustomClick = () => {
    const { customTitle, customPercent } = this.state;
    if (!customTitle || !customPercent) {
      return;
    }

    this.onChange(customTitle, { name: customTitle, value: customPercent });

    // Reset inputs
    this.setState({
      customTitle: '',
      customPercent: '',
    });
  }

  removeCustom = (coef) => {
    const { coefficients, handleChange } = this.props;

    _.remove(coefficients, { ...coef });

    handleChange({
      name: 'coefficients',
      value: [...coefficients],
    });
  }

  renderCoefTable() {
    const { classes, coefficients, t } = this.props;
    const { customTitle, customPercent } = this.state;

    return (
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.coefHeaderCell} colSpan={2}>{t('Pavadinimas')}</TableCell>
            
            <TableCell className={classes.coefHeaderCell} colSpan={2}>{t('Koeficientas')}</TableCell>
          </TableRow>
        </TableHead>
        {coefficients.length ? (
          <TableBody>

            {_.map(coefficients, (coef) => (
              <TableRow key={`${coef.title}`}>
                <TableCell>
                  <Checkbox
                    checked={coef.enabled}
                    color="primary"
                    onChange={(e) => this.onChange(coef.title, { field: 'enabled', value: e.target.checked })}
                  />
                </TableCell>
                <TableCell>{coef.title}</TableCell>
                
                <TableCell component="td" scope="row">
                  
                  <ValueField
                    style={{ width: '90px' }}
                    name="percent"
                    value={coef.percent}
                    defaultValue={coef.normPercent}
                    onBlur={({ value }) => this.onChange(coef.title, { field: 'percent', value: value })}
                  />

                </TableCell>
                <TableCell>
                  {coef.custom ? (
                    <IconButton onClick={() => this.removeCustom(coef)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        ) : null}
        <TableFooter>

          <TableRow>
            <TableCell className={classes.customCell} colSpan={2}>
              <TextField
                className={classes.customTextField}
                label={t('Pavadinimas')}
                value={customTitle}
                onChange={(e) => this.setState({ customTitle: e.target.value })}
                margin="dense"
                variant="outlined"
              />
            </TableCell>
            <TableCell className={classes.customCell}>
              <TextField
                className={classes.textField}
                type="number"
                label={t('Koef.')}
                value={customPercent}
                onChange={(e) => this.setState({ customPercent: e.target.value })}
                margin="dense"
                variant="outlined"
              />
            </TableCell>
            <TableCell>
              <IconButton onClick={this.onAddCustomClick.bind(this)}>
                <AddIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>

        </TableFooter>
      </Table>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <CommonPanel
        title={t('Statinio ypatumų koeficientai')}
        noPadding
      >

        <div style={{ overflowX: 'auto' }}>
          {this.renderCoefTable()}
        </div>

      </CommonPanel>
    );
  }
}

export default compose(withStyles(styles), withTranslation())(CoefficientTable);
