import React, { Component } from 'react';
import Container from './Container';
import store from './_store';

class ServicePackageList extends Component {

  render() {
    return <Container store={store} />
  }
}

export default ServicePackageList;