import NtkViewer from 'views/NtkViewer';

export default [
  {
    key: 'show',
    component: NtkViewer,
    path: '/ntk',
    public: false,
    canReloadOnLinkClick: true,
  },
];