export default theme => ({
  root: {},
  field: {
    margin: theme.spacing(1)
  },
  textField: {
    // width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  resetButton: {
    marginTop: '15px',
  }
});
