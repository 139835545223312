import React, { Component } from 'react';
import _ from 'lodash';
import shortid from 'shortid';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  Button,
  TextField,
  IconButton,
  Grid,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  DragHandle as DragHandleIcon,
} from '@material-ui/icons';

import { confirmDialog } from 'utils/helpers';
import { getClassifiers, getTemplates, resetForm } from './_actions';

import styles from './styles';

// Inject reducers
import { store } from 'utils/store';
import { templateBuilder } from './_reducers';
store.injectReducer('templateBuilder', templateBuilder);

class TemplateBuilder extends Component {

  state = {
    templateToClone: '',
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getClassifiers());
    dispatch(getTemplates());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    this.setState({ templateToClone: '' });

    dispatch(resetForm());
  }

  handleCodeChange({ target }) {
    const { value, name } = target;
    this.updateOneRecordValue(name, 'code', value);
  }

  handleTitleChange({ target }) {
    const { value, name } = target;
    this.updateOneRecordValue(name, 'title', value);
  }

  handleTypeChange({ target }) {
    const { value, name } = target;
    this.updateOneRecordValue(name, 'type', value);
  }

  handleSpecialChange({ target }) {
    const { value, name } = target;
    this.updateOneRecordValue(name, 'special', value);
  }

  updateOneRecordValue(key, field, value) {
    const { records } = this.props;

    const record = _.find(records, { key });
    record[field] = value;

    this.handleRecordsChange(records);
  }

  cloneTemplate() {
    const { templates, t } = this.props;
    const { templateToClone } = this.state;

    if (!templateToClone) {
      return;
    }

    try {
      const template = _.find(templates, { _id: templateToClone }).template;

      if (confirmDialog(t('Ar tikrai norite užkrauti pasirinktą šabloną?'))) {
        this.handleRecordsChange(template);
      }
    } catch (err) {
      console.log(err);
    }
  }

  addRow() {
    const { records } = this.props;

    records.push({
      key: shortid.generate(),
      code: '',
      title: '',
      type: '',
      special: null,
    });
    
    this.handleRecordsChange(records);
  }

  deleteRow(key) {
    const { records } = this.props;

    _.remove(records, { key });
    this.handleRecordsChange(records);
  }

  handleRecordsChange(records) {
    const { onChange } = this.props;

    if (onChange) {
      onChange([...records]);
    }
  }

  renderRow(record) {
    const { classifiers, types, classes } = this.props;
    const { key, code, title, type, special = '' } = record;

    const classifierOptions = !classifiers.length ? null : 
      _.map(classifiers, ({ _id, code, title }) => (
        <MenuItem key={_id} value={code}>{code} - {title}</MenuItem>
      ));

    return (
      <TableRow key={key}>
        {/* Code */}
        <TableCell>
          <TextField
            className={classes.textField}
            value={code || ''}
            onChange={this.handleCodeChange.bind(this)}
            name={key}
            variant="outlined"
            fullWidth
            select
          >
            {classifierOptions}
          </TextField>
        </TableCell>
        {/* Title */}
        <TableCell>
          <TextField
            className={classes.textField}
            name={key}
            variant="outlined"
            value={title || ''}
            onChange={this.handleTitleChange.bind(this)}
            multiline
          />
        </TableCell>
        {/* Type */}
        <TableCell>
          <TextField
            className={classes.textField}
            value={type || ''}
            onChange={this.handleTypeChange.bind(this)}
            name={key}
            variant="outlined"
            fullWidth
            select
          >
            {types.length ? _.map(types, ({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            )) : null}
          </TextField>

          {/* Special: classifier list */}
          {type == 'classifierList' ? (
            <TextField
              className={[classes.textField, classes.special].join(' ')}
              value={special || ''}
              onChange={this.handleSpecialChange.bind(this)}
              name={key}
              variant="outlined"
              fullWidth
              select
            >
              {classifierOptions}
            </TextField>
          ) : null}
        </TableCell>
        {/* Actions */}
        <TableCell>
          <IconButton >
            <DragHandleIcon />
          </IconButton>
          <IconButton onClick={() => this.deleteRow(key)}>
            <DeleteIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const { records, templates, classes, t } = this.props;

    return (
      <React.Fragment>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '40%' }}>{t('Kodas')}</TableCell>
              <TableCell style={{ width: '20%' }}>{t('Pavadinimas')}</TableCell>
              <TableCell style={{ width: '25%' }}>{t('Tipas')}</TableCell>
              <TableCell style={{ width: '15%' }}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Record rows */}
            {_.map(records, record => this.renderRow(record))}
            
            {/* Button: Add new row */}
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => this.addRow()}
                >
                  {t('Pridėti')}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {templates.length ? (
          <Grid container spacing={2} className={classes.cloneWrap}>
            <Grid item sm={4}>
              <TextField
                label={t('Esamo šablono kopijavimas')}
                className={classes.textField}
                onChange={e => this.setState({ templateToClone: e.target.value })}
                value={this.state.templateToClone}
                name="clone"
                variant="outlined"
                fullWidth
                select
              >
                {_.map(templates, ({ _id, title }) => (
                  <MenuItem key={_id} value={_id}>{title}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <Button
                className={classes.btnClone}
                color="primary"
                variant="contained"
                onClick={() => this.cloneTemplate()}
              >
                {t('Kopijuoti šabloną')}
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ templateBuilder, dispatch }) => {
  return { 
    ...templateBuilder,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(TemplateBuilder);