import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import moment from 'moment';

import { DataTable } from 'components';
import Services from 'views/Services';


import styles from './styles';

class Container extends React.Component {

  componentDidMount() {
    this.props.store.getAll();
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  prepareTableColumns() {
    const { t } = this.props;
    const columns = [
      'ID',
      'LINK',
      t('Kodas'),
      t('Pavadinimas'),
      t('Būsena'),
      t('Eilė'),
      t('Sukurtas'),
    ];

    return columns;
  }

  prepareTableData(records) {
    const { t } = this.props;
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/services/${record._id}`);
      row.push(record.code || t('Nenurodytas'));
      row.push(record.title);
      row.push(record.isVisible ? t('Aktyvi') : t('Neaktyvi'));
      row.push(record.order);
      row.push(record.createdAt ? moment(record.createdAt).utc().format('YYYY-MM-DD') : null);
      
      return row;
    });

    return data;
  }

  render() {
    const { classes, store, t } = this.props;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(store.records);

    return (
      <Services
        title={t('Paslaugos')}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Paslaugos') }
        ]}
      >
        <div className={classes.wrap}>
          <DataTable 
            key={data.length}
            columns={columns}
            data={data}
            tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
            createUrl="/services/create"
            // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
            // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
          />
        </div>
      </Services>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(Container));