import {
  SENDING_REQUEST,
  CREATE_SUCCESS,
  FAILURE,
  UPDATE_SUCCESS,
  RESET_FORM,
  SET_VALUE,
} from './_constants';

const initialState = {
  isLoading: false,
  record: null,
  template: null,
};

export const templatesForm = (state = initialState, action) => {
  switch (action.type) {
    
    case RESET_FORM:
      return {
        ...initialState,
      };

    case SET_VALUE:
      return {
        ...state,
        [action.data.name]: action.data.value,
      }

    case SENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        record: action.record,
      };

    case FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state;
  }
}