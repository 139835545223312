export default theme => ({
  root: {},
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '50px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  boldCell: {
    fontWeight: 'bold',
  },
  coefHeaderCell: {
    width: '120px',
  },
  textRight: {
    textAlign: 'right',
  },
  table: {
    '& td div[class*="valuefieldroot"]': {
      borderRadius: '0px',
    },

    '& td div[class*="valuefieldinput"] input': {
      paddingTop: '2px',
      paddingBottom: '2px',
    },

    '& .MuiTableCell-sizeSmall': {
      padding: '2px 3px',
    },

    '& .MuiTableCell-sizeSmall:not(:first-child)': {
      borderLeft: '1px solid #DFE3E8',
    }
  },
  cellWithInput: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  }
});
