import 'rc-tree-select/assets/index.css';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import TreeSelect from 'rc-tree-select';
import _ from 'lodash';
import {
  InputLabel,
} from '@material-ui/core';

import { toast } from 'utils/helpers';

class BuildingTypePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tsOpen: false,
      visible: false,
      searchValue: '',
      expandedKeys: [],
      isLoadedValue: false,
      treeData: undefined,
    };

    this.codeDict = {};
  }

  componentWillReceiveProps(props) {
    const { hierarchy, buildingType } = props;

    const treeData = this.prepareTreeData(hierarchy);
    if (buildingType && buildingType.code && _.keys(this.codeDict).length) {
      const expandedKeys = this.codeDict[buildingType.code] || undefined;
      this.setState({ treeData, expandedKeys });
    } else {
      this.setState({ treeData });
    }
  }

  prepareTreeData(hierarchy) {

    const adaptHierarchyForTree = (items, parentCodes = []) => {
      return _.map(items, (item) => {
        item.key = item.code;
        item.value = item.code;
        // item.disabled = item.children.length > 0;
        item.disabled = false;
        
        // Collect title codes to have useful path down the hierarchy
        item.parentCodes = item.parentCode ? [...parentCodes, item.parentCode] : [];
        this.codeDict[item.code] = item.parentCodes;

        let title = item.title;

        if (!item.allow && item.children.length == 0) {
          // Disabled item in demo mode
          title = <span style={{ color: '#ccc' }}>{item.title}</span>;
        }

        item.title = title;

        if (item.children.length > 0) {
          item.children = adaptHierarchyForTree(item.children, item.parentCodes);
        }
        
        return _.clone(item);
      })
    };

    const tree = adaptHierarchyForTree(hierarchy);
    return tree;
  }

  onSearch = (value) => {
    this.setState({ 
      searchValue: value,
      expandedKeys: !value ? this.state.expandedKeys : undefined
    });
  };

  onChange = (code, titles, { triggerNode }) => {
    const { handleChange, name, t } = this.props;
    if (code && triggerNode.props.children.length > 0) {
      return;
    }

    if (code && !triggerNode.props.allow) {
      toast(t('Priega prie įrašo DEMO aplinkoje negalima', 'warning'));
      return;
    }

    if (!code && !triggerNode) {
      this.setState({ value: code });
      handleChange({
        name,
        value: null,
      });
      return;
    }

    const title = _.first(titles);
    const titleFull = triggerNode.props.titleFull;
    const unit = triggerNode.props.unit;
    
    this.setState({ value: code });

    const value = code ? { title, titleFull, code, unit } : null; 

    handleChange({
      name,
      value,
    });
  };

  onSelect = (_code, node) => {    
    // use onChange instead
    if (node.props.children.length) {
      let { expandedKeys = [] } = this.state;

      if (node.props.expanded) {
        expandedKeys = expandedKeys.filter(k => k !== node.props.eventKey);
      } else {
        expandedKeys = expandedKeys.concat(node.props.eventKey);
      }

      this.setState({
        tsOpen: true,
        expandedKeys,
      });
      return false;
    }

    return false;
  };

  // onDropdownVisibleChange = (visible, info) => {
  onDropdownVisibleChange = () => {
    const { value } = this.state;
    
    if (Array.isArray(value) && value.length > 1 && value.length < 3) {
      // TODO why is this here?
      window.alert('please select more than two item or less than one item.');
      return false;
    }
    return true;
  };

  onExpand = (expandedKeys) => {
    this.setState({ expandedKeys });
  };

  /**
   * Does the actual filtering by search keyword.
   * 
   */
  filterTreeNode = (input, child) => {
    const valid = String(child.props.titleFull).toLowerCase().includes(input.toLowerCase()) || String(child.props.tableNumber).toLowerCase().includes(input.toLowerCase());
    return valid;
  };

  render() {
    const {
      buildingType,
      t,
    } = this.props;

    const {
      treeData,
      searchValue,  
      tsOpen,
      expandedKeys,
    } = this.state;

    const value = buildingType ? buildingType.code : undefined;

    return (
      <div style={{ position: 'relative' }}>
        <TreeSelect
          style={{ width: '100%' }}
          // dropdownStyle={{ maxHeight: 600, overflow: 'auto' }}
          placeholder={`${t('Statinių tipas pagal paskirtį')}*`}
          treeDefaultExpandAll
          searchPlaceholder={`${t('Paieška pagal tekstą')}...`}
          treeExpandedKeys={expandedKeys}
          onTreeExpand={this.onExpand}
          notFoundContent={t('Nerasta')}
          showSearch
          allowClear
          treeLine
          searchValue={searchValue}
          value={value}
          treeData={treeData}
          treeNodeFilterProp="label"
          filterTreeNode={this.filterTreeNode}
          onSearch={this.onSearch}
          open={tsOpen}
          onChange={this.onChange}
          onSelect={this.onSelect}
          onDropdownVisibleChange={(v, info) => {                
            // document clicked
            if (info.documentClickClose && value === '0-0-0-0-value') {
              return false;
            }
            this.setState({
              tsOpen: v,
            });
            return true;
          }}
        />
        {value ? (
          <InputLabel shrink className="tree-select-external-label">
            {t('Statinių tipas pagal paskirtį')}*
          </InputLabel>
        ) : null}
      </div>
    )
  }
}

export default compose(withTranslation())(BuildingTypePicker);