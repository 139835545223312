import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import {
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import { FormField2 } from 'components';
import styles from './styles';

class RulesList extends React.Component {

  onChange({ name, value }) {
    const { rules = [], onChange } = this.props;

    let selectedRules = [ ...rules ];

    if (value) {
      // Checked
      selectedRules.push(name);
    } else {
      // Unchecked
      const idx = _.indexOf(selectedRules, name);
      selectedRules.splice(idx, 1);
    }

    onChange(selectedRules);
  }

  render() {
    const { classes, rules } = this.props;

    return (
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableBody>
            {_.map(this.props.store.record.rules, rule => (
              <TableRow key={rule.rule}>
                <TableCell>
                  {rule.title}
                </TableCell>
                <TableCell>
                  <FormField2 type="checkbox" name={rule.rule} value={_.includes(rules, rule.rule)} onChange={this.onChange.bind(this)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default compose(withStyles(styles), inject('store'))(observer(RulesList));