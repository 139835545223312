import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { Badge, IconButton, Popover, Toolbar, Typography, MenuItem, Menu, Hidden } from '@material-ui/core';
import {
  Menu as MenuIcon,
  // Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
  NotificationsOutlined as NotificationsIcon,
  // Input as InputIcon,
  AccountCircleOutlined as AccountIcon
} from '@material-ui/icons';

// import { getNotifications } from 'services/notification';
import { history } from 'utils/history';
import { canAccess, getCurrentAccount } from 'utils/helpers';
import { ENABLE_MULTILANGUAGE } from 'utils/config';

import { NotificationList } from './components';
import { LocaleSwitcher, HelpMenu } from 'components';

import { logout } from 'views/Users/_actions';
import { userService } from 'views/Users/_services';
import { openAccountDialog } from 'views/App/_actions';

import styles from './styles';

class Topbar extends Component {
  signal = true;
  timer = null;
  fetchingNotifications = false;

  state = {
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null,
    userMenuAnchorEl: null,
    isUserMenuOpen: false
  };

  async getNotifications() {
    try {
      const { _id } = getCurrentAccount();
      const notifications = await userService.getNotifications(_id);

      if (this.signal) {
        this.setState({
          notificationsCount: _.countBy(notifications, { status: 'new' }).true || 0,
          notifications
        });
      }
    } catch (err) {
      console.log('Error while retrieving notifications');
    }
  }

  async triggerNotificationsUpdate() {
    // TODO move notifications to standalone component
    const { isAuthenticated, currentAccount } = this.props;

    if (isAuthenticated && currentAccount) {
      this.fetchingNotifications = true;
      await this.getNotifications();
    }
    this.fetchingNotifications = false;
  }

  async componentDidMount() {
    this.signal = true;
    const { isAuthenticated, currentAccount } = this.props;

    // TODO Disabled notification fetch at interval. Needs reworking.
    if (!this.fetchingNotifications && isAuthenticated && currentAccount) {
      // this.timer = setInterval(async () => {
      //   await this.triggerNotificationsUpdate();
      // }, 30000);
      await this.triggerNotificationsUpdate();
    } else {
      // clearInterval(this.timer);
    }
  }

  componentWillUnmount() {
    this.signal = false;
    clearInterval(this.timer);
  }

  handleSignOut = () => {
    const { dispatch } = this.props;

    dispatch(logout());
  };

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = ({ notificationId }) => {
    const { currentAccount } = this.props;
    if (notificationId) {
      try {
        userService.setNotificationOpened(currentAccount._id, notificationId);
      } catch (err) {
        //...
      }
    }

    this.setState({
      notificationsEl: null
    });
  };

  handleUserMenu = event => {
    this.setState({ userMenuAnchorEl: event.currentTarget, isUserMenuOpen: true });
  };

  handleUserMenuClose = () => {
    this.setState({ userMenuAnchorEl: null, isUserMenuOpen: false });
  };

  handleAccountSwitch = () => {
    const { dispatch } = this.props;
    this.handleUserMenuClose();

    dispatch(openAccountDialog());
  };

  handleCreateAccount = () => {
    // const { history } = this.props;

    // history.push('/companies/join');

    window.location.href = '/companies/join';
  };

  handleProfile() {
    history.push('/profile');
  }

  handleCompanyEdit() {
    const { company } = getCurrentAccount();

    if (!company) {
      return;
    }

    history.push(`/companies/${company._id}`);
  }

  render() {
    const { classes, className, title, titleComponent, isSidebarOpen, onToggleSidebar, onBackButton, currentAccount, user, t } = this.props;
    const { notifications, notificationsCount, notificationsEl, userMenuAnchorEl, isUserMenuOpen } = this.state;
    const rootClassName = classNames(classes.root, className, 'top-navbar');
    const showNotifications = Boolean(notificationsEl);

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            {/* Sidebar collapse button */}
            {!isSidebarOpen ? (
              <IconButton className={classes.menuButton} onClick={onToggleSidebar} variant="text">
                <MenuIcon />
              </IconButton>
            ) : null}
            {/* Back button */}
            <IconButton className={classes.menuButton} onClick={onBackButton} variant="text" title={t('Grįžti')}>
              <ArrowBackIcon />
            </IconButton>
            {/* Title */}
            <Typography className={classes.title} variant="h5">
              {title} {titleComponent}
            </Typography>

            <HelpMenu className={classes.helpButton} />

            {/* Notifications menu */}
            <IconButton className={classes.notificationsButton} onClick={this.handleShowNotifications}>
              <Badge badgeContent={notificationsCount} color="primary" variant="dot" overlap="rectangular">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            {ENABLE_MULTILANGUAGE && <LocaleSwitcher />}

            {/* User menu */}
            <div>
              <IconButton aria-controls="menu-accounts" aria-haspopup="true" onClick={this.handleUserMenu}>
                <AccountIcon />
              </IconButton>
              <Menu
                id="menu-accounts"
                anchorEl={userMenuAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={isUserMenuOpen}
                onClose={this.handleUserMenuClose}>
                <MenuItem onClick={this.handleAccountSwitch} id="li-accounts">
                  {t('Paskyros')}
                </MenuItem>
                <MenuItem onClick={this.handleCreateAccount} id="li-accounts-create">
                  {t('Sukurti naują paskyrą')}
                </MenuItem>
                <MenuItem onClick={this.handleProfile}>{t('Profilio duomenys')}</MenuItem>
                {canAccess('companies.write') ? <MenuItem onClick={this.handleCompanyEdit}>{t('Įmonės duomenys')}</MenuItem> : null}
                <MenuItem onClick={this.handleSignOut}>{t('Atsijungti')}</MenuItem>
              </Menu>
            </div>

            {currentAccount ? (
              <div title={user ? user.email : null}>
                <Typography variant="body2">{user ? user.fullName : null}</Typography>

                <Hidden xsDown>
                  <Typography variant="body2">
                    {/* {user ? user.email : null} */}
                    {currentAccount.position}
                  </Typography>
                </Hidden>

                <Typography variant="caption" display="block">
                  {currentAccount.company ? `${currentAccount.company.name} ${currentAccount.company.department  || ''}` : t('Įmonė nenurodyta')}
                </Typography>
              </div>
            ) : null}
          </Toolbar>
        </div>
        <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}>
          <NotificationList notifications={notifications} onSelect={this.handleCloseNotifications} />
        </Popover>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ app, dispatch }) => {
  return { dispatch, ...app };
};

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation()
)(Topbar);
