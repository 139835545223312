import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

import { DataTable } from 'components';
import { list } from 'services/news';
import { Dashboard as DashboardLayout } from 'layouts';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    maxWidth: '1200px',
  }
}));

const NewsList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      
      try {
        const data = await list();

        setRecords(data);
      } catch (err) {
        console.error(err)
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const prepareTableColumns = () => {
    const columns = [
      'ID',
      'LINK',
      t('Pavadinimas'),
      t('Data'),
      t('Atnaujinta'),
    ];

    return columns;
  }

  const prepareTableData = () => {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/news/${record._id}`);
      row.push(record.title);
      row.push(record.publishedAt ? moment(record.publishedAt).format('YYYY-MM-DD') : null);
      row.push(moment(record.updatedAt).format('YYYY-MM-DD HH:mm'));
      
      return row;
    });

    return data;
  }

  const columns = prepareTableColumns();
  const rows = prepareTableData();

  return (
    <DashboardLayout
      isContentLoading={loading}
      title={t('Naujienos')}      
      breadcrumbs={[
        { title: t('Administravimas') },
        { title: t('Naujienos') },
      ]}
    >
      <div className={classes.root}>
        <DataTable 
          key={rows.length} // Hack to force component to refresh
          columns={columns}
          data={rows}
          createUrl="/news/create"
          onDelete={(idx) => console.log(`Deleting at index ${idx}`)}
          onDeleteBatch={(indexes) => console.log(`Batch deleting indexes ${indexes.join(', ')}`)}
        />
      </div>
    </DashboardLayout>
  )
}

export default NewsList;