import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
// import {
//   Button,
// } from '@material-ui/core';
import {
  Storage as StorageIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { twoOrMoreDecimals } from 'utils/helpers';
import { ClassifierPicker } from 'components';
import { EstimateContext, TYPES } from './EstimateObjectForm';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    // maxWidth: '1200px',
  },
  itemMenu: {
    '& div': {
      minWidth: '120px',
    },
    '& svg': {
      right: '4px',
      position: 'absolute',
    },
  },
  titleCoefficients: {
    fontSize: '9px',
    padding: '0px 8px 2px',
  },
}));

const EditableCell = ({
  value: initialValue,
  row,
  column,
  // inputType = 'text',
  onCellChange, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const { record, setShowRatesModal, setShowResourceSearchModal, setActiveItem } = useContext(EstimateContext);
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const { type } = row.original;
  const field = column.id;
  const notEditable = ['amount'].includes(field) && !!row.original.childCount && type !== TYPES.RATE;
  const classes = useStyles();

  useEffect(() => {
    if (isEditing) {
      !!inputRef.current && inputRef.current.focus();
    }
  }, [isEditing]);

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    const item = row.original;

    if (initialValue != value) onCellChange(field, item, value);
    setIsEditing(false);
  }

  const onClassifierPickerChange = (val) => {
    if (val === undefined) return onBlur();

    const item = row.original;

    onCellChange(field, item, val);
    setIsEditing(false);
  }

  // Autoselect value when focused
  const onFocus = (e) => {
    e.currentTarget.select();
  }

  // Trigger change on `enter` press
  const onKeyDown = (e) => {
    if (e.key !== 'Enter') return;

    onBlur();
  }

  const onClick = () => {
    !notEditable && setIsEditing(true);
  }

  const showRatesModal = (id, parent) => {
    setActiveItem({ _id: id, parent });
    setShowRatesModal(true);
  }

  const showResourceSearchModal = (id) => {
    setActiveItem({
      _id: id,
      type: row.original.rateType || row.original.resourceType,
      code: row.original.code,
      title: row.original.title,
      price: row.original.price,
      quantity: row.original.quantity,
      norm: row.original.norm,
      unit: row.original.unit,
    });
    setShowResourceSearchModal(true);
  }

  const renderRateCoefficients = (coefficients) => {
    // Coefficients: K1, K2, K3, K11, K21, K31, K41, K51
    // Output format: k1=1.2,1.3 k2=0.4 ...
    const output = [];

    // Object.keys(coefficients).forEach(key => {
    //   const values = coefficients[key].filter(v => Number(v) !== 1);
    //   if (!values.length) return;

    //   output.push(`${key}=${values.join(',')}`);
    // });

    Object.keys(coefficients).forEach(key => {
      const values = [];
      const coeffs = coefficients[key];
      if (!coeffs.length) return;

      for (const coeff of coeffs) {
        if (coeff.type === 'coef' && coeff.value === 1) continue;

        if (coeff.type === 'coef') {
          values.push(coeff.value);
        } else if (coeff.type === 'amount') {
          values.push(`${coeff.value}EUR`);
        } else if (coeff.type === 'percent') {
          values.push(`${coeff.value}%`);
        } else {
          console.error(`Coefficient type ${coeff.type} not supported`);
        }
      }

      if (values.length) output.push(`${key}=${values.join(',')}`);
    });

    if (!output.length) return null;

    return (
      <div className={classes.titleCoefficients}>
        {output.join(' ')}
      </div>
    )
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const canSearchResource = ['mechanism', 'material', 'work'].includes(row.original.rateType || row.original.resourceType);

  let additionals;
  let secondary;
  if (field === 'title') {
    switch (type) {
      case TYPES.RATE:
        additionals = (
          <div className="additional">
            {!canSearchResource ? (
              <a onClick={() => showRatesModal(row.original._id, row.original.parent)} title={t('Įkelti įkainį')} href="#">
                <StorageIcon />
              </a>
            ) : (
              <a onClick={() => showResourceSearchModal(row.original._id)} title={t('Resurso paieška')} href="#">
                <SearchIcon />
              </a>
            )}
          </div>
        )

        secondary = renderRateCoefficients(row.original.coefficients);
        break;

      case TYPES.RESOURCE:
        additionals = canSearchResource ? (
          <div className="additional">
            <a onClick={() => showResourceSearchModal(row.original._id)} title={t('Resurso paieška')} href="#">
              <SearchIcon />
            </a>
          </div>
        ) : null;
        break;

      default:
        break;
    }
  }

  let input;
  switch (field) {
    case 'title':
    case 'code':
      input = (
        <textarea
          ref={inputRef}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          rows={4}
        />
      )
      // Note: Disable `onKeyDown` to allow new line on `Enter` press in textarea
      break;

    case 'structure':
      input = (
        <ClassifierPicker
          inputRef={inputRef}
          onChange={onClassifierPickerChange}
          value={value}
        />
      )
      break;

    default:
      input = (
        <input
          ref={inputRef}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
      );
  }

  const getValueWithStyling = () => {
    const result = { value, style: {} };

    if (['norm', 'quantity', 'price', 'amount'].includes(field)) {
      // Is numeric value
      result.value = twoOrMoreDecimals(value);
      result.style = { textAlign: 'right' };
    }

    if (['title', 'code'].includes(field)) {
      result.value = <span className='value' title={value}>{value}</span>
      // result.style = { maxWidth: '300px' };
    }

    if (field === 'structure') {
      result.value = <span className='value' title={`${value?.code} ${value?.title}`}>{value?.title}</span>;
    }

    return result;
  }

  const styled = getValueWithStyling();

  return (
    <>
      <div
        className={`value-wrap ${isEditing ? 'editing' : ''} field-${field} type-${type}`}
        style={styled.style}
        onClick={onClick}
      >
        {isEditing ? input : styled.value}

        {!isEditing && additionals}
      </div>

      {secondary}
    </>
  )
}

export default EditableCell;