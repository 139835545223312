import React, { useState, useEffect, createContext, useMemo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  Button,
  Grid,
  Tabs,
  Tab,
  InputLabel,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TextField,
  IconButton,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { Alert } from '@material-ui/lab';
import {
  Save as SaveIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { EDU_MODE } from 'utils/config';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import * as objectService from 'services/estimateObjects';
import * as itemService from 'services/estimateItems';
import * as projectService from 'services/projects';
import { toast, twoOrMoreDecimals, scrollToElement, setElementHighlight, blobToFileDownload, getQueryVariable, getCurrentAccount } from 'utils/helpers';
import { Dashboard as DashboardLayout } from 'layouts';
import { FormField2 as Field, CommonPanel, NormVersionPicker, MenuCustom, TrialHandler, CommonDialog, AutoComplete } from 'components';
import RatesModal from './RatesModal';
import ResourceSearchModal from './ResourceSearchModal';
import ItemSummaryModal from './ItemSummaryModal';
import RatesImportModal from './RatesImportModal';
import ObjectSummaryModal from './ObjectSummaryModal';
import ResourceManager from './ResourceManager';
import PrintManager from './PrintManager';
import EstimateTreeTable from './EstimateTreeTable';
import EstimateTreeTableSelect from './EstimateTreeTableSelect';
import OldEstimateTreeTable from './OldEstimateTreeTable';
import { timestampToDate } from 'utils/helpers';
import api from 'utils/api';

import './EstimateObjectForm.css';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    // maxWidth: '1200px',
  },
  titleCoefficients: {
    fontSize: '9px',
    padding: '0px 8px 2px',
  },
}));

const TABS = {
  ESTIMATES: 0,
  // ESTIMATES_OLD: 1,
  MATERIALS: 1,
  MECHANISMS: 2,
  EQUIPMENT: 3,
  WORK: 4,
  // SETTINGS: 5,
  // EXPORT: 6,
  PRINT: 5,
  // DIAGNOSTICS: 8,
};

export const DETAIL_LEVEL = {
  ESTIMATE: 'estimate',
  PART: 'part',
  CHAPTER: 'chapter',
  SUBCHAPTER: 'subchapter',
  ELEMENT: 'element',
  GROUP: 'group',
  RATE: 'rate',
  RESOURCE: 'resource',
};

export const DETAIL_LEVEL_VISIBILITY = {
  [DETAIL_LEVEL.ESTIMATE]: [],
  [DETAIL_LEVEL.PART]: [DETAIL_LEVEL.ESTIMATE],
  [DETAIL_LEVEL.CHAPTER]: [DETAIL_LEVEL.ESTIMATE, DETAIL_LEVEL.PART],
  [DETAIL_LEVEL.SUBCHAPTER]: [DETAIL_LEVEL.ESTIMATE, DETAIL_LEVEL.PART, DETAIL_LEVEL.CHAPTER],
  [DETAIL_LEVEL.RATE]: [DETAIL_LEVEL.ESTIMATE, DETAIL_LEVEL.PART, DETAIL_LEVEL.CHAPTER, DETAIL_LEVEL.SUBCHAPTER, DETAIL_LEVEL.ELEMENT, DETAIL_LEVEL.GROUP],
  [DETAIL_LEVEL.RESOURCE]: [DETAIL_LEVEL.ESTIMATE, DETAIL_LEVEL.PART, DETAIL_LEVEL.CHAPTER, DETAIL_LEVEL.SUBCHAPTER, DETAIL_LEVEL.ELEMENT, DETAIL_LEVEL.GROUP, DETAIL_LEVEL.RATE, DETAIL_LEVEL.RESOURCE],
};

export const TYPES = {
  ESTIMATE: 'estimate',
  PART: 'part',
  CHAPTER: 'chapter',
  SUBCHAPTER: 'subchapter',
  GROUP: 'group',
  ELEMENT: 'element',
  RATE: 'rate',
  RESOURCE: 'resource',
};

export const EstimateContext = createContext(null);

const SERVICE_CODE = 'estimates';

const EstimateObjectForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [record, setRecord] = useState(null);
  const [project, setProject] = useState(null);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.ESTIMATES);
  const { projectId, objectId } = useParams();
  const [showRatesModal, setShowRatesModal] = useState(false);
  const [showResourceSearchModal, setShowResourceSearchModal] = useState(false);
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showEstimateImportModal, setShowEstimateImportModal] = useState(false);
  const [showRateImportModal, setShowRateImportModal] = useState(false);
  const [showItemPrintModal, setShowItemPrintModal] = useState(false);
  const [showItemSummaryModal, setShowItemSummaryModal] = useState(false);
  const [showRatesImportModal, setShowRatesImportModal] = useState(false);
  const [showObjectSummaryModal, setShowObjectSummaryModal] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [detailLevel, setDetailLevel] = useState(DETAIL_LEVEL.RESOURCE);
  const [idToHighlight, setIdToHighlight] = useState(null);
  const [treeExpandedRowKeys, setTreeExpandedRowKeys] = useState([]);
  const [treeDetailLevel, setTreeDetailLevel] = useState(DETAIL_LEVEL.ESTIMATE);
  const [showTrialMessage, setShowTrialMessage] = useState(getCurrentAccount().trialServices.includes(SERVICE_CODE));
  const [isTrial, setIsTrial] = useState(!!getQueryVariable('trial'));
  const [estimateCurrentEditable, setEstimateCurrentEditable] = useState();
  const [itemAdded, setItemAdded] = useState(null);


  const fetchRecord = async (id) => {
    setLoading(true);

    try {
      const data = await objectService.show(id);
      setRecord(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašą'), 'error');
    }

    setLoading(false);
    // Remove highlight if any
    setIdToHighlight(null);
  }

  const fetchProject = async (projectId) => {
    setLoading(true);

    try {
      const data = await projectService.show(projectId);
      setProject(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašą'), 'error');
    }

    setLoading(false);
  }

  const fetchConfig = async () => {
    setLoading(true);

    try {
      const data = await objectService.getConfig();
      setConfig(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant konfigūraciją'), 'error');
    }

    setLoading(false);
  }

  useEffect(() => {
    if (isTrial) return;

    if (!loading && !record?._id && objectId) fetchRecord(objectId);
    fetchProject(projectId)
      .then(() => fetchConfig());
  }, []);

  // Trigger scroll-to and highlight of new row
  useEffect(() => {
    if (idToHighlight) {
      setTimeout(function () {
        const el = document.querySelector(`.row-key-${idToHighlight}`);
        if (!el) return;
        scrollToElement(el);
        setElementHighlight(el, true);
      }, 300);
    } else {
      setElementHighlight(null, false);
    }

  }, [idToHighlight]);

  const onSubmit = async (data = null) => {
    setLoading(true);

    try {
      const payload = _.omit(data || record, 'items');
      payload.project = projectId;

      if (!objectId) {
        const { _id } = await objectService.create(payload);

        toast(t('Išsaugota'), 'success');
        history.push(`/projects/${projectId}/objects/${_id}`);
      } else {
        await objectService.update(objectId, payload);

        await fetchRecord(record._id);
        toast(t('Atnaujinta'), 'success');
      }
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko išsaugoti'), 'error');
    }

    setLoading(false);
  };

  const onClone = async () => {
    if (!window.confirm(t('Ar tikrai norite sukurti skaičiavimo kopiją?'))) return;

    setLoading(true);

    try {
      const { data } = await api.post(`/estimates/${objectId}/clone`);

      window.location.href = `/projects/${projectId}/objects/${data._id}`;
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko klonuoti'), 'error');
    }

    setLoading(false);
  };

  const onDelete = async () => {
    if (!window.confirm('Ar tikrai norite ištrinti visus skaičiavimo duomenis?')) return;
    setLoading(true);

    try {
      await api.delete(`/estimates/${objectId}`);
      setLoading(false);
      toast(t('Pašalinta'), 'success');

      history.push(`/projects/${projectId}/dashboard`);
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko pašalinti'), 'error');

      setLoading(false);
    }
  };

  const onRecalc = async () => {
    setLoading(true);

    try {
      await api.post(`/estimates/${objectId}/calculate`);

      await fetchRecord(record._id);
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko perskaičioti'), 'error');
    }

    setLoading(false);
  };

  const onImportExcel = () => {
    history.push(`/projects/${projectId}/objects/${objectId}/import`);
  };

  const onRecordChange = ({ name, value }) => {
    let submitAfter = false;

    switch (name) {
      case 'version':
        if (!window.confirm(t('Perskaičiavus kita versija bus prarastos visos kainų korektūros. Tęsti?'))) return;
        submitAfter = !!record?.version;
        break;

      default:
    }

    const newRecord = {
      ...record,
      [name]: value,
    };

    setRecord(newRecord);

    if (submitAfter) onSubmit(newRecord);

    // Remove highlight if any
    setIdToHighlight(null);
  }

  const expandTreeById = (idsToExpand) => {
    // if (!idsToExpand || treeExpandedRowKeys.includes(idToExpand)) return;
    if (!idsToExpand) return;

    setTreeExpandedRowKeys(idsToExpand);
  }

  const print = async (params) => {
    setLoading(true);

    try {
      const { data } = await api.post(`/estimates/${record._id}/print`, params, { timeout: 1000 * 120 });
      const { fileName, contentType, content } = data;

      var bytes = new Uint8Array(content);
      const blob = new Blob([bytes], { type: contentType });

      blobToFileDownload(fileName, blob);
    } catch (err) {
      console.error(err);

      toast(t('Nepavyko suformuoti dokumento'), 'error');
    }

    setLoading(false);
  }

  const transfer = async (params) => {
    setLoading(true);

    try {
      await api.post(`/estimates/${objectId}/transfer`, params);
      toast(`${t('Perkėlimas nusiųstas')} ${params.pendingCopy}`, 'success')
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko vykdyti skaičiavimo perkėlimo'), 'error');
    }

    setLoading(false);
  }

  const itemActions = {
    addItem: async (selectedItem, type) => {
      setLoading(true);

      try {
        const parent = selectedItem.type === type
          ? selectedItem.parent // if adding same type - add to same parent
          : selectedItem._id; // otherwise - add as selectedItem child

        const order = _.startsWith(type, selectedItem.type)
          ? (selectedItem.order || 0) + 0.5
          : -1;

        const payload = {
          parent,
          type,
          order,
        };

        const { _id: newItemId, parent: parentId } = await itemService.create(record._id, payload);
        await fetchRecord(record._id);

        expandTreeById([parentId, newItemId]);
        setItemAdded(newItemId);
        setIdToHighlight(newItemId);
        return newItemId;

      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    },

    removeItem: async (selectedItem) => {
      let warningMessage = selectedItem.childCount > 0 ? 'Ar tikrai norite ištrinti šį ir po juo esančius įrašus?' : 'Ar tikrai norite pašalinti?';
      if (!window.confirm(t(warningMessage))) return;

      setLoading(true);

      try {
        await itemService.remove(record._id, selectedItem._id);
        await fetchRecord(record._id);
      } catch (err) {
        console.error(err);
        window.alert(t('Klaida trinant'));
      }

      setLoading(false);
    },

    cellChanged: async (field, item, value) => {
      setLoading(true);

      const payload = {
        onlyField: true, // Update only one field instead of whole object
        field,
        value,
        returnEstimate: true
      };

      try {
        const updatedData = await itemService.update(record._id, item._id, payload);
        setRecord(updatedData);
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    },

    multipleResourceSearch: (item, options = {}) => {
      setActiveItem({
        _id: item._id,
        multiple: true,
        ...options,
      });
      setShowResourceSearchModal(true);
    },

    addItemAsExtractedResource: async (selectedItem, type) => {
      setLoading(true);

      try {
        const parent = selectedItem.type === type
          ? selectedItem.parent // if adding same type - add to same parent
          : selectedItem._id; // otherwise - add as selectedItem child

        const order = _.startsWith(type, selectedItem.type)
          ? (selectedItem.order || 0) + 0.5
          : -1;

        const payload = {
          parent,
          type,
          order,
        };

        const { _id: newItemId, parent: parentId } = await itemService.addResourceItemAsRate(record._id, payload);
        await fetchRecord(record._id);
        expandTreeById([parentId, newItemId]);
        setItemAdded(newItemId);
        setIdToHighlight(newItemId);
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    },

    extractResourceAsRate: async (item) => {
      setLoading(true);

      try {
        await itemService.extractAsRate(record._id, item._id);
        await fetchRecord(record._id);
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    },

    extractItemAsEstimate: async (item) => {
      setLoading(true);

      try {
        await itemService.extractAsEstimate(record._id, item._id);
        await fetchRecord(record._id);
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    },

    showItemSummary: (item, activeTab) => {
      setActiveItem({ _id: item._id, activeTab });
      setShowItemSummaryModal(true);
    },

    showRatesImport: (item) => {
      setActiveItem({ _id: item._id, type: item.type });
      setShowRatesImportModal(true);
    },

    reorderItem: async (id, order) => {
      await api.put(`estimates/${record._id}/reorder-item`, { item: id, order });
      await fetchRecord(record._id);
    }
  };

  const renderModals = () => {
    if (!record?._id) return;

    let priceSettings;
    if (showRatesModal && activeItem?.parent) {
      const parent = record.items.find(i => i._id === activeItem?.parent);
      priceSettings = _.get(parent, 'config.priceSettings');
    }

    const ratesProps = {
      objectId: record._id,
      itemId: activeItem?._id,
      initialSearchString: activeItem?.code && !activeItem?.code?.startsWith('IKN-') ? activeItem?.code : null,
      version: record?.version,
      quantityCalc: activeItem?.quantityCalc,
      priceSettings,
      onClose: (reload = false) => {
        setShowRatesModal(false);
        setActiveItem(null);

        if (reload) fetchRecord(record._id);
      },
      itemActions,
      activeItem,
      setActiveItem,
    };

    const insertProps = {

      onClose: () => {
        setShowInsertModal(false);
      }
    };

    const resourceSearchProps = {
      objectId: record._id,
      itemId: activeItem?._id,
      version: record?.version,
      type: activeItem?.type,
      originalItem: activeItem,
      onlyRates: activeItem?.onlyRates,
      onClose: (reload = false) => {
        setShowResourceSearchModal(false);
        setActiveItem(null);

        if (reload) fetchRecord(record._id);
      },
    }

    const itemSummaryProps = {
      objectId: record._id,
      itemId: activeItem?._id,
      initialTab: activeItem?.activeTab,
      onChange: ({ field, value }) => {
        itemActions.cellChanged(field, activeItem, value);
      },
      onClose: (reload = false) => {
        setShowItemSummaryModal(false);
        setActiveItem(null);

        if (reload) fetchRecord(record._id);
      },
    };

    const ratesImportProps = {
      version: record?.version,
      objectId: record._id,
      itemId: activeItem?._id,
      topLevelType: activeItem?.type,
      onClose: (reload = false) => {
        setShowRatesImportModal(false);
        setActiveItem(null);

        if (reload) fetchRecord(record._id);
      },
    };

    const objectSummaryProps = {
      objectId: record._id,
      itemId: activeItem?._id,
      onChange: ({ field, value }) => {
        itemActions.cellChanged(field, activeItem, value);
      },
      onClose: (reload = false) => {
        setShowObjectSummaryModal(false);
        setActiveItem(null);

        if (reload) fetchRecord(record._id);
      },
    };

    const itemPrintModalProps = {
      objectId: record._id,
      itemId: activeItem?._id,
      onPrint: print,
      onClose: () => {
        setShowItemPrintModal(false);
        setActiveItem(null);
      },
    };

    const transferModalProps = {
      objectId: record._id,
      onTransfer: transfer,
      onClose: () => {
        setShowTransferModal(false);
        setActiveItem(null);
      },
    }

    const rateImportModalProps = {
      objectId: record._id,
      version: record?.version.code,
      activeItem,
      fetchRecord,
      onClose: () => {
        setShowRateImportModal(false);
        setActiveItem(null);
      }
    }

    const estimateImportModalProps = {
      objectId: record._id,
      version: record?.version.code,
      fetchRecord,
      onClose: () => {
        setShowEstimateImportModal(false);
        setActiveItem(null);
      }
    }

    return (
      <>
        {showRatesModal && <RatesModal {...ratesProps} />}
        {showInsertModal && <InsertModal {...insertProps} />}
        {showResourceSearchModal && <ResourceSearchModal {...resourceSearchProps} />}
        {showItemSummaryModal && <ItemSummaryModal {...itemSummaryProps} />}
        {showObjectSummaryModal && <ObjectSummaryModal {...objectSummaryProps} />}
        {showItemPrintModal && <ItemPrintModal {...itemPrintModalProps} />}
        {showTransferModal && <TransferModal {...transferModalProps} />}
        {showEstimateImportModal && <EstimateImportModal {...estimateImportModalProps} />}
        {showRateImportModal && <RateImportModal {...rateImportModalProps} />}
        {showRatesImportModal && <RatesImportModal {...ratesImportProps} />}
      </>
    );
  }

  // !!! this might prevent rerendering
  const estimateContextValue = useMemo(() => ({
    record,
    setRecord,
    project,
    setLoading,
    onRecordChange,
    itemActions,
    config,
    setShowRatesModal,
    setShowResourceSearchModal,
    setActiveItem,
    setShowItemSummaryModal,
    setShowRatesImportModal,
    setShowItemPrintModal,
    setShowTransferModal,
    setShowEstimateImportModal,
    setShowRateImportModal,
    setShowObjectSummaryModal,
    setDetailLevel,
    detailLevel,
    onSubmit,
    onRecalc,
    onImportExcel,
    onDelete,
    onClone,
    treeExpandedRowKeys,
    setTreeExpandedRowKeys,
    loading,
    print,
    treeDetailLevel,
    setTreeDetailLevel,
    estimateCurrentEditable,
    estimateCurrentEditable,
    setEstimateCurrentEditable,
    itemAdded,
    setItemAdded
  }),
    [
      record,
      setRecord,
      project,
      loading,
      setLoading,
      setShowRatesModal,
      setShowResourceSearchModal,
      setActiveItem,
      setShowItemSummaryModal,
      setShowRatesImportModal,
      setShowItemPrintModal,
      setShowTransferModal,
      setShowEstimateImportModal,
      setShowRateImportModal,
      setShowObjectSummaryModal,
      detailLevel,
      treeExpandedRowKeys,
      setTreeExpandedRowKeys,
      setTreeDetailLevel,
      estimateCurrentEditable,
      setEstimateCurrentEditable,
      itemAdded,
      setItemAdded
    ]);

  const renderTrial = () => {

    return (
      <TrialHandler
        service="estimates"
      />
    )
  }

  const renderTrialMessage = () => {

    return EDU_MODE ? null : (
      <div style={{ margin: 10 }}>
        <Alert
          variant="filled"
          severity="info"
        // onClose={() => this.setState({ showTrialMessage: false })}
        >
          {t('Jūs naudojate bandomąją (laikinai nemokamą) paslaugos versiją.')}
        </Alert>
      </div>
    )
  }


  const isNew = !record?._id;

  return (
    <>
      <DashboardLayout
        isContentLoading={loading}
        titleComponent={!isNew &&
          <Grid container spacing={2}>
            <Grid item style={{ width: '260px' }}>
              <div style={{ display: 'flex' }}>
                <Field
                  name="title"
                  onChange={onRecordChange}
                  value={record?.title}
                  label={t('Skaičiavimų objekto pavadinimas')}
                  shrink
                  style={{ width: 220 }}
                />
                <IconButton
                  color="primary"
                  onClick={() => onSubmit()}
                  style={{ marginTop: 10 }}
                  size="small"
                  disableFocusRipple={true}
                  disableRipple={true}
                >
                  <SaveIcon style={{ width: 28, height: 28 }} />
                </IconButton>
              </div>
            </Grid>
            <Grid item style={{ paddingLeft: 32 }}>
              <NormVersionPicker
                value={record?.version?.code}
                name="version"
                onChange={({ value }) => onRecordChange({ name: 'version', value })}
                label={t('Kainyno versija')}
                style={{ marginTop: 16, width: '180px' }}
                type="calculations"
                variant="estimates"
                allowBlank
              />
            </Grid>
          </Grid>
        }
        breadcrumbs={[
          { title: t('Projektai'), to: '/projects/' },
          { title: project?.title || '-', to: `/projects/${projectId}/dashboard` },
          { title: t('Objektai') },
          { title: `${!objectId ? t('Naujas skaičiavimų objektas') : (record?.title || '-')} ${record?.version?.version ? '(' + t('Kainynas ') + ' ' + record?.version?.version + ')' : ''}` },
        ]}
        breadcrumbsObject={!isNew && (
          <>
            <div style={{ paddingRight: 12 }}>
              <MenuCustom
                buttonText={t('Veiksmai')}
                endIcon={<MoreVertIcon />}
                buttonVariant="contained"
                buttonColor="primary"
                items={[
                  { label: t('Išsaugoti'), handler: () => onSubmit() },
                  { label: t('Įkelti duomenis iš kitos sąmatos'), handler: () => setShowEstimateImportModal(true) },
                  { label: t('Kopijuoti'), handler: () => onClone() },
                  { label: t('Perskaičiuoti'), handler: () => onRecalc() },
                  { label: t('Importuoti iš BIM (Excel)'), handler: () => onImportExcel() },
                  // { label: t('Kainos sudėtis'), handler: () => setShowObjectSummaryModal(true) },
                  { label: t('Pašalinti'), handler: () => onDelete() },
                  { label: t('Persiųsti'), handler: () => setShowTransferModal(true) }
                ]}
              />
            </div>
          </>
        )}
        beforeMainContent={!isTrial && showTrialMessage && renderTrialMessage()}
      >
        <div className={classes.root} style={{ paddingTop: 12 }}>
          <EstimateContext.Provider value={estimateContextValue}>
            {!isTrial ? (
              <CommonPanel>
                {!isNew && !!config && <EstimateForm activeTab={activeTab} />}

                <Grid container spacing={2}>
                  {isNew ? (
                    <>
                      {!!config ? (
                        <>
                          <Grid item xs={6}>
                            <Field name="title" onChange={onRecordChange} value={record?.title} label={t('Skaičiavimų objekto pavadinimas')} shrink />
                            <Typography variant="body1">
                              {t('Įveskite naujo skaičiavimų objekto pavadinimą ir išsaugokite, kad galėtumėte pradėti skaičiavimus')}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              startIcon={<SaveIcon />}
                              variant="contained"
                              color="primary"
                              onClick={() => onSubmit()}
                              style={{ marginTop: 15 }}
                            // disabled={record?.autosave}
                            >
                              Išsaugoti
                            </Button>
                          </Grid>
                        </>
                      ) : t('Paslauga neteikiama')}
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Tabs
                        value={activeTab}
                        onChange={(_e, newVal) => setActiveTab(newVal)}
                        scrollButtons="auto"
                        variant="scrollable"
                      >
                        <Tab label={t('Sąmata')} disabled={isNew || !record?.version?.code} />
                        {/* <Tab label={`${t('Sąmata')} (OLD)`} disabled={isNew || !record?.version?.code} /> */}
                        <Tab label={t('Medžiagos')} disabled={isNew || !record?.version?.code} />
                        <Tab label={t('Mechanizmai')} disabled={isNew || !record?.version?.code} />
                        <Tab label={t('Įrenginiai')} disabled={isNew || !record?.version?.code} />
                        <Tab label={t('Darbas')} disabled={isNew || !record?.version?.code} />
                        {/* <Tab style={{ width: '20px' }} disabled /> */}
                        {/* <Tab label={t('Nustatymai')} disabled={isNew || !record?.version?.code} /> */}
                        {/* <Tab label={t('Eksportavimas')} disabled={isNew || !record?.version?.code} /> */}
                        <Tab label={t('Spausdinimas')} disabled={isNew || !record?.version?.code} />
                        {/* <Tab label={t('Diagnostika')} disabled={isNew || !record?.version?.code} /> */}
                      </Tabs>
                    </Grid>
                  )}
                </Grid>

                {!isNew && !!config && !record?.version?.code && (
                  <h3 style={{ textAlign: 'center' }}>{t('Pasirinkite kainyno versiją')}</h3>
                )}

                {!isNew && !!config && !!record?.version?.code && (
                  <>
                    {activeTab === TABS.ESTIMATES && <EstimateTreeTable />}
                    {/* {activeTab === TABS.ESTIMATES_OLD && <OldEstimateTreeTable />} */}
                    {activeTab === TABS.MATERIALS && <ResourceManager resourceType="material" onReload={fetchRecord} />}
                    {activeTab === TABS.MECHANISMS && <ResourceManager resourceType="mechanism" onReload={fetchRecord} />}
                    {activeTab === TABS.EQUIPMENT && <ResourceManager resourceType="equipment" onReload={fetchRecord} />}
                    {activeTab === TABS.WORK && <ResourceManager resourceType="work" onReload={fetchRecord} />}
                    {activeTab === TABS.PRINT && <PrintManager />}
                  </>
                )}
              </CommonPanel>
            ) : renderTrial()}
          </EstimateContext.Provider>
        </div>
      </DashboardLayout>

      {renderModals()}
    </>
  )
};

const EstimateForm = ({ activeTab }) => {
  const { t } = useTranslation();
  const { record, onRecordChange, onSubmit, onClone, onRecalc, onDelete, onImportExcel, setShowObjectSummaryModal, setShowTransferModal, setShowEstimateImportModal } = useContext(EstimateContext);

  const isNew = !record?._id;

  return (
    <div>
      {!!isNew && (
        <Grid item xs={3} style={{ textAlign: 'right' }}>
          <div style={{ marginTop: 12 }}>
            <Button
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              onClick={() => onSubmit()}
            // disabled={record?.autosave}
            >
              Išsaugoti
            </Button>
          </div>
        </Grid>
      )}

    </div>
  )
}

const TransferModal = ({ onClose, onTransfer }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pendingCopy, setPendingCopy] = useState('');

  const handlePendingCopyChange = ({ value }) => {
    setPendingCopy(value);
  }

  const handleSubmitTransfer = async () => {
    setLoading(true);

    if (!pendingCopy) {
      toast(t('Įveskite gavėjo el. paštą'), 'error');
      return;
    }

    try {
      await onTransfer({
        pendingCopy
      });
    } catch (err) {
      console.error(err);
      window.alert(t('Įvyko klaida'));
    }

    setLoading(false);

    onClose();
  }

  return (
    <CommonDialog
      visible
      onClose={onClose}
      maxWidth="xs"
      style={{ position: 'relative' }}
      actions={[
        <Button
          color="default"
          variant="text"
          onClick={() => onClose()}
        >
          Atšaukti
        </Button>,
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmitTransfer}
        >
          Vykdyti
        </Button>
      ]}
    >
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{t('Skaičiavimų objekto perkėlimas kitam vartotojui')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Field
                  name="pendingCopy"
                  value={pendingCopy || ''}
                  label={t('El. paštas')}
                  required
                  onChange={handlePendingCopyChange}//setPendingCopy(e.target.value)}
                  // margin="dense"
                  // variant="outlined"
                  autoComplete="off"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </CommonDialog>
  )
}

const EstimateImportModal = ({ onClose, fetchRecord, objectId, version }) => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [estimates, setEstimates] = useState([]);
  const [selectedEstimates, setSelectedEstimates] = useState([]);
  const [selectedObjectVersion, setSelectedObjectVersion] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    const projects = await projectService.list({ lite: true });
    setProjects(projects);
  }

  const getObjects = async (projectId) => {
    if (!projectId) return;
    const objects = await objectService.list({ projectId });
    setSelectedObjectVersion(null);
    setObjects(objects);
  }

  const getEstimates = async (objectId) => {
    if (!objectId) return;

    const estimates = await objectService.show(objectId);
    setSelectedObjectVersion(estimates.version.code);
    setEstimates(estimates.items);
    setFilteredRows(estimates.items);
  }

  const onChange = ({ name, value }) => {
    if (name == 'project') {
      setSelectedObject(null);
      setSelectedEstimates([]);
      setSelectedProject(value);
      getObjects(value);
    }

    if (name == 'object') {
      setSelectedEstimates([]);
      setSelectedObject(value);
      getEstimates(value);
    }

    if (name == 'estimates') {
      setSelectedEstimates(value);
    }
  };

  const handleImport = async () => {
    try {
      if (selectedEstimates.length == 0) {
        toast(t('Nepasirinkta sąmata'), 'error');
        return;
      }
      if (version != selectedObjectVersion) {
        if (!window.confirm(t('Pasirinktas objektas apskaičiuotas kito laikotarpio kainų versija. Ar pakeisti versiją?'))) return;
      }

      await api.post(`/estimates/${objectId}/import-estimates`, { estimates: selectedEstimates, versionChanged: version != selectedObjectVersion });
      toast(t('Sąmatos įkeltos'), 'success');
      await fetchRecord(objectId);
      onClose();
    } catch (err) {
      console.log(err);
      toast(t('Klaida įkeliant sąmatas'), 'error');
    }
  }

  const handleSearch = (value) => {
    setSearchText(value);
    const searchRegex = new RegExp(value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
    const filteredRows = estimates.filter((row) => {
      return Object.keys(row).some((field) => {
        if (!['title', 'code', 'updatedAt'].includes(field)) return;
        return searchRegex.test(!!row[field] ? row[field].toString() : null);
      });
    });
    setFilteredRows(filteredRows);
  }

  const handleSearchClear = () => {
    setSearchText('');
    setFilteredRows(estimates);
  }

  const handleDelete = (data) => {
    setSelectedEstimates(selectedEstimates.filter(estimateId => estimateId != data));
  }

  const renderProjectField = ({ name, ...field }) => {
    return (
      <AutoComplete
        {...field}
        name={name}
        freeSolo={true}
        creatable={false}
        value={selectedProject}
        style={{ height: '32px' }}
        onChange={(newValue) => onChange({ name: 'project', value: newValue.value })}
      />
    );
  }

  const renderObjectField = ({ name, ...field }) => {
    return (
      <AutoComplete
        {...field}
        name={name}
        freeSolo={true}
        creatable={false}
        disabled={!selectedProject}
        value={selectedObject}
        style={{ height: '32px' }}
        onChange={(newValue) => onChange({ name: 'object', value: newValue._id })}
      />
    );
  }

  return (
    <CommonDialog
      visible
      onClose={onClose}
      maxWidth="sm"
      style={{ position: 'relative' }}
      actions={[
        <Button
          color="default"
          variant="text"
          onClick={() => onClose()}
        >
          {t("Atšaukti")}
        </Button>,
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleImport()}
        >
          {t("Vykdyti")}
        </Button>
      ]}
    >
      <div>
        <Table style={{ maxWidth: 550 }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{t('Pasirinkite sąmatą įkėlimui')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {renderProjectField({
                  name: 'project',
                  label: t('Projektas'),
                  options: projects.map((project) => ({ key: project._id, value: project._id, title: project.code ? `${project.code} - ${project.title}` : project.title }))
                })}
                <br />
                {renderObjectField({
                  name: 'object',
                  label: t('Skaičiavimų objektas'),
                  options: objects,
                })}
                <br />
                {!!selectedObject ?
                  <div style={{ maxHeight: 350 }}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      margin="normal"
                      value={searchText}
                      onChange={e => handleSearch(e.target.value)}
                      placeholder={t("Paieška")}
                      InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                          <IconButton
                            title={t('Išvalyti')}
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: !!searchText ? 'visible' : 'hidden' }}
                            onClick={() => handleSearchClear()}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        ),
                      }}
                      style={{ paddingBottom: 5, marginTop: 0 }}
                    />
                    <DataGrid
                      checkboxSelection
                      keepNonExistentRowsSelected
                      rowHeight={38}
                      rows={filteredRows.map((estimate) => ['estimate'].includes(estimate.type) ? (
                        {
                          key: estimate._id,
                          id: estimate._id,
                          value: estimate._id,
                          code: estimate.code,
                          title: estimate.title,
                          updatedAt: timestampToDate(estimate.updatedAt) || null,
                        }
                      ) : null)}
                      columns={[
                        {
                          field: 'code',
                          headerName: t('Kodas'),
                          width: 120,
                          editable: true,
                        },
                        {
                          field: 'title',
                          headerName: t('Pavadinimas'),
                          width: 180,
                          editable: true,
                        },
                        {
                          field: 'updatedAt',
                          headerName: t('Atnaujinta'),
                          width: 140,
                          editable: true,
                        },
                      ]}
                      hideFooter
                      style={{ maxHeight: 260 }}
                      rowSelectionModel={selectedEstimates}
                      onRowSelectionModelChange={(newSelection) => {
                        setSelectedEstimates(newSelection);
                      }}
                    />
                    {selectedEstimates.length > 0 ?
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          flexWrap: "nowrap",
                          listStyle: "none",
                          overflow: "auto",
                          maxWidth: 525,
                          paddingTop: 15,
                          paddingBottom: 15,
                        }}
                      >
                        {selectedEstimates.map((data) => {
                          let estimateData = estimates.filter(estimate => estimate._id == data);
                          return (
                            <Chip
                              variant="outlined"
                              size="small"
                              key={data.key}
                              label={estimateData[0].code}
                              onDelete={() => handleDelete(data)}
                              color="primary"
                              style={{ marginRight: 5 }}
                            />
                          )
                        })}
                      </div>
                      : null}
                  </div>
                  : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </CommonDialog>
  )
}

const RateImportModal = ({ onClose, fetchRecord, objectId, version, activeItem }) => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedObjectWithItems, setSelectedObjectWithItems] = useState(null);
  const [estimates, setEstimates] = useState([]);
  const [selectedEstimates, setSelectedEstimates] = useState([]);
  const [selectedObjectVersion, setSelectedObjectVersion] = useState(null);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    const projects = await projectService.list({ lite: true });
    setProjects(projects);
  }

  const getObjects = async (projectId) => {
    if (!projectId) return;
    const objects = await objectService.list({ projectId });
    setSelectedObjectVersion(null);
    setSelectedObjectWithItems(null);
    setObjects(objects);
  }

  const getEstimates = async (objectId) => {
    if (!objectId) return;
    let items = [];
    const checkChildItemType = (item) => {
      if (['chapter', 'subchapter', 'part'].includes(item.type)) items.push(item);
      item.nodes.forEach(node => {
        checkChildItemType(node);
      })
    }
    const estimates = await objectService.show(objectId);
    setSelectedObjectWithItems(estimates);
    setSelectedObjectVersion(estimates.version.code);
    const estimateItems = estimates.items;
    estimateItems.map(item => {
      items.push(item);
      item.nodes.forEach(itemNode => {
        checkChildItemType(itemNode);
      })
    })
    setEstimates(items);
  }

  const onChange = ({ name, value }) => {
    if (name == 'project') {
      setSelectedObject(null);
      setSelectedEstimates([]);
      setSelectedProject(value);
      getObjects(value);
    }

    if (name == 'object') {
      setSelectedEstimates([]);
      setSelectedObject(value);
      getEstimates(value);
    }

    if (name == 'estimates') {
      setSelectedEstimates(value);
    }
  };

  const onEstimatesSelect = (selectedEstimateIdArray) => {
    selectedEstimateIdArray.length < 1 ? setSelectedEstimates([]) : setSelectedEstimates(selectedEstimateIdArray);
  }

  const handleImport = async () => {
    try {
      if (selectedEstimates.length == 0) {
        toast(t('Nepasirinkta sąmata'), 'error');
        return;
      }
      if (version != selectedObjectVersion) {
        if (!window.confirm(t('Pasirinktas objektas apskaičiuotas kito laikotarpio kainų versija. Ar pakeisti versiją?'))) return;
      }

      // Remove rates and resources from selected, and sorts for parent id assignment
      let transferableIds = [];
      estimates.map(estimate => selectedEstimates.includes(estimate._id) ? transferableIds.push(estimate._id) : null);

      await api.post(`/estimates/${objectId}/import-estimate-items`, { estimates: transferableIds, versionChanged: version != selectedObjectVersion, itemId: activeItem._id, itemType: activeItem.type });
      toast(t('Sąmatos įkeltos'), 'success');
      await fetchRecord(objectId);
      onClose();
    } catch (err) {
      console.log(err);
      toast(t('Klaida įkeliant sąmatas'), 'error');
    }
  }

  const renderProjectField = ({ name, ...field }) => {
    return (
      <AutoComplete
        {...field}
        name={name}
        freeSolo={true}
        creatable={false}
        value={selectedProject}
        style={{ height: '32px', marginTop: '8px' }}
        onChange={(newValue) => onChange({ name: 'project', value: newValue.value })}
      />
    );
  }

  const renderObjectField = ({ name, ...field }) => {
    return (
      <AutoComplete
        {...field}
        name={name}
        freeSolo={true}
        creatable={false}
        disabled={!selectedProject}
        value={selectedObject}
        style={{ height: '32px', marginTop: '8px' }}
        onChange={(newValue) => onChange({ name: 'object', value: newValue._id })}
      />
    );
  }

  return (
    <CommonDialog
      visible
      onClose={onClose}
      maxWidth="md"
      style={{ position: 'relative' }}
      actions={[
        <Button
          color="default"
          variant="text"
          onClick={() => onClose()}
        >
          {t("Atšaukti")}
        </Button>,
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleImport()}
        >
          {t("Vykdyti")}
        </Button>
      ]}
    >
      <div>
        <Table style={{ maxWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{`${t('Pasirinkite objektą įkėlimui')} ${activeItem.code}`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {renderProjectField({
                  name: 'project',
                  label: t('Projektas'),
                  options: projects.map((project) => ({ key: project._id, value: project._id, title: project.code ? `${project.code} - ${project.title}` : project.title }))
                })}
                <br />
                {renderObjectField({
                  name: 'object',
                  label: t('Skaičiavimų objektas'),
                  options: objects,
                })}
              </TableCell>
            </TableRow>
            {!!selectedObjectWithItems ?
              <TableRow>
                <TableCell>
                  <EstimateTreeTableSelect
                    key={selectedObjectWithItems._id}
                    record={selectedObjectWithItems}
                    onEstimatesSelect={onEstimatesSelect}
                  />
                </TableCell>
              </TableRow>
              : null}
          </TableBody>
        </Table>
      </div>
    </CommonDialog>
  )
}

const ItemPrintModal = ({ onClose, objectId, itemId, onPrint }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const reports = [
    {
      title: `${t('Lokalinė sąmata (suminė pagal skyrius)')}`, //LS1
      type: 'localEstimateChapters',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    // {
    //   title: `${t('Lokalinė sąmata (suminė pagal skyrius su darbų sudėtimis)')}`, //LS11
    //   type: 'localEstimateChaptersWithWorkComposition',
    //   params: ['id', 'SamCode'],
    //   values: [objectId, itemId],
    // },
    {
      title: `${t('Lokalinė sąmata (pagal skyrius-įkainius su priskaitymais)')}`, //LS2
      type: 'localEstimateAdditions',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    // {
    //   title: `${t('Lokalinė sąmata (pagal skyrius-įkainius su priskaitymais ir darbų sudėtimis)')}`, //LS21
    //   type: 'localEstimateAdditionsWithWorkComposition',
    //   params: ['id', 'SamCode'],
    //   values: [objectId, itemId],
    // },
    {
      title: `${t('Lokalinė sąmata (pagal skyrius-įkainius)')}`, //LS3
      type: 'localEstimateRates',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    // {
    //   title: `${t('Lokalinė sąmata (pagal skyrius-įkainius su darbų sudėtimis)')}`, //LS31
    //   type: 'localEstimateRatesWithWorkComposition',
    //   params: ['id', 'SamCode'],
    //   values: [objectId, itemId],
    // },
    {
      title: `${t('Lokalinė sąmata (pagal skyrius-įkainius su resursais)')}`, //LS4
      type: 'localEstimateResources',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    // {
    //   title: `${t('Lokalinė sąmata (pagal skyrius-įkainius su resursais ir darbų sudėtimis)')}`, //LS41
    //   type: 'localEstimateResourcesWithWorkComposition',
    //   params: ['id', 'SamCode'],
    //   values: [objectId, itemId],
    // },
    {
      title: `${t('Lokalinė sąmata (suminė pagal skyrius su tiesioginėmis ir netiesioginėmis išlaidomis)')}`, //LS5
      type: 'totalsLocalObjectEstimateWithExpenses',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    {
      title: `${t('Medžiagų žiniaraštis (sąmatai)')}`,
      type: 'materialReportForEstimate',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    {
      title: `${t('Medžiagų žiniaraštis (pagal sąmatos skyrius)')}`,
      type: 'materialReportForChapter',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    {
      title: `${t('Mechanizmų žiniaraštis (sąmatai)')}`,
      type: 'mechanismReportForEstimate',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    {
      title: `${t('Mechanizmų žiniaraštis (pagal sąmatos skyrius)')}`,
      type: 'mechanismReportForChapter',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    {
      title: `${t('Įrenginių žiniaraštis (sąmatai)')}`,
      type: 'equipmentReportForEstimate',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
    {
      title: `${t('Darbo užmokesčio žiniaraštis (sąmatai)')}`,
      type: 'salaryReportForEstimate',
      params: ['id', 'SamCode'],
      values: [objectId, itemId],
    },
  ];

  const handlePrint = async ({ type, params, values, fileType }) => {
    setLoading(true);

    try {
      await onPrint({
        type,
        params,
        values,
        fileType,
      });
    } catch (err) {
      console.error(err);
      window.alert(t('Įvyko klaida'));
    }

    setLoading(false);

    onClose();
  };

  return (
    <CommonDialog
      visible
      onClose={onClose}
      maxWidth="md"
      style={{ position: 'relative' }}
    // title={t('Spausdinimas')}
    >
      <div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Formos pavadinimas')}</TableCell>
              <TableCell colSpan={2} style={{ textAlign: 'center' }}>{t('Formatas')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map(({ title, type, ...rest }) => (
              <TableRow key={type}>
                <TableCell>{title}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Button disabled={loading} variant="outlined" color="primary" onClick={() => handlePrint({ fileType: 'PDF', type, ...rest })}>
                    PDF
                  </Button>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Button disabled={loading} variant="outlined" color="primary" onClick={() => handlePrint({ fileType: 'XLSX', type, ...rest })}>
                    XLSX
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </div>
    </CommonDialog>
  )
};

const InsertModal = () => {
  // TODO show insert options in modal instead of dropdown menu
  return (
    <pre>ASDASDASDSDASDA</pre>
  )
}

export default EstimateObjectForm;