export default (theme) => ({
  root: {},
  wrap: {
    padding: 0,
    maxWidth: '1200px',
    position: 'relative',
  },
  tableWrap: {
    background: '#ffffff',
  },
  stickyTableWrap: {
    background: '#ffffff',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 12,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',    

    '& thead tr': {
      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& tbody tr': {
      '&:not(.no-hover):hover': {
        backgroundColor: '#d9d9d9',
        cursor: 'pointer',
      },

      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& input[type="number"], input[type="text"]': {
      textAlign: 'right',
      border: '1px solid #316ac5',
      borderRadius: '4px',
      margin: '2px 0px',

      '&[disabled]': {
        border: '1px solid #cccccc',
      }
    },

    '& input[type="text"]': {
      borderColor: '#c4c4c4',
      padding: '6px 6px 7px',
      color: '#12161b',
      textAlign: 'left',
    },
  },
  firstColumn: {
    position: 'relative',
    // whiteSpace: 'nowrap',
    // verticalAlign: 'top',
  },
  expander: {
    left: '4px',
    border: '1px solid black',
    padding: 0,
    zIndex: 999,
    position: 'absolute',
    background: 'white',
    width: '13px',
    height: '13px',
    lineHeight: '13px',
    textAlign: 'center',
    marginTop: 1,
  },
  spacer: {
    display: 'inline-block',
    width: 10,
  },
  expandedRow: {
    fontWeight: 'bold',
  },
  detailsRow: {
    backgroundColor: '#eee',

    '&:hover': {
      backgroundColor: '#eee !important',
    },
  },
  parametersHeaderRow: {
    textAlign: 'center',
    backgroundColor: '#eee',
    fontWeight: 'bold',
  },
  parameterCode: {
    textDecoration: 'underline',
    color: 'blue',
  },
  textRight: {
    textAlign: 'right',
  },
  textCenter: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  rowPriceTotal: {
    fontWeight: 'bold',
  },
  loaderWrap: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    background: '#f9fafb',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.8,
  },
  dialogContentWrap: {
    padding: theme.spacing(),
  },
  exportDialogText: {
    marginBottom: '10px',
    marginLeft: '16px',
  },
  inputError: {
    '& input': {
      borderColor: 'red !important',
    },
  },
  iconLabelWrapper: {
    flexDirection: "row-reverse",
  },
  labelContainer: {
    width: "auto",
    padding: 0
  },
});