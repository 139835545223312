import { observable, action, decorate } from 'mobx';
import _ from 'lodash';
import { getAll as getAllAccounts, addLicence, removeLicence } from 'services/accounts';
import { getServices, getOrders, getServicePackages } from 'services/companies';

class Store {
  // Observable
  data = {
    servicePackages: [],
    services: [],
    accounts: [],
    orders: [],
  }

  // Observable
  company = null;

  // Observable
  account = null;

  // Observable
  orderItemDetails = null;

  // Observable
  loading = false

  // Action
  reset = () => {
    this.data = {
      servicePackages: [],
      services: [],
      accounts: [],
      orders: [],
    };
    this.loading = false;
    this.company = null;
    this.account = null;
    this.orderItemDetails = null;
  }

  // Action
  setData = data => {
    this.data = data;
  }

  // Action
  setAccount = data => {
    this.account = data;
  }

  // Action
  setLoading = (loading = true) => {
    this.loading = loading;
  }

  // Action
  setCompany = data => {
    this.company = data;
  }

  // Action
  setOrderItemDetails = _id => {
    this.orderItemDetails = this.getOrderItem(_id);
  }

  getOrderItem = _id => {
    return _.find(this.data.servicePackages, { _id });
  }

  createAccountLicence = async ({ service, orderItem }) => {
    const account = this.account._id;
    const order = this.orderItemDetails.order._id; 

    const data = { account, order, service, orderItem };
    await addLicence(account, data);

    await this.loadData();
  }

  removeAccountLicence = async (accountId, licenceId) => {
    await removeLicence(accountId, licenceId);
    
    await this.loadData();
  }

  loadData = async () => {
    this.setLoading();

    const accounts = await getAllAccounts({ licences: true });
    
    this.setData({
      ...this.data,
      accounts,
    });

    this.setLoading(false);
  }

  loadServices = async () => {
    if (!this.company) {
      console.log('Company not set');
      return;
    }

    this.setLoading();

    const services = await getServices(this.company._id);
    this.setData({ ...this.data, services });

    this.setLoading(false);
  }

  loadServicePackages = async (service) => {
    if (!this.company) {
      console.log('Company not set');
      return;
    }

    this.setLoading();

    this.setOrderItemDetails(null);

    const servicePackages = await getServicePackages(this.company._id, service);
    this.setData({ ...this.data, servicePackages });

    this.setLoading(false);
  }

  loadOrders = async (service) => {
    if (!this.company) {
      console.log('Company not set');
      return;
    }

    this.setLoading();

    const orders = await getOrders(this.company._id, { service });
    this.setData({ ...this.data, orders });

    this.setLoading(false);
  }

  accountHasService = (serviceId) => {
    const { account } = this;
    
    if (!account.licences) return false;

    for (const licence of account.licences) {
      if (licence.service._id === serviceId) {
        return true;
      }
    }

    return false;
  }
}

decorate(Store, {
  data: observable,
  company: observable,
  account: observable,
  orderItemDetails: observable,
  loading: observable,
  reset: action,
  setData: action,
  setOrderItemDetails: action,
  setCompany: action,
  serAccount: action,
  setLoading: action,
});

export default new Store;