export default theme => ({
  root: {},
  field: {
    margin: theme.spacing(3)
  },
  customTextField: {
    width: '100%',
    maxWidth: '100%',
    marginRight: 0
  },
  textField: {
    width: '80px',
    maxWidth: '100%',
    marginRight: 0
  },
  boldCell: {
    fontWeight: 'bold',
  },
  coefHeaderCell: {
    width: '120px',
  },
  customCell: {
    padding: '6px 5px',
  }
});
