import React from 'react';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Box,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';


import styles from './styles';

class CommonDialog extends React.Component {

  render() {
    const { visible, children, title, actions, onClose, maxWidth = 'sm', fullWidth = true, scroll = 'paper', showClose, classes, disableBackdropClick, loading } = this.props;

    return (
      <Dialog
        open={visible}
        onClose={onClose ? () => onClose() : undefined}
        aria-labelledby="common-dialog-title"
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        scroll={scroll}
        disableBackdropClick={disableBackdropClick}
      >
        {showClose && !!onClose && (
          <div className={classes.closeBtn}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}

        {title ? <DialogTitle id="common-dialog-title">{title}</DialogTitle> : null}
        
        <DialogContent dividers={scroll === 'paper'} tabIndex={-1}>
          {children}
        </DialogContent>

        { actions ? <DialogActions>{actions}</DialogActions> : null}
      </Dialog>
    )
  }
}

export default withStyles(styles)(CommonDialog);