import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withStyles, TextField, MenuItem } from '@material-ui/core';
import { CommonPanel } from 'components';
import NumberFormat from 'react-number-format';

import styles from './styles';

import BuildingTypePicker from 'components/NtkFilters/components/BuildingTypePicker';

class SiteDetails extends Component {
  onChange(e) {
    const { handleChange, versions } = this.props;
    const data = {
      name: e.target.name,
      value: e.target.value
    };

    // Set object as pricing value
    if (data.name === 'pricing') {
      data.value = _.find(versions, { code: data.value }) || '';
    }

    handleChange(data);
  }

  componentDidUpdate() {
    const { pricing, versions, handleChange, type } = this.props;

    if (!pricing && versions.length > 0) {
      handleChange({
        name: 'pricing',
        value: _.first(versions)
      });
    }

    if (!type) {
      handleChange({
        name: 'type',
        value: 'NTK pastatai'
      });
    }
  }

  renderUserInputs() {
    const {
      classes,
      title,
      description,
      unit,
      amount,
      pricing,
      type,
      buildingType,
      t
    } = this.props;

    if (!pricing || !type || !buildingType) {
      return;
    }

    return (
      <div>
        <NumberFormat
          required
          fullWidth
          customInput={TextField}
          allowNegative={false}
          isNumericString
          className={classes.textField}
          label={
            <span>
              {t('Įveskite kiekį')}, {unit || '-'}
            </span>
          }
          onBlur={this.onChange.bind(this)}
          onFocus={e => e.target.select()}
          onKeyDown={({ key, target }) => {
            if (key == 'Enter') {
              target.blur();
            }
          }}
          name="amount"
          value={amount}
          margin="dense"
          variant="outlined"
        />

        <TextField
          fullWidth
          // multiline
          className={classes.textField}
          label={t('Skaičiavimų objekto pavadinimas')}
          onBlur={this.onChange.bind(this)}
          onChange={this.onChange.bind(this)}
          onKeyDown={({ key, target }) => {
            if (key == 'Enter') {
              target.blur();
            }
          }}
          name="title"
          value={title}
          margin="dense"
          variant="outlined"
        />

        <TextField
          fullWidth
          // multiline
          className={classes.textField}
          label={t('Pastabos')}
          onBlur={this.onChange.bind(this)}
          onChange={this.onChange.bind(this)}
          onKeyDown={({ key, target }) => {
            if (key == 'Enter') {
              target.blur();
            }
          }}
          name="description"
          value={description}
          margin="dense"
          variant="outlined"
        />
      </div>
    );
  }

  render() {
    const {
      classes,
      types,
      versions,
      hierarchy,
      type,
      buildingType,
      pricing,
      handleChange,
      style,
      t
    } = this.props;

    return (
      <div style={style}>
        <CommonPanel title={t('Statinio duomenys')} noPadding>
          <form
            autoComplete="off"
            noValidate
            onSubmit={e => e.preventDefault()}>
            <div className={classes.field}>
              <TextField
                // disabled
                required
                fullWidth
                select
                className={classes.textField}
                label={t('Statinių paskirties grupė')}
                onChange={this.onChange.bind(this)}
                name="type"
                value={type}
                margin="dense"
                variant="outlined">
                {_.map(types, type => (
                  <MenuItem key={type.code} value={type.code}>
                    {type.title}
                  </MenuItem>
                ))}
              </TextField>

              {versions.length > 0 ? (
                <div>
                  <TextField
                    required
                    fullWidth
                    select
                    className={classes.textField}
                    label={t('Kainyno versija')}
                    onChange={this.onChange.bind(this)}
                    name="pricing"
                    value={pricing ? pricing.code : ''}
                    margin="dense"
                    variant="outlined"
                  >
                    {_.map(versions, version => (
                      <MenuItem key={version.code} value={version.code}>
                        {version.version}
                      </MenuItem>
                    ))}
                  </TextField>

                  <BuildingTypePicker
                    hierarchy={hierarchy}
                    buildingType={buildingType}
                    name="buildingType"
                    handleChange={handleChange}
                  />

                  {this.renderUserInputs()}
                </div>
              ) : null}
            </div>
          </form>
        </CommonPanel>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(SiteDetails);
