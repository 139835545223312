import React, { Component } from 'react';
import {
  withStyles,
  Dialog,
  DialogTitle,
} from '@material-ui/core';

import styles from './styles';

class FormDialog2 extends Component {

  handleClose(wasSubmitted, value) {
    const { onClose } = this.props;

    if (onClose) {
      onClose(wasSubmitted, value);
    } else {
      console.log('Modal close handler not provided');
    }
  }

  handleSubmit(value) {
    // `true` flag identifies that form was submitted
    this.handleClose(true, value);
  }

  render() {
    const { visible, component, title } = this.props;

    // Additional props to bind with form actions
    const additionalProps = {
      onSubmit: this.handleSubmit.bind(this),
      onCancel: this.handleClose.bind(this),
    };

    return (
      <Dialog
        open={visible}
        onClose={this.handleClose.bind(this)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        {title ? (<DialogTitle id="form-dialog-title">{title}</DialogTitle>) : null}
        {React.cloneElement(component, additionalProps)}
      </Dialog>
    )
  }
}

export default withStyles(styles)(FormDialog2);