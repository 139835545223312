import React from 'react';
import { compose } from 'recompose';
import {
  withStyles,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import { CommonPanel, Form } from 'components';
import BuildingTypePicker from 'components/NtkFilters/components/BuildingTypePicker';

import styles from './styles';

class DetailsBlock extends Form {

  render() {
    const { store } = this.props;
    const { options, record } = store;

    return (
      <CommonPanel
        title="Statinio duomenys"
        noPadding
      >
        
        <div style={{ margin: 8 }}>
          {this.renderFormField({ name: 'version', label: 'Versija', type: 'select', options: options.versions })}

          {record.version && (
            <BuildingTypePicker
              hierarchy={options.buildingTypes}
              buildingType={record.buildingType}
              name="buildingType"
              handleChange={this.onChange.bind(this)}
            />
          )}
        </div>

      </CommonPanel>
    )
  }
}

export default compose(withStyles(styles), inject('store'))(observer(DetailsBlock));