import api from 'utils/api';

const servicePath = '/tree-templates';

export const getAll = async (params) => {
  // const response = await api.get(servicePath, { params });
  // const records = response.data;

  // return records;
  return [];
}

export const getOne = async (id) => {
  // const response = await api.get(`${servicePath}/${id}`);
  // const record = response.data;

  // return record;
  return {};
}

export const getTree = async (id) => {
  const record = [
    {
      treeCode: 'abc123',
      title: 'Elementas 1',
      hasNodes: true,
      nodes: [
        {
          treeCode: 'abc124',
          title: 'Elementas 1-1',
          hasNodes: false,
        },
        {
          treeCode: 'abc120',
          title: 'Elementas 1-2',
          hasNodes: false,
        },
        {
          treeCode: 'abc125',
          title: 'Elementas 1-3',
          hasNodes: false,
        },
      ]
    },
    {
      treeCode: 'abc126',
      title: 'Elementas 2',
      hasNodes: false,
    },
    {
      treeCode: 'abc127',
      title: 'Elementas 3',
      hasNodes: true,
      nodes: [
        {
          treeCode: 'abc128',
          title: 'Elementas 3-1',
          hasNodes: true,
          nodes: [
            {
              treeCode: 'abc129',
              title: 'Elementas 3-1-1',
              hasNodes: true,
            },
            {
              treeCode: 'abc130',
              title: 'Elementas 3-1-2',
              hasNodes: false,
            },        
          ]
        },
        {
          treeCode: 'abc131',
          title: 'Elementas 3-2',
          hasNodes: false,
        },        
      ]
    }
  ];

  // const response = await api.get(`${servicePath}/${id}`);
  // const record = response.data;

  // return record;
  return record;
}

export const createOne = async (details) => {
  // const response = await api.post(servicePath, details);
  // const record = response.data;

  // return record;
  return {};
}

export const updateOne = async (details) => {
  // const response = await api.put(`${servicePath}/${details._id}`, details);
  // const record = response.data;

  // return record;
  return {};
}
