import permissionConstants from './_constants';

const initialState = {
  // Shared props
  isLoading: false,
  error: null,

  //  List of items
  list: {
    limit: 10,
    permissions: [],
    selectedPermissions: [],
  },

  // Currently viewed item
  single: {
    permission: null,
  }
};

export const permissions = (state = initialState, action) => {
  switch (action.type) {
    case permissionConstants.UPDATE_REQUEST:
    case permissionConstants.CREATE_REQUEST:
    case permissionConstants.GETONE_REQUEST:
    case permissionConstants.GETALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case permissionConstants.GETALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: {
          ...state.list,
          permissions: action.permissions,
        }
      };
    case permissionConstants.CREATE_FAILURE:
    case permissionConstants.GETONE_FAILURE:
    case permissionConstants.GETALL_FAILURE:
    case permissionConstants.UPDATE_FAILURE:
      return { 
        ...state,
        isLoading: false,
        error: action.error,
      };
    case permissionConstants.GETONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        single: {
          ...state.single,
          permission: action.permission,
        }
      }
    case permissionConstants.CREATE_SUCCESS:
    case permissionConstants.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case permissionConstants.RESET_FORM:
      return {
        ...state,
        isLoading: false,
        single: {
          ...initialState.single,
        }
      }
    default:
      return state
  }
}