import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  Tab,
  Tabs,
  makeStyles,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import {
  CommonDialog,
  NormPriceComposition,
  NormCoefficients,
  EstimatePriceSettings,
  RichTextEditor,
} from 'components';
import api from 'utils/api';

const TABS = {
  PRICE_COMPOSITION: 'price_composition',
  COEFFICIENTS: 'coefficients',
  PRICE_SETTINGS: 'price_settings',
  JOB_COMPOSITION: 'job_composition',
}

const allowedToEditIfRate = [
  'k9',
  'k8',
  'k11',
  'k21',
  'k31',
  'k51',
];

const allowedToEditIfNotRate = [
  'pdu',
  'sd',
  'pmed',
  'pmech',
  'sti',
  'pr',
  'p',
  'k11',
  'k21',
  'k31',
  'k51',
  'pvm',
];

const useStyles = makeStyles({
  root: {},
  filePickerTableContainer: {
    maxWidth: 800,
  },
  footerWrap: {
    marginTop: 40,
  }
})

const ItemSummaryModal = ({ objectId, itemId, onClose, onChange, initialTab = TABS.PRICE_COMPOSITION }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(initialTab);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [title, setTitle] = useState(null);
  const [isRate, setIsRate] = useState(false);
  const [hadChanges, setHadChanges] = useState(false);
  const [priceSettings, setPriceSettings] = useState(null);
  const [coefficients, setCoefficients] = useState(null);
  const [workComposition, setWorkComposition] = useState();

  const fetchItem = async () => {
    setLoading(true);

    try {
      const { data } = await api.get(`/estimates/${objectId}/items/${itemId}/title-summary`);
      setItem({ ...data });
      setIsRate(data.type === 'rate');
      setTitle(<><b>{data.code}</b> {data.title}</>);

      const { data: activeTabData } = await api.get(`/estimates/${objectId}/items/${itemId}/partial-summary/${activeTab}`);
      if (activeTab === TABS.JOB_COMPOSITION) {
        setWorkComposition(activeTabData?.workComposition);
      }
      if (activeTab === TABS.PRICE_COMPOSITION) {
        setItem({ ...data, totals: activeTabData.totals });
      }
      if (activeTab === TABS.COEFFICIENTS) {
        const coefficients = activeTabData?.coefficients ? activeTabData.coefficients : [];
        const coefficientsSpec = activeTabData?.coefficientsSpec ? activeTabData.coefficientsSpec : [];
        const customCoefficients = activeTabData?.customCoefficients ? activeTabData.customCoefficients : [];
  
        setCoefficients({ coefficients, coefficientsSpec, customCoefficients });
        setItem({ ...data, coefficients, coefficientsSpec, customCoefficients });
      }
      if (activeTab === TABS.PRICE_SETTINGS) {
        setPriceSettings(activeTabData.priceSettings);
        setItem({ ...data, priceSettings: activeTabData.priceSettings});
      }
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!item) fetchItem();
  }, []);

  const handleClose = () => {
    onClose(hadChanges);
  };

  const handleTabChange = async (event, tabCode) => {
    if (tabCode === TABS.PRICE_SETTINGS && !item.priceSettings) {
      const { data } = await api.get(`/estimates/${objectId}/items/${itemId}/partial-summary/${tabCode}`);
      setPriceSettings(data.priceSettings);
      setItem({ ...item, priceSettings: data.priceSettings});
    }
    if (tabCode === TABS.COEFFICIENTS && !item.coefficients && !item.coefficientsSpec && !item.customCoefficients) {
      const { data } = await api.get(`/estimates/${objectId}/items/${itemId}/partial-summary/${tabCode}`);
      const coefficients = data?.coefficients ? data.coefficients : [];
      const coefficientsSpec = data?.coefficientsSpec ? data.coefficientsSpec : [];
      const customCoefficients = data?.customCoefficients ? data.customCoefficients : [];

      setCoefficients({ coefficients, coefficientsSpec, customCoefficients });
      setItem({ ...item, coefficients, coefficientsSpec, customCoefficients });
    }      
    if (tabCode === TABS.JOB_COMPOSITION && !item.workComposition) {
      const { data } = await api.get(`/estimates/${objectId}/items/${itemId}/partial-summary/${tabCode}`);
      setWorkComposition(data?.workComposition);
      setItem({ ...item, workComposition: data?.workComposition });
    }
    if (tabCode === TABS.PRICE_COMPOSITION && !item.totals) {
      const { data } = await api.get(`/estimates/${objectId}/items/${itemId}/partial-summary/${tabCode}`);
      setItem({ ...item, totals: data.totals });
    }
    setActiveTab(tabCode);
    return;
  };

  const onPriceSettingsChange = async (priceSettings) => {
    setPriceSettings([...priceSettings]);
  };

  const onCoefficientsChange = async ({
    coefficients = [],
    coefficientsSpec = [],
    customCoefficients = [],
  }) => {
    const coeffs = {
      coefficients,
      coefficientsSpec,
      customCoefficients,
    };

    setCoefficients(coeffs);
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (activeTab === TABS.PRICE_SETTINGS) {
      try {
        await api.put(`/estimates/${objectId}/items/${itemId}/price-settings`, priceSettings);

        setHadChanges(true);

        await fetchItem();
      } catch (err) {
        console.error(err);
      }
    } else if (activeTab === TABS.COEFFICIENTS) {
      try {
        await api.put(`/estimates/${objectId}/items/${itemId}/coefficients`, coefficients);

        setHadChanges(true);

        await fetchItem();
      } catch (err) {
        console.error(err);
      }
    } else if (activeTab === TABS.JOB_COMPOSITION) {
      try {
        await api.put(`/estimates/${objectId}/items/${itemId}`, {
          value: workComposition,
          field: 'workComposition',
          onlyField: true,
        });

        setHadChanges(true);

        await fetchItem();
      } catch (err) {
        console.error(err);
      }
    } else {
      window.alert('Unknown type ' + activeTab);
    }

    setLoading(false);
  }

  const actions = (
    <>
      {[TABS.COEFFICIENTS, TABS.PRICE_SETTINGS, TABS.JOB_COMPOSITION].includes(activeTab) && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          {activeTab === TABS.COEFFICIENTS && t('Perskaičiuoti su koeficientais')}
          {activeTab === TABS.PRICE_SETTINGS && t('Perskaičiuoti su kainos nustymais')}
          {activeTab === TABS.JOB_COMPOSITION && t('Išsaugoti darbų sudėtį')}
        </Button>
      )}

      <Button
        color="primary"
        onClick={handleClose}
      >
        Uždaryti
      </Button>
    </>
  );

  return (
    <CommonDialog
      visible
      onClose={handleClose}
      maxWidth="md"
      style={{ position: 'relative' }}
      showClose
      actions={actions}
      disableBackdropClick
    >
      {title && (
        <Typography variant="body1">
          {title}
        </Typography>
      )}

      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab label="Kainos sudėtis" value={TABS.PRICE_COMPOSITION} />
        <Tab label="Darbų sudėtis" value={TABS.JOB_COMPOSITION} />
        {isRate && <Tab label="Koeficientai" value={TABS.COEFFICIENTS} />}
        <Tab label="Darbo kainos nustatymai" value={TABS.PRICE_SETTINGS} />
      </Tabs>

      <div style={{ minHeight: '450px', marginTop: '10px' }}>
        {activeTab === TABS.PRICE_COMPOSITION && <NormPriceComposition hideAdditionals={!isRate} item={item} />}
        {activeTab === TABS.JOB_COMPOSITION && (
          // <TextField
          //   variant="outlined"
          //   label={t('Darbų sudėties redagavimas')}
          //   value={workComposition || ''}
          //   multiline
          //   rows={30}
          //   onChange={(e) => setWorkComposition(e.currentTarget.value)}
          //   fullWidth
          // />
          <RichTextEditor
            placeholder={`${t('Įveskite darbų sudėties aprašymą')}...`}
            value={workComposition}
            onChange={(html) => setWorkComposition(html)}
          />
        )}
        {activeTab === TABS.COEFFICIENTS && isRate && <NormCoefficients onChange={onCoefficientsChange} item={item} />}
        {activeTab === TABS.PRICE_SETTINGS && <EstimatePriceSettings isRate={isRate} onChange={onPriceSettingsChange} priceSettings={priceSettings} />}
      </div>
    </CommonDialog>
  )
}

export default ItemSummaryModal;