import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';

// Material icons
import {
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@material-ui/icons';

// Component styles
import styles from './styles';

class NotificationList extends Component {
  render() {
    const { className, classes, notifications, onSelect, t } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        {notifications.length > 0 ? (
          <Fragment>
            <div className={classes.content}>
              <List component="div">
                {notifications.map(notification => (
                  <Link
                    key={notification._id}
                    to={notification.link}
                  >
                    <ListItem
                      className={classes.listItem}
                      component="div"
                      onClick={() => onSelect({ notificationId: notification._id})}
                    >
                      <ListItemText
                        classes={{ secondary: classes.listItemTextSecondary }}
                        primary={notification.status == 'new' ? (
                          <strong>{notification.content}</strong>
                        ) : notification.content}
                        // secondary={notification.status}
                      />
                      <ArrowForwardIosIcon className={classes.arrowForward} />
                    </ListItem>
                    {/* <Divider /> */}
                  </Link>
                ))}
              </List>
            </div>
          </Fragment>
        ) : (
          <div className={classes.empty}>
            <Typography variant="h6">
              {t('Nėra pranešimų')}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

NotificationList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  onSelect: PropTypes.func
};

NotificationList.defaultProps = {
  notifications: [],
  onSelect: () => {}
};

export default compose(withStyles(styles), withTranslation())(NotificationList);
