import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
const useStyles = makeStyles({
  box: {
    marginTop: '10px',

    '& table': {
      backgroundColor: 'white',
    },
    '& tfoot': {
      backgroundColor: '#eee',
    },
    '& tfoot tr td': {
      borderColor: '#eee !important',
    },
    '& .MuiTypography-caption': {
      color: '#44505c',
    },
  },
});

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, padding = 0, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.box} p={padding}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

export default TabPanel;