export default theme => ({
  root: {
    width: '100%',
    // overflowX: 'auto',
  },
  // table: {
  //   minWidth: 650,
  //   '& tr': {
  //     height: 'auto',
  //     '& .MuiCheckbox-root': {
  //       padding: 0,
  //     },
  //     '& th': {
  //       fontSize: '12px',
  //     },
  //   },
  // },
  textCenter: {
    textAlign: 'center',
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
});