export default () => ({
  root: {
    marginTop: '20px',
  },
  table: {

    '& td, th': {
      border: '1px solid #DFE3E8',

      '&.actions': {
        border: '1px solid #ffffff',
      }
    }
  },
  priceTable: {
    '& td': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  textRight: {
    textAlign: 'right',
  },
  numberInput: {
    margin: 0,
  },
});
