import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  withStyles,
  Button,
  Grid,
} from '@material-ui/core';
import { observer, Provider } from 'mobx-react';

import { FormField2, CommonPanel } from 'components';
import Services from 'views/Services';
import ServiceRules from './components/ServiceRules';
import ServicePackages from './components/ServicePackages';

import { toast } from 'utils/helpers';
import styles from './styles';

class Container extends React.Component {

  componentDidMount() {
    const { match, store } = this.props;

    if (match && match.params.id) {
      store.getOne(match.params.id);
    }

    this.initLoading();
  }

  async initLoading() {
    await this.props.store.loadOptions();
    await this.props.store.loadData();
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  onChange({ name, value }) {
    const { store } = this.props;

    this.props.store.setRecord({
      ...store.record,
      [name]: value,
    });
  }

  onDelete() {
    const { t } = this.props;
    if (!window.confirm(t('Ar tikrai norite pašalinti?'))) {
      return;
    }

    this.props.store.remove();
  }

  submitForm() {
    const { store, t } = this.props;

    if (!store.record || (!store.record.code || !store.record.title)) {
      toast(t('Kodas ir pavadinimas turi būti užpildyti'), 'warning');
      return;
    }

    if (store.record._id) {
      store.update();
    } else {
      store.create();
    }
  }

  renderFormField({ name, ...field }) {
    const { record } = this.props.store;
    
    return (
      <FormField2
        {...field}
        name={name}
        // autoComplete={`project-${name}`}
        key={name}
        value={record ? record[name] : (field.multiple ? [] : '')}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderForm() {
    const { t } = this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item md={6}>
            {this.renderFormField({ name: 'code', label: t('Kodas'), type: 'text' })}

            {this.renderFormField({ name: 'title', label: t('Pavadinimas'), type: 'text' })}

            {this.renderFormField({ name: 'description', label: t('Aprašymas'), type: 'text' })}

            {this.renderFormField({ name: 'isVisible', label: t('Paslaugą galima užsakyti'), type: 'checkbox' })}
          </Grid>
          <Grid item md={3}>
            {this.renderFormField({ name: 'order', label: t('Eiliškumas'), type: 'number' })}
          </Grid>
        </Grid>

        <ServiceRules />

        <ServicePackages />
      </>
    )
  }
  
  render() {
    const { classes, store, t } = this.props;
    const title = store.record._id ? `${t('Paslauga')}: ${store.record.title}` : t('Nauja paslauga');

    return (
      <Services
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Paslaugos'), to: '/services' },
          { title }
        ]}
      >
        <Provider store={this.props.store}>
          <div className={classes.wrap}>
            
            <CommonPanel
              footer={(
                <Grid container>
                  <Grid item xs>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.submitForm.bind(this)}
                    >
                      {t('Išsaugoti')}
                    </Button>
                  </Grid>

                  <Grid item xs style={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      onClick={this.onDelete.bind(this)}
                      className={classes.deleteButton}
                    >
                      {t('Pašalinti')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            >
              {this.renderForm()}
            </CommonPanel>

          </div>
        </Provider>
      </Services>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(Container));