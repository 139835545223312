import React from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  withStyles,
} from '@material-ui/core';

import Companies from 'views/Companies';
import { LicenceManager, CommonPanel } from 'components';
import { getCurrentAccount } from 'utils/helpers';

import styles from './styles';

class Container extends React.Component {
  state = { company: null }
  
  componentDidMount() {
    const { company } = getCurrentAccount();

    this.setState({ company });
  }
  render() {
    const { classes, t } = this.props;
    const title = t('Įmonės paslaugų paketų valdymas');

    return (
      <Companies
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title }
        ]}
      >
        <div className={classes.wrap}>
          <CommonPanel>
            {this.state.company ? <LicenceManager company={this.state.company._id} /> : null}
          </CommonPanel>
        </div>
      </Companies>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(Container));