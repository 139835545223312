import api from 'utils/api';
import { objectToQueryString, toast } from 'utils/helpers';

export const list = async (estimateId, params) => {
  const query = objectToQueryString(params);
  const { data } = await api.get(`/estimates/${estimateId}/items${query ? '?' + query : ''}`);

  return data;
}

export const show = async (estimateId, id) => {
  const { data } = await api.get(`/estimates/${estimateId}/items/${id}`);

  return data;
}

export const create = async (estimateId, details) => {
  try {
    const { data } = await api.post(`/estimates/${estimateId}/items`, details).catch(error => {
      if (error.response.data.error == 'RATE_ITEM_COUNT_OVER_TRIAL_LIMIT') {
        toast('Viršytas sąmatos bandomosios paslaugos įkainių kiekis', 'error');
      }
    });
    return data;

  } catch (err) {
    console.log(err);
  }
}

export const update = async (estimateId, id, details) => {
  const { data } = await api.put(`/estimates/${estimateId}/items/${id}`, details);

  return data;
}

export const remove = async (estimateId, id) => {
  return await api.delete(`/estimates/${estimateId}/items/${id}`);
}

export const extractAsRate = async (estimateId, id) => {
  return await api.put(`/estimates/${estimateId}/items/${id}/to-rate`);
}

export const extractAsEstimate = async (estimateId, id) => {
  return await api.put(`/estimates/${estimateId}/items/${id}/to-estimate`);
}

export const addResourceItemAsRate = async (estimateId, details) => {
  const { data } = await api.post(`/estimates/${estimateId}/items/add-resource-item-as-rate`, details);
  return data;
}