import React, { useState } from 'react';
import {
  IconButton,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';

const MenuCustom = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    buttonText,
    buttonIcon,
    buttonVariant,
    buttonColor,
    startIcon,
    endIcon,
    items = [],
  } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (a, b, c) => {
    setAnchorEl(null);
  };

  const handleItemClick = item => {
    handleClose();
    item.handler();
  }
  
  return (
    <>
      {!!buttonIcon && !startIcon && !endIcon ? (
        <IconButton onClick={handleClick} title={buttonText} variant={buttonVariant} color={buttonColor}>
          {buttonIcon}
        </IconButton>
      ) : (
        <Button onClick={handleClick} variant={buttonVariant} color={buttonColor} startIcon={startIcon} endIcon={endIcon}>
          {buttonText}
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, idx) => (
          <MenuItem key={`menu-item-${idx}`} onClick={() => handleItemClick(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MenuCustom;