import * as dataService from '../../shared/_services';
import { toast } from 'views/App/_actions';
import { history } from 'utils/history';
import {
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  FAILURE,
  SENDING_REQUEST,
  RESET_FORM,
} from './_constants';
const listPath = '/classifier-groups';

export const resetForm = () => (dispatch) => {
  dispatch({ type: RESET_FORM });
}



export const getOne = (id) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const record = await dataService.getOne(id);

    dispatch({ type: CREATE_SUCCESS, record });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }

}

export const updateOne = (details) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    await dataService.updateOne(details);

    // Updated successfully
    dispatch({ type: UPDATE_SUCCESS });
    history.push(listPath);
    toast('Updated successfully', 'success');
  } catch (error) {
    // Update failed
    dispatch({ type: FAILURE });
    toast('Failed to update', 'error');
  }
}

export const createOne = (details) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    await dataService.createOne(details);

    // company created successfully
    dispatch({ type: CREATE_SUCCESS });
    history.push(listPath);
    toast('Created successfully', 'success');
  } catch (error) {
    // company create failed
    dispatch({ type: FAILURE });
    toast('Failed to create', 'error');
  }
}
