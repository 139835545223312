import React, { Component } from 'react';
import _ from 'lodash';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';
import { locales } from 'utils/config';

import styles from './styles';
import { 
  withStyles,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { 
  Language as LanguageIcon,
} from '@material-ui/icons';

class LocaleSwitcher extends Component {
  state = {
    anchorEl: null,
    isOpen: false,
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget, isOpen: true });
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null, isOpen: false });
  }

  setLocale(locale) {
    localStorage.setItem('locale', locale);
    this.props.i18n.changeLanguage(locale);
  }

  render() {
    const { anchorEl, isOpen } = this.state;
    const { classes, activeLocale } = this.props;

    return (
      <div>
        <IconButton
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={this.handleMenu}
        >
          <LanguageIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isOpen}
          onClose={this.handleMenuClose}
        >
          {_.map(locales, (locale) => {
            return (
              <MenuItem
                key={locale}
                onClick={() => this.setLocale(locale)}
                className={activeLocale === locale ? classes.activeItem : null}
              >
                {locale.toUpperCase()}
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
)(LocaleSwitcher);
