import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

// Service worker
import * as serviceWorker from './common/serviceWorker';

// AppWrapper
import AppWrapper from 'views/AppWrapper';

import './i18n';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Suspense fallback="...">
    <AppWrapper />
  </Suspense>
);

serviceWorker.unregister();
