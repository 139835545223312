import _ from 'lodash';
import * as dataService from 'views/PermissionCategories/shared/_services';

const categoryOptions = async () => {
  const records = await dataService.getAll();

  return _.map(records, record => ({ 
    value: record._id, 
    label: record.title,
  }));
};

export default [
  {
    name: 'category',
    label: 'Kategorija',
    type: 'select',
    options: categoryOptions,
  },
  {
    name: 'title',
    label: 'Pavadinimas',
    type: 'text',
  },
  {
    name: 'code',
    label: 'Kodas',
    type: 'text',
  },
  {
    name: 'adminOnly',
    label: 'Matomas tik administratoriui',
    type: 'checkbox',
  },
];