import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { DataTable } from 'components';
import { getAll } from 'services/accountLicenceLogs';
import { toast } from 'utils/helpers';
import { Dashboard as DashboardLayout } from 'layouts';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    maxWidth: '1200px',
  }
}));

const AccountLicenceLogList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  async function loadRecords() {
    setLoading(true);
    
    try {
      const data = await getAll();
      setRecords(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašus'), 'error');
    }

    setLoading(false);
  }

  useEffect(() => {
    loadRecords();
  }, []);

  const prepareTableColumns = () => {
    const columns = [
      'ID',
      t('Vartotojas'),
      t('Paslauga'),
      t('Įmonė'),
      t('Užsakymas'),
      t('Veiksmas'),
      t('Laikas'),
    ];

    return columns;
  }

  const prepareTableData = () => {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(<Link to={`/accounts/${record.account.id}`}>{_.get(record, 'account.user.email')}</Link>);
      row.push(record.serviceTitle);
      row.push(<Link to={`/companies/${record.order.company.id}`}>{_.get(record, 'order.company.name')}</Link>);
      row.push(<Link to={`/orders/${record.order.id}`}>{_.get(record, 'record.order.number')}</Link>);
      row.push(record.action)
      row.push(record.createdAt)
      
      return row;
    });

    return data;
  }

  const columns = prepareTableColumns();
  const rows = prepareTableData();

  return (
    <DashboardLayout 
      isContentLoading={loading}
      title={t('Paskyrų licencijų žurnalas')}      
      breadcrumbs={[
        { title: t('Administravimas') },
        { title: t('Paskyrų licencijų žurnalas') },
      ]}
    >
      <div className={classes.root}>
        <DataTable 
          key={rows.length} // Hack to force component to refresh
          columns={columns}
          tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
          data={rows}
          deleteOne={() => null}
        />
      </div>

    </DashboardLayout>
  )
};

export default AccountLicenceLogList;