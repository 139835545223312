export default theme => ({
  root: {},
  wrap: {
    padding: 0,
    maxWidth: '1200px',
  },
  content: {
    marginTop: theme.spacing(2),
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  accountsPanel: {
    marginTop: theme.spacing(2),
  },
  btnJoin: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  }
});
