import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
} from '@material-ui/core';

import { CommonPanel } from 'components';
import TreeTemplates from 'views/TreeTemplates';
import TreeStructure from './components/TreeStructure';
import TreeTable from './components/TreeTable';
import styles from './styles';

import { getTree } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { treeTemplatesForm } from './_reducers';
store.injectReducer('treeTemplatesForm', treeTemplatesForm);

class Form extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getTree());
  }

  render() {
    const { classes, tree, t } = this.props;

    return (
      <TreeTemplates
        title={t('Struktūros šablonas')}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Duomenų struktūros šablonai'), to: '/tree-templates' },
          { title: t('Struktūros šablonas') }
        ]}
      >
        
        <CommonPanel noPadding className={classes.wrapper}>
          <Grid container spacing={2}>
            <Grid item sm={3}>
              <TreeStructure
                tree={tree}
              />
            </Grid>
            <Grid item sm={9}>
              <TreeTable
                tree={tree}
              />
            </Grid>
          </Grid>
        </CommonPanel>

      </TreeTemplates>
    );
  }
}

const mapStateToProps = ({ treeTemplatesForm, dispatch }) => {
  return { 
    ...treeTemplatesForm,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(Form);