import api from 'utils/api';

const createOne = async (details) => {
  const response = await api.post('/documents', details);
  const { user } = response.data;

  return user;
}

const updateOne = async (details) => {
  const response = await api.put(`/documents/${details._id}`, details);
  const { user } = response.data;

  return user;
}

const getAll = async () => {
  const response = await api.get('/documents');
  const documents = response.data;

  return documents;
}

const getOne = async (id) => {
  const response = await api.get(`/documents/${id}`);
  const document = response.data;

  return document ;
}

export const documentService = {
  createOne,
  updateOne,
  getAll,
  getOne,
}

;