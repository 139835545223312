/* eslint-disable no-undef */
import _ from 'lodash';

export const locales = [
  'lt',
  'en',
];

// Environment key prefix
const envKeyPrefix = 'REACT_APP_';

// Environment
export const ENV = _.get(process.env, `${envKeyPrefix}ENV`, 'production');
// API URL
export const API_URL = _.get(process.env, `${envKeyPrefix}API_URL`);
// Google reCAPTCHA client key
export const RECAPTCHA_SITE_KEY = _.get(process.env, `${envKeyPrefix}RECAPTCHA_SITE_KEY`);
// Locale errors flag
export const SHOW_LOCALE_ERRORS = _.get(process.env, `${envKeyPrefix}SHOW_LOCALE_ERRORS`) != 'false';
// Enable option to export Excel from norm service
export const ENABLE_NORMS_EXCEL_EXPORT = _.get(process.env, `${envKeyPrefix}ENABLE_NORMS_EXCEL_EXPORT`) == 'true';
// Enable multilanguage (language switching)
export const ENABLE_MULTILANGUAGE = _.get(process.env, `${envKeyPrefix}ENABLE_MULTILANGUAGE`) == 'true';
// Google tracking ID
export const GOOGLE_TRACKING_ID = _.get(process.env, `${envKeyPrefix}GOOGLE_TRACKING_ID`);
// Educational mode
export const EDU_MODE = _.get(process.env, `${envKeyPrefix}EDU_MODE`);
