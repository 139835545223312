import {
  SENDING_REQUEST,
  GET_CLASSIFIERS,
  GET_TEMPLATES,
  FAILURE,
  RESET_FORM,
} from './_constants';

const initialState = {
  isLoading: false,
  classifiers: [],
  templates: [],
  types: [
    { value:'string', label: 'Tekstas' },
    { value:'number', label: 'Skaičius' },
    { value:'formula', label: 'Formulė' },
    { value:'codeNorms', label: 'Normatyvų kodas' },
    { value:'icsCode', label: 'ICS kodas' },
    { value:'icsCodeTitle', label: 'ICS kodo pavadinimas' },
    { value:'classifierList', label: 'Klasifikatoriaus sąrašas' },
  ],
};

export const templateBuilder = (state = initialState, action) => {
  switch (action.type) {
    
    case RESET_FORM:
      return {
        ...initialState,
      };

    case SENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CLASSIFIERS:
      return {
        ...state,
        isLoading: false,
        classifiers: [...action.classifiers],
      };

    case GET_TEMPLATES:
      return {
        ...state,
        isLoading: false,
        templates: action.templates,
      };

    case FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state;
  }
}