import React from 'react';
import { withStyles } from '@material-ui/core';
import { Form } from 'react-final-form'
import _ from 'lodash';

import { FormField } from 'components';

import styles from './styles';
import fields from './fields';

const PermissionForm = props => {
  const {
    classes,
    permission,
    onSubmit,
  } = props;

  const validate = (values) => {
    let errors = {};

    // if (!values.name) {
    //   errors.name = 'Required';
    // }

    return errors;
  }

  return (
    <Form
      initialValues={permission}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form
          id="permissionForm"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          
          {_.map(fields, field => <FormField key={field.name} {...field}/>)}
          
        </form>
      )}
    />
  );
};

export default withStyles(styles)(PermissionForm);
