import { observable, action, decorate } from 'mobx';
import _ from 'lodash';
import {
  getTree,
  getPackages,
  getDetails,
  doCalculate,
  getExcelExport,
  getPriceSettings,
} from 'services/norms';
import {
  scrollToElement,
  blinkElement,
  b64toBlob,
  blobToFileDownload,
} from 'utils/helpers';
import * as normsTreeService from '../../_services';

class Store {
  type = 'calculations'

  // Observable
  data = []

  // Observeable
  touchedItems = []

  // Observable
  mismatchedItems = []

  // Observable
  priceSettings = []

  // Observable
  defaultPriceSettings = []

  // Observable
  applyPriceSettings = false

  // Observable
  loading = false

  // Observable
  version = null

  // Observable
  lastExpandedCodePath = null

  doRecalc = _.debounce((item) => this.recalculate(item), 3000)

  // Action
  reset = () => {
    this.data = [];
    this.touchedItems = [];
    this.loading = false;
    this.version = null;
    this.lastExpandedCodePath = null;
    this.priceSettings = [];
    this.applyPriceSettings = false;
    this.defaultPriceSettings = [];
    this.mismatchedItems = [];
  }

  // Action
  setData = data => {
    this.data = data;
  }

  // Action
  setTouchedItems(data) {
    this.touchedItems = data;
  }

  // Action
  addTouchedItem(item) {
    // Check if not already in
    if (_.find(this.touchedItems, ({ code }) => code === item.code)) return;

    this.touchedItems.push(item);

    return this.touchedItems;
  }

  // Action
  setLoading = loading => {
    this.loading = loading;
  }

  // Action
  setMismatchedItems = data => {
    this.mismatchedItems = data;
  }

  // Action
  setPriceSettings = priceSettings => {
    this.priceSettings = priceSettings;
  }

  // Action
  setDefaultPriceSettings = defaultPriceSettings => {
    this.defaultPriceSettings = defaultPriceSettings;
  }

  // Action
  setLastExpandedCodePath = lastExpandedCodePath => {
    this.lastExpandedCodePath = lastExpandedCodePath;
  }

  // Action
  setInitialQuantityCalc = quantityCalc => {
    this.quantityCalc = quantityCalc;
  }

  // Action
  setVersion = version => {
    this.version = version;
    this.touchedItems = [];

    if (version) {
      this.loadData();
    }
  }

  init = async (overridePriceSettings = null) => {
    await this.loadPriceSettings(overridePriceSettings);
  }

  loadPriceSettings = async (overridePriceSettings = null) => {
    const priceSettings = overridePriceSettings || await getPriceSettings();
    console.log({ priceSettings, overridePriceSettings })
    this.setPriceSettings(priceSettings);
    this.setDefaultPriceSettings(priceSettings);
  }

  applyDefaultPriceSettings = (items) => {
    const defaults = this.priceSettings;

    // Only selected items
    // const items = items.filter(i => i.isExporting);

    // Do not apply defaults if original value is 0 for these items
    const skipIfZeroCodes = ['k8', 'k9'];

    // All calculated items
    items.forEach(item => {
      try {
        item.details.priceSettings.forEach((setting, idx) => {
          const defaultValue = defaults.find(d => d.code === setting.code);

          const skip = skipIfZeroCodes.includes(setting.code) && setting.value === 0;
          if (!skip) {
            item.details.priceSettings[idx] = {
              ...item.details.priceSettings[idx],
              titleCalc: defaultValue.title,
              unitCalc: defaultValue.unit,
              valueCalc: defaultValue.value,
            };
          }
        });
      } catch (err) {
        console.log(`Cannot set price settings for ${item.code}`);
      }
    });

    return items;
  }

  applyQuantityCalc = (quantityCalc = null) => {
    this.setInitialQuantityCalc(quantityCalc);
  }

  recalcWithPriceSettings = async () => {
    this.setLoading(true);

    const items = this.applyDefaultPriceSettings(this.touchedItems);

    try {
      for (const item of items) {
        await this.recalculate(item);
      }
    } catch (err) {
      console.log(err);
    }

    this.setLoading(false);
  }

  loadData = async () => {
    this.setLoading(true);

    // const version = this.version.code;
    // const data = await getPackages({ version });
    const data = await getPackages({ type: this.type });

    this.setData(data);

    if (this.lastExpandedCodePath) {
      try {
        this.expandPath(this.lastExpandedCodePath.split(';'));
      } catch (err) {
        console.log('Cannot expand code path');
      }
    }

    this.setLoading(false);
  }

  // Action
  handleExpand = item => {

    item.expanded = !item.expanded;

    if (!item.expanded) {
      this.setLastExpandedCodePath(null);
    } else {
      this.setLastExpandedCodePath(item.codePath);
    }

    if (!item.expanded) {
      return;
    }

    this.loadNext(item);
  }

  loadNext = async (item) => {
    if (item.type && item.type === 'package') {
      return;
    }

    this.setLoading(true);

    const data = await getTree({ code: item.code, version: this.version.code });
    const hasChildren = data.length > 0;
    item.hasChildren = hasChildren;

    if (hasChildren) {
      const children = _.map(data, row => ({ ...row, parentTitle: item.title }));
      item.children = children;
    } else {
      this.loadDetails(item);
    }

    this.setLoading(false);
  }

  // Action
  handleParameterExpand = item => {
    item.expanded = !item.expanded;
  }

  // Action
  updateItemWithDetails = (item, details) => {
    item.details = details;
  }

  loadDetails = async (item, forceLoad = false) => {
    if (item.details && !forceLoad) return;

    this.setLoading(true);

    let details = await getDetails({ code: item.code, version: this.version.code, type: this.type });
    let title = `${item.title} (${item.unit})`;

    if (item.code.indexOf('#') > 0) {
      title = `${item.parentTitle} ${title}`;
    }

    if (this.quantityCalc !== null) {
      item.quantityCalc = this.quantityCalc;
    }

    title = `${item.code} ${title}`;

    details.title = title;
    details.details = item.description;

    this.updateItemWithDetails(item, details);

    this.setLastExpandedCodePath(details.codePath);
    console.log({ item })
    await this.recalculate(item);

    // TODO improve to remove the need for 2 recalculate calls
    const updatedItem = this.applyDefaultPriceSettings([item])[0];
    await this.recalculate(updatedItem);


    this.setLoading(false);

    this.addTouchedItem(item);
  }

  expandPath = async (codes, isEmbeded = false) => {
    this.setLoading(true);

    // Build collection - package dictionary for faster search
    let collectionDict = {};
    _.forEach(this.data, item => {
      if (item.type === 'package') {
        _.forEach(item.children, child => {
          if (!collectionDict[child.code]) {
            collectionDict[child.code] = item;
          }
        });
      }
    });

    let rootItem = null;
    let loop = true;
    let codesToLoop = codes;

    // Sequentionally loop through codes and expand branches
    while (loop) {
      const code = _.first(codesToLoop);
      if (!rootItem) {
        rootItem = collectionDict[code];
      }

      const child = _.find(rootItem.children, { code });
      rootItem.expanded = true;
      if (child) {
        await this.loadNext(child);
        rootItem = child;
        rootItem.expanded = !isEmbeded;
      }

      if (codesToLoop.length > 1) {
        codesToLoop = _.slice(codesToLoop, 1);
      } else {
        loop = false;
        const el = document.querySelector(`.code-${code.replace('#', '')}`);
        if (el) {
          scrollToElement(el, isEmbeded);
          blinkElement(el, isEmbeded ? 5000 : null);
        }
      }
    }

    this.setLoading(false);
  }

  // Reset item
  resetItem = async (item) => {
    item.quantityCalc = 1;
    await this.loadDetails(item, true);
  }

  // Calculations
  recalculate = async (item) => {
    if (!item.details) {
      return await this.loadDetails(item);
    }

    this.setLoading(true);

    const {
      quantityCalc,
      priceCalc,
      amountCalc,
      totals,
      details,
      hasZeroNormOrPrice,
    } = await doCalculate(item, this.version?.version);

    item.quantityCalc = quantityCalc;
    item.priceCalc = priceCalc;
    item.amountCalc = amountCalc;
    item.totals = totals;
    item.hasZeroNormOrPrice = hasZeroNormOrPrice;

    this.updateItemWithDetails(item, details);

    this.setLoading(false);
  }

  // Export to Excel
  exportToExcel = async (type, priceSettings) => {
    this.setLoading(true);

    const items = this.touchedItems.filter(i => i.isExporting);

    try {
      const base64Response = await getExcelExport({
        type,
        items,
        version: this.version,
        priceSettings,
        mismatchedResources: this.mismatchedItems,
      });

      this.downloadFile('normatyvu-skaiciavimai.xlsx', base64Response);
    } catch (err) {
      console.log(err);
    }

    this.setLoading(false);
  }

  downloadFile = (name, base64Data) => {
    const blob = b64toBlob(base64Data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

    blobToFileDownload(name, blob);
  }

  downloadCollection = async (code, version) => {
    this.setLoading(true);
    let response;

    try {
      response = normsTreeService.downloadCollection(code, version);
    } catch (err) {
      console.log(err);
    }

    this.setLoading(false);
    return response;
  }

  checkMismatchingItems = () => {
    const items = this.touchedItems.filter(i => i.isExporting);

    const uniqueResourceMap = {};
    for (const item of items) {
      for (const resource of _.get(item, 'details.resources', [])) {
        const existingItem = uniqueResourceMap[resource.code];

        if (!existingItem) {
          uniqueResourceMap[resource.code] = {
            ...resource,
            matched: true,
            values: [
              {
                code: item.code,
                title: item.title,
                value: resource.priceCalc,
              },
            ],
          };
          continue;
        }

        if (existingItem.priceCalc !== resource.priceCalc) {
          existingItem.matched = false;
          existingItem.values.push({
            code: item.code,
            title: item.title,
            value: resource.priceCalc,
          });
        }
      }
    }

    const mismatchedItems = Object.values(uniqueResourceMap).filter(r => !r.matched)
      .map(r => ({ ...r, priceCalc: r.price }));
    this.setMismatchedItems(mismatchedItems);
    return mismatchedItems.length;
  }
}

decorate(Store, {
  data: observable,
  touchedItems: observable,
  loading: observable,
  version: observable,
  mismatchedItems: observable,
  lastExpandedCodePath: observable,
  priceSettings: observable,
  defaultPriceSettings: observable,
  reset: action,
  setData: action,
  setTouchedItems: action,
  addTouchedItem: action,
  setLoading: action,
  setVersion: action,
  setPriceSettings: action,
  setInitialQuantityCalc: action,
  applyQuantityCalc: action,
  setDefaultPriceSettings: action,
  setLastExpandedCodePath: action,
  setMismatchedItems: action,
  handleExpand: action,
  handleParameterExpand: action,
  updateItemWithDetails: action,
  applyDefaultPriceSettings: action,
});

export default new Store;