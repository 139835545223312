import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { show, create, update, remove } from 'services/news';
import { toast } from 'utils/helpers';
import { Dashboard as DashboardLayout } from 'layouts';
import { FormField2 as FormField, CommonPanel } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    maxWidth: '1200px',
  }
}));

const NewsForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);
  // Take ID from URL parameter :id described in _routes.js file
  // If URL ends with `/create` the ID will be undefined
  const { id } = useParams();
  

  const loadRecord = async (id) => {
    setLoading(true);
    
    try {
      const data = await show(id);
      setRecord(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašą'), 'error');
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!loading && !record._id && id) loadRecord(id);
  }, []);

  const submit = async () => {
    try {
      if (!id) {
        await create(record);
      } else {
        await update(id, record);
      }

      toast(t('Išsaugota'), 'success');
      history.push('/news');
    } catch (e) {
      console.error(e);
      toast(t('Nepavyko išsaugoti'), 'error');
    }
  };

  const handleChange = ({ name, value }) => {
    setRecord({
      ...record,
      [name]: value,
    });
  };

  const onDelete = async (recordId) => {
    if (window.confirm(t('Ar tikrai norite pašalinti naujieną?'))) {
      try {
        await remove(recordId);
        
        toast(t('Pašalinta'), 'success');
        history.push('/news');
      } catch (err) {
        console.log(err);
      }
    }
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(4, t('Pavadinimas per trumpas'))
      .required(t('Užpildykite lauką')),
    content: Yup.string()
      .required(t('Užpildykite lauką')),
  });

  const renderForm = () => (
    <CommonPanel
      footer={(
        <div>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={submit}
          >
            {t('Išsaugoti')}
          </Button>
          {id ? 
            <Button
              visibility={'hidden'}
              style={{ 'marginLeft': '25px', 'backgroundColor': '#cb1a31' }}
              color="primary"
              variant="contained"
              onClick={() => onDelete(record._id)}
            >
              {t('Ištrinti')}
            </Button>
            : null}
        </div>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormField
            label={t('Pavadinimas')}
            name="title"
            value={record?.title || ''}
            onChange={handleChange}
          />
        
          <FormField
            label={t('Data')}
            name="publishedAt"
            value={record?.publishedAt || ''}
            onChange={handleChange}
            type="date"
          />
          
          <FormField
            label={t('Aprašymas')}
            name="content"
            value={record?.content || ''}
            onChange={handleChange}
            multiline
            rows={10}
          />
        </Grid>
      </Grid>
    </CommonPanel>
  );

  return (
    <DashboardLayout 
      isContentLoading={loading}
      title={t('Naujienos')}      
      breadcrumbs={[
        { title: t('Administravimas') },
        { title: t('Naujienos'), to: '/news' },
      ]}
    >
      <div className={classes.root}>
        {renderForm()}
      </div>
    </DashboardLayout>
  )
};

export default NewsForm;