import documentConstants from './_constants';
import { documentService } from './_services';
import { history } from 'utils/history';
import { toast } from 'views/App/_actions';

const listPath = '/documents';

export const getAll = () => async (dispatch) => {
  dispatch({ type: documentConstants.GETALL_REQUEST });

  try {
    const documents = await documentService.getAll();

    dispatch({ type: documentConstants.GETALL_SUCCESS, documents });
  } catch (error) {
    dispatch({ type: documentConstants.GETALL_FAILURE, error });
  }
}

export const getOne = (id) => async (dispatch) => {
  dispatch({ type: documentConstants.GETONE_REQUEST });

  try {
    const document = await documentService.getOne(id);

    dispatch({ type: documentConstants.GETONE_SUCCESS, document });
  } catch (error) {
    dispatch({ type: documentConstants.GETONE_FAILURE, error });
  }
}

export const updateOne = (details) => async (dispatch) => {
  dispatch({ type: documentConstants.UPDATE_REQUEST });

  try {
    await documentService.updateOne(details);

    // Updated successfully
    dispatch({ type: documentConstants.UPDATE_SUCCESS });
    history.push(listPath);
    toast('Updated successfully', 'success');
  } catch (error) {
    // Update failed
    dispatch({ type: documentConstants.UPDATE_FAILURE });
    toast('Failed to update', 'error');
  }
}

export const createOne = (details) => async (dispatch) => {
  dispatch({ type: documentConstants.CREATE_REQUEST });

  try {
    await documentService.createOne(details);

    // document created successfully
    dispatch({ type: documentConstants.CREATE_SUCCESS });
    history.push(listPath);
    toast('Created successfully', 'success');
  } catch (error) {
    // document create failed
    dispatch({ type: documentConstants.CREATE_FAILURE });
    toast('Failed to create', 'error');
  }
}

export const resetForm = () => (dispatch) => {
  dispatch({ type: documentConstants.RESET_FORM });
}
