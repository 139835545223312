export default [
  {
    name: 'title',
    label: 'Pavadinimas',
    type: 'text',
  },
  {
    name: 'code',
    label: 'Kodas',
    type: 'text',
    required: true,
  },
]