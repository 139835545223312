import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import { getReport } from 'views/NtkViewer/_actions';
import { CommonPanel, FileLink } from 'components';
import * as dataService from 'views/RecoveryPrice/shared/_services';
import { blobToFileDownload } from 'utils/helpers';

import styles from './styles';

class NtkResults extends Component {

  renderMaterials({ materials, variants, engineeringRanges }) {
    if (engineeringRanges) {
      return _.map(_.keys(engineeringRanges), range => {
        return (
          <TableRow key={range}>
            <TableCell className="headcol">{range}</TableCell>
            {_.map(variants, variant => {
              return (
                <TableCell key={`${range}-${variant}`}>{engineeringRanges[range][variant]}</TableCell>
              );
            })}
          </TableRow>
        );
      })
    }

    if (materials) {
      return _.map(materials, ({ type, values }) => (
        <TableRow key={type}>
          <TableCell className="headcol">{type}</TableCell>
          <TableCell colSpan={variants.length}>{values.join(', ').toLowerCase()}</TableCell>
        </TableRow>
      ));
    }
  }

  handlePrint = async ({ type, params, values, fileType }) => {
    const { t, dispatch } = this.props;

    try {
      if (this.props.type === 'Inv planavimas') {
        const data = await dataService.getNtkReport({ type, params, values, fileType });
        const { fileName, contentType, content } = data;

        var bytes = new Uint8Array(content);
        const blob = new Blob([bytes], { type: contentType });

        blobToFileDownload(fileName, blob);
      } else {
        dispatch(getReport({ type, params, values, fileType }));
      }
    } catch (err) {
      console.error(err);
      window.alert(t('Įvyko klaida'));
    }
  };

  getPrintOptions() {
    if (this.props.type === 'NTK pastatai') {
      const printOptions = {
        type: 'buildingReport',
        params: ['hcode', 'ver'], 
        values: [this.props.combinations[0].code, this.props.version],
      }
      return printOptions;
    }
    if (this.props.type === 'NTK inzineriniai') {
      const printOptions = {
        type: 'engineeringBuildingReport',
        params: ['hcode', 'ver', 'combi'], 
        values: [this.props.buildingType.code, this.props.version, _.uniq(_.map(this.props.combinations, combi => combi.code)).join(';')],
      }
      return printOptions;
    }
    if (this.props.type === 'Inv planavimas') {
      const printOptions = {
        type: 'economicIndicatorsReport',
        params: ['hcode', 'ver', 'combi'], 
        values: [this.props.buildingType.code, this.props.version, _.uniq(_.map(this.props.combinations, combi => combi.code)).join(';')],
      }
      return printOptions;
    }
    const printOptions = {
      type: null,
      params: ['hcode', 'ver', 'combi'], 
      values: [this.props.buildingType.code, this.props.version, _.uniq(_.map(this.props.combinations, combi => combi.code)).join(';')],
    };
    return printOptions;
  }

  renderPrintButtons() {
    const { t } = this.props;
    const printOptions = this.getPrintOptions();

    return(
      <div style={{display: 'flex', paddingBottom: '8px'}}>
        <Button style={{marginLeft: 'auto'}} variant="outlined" color="primary" onClick={() => this.handlePrint({...printOptions, fileType: 'PDF'})}>
          {t('Spausdinti (PDF)')}
        </Button>
        <Button style={{marginLeft: '20px'}} textAlign="center" variant="outlined" color="primary" onClick={() => this.handlePrint({...printOptions, fileType: 'XLSX'})}>
          {t('Spausdinti (Excel)')}
        </Button> 
      </div>
    );
  }

  renderTable() {
    const { data, materials, buildingType, type, combinations, files, classes, t } = this.props;
    const isBuilding = !_.includes(['ntk inzineriniai', 'inv planavimas'], type.toLowerCase());
    console.log({ files })
    // Collect unique variants (defines column count)
    const variants = _.map(data.variants, item => item.range);

    if (!variants || variants.length == 0) {
      return (
        <div className={classes.noData}>
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            {t('Nėra duomenų pagal pasirinktus parametrus')}
          </Typography>
        </div>
      );
    }

    let engineeringRanges = {};
    let uniqueCombinationCodes = [];
    // Get engineering ranges (for engineering structures only)
    if (_.filter(combinations, item => item.type).length) {
      _.forEach(combinations, ({ code, type, value }) => {
        if (!engineeringRanges[type]) {
          engineeringRanges[type] = {};
        }

        engineeringRanges[type][code] = value;
        uniqueCombinationCodes.push(code);
      });
    } else {
      engineeringRanges = null;
    }    
    uniqueCombinationCodes = _.uniq(uniqueCombinationCodes);
    
    let prices = [];
    let coefficients = {};
    let correctionValues = {};
    let constructions = {};
    let depreciation = '';
    let engineeringDepreciation = [];
    let count = 0; // To prevent non-unique index error in rendering

    _.forEach(variants, (variant) => {
      // Get prices per variant
      const item = _.find(data.variants, { range: variant });
      const price = item.price;
      const tableSuffix = item.tableSuffix;
      prices.push({ variant, price, tableSuffix });

      // Get coefficients per variant
      _.forEach(item.coefficients, ({ title, value }) => {
        if (value) {
          if (coefficients[title]) {
            coefficients[title].push({ variant, value, key: count });
          } else {
            coefficients[title] = [{ variant, value, key: count }];
          }
          count++;
        }
      });

      // Get correction values per variant
      _.forEach(item.coefficients, ({ title, price }) => {
        if (price) {
          if (correctionValues[title]) {
            correctionValues[title].push({ variant, price, key: count });
          } else {
            correctionValues[title] = [{ variant, price, key: count }];
          }
          count++;
        }
      });

      // Get constructions per variant
      _.forEach(item.constructions, ({ title, value }) => {
        if (constructions[title]) {
          constructions[title].push({ variant, value, key: count });
        } else {
          constructions[title] = [{ variant, value, key: count }];
        }
        count++;
      });

      // Get depreciation string
      _.forEach(item.depreciation, ({ title, value }) => {
        if (type.toLowerCase() == 'ntk pastatai') {
          depreciation = depreciation ? `${depreciation} ${title} - ${value}%;` : `${title} - ${value}%;`;
        }
        if (type.toLowerCase() == 'ntk inzineriniai') {
          engineeringDepreciation.push(`${value}%`);
          count++;
        }
      });
    });


    return (
      // <div style={{ position: 'relative', }}>
      <div className="ntk-table">
        <Table size="small" className={classes.table}>
          <TableBody>
            {/* Selection details */}
            <TableRow className={classes.rowSeparator}>
              <TableCell className="headcol bg-grey">{t('Statinio paskirtis')}</TableCell>
              <TableCell colSpan={variants.length}>
                {buildingType ? (buildingType.titleFull || buildingType.title) : '-'}
              </TableCell>
            </TableRow>

            {/* Pricing table */}
            {type.toLowerCase() != 'inv planavimas' ? isBuilding ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">{t('Kainyno nuoroda')}</TableCell>
                <TableCell colSpan={variants.length}>{data.pricingTable}</TableCell>
              </TableRow>
            ) : (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">
                  {t('Kainyno nuoroda')} 
                </TableCell>
                <TableCell colSpan={prices.length}>
                  {data.pricingTable}
                </TableCell>
                {/* {_.map(prices, ({ tableSuffix }) => <TableCell key={tableSuffix} />)} */}
              </TableRow>
            ) : null}

            {files ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell  className="headcol bg-grey">&nbsp;</TableCell>
                <TableCell colSpan={variants.length}>
                  {_.map(files, ({ fileName, contentType, content }) => (
                    <span key={fileName} style={{ marginRight: 10 }}>
                      <FileLink
                        fileName={fileName}
                        contentType={contentType}
                        content={content}
                      />
                    </span>
                  ))}
                </TableCell>
              </TableRow>
            ) : null}

            {/* Constructions */}
            <TableRow className={classes.rowSeparator}>
              <TableCell className="headcol bg-grey">{t('Konstrukcijos')}</TableCell>
              <TableCell colSpan={variants.length}>&nbsp;</TableCell>
            </TableRow>

            {this.renderMaterials({ materials, variants, engineeringRanges })}

            {/* Depreciation */}
            {depreciation || engineeringDepreciation ? 
              (type.toLowerCase() == 'ntk pastatai' ? (
                <TableRow>
                  <TableCell align="right" style={{paddingRight: 4}} className="headcol">
                    <strong>{t('Statinio nusidevėjimas per metus kai sienų medžiaga')}</strong>
                  </TableCell>
                  <TableCell colSpan={variants.length} style={{verticalAlign: 'bottom'}}>{depreciation}</TableCell>
                </TableRow>
              ) : type.toLowerCase() == 'ntk inzineriniai' ? (
                  <TableRow>
                    <TableCell align="right" style={{paddingRight: 4}} className="headcol">
                      <strong>{t('Statinio nusidevėjimas per metus')}</strong>
                    </TableCell>
                    {_.map(engineeringDepreciation, (value) => (
                      value != null ? <TableCell>{value}</TableCell> : <TableCell>&nbsp;</TableCell>
                    ))}
                  </TableRow>
                ) : null
              ) : null
            }
            
            {/* Amounts and prices */}
            {isBuilding ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">
                  {t('Statybos kaina')}
                </TableCell>
                <TableCell colSpan={variants.length}>&nbsp;</TableCell>
              </TableRow>
            ) : null}

            {isBuilding ? (
              <TableRow>
                <TableCell  className="headcol">{t('Statinio tūris')}, m<sup>3</sup></TableCell>
                {_.map(prices, ({ variant }) => (
                  variant != null ? <TableCell key={variant}>{variant}</TableCell> : null
                ))}
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell className="headcol"><strong>{t('Statybos kaina su PVM')}, EUR/{buildingType.unit}</strong></TableCell>
              {_.map(prices, ({ variant, price }) => (
                variant != null ? <TableCell key={variant + price}><strong>{price}</strong></TableCell> : null
              ))}
            </TableRow>

            {/* Coefficients */}
            {_.keys(coefficients).length ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">{t('Statinio kainos pataisos koeficientai')}</TableCell>
                <TableCell colSpan={variants.length}>&nbsp;</TableCell>
              </TableRow>
            ) : null}

            {_.map(_.keys(coefficients), (title) => (
              <TableRow key={title}>
                <TableCell className="headcol">{title}</TableCell>
                {_.map(coefficients[title], ({ value, key}) => (
                  <TableCell key={key}>{value}</TableCell>
                ))}
              </TableRow>
            ))}

            {/* Correction values */}
            {_.keys(correctionValues).length ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">{t('Statinio kainos pataisos rodikliai')}</TableCell>
                <TableCell colSpan={variants.length}>&nbsp;</TableCell>
              </TableRow>
            ) : null}

            {_.map(_.keys(correctionValues), (title) => (
              <TableRow key={title}>
                <TableCell  className="headcol">{title}</TableCell>
                {uniqueCombinationCodes.length ? _.map(uniqueCombinationCodes, uniqueCode => {
                  const item = _.find(correctionValues[title], cv => cv.variant == uniqueCode);
                  
                  if (item) {
                    return <TableCell key={item.key}>{item.price}</TableCell>
                  } else {
                    return <TableCell key={uniqueCode} />
                  }
                }) : _.map(correctionValues[title], ({ key, price }) => (
                  <TableCell key={key}>{price}</TableCell>
                ))}
              </TableRow>
            ))}

            {/* Constructions */}
            {_.keys(constructions).length ? (
              isBuilding ? (
                <TableRow className={classes.rowSeparator}>
                  <TableCell className="headcol bg-grey">{t('Konstrukcijos')}</TableCell>
                  <TableCell colSpan={variants.length}>{t('Konstrukcijų lyginamasis svoris kainoje')} ({t('visa kaina')}=100%)</TableCell>
                </TableRow>
              ) : (
                <TableRow className={classes.rowSeparator}>
                  <TableCell className="headcol bg-grey">{t('Konstrukcijos')}</TableCell>
                  <TableCell colSpan={variants.length}>&nbsp;</TableCell>
                </TableRow>
              )
            ) : null}

            {_.map(_.keys(constructions), (title) => (
              <TableRow key={title}>
                <TableCell  className="headcol">{title}</TableCell>
                {isBuilding ? (
                  _.map(constructions[title], ({ key, value }) => (
                    <TableCell key={key}>{value}</TableCell>
                  ))
                ) : (
                  <TableCell  colSpan={variants.length} />
                )}
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </div>
      // </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {this.renderPrintButtons()}
        <CommonPanel
          title={this.props.title || t('Nekilnojamojo turto atkūrimo kaštų (statybinės vertės) kainynas')}
          noPadding
        >
          {this.renderTable()}
        </CommonPanel>
      </div>
    );
  }
}

export default compose(withStyles(styles), withTranslation())(NtkResults);
