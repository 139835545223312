export default () => ({
  root: {},
  wrap: {
    background: '#ffffff',
    padding: 0,
    maxWidth: '1200px',
  },
  deleteButton: {
    backgroundColor: '#dc004e',
    color: '#ffffff',
  }
});
