/* eslint-disable quotes */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormField2 as Field } from 'components';
import {
  TextField,
  IconButton,
} from '@material-ui/core';
import {
  Table,
  HeaderRow,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import {
  HeaderCellSelect,
  CellSelect,
  SelectClickTypes,
  useRowSelect,
} from "@table-library/react-table-library/select";
import { useTheme } from "@table-library/react-table-library/theme";
import { Virtualized } from "@table-library/react-table-library/virtualized";
import { useTree, CellTree, TreeExpandClickTypes } from "@table-library/react-table-library/tree";

const ROW_HEIGHT = 36;

import {
  TYPES,
} from './EstimateObjectForm';

const EstimateTreeTableSelect = ({ record, onEstimatesSelect }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [treeExpandedRowKeys, setTreeExpandedRowKeys] = useState([]);

  let data = { nodes: record.items };

  const onTreeChange = (action, state) => {
    // console.log({ action, state });
  }

  const tree = useTree(data, {
    onChange: onTreeChange,
  }, {
    // treeYLevel: 1,
    indentation: 0,
    clickType: TreeExpandClickTypes.ButtonClick,
    treeIcon: {
      size: '10px',
      // margin: '4px',
      iconRight: <>&#x25BA;</>,
      iconDown: <>&#x25BC;</>,
    },
  });

  const theme = useTheme({
    Table: `
        --data-table-library_grid-template-columns: 40px 110px 300px 80px 9% 9% 10% 85px 0px;
      `,
    BaseCell: `
      &:nth-of-type(2) {
        left: 0px;
      }

      &:nth-of-type(3) {
        left: 110px;
      }
      &:last-of-type{
        right: 0px;
      }
      
    `,
  });

  useEffect(() => {
    if (!treeExpandedRowKeys || !treeExpandedRowKeys?.length) return;

    tree.fns.onAddByIds(treeExpandedRowKeys);
    setTreeExpandedRowKeys(null);
  }, [treeExpandedRowKeys]);

  const renderType = (text, record) => {
    let value = typeLangMap[text] || text;

    if (text === TYPES.RATE && record.rateType) {
      value = `${t('Įk.')} ${rateTypeLangMap[record.rateType].toLowerCase()}`;
    } else if (text === TYPES.RESOURCE && record.resourceType) {
      value = resourceTypeLangMap[record.resourceType];
    }

    return (
      <span title={value}>{value}</span>
    )
  }

  const typeLangMap = {
    'estimate': t('Sąmata'),
    'part': t('Sąm. dalis'),
    'chapter': t('Skyrius'),
    'subchapter': t('Poskyris'),
    'group': t('El. grupė'),
    'element': t('Elementas'),
    'rate': t('Įkainis'),
    'resource': t('Resursas'),
  }

  const resourceTypeLangMap = {
    'work': t('Darbas'),
    'mechanism': t('Mechanizmas'),
    'material': t('Medžiaga'),
    'equipment': t('Įrenginys'),
    'other': t('Kita'),
  }

  const rateTypeLangMap = {
    'work': t('Darbas'),
    'mechanism': t('Mechanizmas'),
    'material': t('Medžiaga'),
    'equipment': t('Įrenginys'),
  }
  
  const select = useRowSelect(
    data,
    {
      onChange: onSelectChange,
    },
    {
      clickType: SelectClickTypes.ButtonClick,
    }
  );
  
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  data = {
    nodes: data.nodes.filter((item) =>
      item.code.toLowerCase().includes(search.toLowerCase()) || item.title.toLowerCase().includes(search.toLowerCase())
    ),
  };

  function onSelectChange(action, state) {
    state.ids.length > 0 ? onEstimatesSelect(state.ids) : onEstimatesSelect([]);
  }

  return (
    <>
      <div style={{ maxHeight: 350 }}>
        <TextField
          variant="outlined"
          width="sm"
          size="small"
          margin="normal"
          value={search}
          onChange={handleSearch}
          placeholder={t("Sąmatos paieška")}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title={t('Išvalyti')}
                aria-label="Clear"
                size="small"
                style={{ visibility: !!search ? 'visible' : 'hidden' }}
                onClick={() => setSearch('')}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          style={{ paddingBottom: 5, marginTop: 0 }}
        /> 
      </div>
      <div style={{ height: `${window.innerHeight - 550}px` }}>
        <Table
          data={data}
          layout={{
            isDiv: true,
            fixedHeader: true,
            custom: true,
          }}
          tree={tree}
          className="tree-table"
          theme={theme}
          select={select}
        >
          {(tableList) => (
            <Virtualized
              tableList={tableList}
              rowHeight={ROW_HEIGHT}
              header={() => (
                <HeaderRow>
                  {/* <HeaderCell hide>index</HeaderCell> */}
                  <HeaderCellSelect style={{ paddingLeft: 12, paddingTop: 10 }} />
                  <HeaderCell stiff pinLeft>{t('Kodas')}</HeaderCell>
                  <HeaderCell pinLeft>{t('Pavadinimas')}</HeaderCell>
                  <HeaderCell>{t('Mato vnt.')}</HeaderCell>
                  <HeaderCell>{t('Kiekis')}</HeaderCell>
                  <HeaderCell>{t('Kaina')}</HeaderCell>
                  <HeaderCell>{t('Suma')}</HeaderCell>
                  <HeaderCell>{t('Tipas')}</HeaderCell>
                  <HeaderCell pinRight />
                </HeaderRow>
              )}
              body={(item, index) => (
                <Row 
                  id={item._id} 
                  key={item._id}
                  item={item} 
                  style={item.type === "resource" && (item.norm == 0 || item.price == 0 || item.amount == 0) ? {color: "red"} : null} 
                  className={`tree-row-type-${item.type} row-key-${item._id}`}
                >
                  {!['resource', 'rate', 'element', 'group'].includes(item.type) ? <CellSelect style={{ paddingLeft: 12, paddingTop: 4 }} item={item} /> : <Cell />}
                  
                  {/* <Cell hide>{item.index}</Cell> */}
                  {!!item.nodes?.length ? (
                    <CellTree
                      item={item}
                      pinLeft
                      style={{ paddingLeft: `${item.level * 5}px` }}
                    >
                      <Cell
                        name="code"
                        value={item.code || '-'}
                        item={item}
                        style={{ border: 'none' }}
                      > 
                        {item.code}
                      </Cell>
                    </CellTree>
                  ) : (
                    <Cell
                      pinLeft
                      style={{ paddingLeft: `${item.level * 5 + 5}px` }}
                    >
                      <Cell
                        name="code"
                        value={item.code || '-'}
                        item={item}
                        style={{ border: 'none' }}
                      > 
                        {item.code} 
                      </Cell>
                    </Cell>
                  )}
                  <Cell pinLeft>
                    <Cell
                      name="title"
                      value={item.title}
                      item={item}
                      style={item.type === 'rate' ? { paddingRight: '20px', border: 'none' } : { border: 'none' }}
                    >
                      {item.title}
                    </Cell>
                  </Cell>
                  <Cell style={{ textAlign: 'center' }}>
                    <Cell
                      name="unit"
                      value={item.unit}
                      item={item}
                      style={{ border: 'none' }}
                    >
                      {item.unit}
                    </Cell>
                  </Cell>
                  <Cell style={{ textAlign: 'right' }}>
                    <Cell
                      name="quantity"
                      value={item.quantity}
                      item={item}
                      style={{ border: 'none' }}
                    >
                      {item.quantity}
                    </Cell>
                  </Cell>
                  <Cell style={{ textAlign: 'right' }}>
                    <Cell
                      name="price"
                      value={item.price}
                      item={item}
                      style={{ border: 'none' }}
                    >
                      {item.price}
                    </Cell>
                  </Cell>
                  <Cell style={{ textAlign: 'right' }}>
                    <Cell
                      name="amount"
                      value={item.amount}
                      item={item}
                      style={{ border: 'none' }}
                    >
                      {item.amount}
                    </Cell>
                  </Cell>
                  <Cell style={{ textAlign: 'center' }}>{renderType(item.type, item)}</Cell>
                </Row>
              )}
            />
          )}
        </Table>
      </div >
    </>
  )
}

export default EstimateTreeTableSelect;