import _ from 'lodash';
import { canAccess } from 'utils/helpers';
import { getAll as getAllAccounts } from 'services/accounts';

const accountOptions = async () => {
  const accounts = await getAllAccounts();

  return _.map(accounts, account => ({ 
    value: account._id, 
    label: account.user ? `${account.user.firstName} ${account.user.lastName} - ${account.position}` : account.position,
  }));
};


const typeOptions = () => Promise.resolve([
  { value: 'personal', label: 'Individualiai dirbantis asmuo' },
  { value: 'uab', label: 'UAB' },
  { value: 'ab', label: 'Akcinė bendrovė' },
  { value: 'ii', label: 'Individuali įmonė' },
  { value: 'vsi', label: 'Viešoji įstaiga' },
  { value: 'mb', label: 'Mažoji bendrija' },
  { value: 'edu', label: 'Mokymo įstaiga' },
  { value: 'other', label: 'Kita' },
]);

const countryOptions = () => Promise.resolve([
  { value: 'lt', label: 'Lietuva' },
  { value: 'lv', label: 'Latvija' },
  { value: 'ee', label: 'Estija' },
]);

const getFields = async () => {
  let fields = [
    {
      name: 'type',
      label: 'Tipas',
      type: 'select',
      options: typeOptions,
      required: true,
    },
    {
      name: 'name',
      label: 'Pavadinimas',
      type: 'text',
      required: true,
    },
    {
      name: 'department',
      label: 'Padalinys',
      type: 'text',
      required: false,
    },
    {
      name: 'code',
      label: 'Įmonės kodas',
      type: 'text',
      required: true,
    },
    {
      name: 'vat',
      label: 'PVM kodas',
      type: 'text',
    },
    {
      name: 'country',
      label: 'Šalis',
      type: 'select',
      options: countryOptions,
    },
    {
      name: 'email',
      label: 'El. paštas',
      type: 'text',
    },
    {
      name: 'phone',
      label: 'Telefonas',
      type: 'text',
    },
    {
      name: 'address',
      label: 'Adresas',
      type: 'text',
    },
    {
      name: 'website',
      label: 'Tinklapis',
      type: 'text',
    },
    {
      name: 'comment',
      label: 'Pastabos',
      type: 'text',
    },
  ];

  if (canAccess('administrator.layout')) {
    fields.push({ name: 'owner', label: 'Priklauso paskyrai', type: 'select', options: accountOptions });
  }

  return fields;
}

export default getFields;