import List from './components/List';
import Form from './components/Form';

export default [
  {
    key: 'list',
    component: List,
    path: '/tree-templates',
    public: false,
  },
  {
    key: 'create',
    component: Form,
    path: '/tree-templates/create',
    public: false,
  },
  {
    key: 'edit',
    component: Form,
    path: '/tree-templates/:id',
    public: false,
  }
];