import api from 'utils/api';

const servicePath = '/service-packages';

export const getAll = async () => {
  try {
    const response = await api.get(servicePath);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const getOne = async (id) => {
  try {
    const response = await api.get(`${servicePath}/${id}`);
    const records = response.data;

    return records;
  } catch (err) {
    console.log(err);
  }
}

export const create = async (details) => {
  try {
    const response = await api.post(servicePath, details);
    const record = response.data;

    return record;
  } catch (err) {
    console.log(err);
  }
}

export const update = async (id, details) => {
  try {
    const response = await api.put(`${servicePath}/${id}`, details);
    const record = response.data;

    return record;
  } catch (err) {
    console.log(err);
  }
}