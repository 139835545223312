import _ from 'lodash';
import {
  REQUEST,
  REQUEST_FAILED,
  VALUE_CHANGED,
  RESET_USER_INPUT,
  GET_TYPES_SUCCESS,
  GET_FILES_SUCCESS,
  GET_VERSIONS_SUCCESS,
  GET_HIERARCHY_SUCCESS,
  GET_MATERIALS_SUCCESS,
  TOGGLE_MATERIALS,
  SELECT_MATERIALS_SUCCESS,
  GET_COMBINATIONS_SUCCESS,
  GET_PRICES_SUCCESS,
  RESET_FORM,
  GET_REPORT_SUCCESS,
} from './_constants';
import * as dataService from 'views/RecoveryPrice/shared/_services';
import { blobToFileDownload } from 'utils/helpers';

export const resetForm = () => (dispatch) => {
  dispatch({ type: RESET_FORM });
}

export const valueChanged = (data, changeValueOnly = false) => (dispatch, getState) => {
  dispatch({ type: VALUE_CHANGED, data });
  
  if (changeValueOnly) {
    return;
  }

  switch (data.name) {

    case 'type':
      dispatch({ type: RESET_USER_INPUT });
      // Load versions by type
      dispatch(getVersions({ type: data.value }));
      break;

    case 'pricing':
      // dispatch({ type: RESET_USER_INPUT });
      dispatch({ type: VALUE_CHANGED, data: { name: 'buildingType', value: '' } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'selectedMaterials', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'combinations', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'materials', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'prices', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'files', value: null } });

      dispatch(getHierarchy());
      break;

    case 'buildingType':
      // dispatch({ type: RESET_USER_INPUT });
      dispatch({ type: VALUE_CHANGED, data: { name: 'selectedMaterials', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'initialMaterials', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'combinations', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'materials', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'prices', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'files', value: null } });

      setMaterialsByBuildingType(data, dispatch, getState().ntkViewer);
      dispatch(getFiles());
      break;

    case 'materials':
      dispatch({ type: VALUE_CHANGED, data: { name: 'selectedMaterials', value: data.value } });
      dispatch(getAvailableMaterials(data.value));
      break;

    default:
      break;
  }

}

export const getFiles = () => async (dispatch, getState) => {
  const { type, buildingType, pricing } = getState().ntkViewer;

  if (!buildingType || !type || !pricing) {
    return;
  }

  dispatch({ type: REQUEST });

  try {
    const params = {
      code: buildingType.code,
      version: pricing.code,
      type,
    };
    const files = await dataService.getFiles(params);
    
    dispatch({ type: GET_FILES_SUCCESS, files });
  } catch (error) {
    dispatch({ type: REQUEST_FAILED, error });
  }
}

export const getTypes = () => async (dispatch) => {
  dispatch({ type: REQUEST });

  try {
    const types = await dataService.getTypes();

    dispatch({ type: GET_TYPES_SUCCESS, types });
  } catch (error) {
    dispatch({ type: REQUEST_FAILED, error });
  }
}

export const getVersions = (params) => async (dispatch, getState) => {
  dispatch({ type: REQUEST });

  try {
    const versions = await dataService.getVersions(params);

    dispatch({ type: GET_VERSIONS_SUCCESS, versions });
    if (!getState().ntkViewer.pricing) {
      dispatch(valueChanged({ name: 'pricing', value: versions.find(version => version.code != null) }));
    }

    if (getState().ntkViewer.pricing) {
      dispatch({ type: VALUE_CHANGED, data: { name: 'buildingType', value: '' } });
      dispatch(getHierarchy());
    }
  } catch (error) {
    dispatch({ type: REQUEST_FAILED, error });
  }

  dispatch(toggleMaterials());
}

export const getHierarchy = () => async (dispatch, getState) => {
  dispatch({ type: REQUEST });

  const state = getState().ntkViewer;
  const params = { 
    type: state.type,
    version: state.pricing.code,
  };
  
  try {
    const hierarchy = await dataService.getHierarchy(params);

    dispatch({ type: GET_HIERARCHY_SUCCESS, hierarchy });
  } catch (error) {
    dispatch({ type: REQUEST_FAILED, error });
  }

  dispatch(toggleMaterials());
}

export const getMaterials = (params) => async (dispatch, getState) => {
  dispatch({ type: REQUEST });
  
  try {
    const materials = await dataService.getMaterials(params);

    dispatch({ type: GET_MATERIALS_SUCCESS, materials });

    const { type, buildingType, pricing } = getState().ntkViewer;
    if (type && type.toLowerCase() == 'ntk inzineriniai') {
      const params = { 
        code: buildingType.code,
        version: pricing.code,
        type,
      };
      dispatch(getCombinations(params));
    }
  } catch (error) {
    dispatch({ type: REQUEST_FAILED, error });
  }

  dispatch(toggleMaterials());
}

export const getReport = (params) => async (dispatch, getState) => {
  const { type, buildingType, pricing } = getState().ntkViewer;

  if (!buildingType || !type || !pricing) {
    return;
  }

  dispatch({ type: REQUEST });

  try {
    const data = await dataService.getNtkReport(params);
    const { fileName, contentType, content } = data;

    var bytes = new Uint8Array(content);
    const blob = new Blob([bytes], { type: contentType });

    blobToFileDownload(fileName, blob);
    dispatch({ type: GET_REPORT_SUCCESS, data });
  } catch (error) {
    dispatch({ type: REQUEST_FAILED, error });
  }
}

const toggleMaterials = () => (dispatch, getState) => {
  const {
    buildingType,
    pricing,
    type,
    initialMaterials,
  } = getState().ntkViewer;
  
  const show = buildingType
    && pricing
    && type
    && initialMaterials.length;

  dispatch({ type: TOGGLE_MATERIALS, show });
}

const getAvailableMaterials = (selectedMaterials) => async (dispatch, getState) => {
  dispatch({ type: REQUEST });

  try {
    const state = getState().ntkViewer;
    const params = { 
      code: state.buildingType.code,
      version: state.pricing.code,
      materials: selectedMaterials,
    };

    const materials = await dataService.getMaterials(params);

    dispatch({ type: SELECT_MATERIALS_SUCCESS, materials });
    if (selectedMaterials.length == 0) {
      dispatch({ type: VALUE_CHANGED, data: { name: 'selectedMaterials', value: null } });
      dispatch({ type: VALUE_CHANGED, data: { name: 'prices', value: null } });

      const { type, buildingType, pricing } = getState().ntkViewer;
      if (type && type.toLowerCase() == 'ntk inzineriniai') {
        const params = { 
          code: buildingType.code,
          version: pricing.code,
          type,
        };
        dispatch(getCombinations(params));
      }
    } else {
      params.type = state.type;
      dispatch(getCombinations(params));
    }
  } catch (error) {
    dispatch({ type: REQUEST_FAILED, materials: [] });
  }

  dispatch(toggleMaterials());
}

const getCombinations = (params) => async (dispatch, getState) => {
  dispatch({ type: REQUEST });

  try {
    let combinations;
    try {
      combinations = await dataService.getCombinations(params);
    } catch (err) {
      console.log('Combinations not found')
      combinations = [];
    }

    dispatch({ type: GET_COMBINATIONS_SUCCESS, combinations });

    if (params.type.toLowerCase() == 'ntk inzineriniai') {
      // Engineering structures
      const joinedCombi = _.uniq(_.map(combinations, combi => combi.code)).join(';');
      dispatch({ type: VALUE_CHANGED, data: {
        name: 'combinationCode',
        value: joinedCombi,
      }});


      dispatch(getPrices({ 
        code: getState().ntkViewer.buildingType.code,
        version: params.version,
        combinations: joinedCombi,
        type: params.type.toLowerCase(),
      }));
    } else {
      // Buildings

      // TODO hack, discuss this place with team
      if (combinations.length === 1) {

        // Pick first combination code
        const { code } = _.first(combinations);
        dispatch({ type: VALUE_CHANGED, data: {
          name: 'combinationCode',
          value: code,
        }});

        dispatch(getPrices({ code, version: params.version }));
      } else {
        dispatch({ type: VALUE_CHANGED, data: { name: 'prices', value: null } });
      }
    }
  } catch (error) {
    dispatch({ type: REQUEST_FAILED, error });
  }
}

const getPrices = (params) => async (dispatch) => {
  dispatch({ type: REQUEST });

  try {
    const prices = await dataService.getNtkPrices(params);

    dispatch({ type: GET_PRICES_SUCCESS, prices });
  } catch (error) {
    dispatch({ type: REQUEST_FAILED, error });
  }
}

/**
 * HELPERS
 */

const setMaterialsByBuildingType = ({ value }, dispatch, state) => {
  const params = {
    code: value ? value.code : null,
    version: state.pricing.code,
  };

  if (value) {
    dispatch(getMaterials(params));
  } else {
    dispatch({ type: GET_MATERIALS_SUCCESS, materials: [] });
  }
}