import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import styles from './styles';

import RecoveryPrice from 'views/RecoveryPrice';
import { getAll } from './_actions';

import RecoveryPriceTable from 'components/RecoveryPriceTable';
import { TrialHandler } from 'components';
import { getQueryVariable, getCurrentAccount } from 'utils/helpers';
import { EDU_MODE } from 'utils/config';

const SERVICE_CODE = 'ntk';

class RecoveryPriceList extends Component {
  state = { isTrial: false }

  componentDidMount() {
    if (getQueryVariable('trial')) {
      this.setState({ isTrial: true });
      return;
    }

    if (_.includes(getCurrentAccount().trialServices, SERVICE_CODE)) {
      this.setState({ showTrialMessage: true });
    }

    const { dispatch } = this.props;

    dispatch(getAll());
  }

  renderService() {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        <RecoveryPriceTable noTitle /> 
      </div>
    )
  }

  renderTrial() {

    return (
      <TrialHandler
        service={SERVICE_CODE}
      />
    )
  }

  renderTrialMessage() {
    const { t } = this.props;

    return EDU_MODE ? null : (
      <div style={{ margin: 10 }}>
        <Alert 
          variant="filled"
          severity="info"
          // onClose={() => this.setState({ showTrialMessage: false })}
        >
          {t('Jūs naudojate bandomąją (laikinai nemokamą) paslaugos versiją.')}
        </Alert>
      </div>
    )
  }

  render() {
    const { t } = this.props;
    const { isTrial, showTrialMessage } = this.state;
    
    return (
      <RecoveryPrice
        title={t('Atkuriamosios vertės skaičiavimai')}
        breadcrumbs={[
          { title: t('Paslaugos') },
          { title: t('Atkuriamosios vertės skaičiavimai') },
        ]}
        beforeMainContent={!isTrial && showTrialMessage && this.renderTrialMessage()}
      >
        
        {!this.state.isTrial ? this.renderService() : this.renderTrial()}

      </RecoveryPrice>
    );
  }
}

const mapStateToProps = ({ recoveryPriceList, dispatch }) => {
  return { 
    ...recoveryPriceList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(RecoveryPriceList);