import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  withStyles,
} from '@material-ui/core';
import { DataTable } from 'components';
import Permissions from 'views/Permissions';
import { getAll } from 'views/Permissions/_actions';

import styles from './styles';

class PermissionList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  onPermissionsSelect(permissions) {
    // eslint-disable-next-line no-console
    // console.log({ permissions });
  }
  prepareTableColumns() {
    const { t } = this.props;
    const columns = [
      'ID',
      'LINK',
      t('Pavadinimas'),
      t('Kategorija'),
      t('Kodas'),
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/permissions/${record._id}`);
      row.push(record.title);
      row.push(record.category ? record.category.title : null);
      row.push(record.code);

      return row;
    });

    return data;
  }

  renderPermissions() {
    const { classes, isLoading, list, t } = this.props;
    const { permissions } = list;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(permissions);
    
    return (
      <DataTable
        title={t('Priegos teisės')}
        columns={columns}
        data={data}
        tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
        createUrl="/permissions/create"
      />
    );
  }

  
  render() {
    const { classes, t } = this.props;
    const title = t('Prieigos teisės');

    return (
      <Permissions
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title }
        ]}
      >
        
        <div className={classes.content}>
          {this.renderPermissions()}
        </div>

      </Permissions>
    );
  }
}

const mapStateToProps = ({ permissions, dispatch }) => {
  return { 
    ...permissions,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(PermissionList);