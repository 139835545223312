import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
} from '@material-ui/core';

import { DataTable } from 'components';
import { canAccess } from 'utils/helpers';

import styles from './styles';

class UserAccounts extends Component {

  prepareTableColumns() {
    const { t } = this.props;

    const columns = [
      'ID',
      'LINK',
      t('Pareigos'),
      t('Įmonė'),
      t('Būsena'),
      t('Sukurta'),
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/accounts/${record._id}`);
      row.push(record.position);
      row.push(record.company ? record.company.name : null);
      row.push(record.status);
      row.push(moment.parseZone(record.createdAt).format('YYYY-MM-DD'));

      return row;
    });

    return data;
  }

  render() {
    const { classes, isLoading, records, current, t } = this.props;
    
    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    return (
      <DataTable 
        title={t('Vartotojo paskyros')}
        columns={columns} 
        data={data}
        {...(canAccess('accounts.create') && {createUrl: `/accounts/create?user=${current._id}`})}
      />
    );
  }
}

export default compose(withStyles(styles), withTranslation())(UserAccounts);