import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Paper,
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

import { FormField2 as Field, ClassifierPicker } from 'components';
import { ImportContext } from './ImportManager';
import { TreeTable } from 'components/TreeTable';

const MappingManager = () => {
  const {
    loading,
    record: contextRecord,
    config: contextConfig,
    classifiers: preloadedClassifiers,
    importActions,
  } = useContext(ImportContext);

  const targetFields = [
    { label: 'Elemento pavadinimas', value: 'fields.elementName' },
    { label: 'Elemento žymėjimas (TAG)', value: 'fields.elementTag' },
    { label: 'Funkcinės sistemos tipas', value: 'fields.functionalSystemType' },
    { label: 'Techninės sistemos tipas', value: 'fields.technicalSystemType' },
    { label: 'Elemento klasifikavimo tipas', value: 'fields.elementClassifierType' },
    { label: 'Projekto kodas (IFCProject)', value: 'fields.projectCode' },
    { label: 'Vieta (IFCSite)', value: 'fields.site' },
    { label: 'Objekto kodas (IFCBuilding)', value: 'fields.objectCode' },
    { label: 'Aukštas (IFCFloor)', value: 'fields.floor' },
    { label: 'Medžiagiškumas', value: 'fields.materials' },
    { label: 'Plotas (netto)', value: 'fields.areaNetto' },
    { label: 'Plotas (brutto) ', value: 'fields.areaBrutto' },
    { label: 'Tūris  (netto)', value: 'fields.volumeNetto' },
    { label: 'Tūris (brutto)', value: 'fields.volumeBrutto' },
    { label: 'Svoris (netto)', value: 'fields.weightNetto' },
    { label: 'Svoris (brutto)', value: 'fields.weightBrutto' },
    { label: 'Ilgis', value: 'fields.len' },
    { label: 'Aukštis', value: 'fields.height' },
    { label: 'Plotis', value: 'fields.width' },
    { label: 'Perimetras', value: 'fields.perimeter' },
    { label: 'Storis', value: 'fields.thickness' },
    { label: 'Sienelės storis ', value: 'fields.sideThickness' },
    { label: 'Diametras ', value: 'fields.diameter' },
    { label: 'Funkcinės savybės', value: 'fields.functionalQualities' },
    { label: 'Šilumos  perdavimo koeficientas', value: 'fields.heatTransferCoef' },
    { label: 'Varža', value: 'fields.resistance' },
    { label: 'Gaisrinė sauga (klasė)', value: 'fields.fireSafety' },
    { label: 'IP klasė', value: 'fields.ipClass' },
    { label: 'Spalva', value: 'fields.color' },
    { label: 'Kita', value: 'fields.other' },
  ];

  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);
  // const [targetFields, setTargetFields] = useState(contextConfig.targetFields); // Replace with coded
  // const headers = contextRecord.headers;
  const sample = contextRecord.sample;
  // const targetFieldMap = Object.fromEntries(targetFields.map(({ value, label }) => [value, label]));
  const estimateLevels = contextConfig.estimateLevels;
  const estimateLevelMap = Object.fromEntries(estimateLevels.map(({ value, label }) => [value, label]));
  const [headers, setHeaders] = useState(!!contextRecord.mapping.length ? contextRecord.mapping : contextRecord.headers.map((header, headerIdx) => {
    const sampleRowObject = {};
    sample.forEach((row, rowIdx) => {
      // sampleRowObject[`sample-${idx}`] = cell;
      sampleRowObject[`sample-${rowIdx}`] = row[headerIdx];
    });

    const item = {
      key: headerIdx,
      field: header,
      ...sampleRowObject,
    };

    return item;
  }));

  useEffect(() => {
    if (!dirty) return;

    // const payload = headers
    //   .filter(h => h.selected)
    //   .map(({ key, field, value, type, action, selected }) => ({ key, field, value, type, action, selected }));

    // if (!payload.length) return;

    importActions.setMappingResult(headers);
  }, [headers]);

  const handleHeaderChange = (idx, name, value) => {
    const newHeaders = [...headers];

    newHeaders[idx][name] = value;

    if (name === 'selected' && !value) {
      newHeaders[idx].action = null;
      newHeaders[idx].value = null;
      newHeaders[idx].type = '';
    }

    setHeaders(newHeaders);
    setDirty(true);
  };

  const defaultColumns = [
    {
      label: '',
      value: 'selected',
      width: 30,
      fixed: 'left',
      render: (text, record, idx) => (
        <div style={{ textAlign: 'center' }}>
          <input
            type="checkbox"
            checked={!!record.selected}
            onChange={(e) => handleHeaderChange(idx, 'selected', e.currentTarget.checked)}
          />
        </div>
      )
    },
    {
      label: 'Duomenų šaltinio stulpelis',
      value: 'field',
      width: 120,
      fixed: 'left',
    },
    {
      label: 'Susiejamas laukas',
      value: 'value',
      width: 120,
      fixed: 'left',
      render: (text, record, idx) => (
        <ClassifierPicker
          preloadedClassifiers={preloadedClassifiers}
          name={record.field}
          onChange={(classifier) => handleHeaderChange(idx, 'value', classifier?.code)}
          value={record.value}
          style={{ width: '100%', border: '1px solid grey', borderRadius: '5px' }}
          disabled={!record.selected}
        />
        // <select
        //   style={{ width: '100%' }}
        //   onChange={(e) => handleHeaderChange(idx, 'value', e.target.value)}
        //   value={record.value}
        //   disabled={!record.selected}
        // >
        //   <option value=""></option>
        //   {targetFields.map((field) => (
        //     <option key={field.value} value={field.value}>{field.label}</option>
        //   ))}
        // </select>
      )
    },
    // {
    //   label: 'Sąmatos struktūros laukai',
    //   value: 'type',
    //   width: 120,
    //   fixed: 'left',
    //   render: (text, record, idx) => (
    //     <select
    //       style={{ width: '100%' }}
    //       onChange={(e) => handleHeaderChange(idx, 'type', e.target.value)}
    //       value={record.type}
    //       disabled={!record.selected}
    //     >
    //       <option value=""></option>
    //       {estimateLevels.map((field) => (
    //         <option key={field.value} value={field.value}>{field.label}</option>
    //       ))}
    //     </select>
    //   )
    // },
    {
      label: 'Sumuoti',
      value: 'action',
      width: 60,
      fixed: 'left',
      render: (text, record, idx) => (
        <div style={{ textAlign: 'center' }}>
          <input
            type="checkbox"
            checked={record.action === 'sum'}
            onChange={(e) => handleHeaderChange(idx, 'action', e.currentTarget.checked ? 'sum' : null)}
            disabled={!record.selected}
          />
        </div>
      )
    },
    // {
    //   label: 'Vienodus grupuoti',
    //   value: 'group_same',
    //   width: 60,
    //   fixed: 'left',
    //   render: (text, record, idx) => (
    //     <div style={{ textAlign: 'center' }}>
    //       <input
    //         type="checkbox"
    //         checked={record.action === 'group'}
    //         onChange={(e) => handleHeaderChange(idx, 'group_same', e.currentTarget.checked ? 'group' : null)}
    //         disabled={!record.selected}
    //       />
    //     </div>
    //   )
    // },
  ];

  const createColumn = (field) => {
    const column = {
      title: field.label,
      dataIndex: field.value,
      key: field.value,
      width: field.width,
      ellipsis: field.ellipsis,
      render: field.render,
      // render: (text) => <span title={text}>{text}</span>,
      // editable: true,
      fixed: field.fixed,
    }

    return column;
  }
  const sampleColumns = sample.map((value, idx) => ({
    label: '',
    value: `sample-${idx}`,
    ellipsis: { showTitle: false },
    width: 60,
    render: (text) => <span title={text}>{text}</span>,
  }));
  const columns = [...defaultColumns, ...sampleColumns].map(field => createColumn(field))

  return (
    <div>
      {/* <div style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('Importuoti')}
        </Button>
      </div> */}

      <Paper style={{ margin: '20px auto 0px', padding: '10px 20px' }}>
        <TreeTable
          onCellChange={() => { }}
          columns={columns}
          data={headers}
        />

      </Paper>
    </div>
  )
}

export default MappingManager;