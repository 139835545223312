import React, { Component } from 'react';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import styles from './styles';

class TreeTable extends Component {


  renderTable() {
    const { classes, tree } = this.props;

    if (!tree) {
      return <span>Nėra duomenų</span>
    }

    return (
      <Table className={classes.table} size="small">
        {/* Table headers */}
        <TableHead>
          <TableRow>
            <TableCell>Stulpelis 1</TableCell>
            <TableCell>Stulpelis 2</TableCell>
            <TableCell>Stulpelis 3</TableCell>
            <TableCell>Stulpelis 4</TableCell>
            <TableCell>Stulpelis 5</TableCell>
          </TableRow>
        </TableHead>

        {/* Table body */}
        <TableBody>
          <TableRow>
            <TableCell>Eilutė 1</TableCell>
            <TableCell>Eilutė 1</TableCell>
            <TableCell>Eilutė 1</TableCell>
            <TableCell>Eilutė 1</TableCell>
            <TableCell>Eilutė 1</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Eilutė 1</TableCell>
            <TableCell>Eilutė 1</TableCell>
            <TableCell>Eilutė 1</TableCell>
            <TableCell>Eilutė 1</TableCell>
            <TableCell>Eilutė 1</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  render() {

    return (
      <div>
        {this.renderTable()}
      </div>
    )
  }
}

export default withStyles(styles)(TreeTable);