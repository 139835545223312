import { observable, action, decorate, toJS } from 'mobx';
import _ from 'lodash';
import { toast, isAdminCurrent } from 'utils/helpers';
import { history } from 'utils/history';
import {
  getOne,
  createOne,
  updateOne,
  getStatusTypes,
} from 'services/accounts';
import { getAll as getAllCompanies } from 'services/companies';
import { getAll as getAllUsers } from 'services/users';
import { getAll as getAllPermissionGroups } from 'services/permissionGroups';

const DEFAULT_OPTIONS = {
  status: [],
  users: [],
  companies: [],
  permissionGroups: [],
};

class Store {
  record = null
  isAdmin = false
  options = {...DEFAULT_OPTIONS}

  // Action
  reset = () => {
    this.record = null;
    this.isAdmin = false;
    this.options = {...DEFAULT_OPTIONS};
  }

  // Action
  setRecord = record => {
    this.record = record;
  }

  // Action
  setIsAdmin = () => {
    this.isAdmin = isAdminCurrent();
  }

  getOne = async (id) => {
    const data = await getOne(id, { simple: true });

    this.setRecord(data);
  }

  create = async () => {
    const record = toJS(this.record);

    try {
      await createOne(record);
      toast('Įrašas sukurtas', 'success');
      history.push('/accounts');
    } catch (err) {
      toast('Nepavyko išsaugoti', 'error');
    }
  }

  update = async (type = null) => {
    const record = toJS(this.record);
    
    try {
      await updateOne(record._id, record);
      toast('Įrašas atnaujintas', 'success');

      if (type == 'acceptUser') {
        this.isAdmin ? history.push('/accounts') : history.push('/companies/services');
        return;
      }

      history.push('/accounts');
    } catch (err) {
      toast('Nepavyko išsaugoti', 'error');
    }
  }

  // Action
  setOptions = (field, options) => {
    this.options[field] = options;
  }

  loadOptions = async () => {
    this.setIsAdmin();

    const status = await getStatusTypes();
    const statusOptions = _.map(_.keys(status), value => ({
      value,
      label: status[value],
    }));
    this.setOptions('status', statusOptions);

    const { isAdmin } = this;
    if (!isAdmin) return;

    const users = await getAllUsers();
    const companies = await getAllCompanies();
    const permissionGroups = await getAllPermissionGroups();

    const usersOptions = users.map(user => ({ 
      value: user._id, 
      label: `${user.firstName} ${user.lastName}`,
    }));

    const companiesOptions = companies.map(company => ({
      value: company._id, 
      label: `${company.name}`,
    }));

    const permissionGroupsOptions = permissionGroups.map(permissionGroup => ({
      value: permissionGroup._id, 
      label: `${permissionGroup.title}`,
    }));

    this.setOptions('users', usersOptions);
    this.setOptions('companies', companiesOptions);
    this.setOptions('permissionGroups', permissionGroupsOptions);
  }
}

decorate(Store, {
  record: observable,
  isAdmin: observable,
  options: observable,
  setRecord: action,
  setIsAdmin: action,
  setOptions: action,
  reset: action,
});

export default new Store;