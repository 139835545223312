import {
  REQUEST,
  REQUEST_FAILED,
  VALUE_CHANGED,
  RESET_USER_INPUT,
  TOGGLE_MATERIALS,
  GET_FILES_SUCCESS,
  GET_TYPES_SUCCESS,
  GET_VERSIONS_SUCCESS,
  GET_HIERARCHY_SUCCESS,
  GET_MATERIALS_SUCCESS,
  SELECT_MATERIALS_SUCCESS,
  GET_COMBINATIONS_SUCCESS,
  GET_PRICES_SUCCESS,
  GET_REPORT_SUCCESS,
  RESET_FORM,
} from './_constants';

const initialState = {
  isLoading: false,
  error: null,
  showResults: false,
  showMaterials: false,
  
  // Selected values
  type: '',
  pricing: '',
  buildingType: '',
  combinationCode: '',
  selectedMaterials: null,

  // Data
  types: null,
  versions: null,
  hierarchy: null,
  materials: null,
  initialMaterials: null,
  combinations: null,
  prices: null,
  files: null,
};

export const ntkViewer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    
    case REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        types: null,
      }

    case RESET_FORM:
      return {
        ...initialState,
      };

    case VALUE_CHANGED:
      return {
        ...state,
        [action.data.name]: action.data.value,
      };
    
    case RESET_USER_INPUT:
      return {
        ...state,
        showResults: false,
        pricing: '',
        buildingType: '',
        versions:null,
        hierarchy: null,
        materials: null,
        initialMaterials: null,
        selectedMaterials: null,
        combinations: null,
        prices: null,
        files: null,
      };

    case TOGGLE_MATERIALS:
      return {
        ...state,
        showMaterials: action.show,
      };

    case GET_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        types: action.types,
      }

    case GET_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        files: action.files,
      }

    case GET_VERSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        versions: action.versions,
      }
    
    case GET_HIERARCHY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hierarchy: action.hierarchy,
      }

    case GET_MATERIALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        materials: action.materials,
        initialMaterials: action.materials,
      };

    case SELECT_MATERIALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        materials: action.materials,
      };

    case GET_COMBINATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        combinations: action.combinations,
      };

    case GET_PRICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prices: action.prices,
      };
    
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        file: action.files,
      }

    default:
      return state;
  }
}