import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles, Button } from '@material-ui/core';
import { Form } from 'react-final-form';

import styles from './styles';
import fields from './fields';

import { CommonPanel } from 'components';
import { FormField } from 'components';
import ClassifierGroups from 'views/ClassifierGroups';
import { getOne, updateOne, createOne, resetForm } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { classifierGroupsForm } from './_reducers';
store.injectReducer('classifierGroupsForm', classifierGroupsForm);


class ClassifierGroupsForm extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;

    if (match.params.id) {
      dispatch(getOne(match.params.id));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetForm());
  }


  validate(values) {
    let errors = {};

    if (!values.code) {
      errors.code = this.props.t('Privalomas');
    }

    if (!values.title) {
      errors.title = this.props.t('Privalomas');
    }

    return errors;
  }

  onSubmit(values) {
    const { dispatch } = this.props;

    if (values._id) {
      // Update
      dispatch(updateOne(values));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  submitForm() {
    document
      .getElementById('classifierGroupForm')
      .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }


  render() {
    const { classes, record, t } = this.props;
    const title = record ? `${t('Grupės kodas')}: ${record.code}` : t('Nauja grupė');

    return (
      <ClassifierGroups
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Klasifikatoriaus grupės'), to: '/classifier-groups' },
          { title }
        ]}
      >
        
        <CommonPanel>
          <Form
            initialValues={record}
            onSubmit={this.onSubmit.bind(this)}
            validate={this.validate.bind(this)}
            render={({ handleSubmit }) => (
              <form
                id="classifierGroupForm"
                className={classes.form}
                onSubmit={handleSubmit}
              >
                
                {_.map(fields, field => <FormField key={field.name} {...field}/>)}
                
              </form>
            )}
          />

          <Button
            color="primary"
            variant="contained"
            onClick={event => this.submitForm(event)}
          >
            {t('Išsaugoti')}
          </Button>

        </CommonPanel>

      </ClassifierGroups>
    );
  }
}

const mapStateToProps = ({ classifierGroupsForm, dispatch }) => {
  return { 
    ...classifierGroupsForm,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(ClassifierGroupsForm);