import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';
import { twoOrMoreDecimals } from 'utils/helpers';
import { FormField2 } from 'components';


const useStyles = makeStyles({
  root: {},
  bold: {
    fontWeight: 'bold',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 12,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

    '& thead tr': {
      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& tbody tr': {
      '&:not(.no-hover):hover': {
        backgroundColor: '#d9d9d9',
        cursor: 'pointer',
      },

      '& td, th': {
        border: '1px solid #ddd',
        padding: 8,
      },
    },

    '& input[type="number"], input[type="text"]': {
      textAlign: 'right',
      border: '1px solid #316ac5',
      borderRadius: '4px',
      margin: '2px 0px',

      '&[disabled]': {
        border: '1px solid #cccccc',
      }
    },

    '& input[type="text"]': {
      borderColor: '#c4c4c4',
      padding: '6px 6px 7px',
      color: '#12161b',
      textAlign: 'left',
    },
  },
});

const NormCoefficients = ({ item, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const payload = item?.coefficients
    ? item
    : item?.details;

  const onInputChange = (name, value, customCoefIdx) => {
    payload.customCoefficients[customCoefIdx][name] = value;

    onChange(payload);
  }

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      onChange(payload, true);
    }
  }

  const onRemoveCoefficientClick = (idx) => {
    const coeffs = [...payload.customCoefficients];
    coeffs.splice(idx, 1);

    payload.customCoefficients = coeffs;
    onChange(payload, true);
  }

  const onAddCoefficientClick = (item) => {
    if (!payload.customCoefficients) {
      payload.customCoefficients = [];
    }

    const existsEmpty = _.find(payload.customCoefficients, i => !i.title) !== undefined;
    if (existsEmpty) return;

    payload.customCoefficients.push({
      code: `${payload.customCoefficients.length}-${_.random(9999)}`,
      title: null,
      selected: true,
      k1: 1,
      k2: 1,
      k3: 1,
    });

    onChange(payload, true);
  }


  if (!payload?.coefficients) return null;

  return (
    <>
      <Typography variant="caption" display="block" style={{ textAlign: 'right' }}>
        K1 - {t('darbo sąnaudos')},
        K2 - {t('mechanizmai')},
        K3 - {t('medžiagos')}
      </Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th colSpan={2}>{t('Koeficientų taikymo sąlygos')}</th>
            <th style={{ width: '8%' }}>K1</th>
            <th style={{ width: '8%' }}>K2</th>
            <th style={{ width: '8%' }}>K3</th>
          </tr>
        </thead>
        <tbody>
          {_.map(payload.coefficients, (coefficient, idxCoef) => (
            <tr key={`${idxCoef}-coefficient`}>
              <td style={{ textAlign: 'center' }}>
                <FormField2
                  type="checkbox"
                  name={`payload.coefficients[${idxCoef}].selected`}
                  value={coefficient.selected}
                  onChange={(e) => {
                    payload.coefficients[idxCoef].selected = e.value;

                    onChange(payload, true);
                  }}
                  naked
                  style={{ padding: 0 }}
                />
              </td>
              <td>{coefficient.title}</td>
              <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k1)}</td>
              <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k2)}</td>
              <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k3)}</td>
            </tr>
          ))}

          {payload.customCoefficients && payload.customCoefficients.length ? _.map(payload.customCoefficients, (coefficient, coefIdx) => (
            <tr key={coefficient.code}>
              <td style={{ textAlign: 'center' }}>
                <FormField2
                  type="checkbox"
                  name="selected"
                  value={coefficient.selected}
                  onChange={(e) => {
                    payload.customCoefficients[coefIdx].selected = e.value;

                    onChange(payload, true);
                  }}
                  naked
                  style={{ padding: 0 }}
                />
              </td>
              <td>
                <div>
                  <div style={{ display: 'inline-block', width: '80%' }}>
                    <FormField2
                      type="text"
                      name="title"
                      value={coefficient.title}
                      onChange={(e) => onInputChange(e.name, e.value, coefIdx, false)}
                      placeholder="Koeficiento pavadinimas..."
                      naked
                    />
                  </div>

                  <Button
                    variant="text"
                    onClick={() => onRemoveCoefficientClick(coefIdx)}
                    size="small"
                    style={{ display: 'inline-block', width: '20%', color: 'red' }}
                  >
                    Pašalinti
                  </Button>
                </div>
              </td>
              <td>
                <FormField2
                  type="number"
                  name="k1"
                  value={coefficient.k1}
                  onChange={(e) => onInputChange(e.name, e.value, coefIdx)}
                  onKeyDown={e => onInputKeyDown(e)}
                  min={0}
                  step={0.0001}
                  naked
                />
              </td>
              <td>
                <FormField2
                  type="number"
                  name="k2"
                  value={coefficient.k2}
                  onChange={(e) => onInputChange(e.name, e.value, coefIdx)}
                  onKeyDown={e => onInputKeyDown(e)}
                  min={0}
                  step={0.0001}
                  naked
                />
              </td>
              <td>
                <FormField2
                  type="number"
                  name="k3"
                  value={coefficient.k3}
                  onChange={(e) => onInputChange(e.name, e.value, coefIdx)}
                  onKeyDown={e => onInputKeyDown(e)}
                  min={0}
                  step={0.0001}
                  naked
                />
              </td>
            </tr>
          )) : null}
        </tbody>
        <tfoot>
          <tr className={classes.textCenter}>
            <td colSpan={5}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => onAddCoefficientClick(item)}
                style={{ marginTop: '10px' }}
              >
                {t('Pridėti koeficientą')}
              </Button>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  )
}

export default NormCoefficients;