import React from 'react';
import { Link } from '@material-ui/core';
import { blobToFileDownload } from 'utils/helpers';

class FileLink extends React.Component {

  onClick(e) {
    e.preventDefault();
    const { fileName, contentType, content } = this.props;
    
    var bytes = new Uint8Array(content);
    const blob = new Blob([bytes], {type: contentType});

    blobToFileDownload(fileName, blob);
  }

  render() {
    const { fileName } = this.props;

    return (
      <Link href="#" onClick={this.onClick.bind(this)}>
        {fileName}
      </Link>
    )
  }
}

export default FileLink;